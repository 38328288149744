import { Injectable } from '@angular/core';
import {
  Resolve,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router
} from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { SolutionType } from 'app/domain';
import { SolutionTypeService } from '../solution-type.service';
import { SolutionBuilderOptionsDataStore } from 'app/modules/solution-builder/services/solution-options-data-store.service';

/**
 * Resolver to grab the Solution Types from the server and store them.  If we have them cached,
 * we will return those.
 */
@Injectable({ providedIn: 'root' })
export class SolutionTypesResolver
  implements Resolve<Observable<SolutionType[]>> {
  constructor(
    private router: Router,
    private solutionTypeService: SolutionTypeService,
    private solutionDataStore: SolutionBuilderOptionsDataStore
  ) {}
  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<SolutionType[]> {
    return this.solutionTypeService.getSolutionTypes().pipe(
      tap((types: SolutionType[]) => {
        this.solutionDataStore.setSolutionTypes(types);
      }),
      catchError(() => {
        this.router.navigate(['/landing/errors'], {
          queryParams: {
            message:
              'There was an issue retrieving the Solutions for this page.'
          }
        });
        return throwError;
      })
    );
  }
}
