import {
  trigger,
  transition,
  style,
  animate,
  state
} from '@angular/animations';

export const slideInLeft = trigger('slideInLeft', [
  state('in', style({ opacity: 1, transform: 'translateX(0%)' })),
  transition(':enter', [
    style({ opacity: 0, transform: 'translateX(-30%)' }),
    animate('1s {{delay}} cubic-bezier(.8,-0.6,0.2,1.5)')
  ]),
  transition(':leave', animate(600, style({ opacity: 0 })))
]);
