import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'sb-requires-login-page',
  templateUrl: './requires-login-page.component.html',
  styleUrls: ['./requires-login-page.component.scss']
})
export class RequiresLoginPageComponent implements OnInit {
  message: string = ` Looks like you're not logged in.  This may be due to inactivity.  Please click the button below to log back in.  Please note that
  you should close this page and relaunch from CE.`;

  constructor(private activatedRoute: ActivatedRoute) {}

  ngOnInit(): void {
    const paramMap = this.activatedRoute.snapshot.queryParamMap;
    this.message = paramMap.get('message');
  }
}
