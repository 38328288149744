<div *ngIf="product">
  <!-- Item Tags-->
  <ul class="menu sb-product-tags">
    <li>
      <span class="sb-product-tag is-active" title="Product Number & HCPCS"
        >{{ product.categoryName | categoryName }} -
        {{ product.vendorProductNumber }}
        <span *ngIf="product.hcpcsCode1">({{ product | hcpcCodes }})</span>
      </span>
    </li>
  </ul>
  <div class="sb-selected-item" kendoTooltip>
    <header>
      <div>
        <h5 class="sb-product-item-name" [attr.title]="product.description">
          {{ product.description }}<br /><small
            class="sb-product-item-vendor"
            >{{ product.vendorName }}</small
          >
        </h5>
      </div>
      <div>
        <div class="sb-product-actions">
          <button
            *ngIf="editable && product.requiresCustomization()"
            class="sb-edit-custom-product-button"
            (click)="showCustomizationDialog(product)"
            [iconClass]="'fas fa-pencil'"
            [primary]="true"
            title="Customize Product"
            kendoButton
          ></button>
          <button
            *ngIf="
              editable && supportsNotes && !product.requiresCustomization()
            "
            (click)="showNoteDialog()"
            kendoButton
            [iconClass]="'fas fa-sticky-note'"
            title="Edit Note"
          ></button>
          <button
            *ngIf="updateable"
            (click)="updateItem()"
            kendoButton
            [iconClass]="'fas fa-pencil'"
            title="Update"
          ></button>
          <button
            *ngIf="editable"
            (click)="showConfirmRemoveProductDialog(product, removeDialog)"
            kendoButton
            class="sb-remove-product-button warning"
            [iconClass]="'fas fa-times'"
            title="Remove Product"
          ></button>
        </div>
      </div>
    </header>
    <div class="sb-selected-item-content">
      <div class="sb-product-information">
        <div>
          <label>Rank</label>
          <sb-rank-indicator [rank]="product.rank"></sb-rank-indicator>
        </div>
        <div *ngIf="showMsrp">
          <label>MSRP</label>
          <span>
            {{ product.msrp | currency }}
          </span>
        </div>
        <div *ngIf="showPricing">
          <label>Unit Cost</label>
          <span>
            {{ product.costPricePerUnit | currency }}
          </span>
        </div>

        <div *ngIf="showPricing">
          <label title="Expected Reimbursement">ER</label>
          <span>
            {{ product.expectedReimbursement | currency }}
          </span>
        </div>
        <div *ngIf="showPricing">
          <label>Margin</label>
          <span>
            {{
              (product.getMargin() ? product.getMargin() : 0) | percent: '1.2'
            }}
          </span>
        </div>
        <div *ngIf="!product.isBaseProduct">
          <span class="sb-quantity-meta">
            <label>Quantity</label>
            <div *ngIf="editable">
              <span>{{ product.defaultQuantity }}</span>
              <button
                kendoButton
                look="flat"
                [iconClass]="'fas fa-caret-up'"
                (click)="incrementQuantity()"
              ></button>
              <button
                kendoButton
                look="flat"
                [iconClass]="'fas fa-caret-down'"
                (click)="decrementQuantity()"
              ></button>
            </div>
            <span *ngIf="!editable">{{ product.defaultQuantity }}</span>
          </span>
        </div>
      </div>
    </div>
    <footer *ngIf="hasNotes()">
      <div class="sb-product-notes">
        <div *ngIf="product.externalNote">
          <label>External Note</label>
          <span class="note">{{ product.externalNote }}</span>
        </div>
        <div *ngIf="product.internalNote">
          <label>Internal Note</label>
          <span class="note">{{ product.internalNote }}</span>
        </div>
      </div>
    </footer>
  </div>
</div>

<ng-template #removeDialog>
  <p *ngIf="product.isBaseProduct">
    Removing the Base Model will remove its associated products and return you
    to the Base Model selection menu.
  </p>
  <p>
    Are you sure you want to remove
    <strong>{{ product.description }}</strong> from the solution?
  </p>
</ng-template>
