import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { environment } from 'environments/environment';
import {
  ClientDetail,
  ExpectedReimbursementRequest,
  ExpectedReimbursementResponse,
  ExpectedReimbursementInfo
} from 'app/domain/models/solution-builder/expected.reimbursement';
import { SolutionBuilderLoggerService } from 'app/domain/services/solution-builder-logger.service';

@Injectable({ providedIn: 'root' })
export class ExpectedReimbursementService {
  constructor(
    private http: HttpClient,
    private logger: SolutionBuilderLoggerService
  ) {}

  getExpectedReimbursement(
    request: ExpectedReimbursementInfo
  ): Observable<ExpectedReimbursementResponse> {
    if (
      !request ||
      !request.client ||
      !request.payers ||
      (request.payers && request.payers.length === 0)
    ) {
      this.logger.warn('Call to expected Reimbursement is missing information');
      return of(null);
    }

    return this.http.post<ExpectedReimbursementResponse>(
      `${environment.apiExpectedReimbursment}/${request.workOrderId}/estimate`,
      request
    );
  }
}
