import {
  Component,
  OnInit,
  Input,
  HostBinding,
  OnDestroy,
  AfterViewInit
} from '@angular/core';
import { FieldOption } from 'app/domain';
import { FormControl } from '@angular/forms';
import { v4 } from 'uuid';
import { SbBaseFieldComponent } from '../sb-base-field.component';
import { untilDestroyed } from 'app/core';

@Component({
  selector: 'sb-radio-group-field',
  templateUrl: './sb-radio-group-field.component.html',
  styleUrls: ['./sb-radio-group-field.component.scss']
})
export class SbRadioGroupFieldComponent extends SbBaseFieldComponent
  implements OnInit, OnDestroy, AfterViewInit {
  @HostBinding('class') class: string = 'sb-radio-group-field';

  @Input()
  options: Array<FieldOption>;

  @Input()
  horizontal: boolean;

  id: string = v4();

  selectedOption: FieldOption;

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.control.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe((value: string | number) => {
        if (this.options) {
          this.selectedOption = this.options.find(o => o.value === value);
        }
      });
  }
  ngAfterViewInit(): void {
    this.control.updateValueAndValidity();
  }

  ngOnDestroy(): void {}

  setValue(option: FieldOption): void {
    this.control.setValue(option.value);
    this.selectedOption = option;
  }
}
