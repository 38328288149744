<sb-selected-product-item
  *ngIf="asset.product"
  [product]="asset.product"
  [showPricing]="true"
  [showMsrp]="false"
  [updateable]="true"
  [editable]="true"
  (update)="editAsset()"
  (remove)="removeAsset()"
></sb-selected-product-item>
<sb-card *ngIf="!asset.product">
  <div class="grid-x grid-padding-x">
    <div class="small-11 cell">
      <h3>{{ asset.productIdAlternate }}</h3>
      NSM does not have an associated Product for this Asset.
    </div>
    <div class="small-1 cell align-self-middle">
      <button
        kendoButton
        (click)="editAsset()"
        class="is-round"
        [iconClass]="'fas fa-pencil'"
        title="Update"
      ></button>
      <button
        (click)="removeAsset()"
        kendoButton
        class="is-round warning"
        [iconClass]="'fas fa-times'"
        title="Remove Product"
      ></button>
    </div></div
></sb-card>
