import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpEvent } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import {
  Product,
  VendorFormLoadResults,
  VendorFormValidateResults,
  CeProductType,
  ProductSearchRequest
} from 'app/domain/models/solution-builder/models';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';
import * as _ from 'lodash';
import { ProductHelperService } from './product-helper.service';
import { ApiUrlBuilder } from './api-url.builder';
import { Cacheable } from 'ngx-cacheable';

@Injectable({
  providedIn: 'root'
})
export class ProductsService {
  constructor(
    private http: HttpClient,
    private productMapper: ProductHelperService
  ) {}

  @Cacheable({ maxCacheCount: 100 })
  getProductThreshold(itemGroup: string): Observable<CeProductType> {
    if (!itemGroup) {
      return of(null);
    }

    return this.http
      .get<CeProductType>(`${environment.apiProducts}/types/${itemGroup}`)
      .pipe(
        map((result: object) => {
          const productType = new CeProductType();
          productType.marginThreshold = result['nsm_marginthresholdpercentage'];
          productType.name = result['nsm_name'];
          productType.updMapping = result['nsm_updmapping'];
          productType.fnOMapping = result['nsm_fomappingid'];
          productType.id = result['nsm_producttypeid'];

          return productType;
        })
      );
  }
  searchProducts(searchString: string): Observable<Product[]> {
    let params = new HttpParams();
    if (searchString) {
      params = params.append('searchString', searchString);
    }

    return this.http
      .get<Product[]>(`${environment.apiProducts}/search`, { params })
      .pipe(
        map((result: Product[]) => {
          return this.productMapper.mapProducts(result);
        })
      );
  }

  getCompatibleProductsForBase(
    productNumber: string,
    searchString?: string
  ): Observable<Product[]> {
    let params = new HttpParams();
    if (searchString) {
      params = params.append('searchString', searchString);
    }

    return this.http
      .get<Product[]>(
        `${environment.apiProducts}/compatible/${productNumber}`,
        { params }
      )
      .pipe(
        map((result: Product[]) => {
          return this.productMapper.mapProducts(result);
        })
      );
  }

  searchCompatibleProductsForBase(
    product: Product,
    request: ProductSearchRequest,
    isAftermarket: boolean
  ): Observable<Product[]> {
    let urlBuilder: ApiUrlBuilder = new ApiUrlBuilder(product).addPathPart(
      `${environment.apiProducts}/${
        isAftermarket ? 'aftermarket' : 'compatible'
      }`
    );

    if (!isAftermarket) {
      urlBuilder = urlBuilder
        .includeVendorProductNumber()
        .includeProductNumber()
        .includeModel();
    } else {
      urlBuilder = urlBuilder.includeProductNumber();
    }

    let url = urlBuilder.addPathPart('search').build();

    return this.http.post<Product[]>(url, request).pipe(
      map((result: Product[]) => {
        return this.productMapper.mapProducts(result);
      })
    );
  }

  loadProductsFromFile(
    file: File
  ): Observable<HttpEvent<VendorFormLoadResults>> {
    const formData = new FormData();
    formData.append('file', file, file.name);
    return this.http.post<VendorFormLoadResults>(
      `${environment.apiProducts}/load`,
      formData,
      {
        reportProgress: true,
        observe: 'events'
      }
    );
  }

  validateImportFile(
    file: File
  ): Observable<HttpEvent<VendorFormValidateResults>> {
    const formData = new FormData();
    formData.append('file', file, file.name);
    return this.http.post<VendorFormValidateResults>(
      `${environment.apiProducts}/validate`,
      formData,
      {
        reportProgress: true,
        observe: 'events'
      }
    );
  }
}
