<div class="sb-container">
  <header class="sb-header">
    <div class="grid-x">
      <div class="small-7 cell">
        <ul class="sb-tab-menu">
          <li>
            <a class="is-active">Requires Login</a>
          </li>
        </ul>
      </div>

      <div class="small-5 cell align-self-middle"></div>
    </div>
  </header>
  <main class="sb-content">
    <!-- Page Header Section-->
    <header class="sb-content-header">
      <div class="grid-x align-middle">
        <div class="small-8 cell">
          <ul class="sb-breadcrumbs">
            <li class="is-parent"><span>Solution Builder</span></li>
            <li class="is-divider"><span>/</span></li>
            <li class="is-active"><span class="">Error</span></li>
          </ul>
          <h1 class="sb-content-heading">Uh-oh! An Error Occured!</h1>
        </div>
        <div class="small-4 cell text-right"></div>
      </div>
    </header>
    <!-- Error Details Section -->
    <section>
      <div class="grid-x">
        <div class="small-4 cell">
          <p>
            Looks like we've ran into an issue! Please check the console for
            more information. You can additionally contact an Administrator to
            help resolve your issue. If you do, please remain on the page.
          </p>
          <sb-callout *ngIf="message" [error]="true">
            {{ message }}
          </sb-callout>
        </div>
      </div>
    </section>
  </main>
</div>
