export class DocumentType {
  id: string;
  name: string;
  description: string;
  isSystem: boolean;
  requiresVendor?: boolean;
  requiresPayer?: boolean;
  isDeliveryDocument?: boolean;
}

export class UploadedDocument {
  azureFileName: string;
}

export class CeDocument {
  workOrder: string;
  documentTypes: string;
  uri: string;
  fileName: string;
  fileLength: number;
  dateTimeOffset: string;
  uploadedBy: string;
  uploadedDate: string;
  expirationDate: string;
  blobGuid: string;
}
