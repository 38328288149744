import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

/**
 * Simple service for writing logs to 'some' location.  For now, that's just the console.
 */
@Injectable({ providedIn: 'root' })
export class SolutionBuilderLoggerService {
  constructor() {}

  info(message: string): void {
    if (this.canLogToConsole()) {
      console.info(message);
    }
  }

  warn(message: string): void {
    if (this.canLogToConsole()) {
      console.warn(message);
    }
  }

  error(message: string, err?: any): void {
    console.error(message, err ? err : '');
  }

  debug(message: string, err?: any) {
    if (this.canLogToConsole()) {
      console.log(message, err ? err : '');
    }
  }

  private canLogToConsole(): boolean {
    return !environment.production;
  }
}
