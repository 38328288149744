import { Component, OnInit, AfterViewInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormControl,
  Validators
} from '@angular/forms';
import { DialogContentBase, DialogRef } from '@progress/kendo-angular-dialog';
import { Product } from 'app/domain';
import { SolutionBuilderLoggerService } from 'app/domain/services/solution-builder-logger.service';
import { CustomValidators } from 'app/domain/validators/custom.validators';
import { ToastrService } from 'ngx-toastr';
import { SolutionBuilderClient } from '../../services/solution-builder-client.service';

@Component({
  selector: 'sb-semi-custom-product-dialog-component',
  templateUrl: './semi-custom-product-dialog.component.html',
  styleUrls: ['./semi-custom-product-dialog.component.scss']
})
export class SbSemiCustomProductDialogComponent extends DialogContentBase
  implements OnInit, AfterViewInit {
  fgSemiCustomProduct: FormGroup;
  fcMsrp: FormControl = new FormControl('', Validators.required);
  fcCost: FormControl = new FormControl('', Validators.required);
  fcNotes: FormControl = new FormControl(
    '',
    Validators.compose([
      Validators.required,
      CustomValidators.preventWhitespaceOnly,
      Validators.maxLength(1000)
    ])
  );
  product: Product;

  constructor(
    private fb: FormBuilder,
    private solutionBuilderClient: SolutionBuilderClient,
    private logger: SolutionBuilderLoggerService,
    private toastr: ToastrService,
    dialog: DialogRef
  ) {
    super(dialog);
  }

  ngOnInit(): void {
    this.fgSemiCustomProduct = this.fb.group({
      fcMsrp: this.fcMsrp,
      fcCost: this.fcCost,
      fcNotes: this.fcNotes
    });
  }

  ngAfterViewInit(): void {
    if (this.product) {
      this.fcMsrp.setValue(this.product.msrp);
      this.fcCost.setValue(this.product.costPricePerUnit);
      this.fcNotes.setValue(this.product.externalNote);
    } else {
      this.logger.debug('No Product was passed to the dialog.');
    }
  }

  onCancelAction(): void {
    this.dialog.close();
  }

  onConfirmAction(): void {
    if (this.fgSemiCustomProduct.valid && this.product) {
      this.product.msrp = this.fcMsrp.value;
      this.product.costPricePerUnit = this.fcCost.value;
      this.product.externalNote = this.fcNotes.value;

      // Need to explicitly call this so
      // the changes are saved locally.
      this.solutionBuilderClient.saveLocally();
      this.onCancelAction();
    } else {
      this.toastr.warning(
        'The Semi-Custom Product form is invalid.  Please fill out all fields before saving.'
      );
    }
  }
}
