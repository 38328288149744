import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { map } from 'rxjs/operators';

import { environment } from 'environments/environment';
import { Branch, IBranch } from 'app/domain/models/core/models';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class BranchService {
  constructor(private http: HttpClient) {}

  getBranches(): Observable<Branch[]> {
    return this.http.get<IBranch[]>(environment.apiBranches).pipe(
      map((response: IBranch[]) => {
        const branches: Branch[] = [];
        if (response && response instanceof Array) {
          response.forEach((b: IBranch) => {
            branches.push(new Branch(b.id, b.name, b.isActive, b.branchId));
          });
        }
        return branches;
      })
    );
  }

  getBranch(branchId: number): Observable<Branch> {
    return this.http
      .get<IBranch>(`${environment.apiBranches}/${branchId}`)
      .pipe(
        map((b: IBranch) => {
          return b ? new Branch(b.id, b.name, b.isActive, b.branchId) : null;
        })
      );
  }
}
