import * as _ from 'lodash';
import { SolutionBuilderClient } from '../services/solution-builder-client.service';
import { ISaveValidator } from './models';

export class MarginThresholdSaveValidator implements ISaveValidator {
  validate(messages: string[], client: SolutionBuilderClient): void {
    const solutions = client.getSolutions();

    let totalReimbursement = client.getExpectedReimbursementCost();
    let totalCost = _.sumBy(solutions, s => s.getTotalCost());
    const actualThreshold =
      (totalReimbursement - totalCost) / totalReimbursement;
    const expectedThreshold = client.getMarginThreshold() / 100;

    if (actualThreshold < expectedThreshold) {
      const a = (expectedThreshold * 100).toFixed(2);
      const b = (actualThreshold * 100).toFixed(2);
      messages.push(
        `This solution is below the margin threshold.  Expected a margin of at least ${a}%.  Actual margin is ${b}%. Do you wish to continue?`
      );
    }
  }
}
