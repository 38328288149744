import {
  Component,
  OnInit,
  Input,
  ViewChild,
  HostBinding,
  SimpleChanges
} from '@angular/core';

import { FieldOption } from 'app/domain';
import { SbBaseFieldComponent } from '../sb-base-field.component';
import { ComboBoxComponent } from '@progress/kendo-angular-dropdowns';

@Component({
  selector: 'sb-combobox-field',
  templateUrl: './sb-combobox-field.component.html',
  styleUrls: ['./sb-combobox-field.component.scss']
})
export class SbComboBoxFieldComponent extends SbBaseFieldComponent
  implements OnInit {
  @HostBinding('class') class: string = 'sb-combobox-field';

  @ViewChild(ComboBoxComponent)
  comboboxComponent: ComboBoxComponent;

  /**
   * Style overwrite to allow solid state colored dropdown
   */
  @Input()
  filled: boolean;

  @Input()
  options: Array<FieldOption>;

  virtual: any = {
    itemHeight: 28
  };

  constructor() {
    super();
  }

  ngOnInit(): void {}

  valueChange(e: any): void {
    // Hook for when a value changes
  }
  handleBlur(e: any): void {
    this.comboboxComponent.toggle(false);
  }

  isItemSelected(itemText: string): boolean {
    const values = this.control.value;

    if (!values) {
      return false;
    }

    return values.some((item: FieldOption) => {
      return (
        item.value.toString().toLowerCase() ===
        itemText.toString().toLowerCase()
      );
    });
  }
}
