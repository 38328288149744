import { Pipe, PipeTransform } from '@angular/core';

/**
 * This Pipe is used to trim the numeric prefix off the category name.
 * Ex. 01 - Batteries.  '01 - ' needs to go.
 */
@Pipe({
  name: 'categoryName'
})
export class CategoryNamePipe implements PipeTransform {
  private readonly targetTruncString: string = '-';

  transform(categoryName: string = ''): string {
    if (!categoryName) {
      return null;
    }
    return categoryName
      .substring(
        categoryName.indexOf(this.targetTruncString) +
          this.targetTruncString.length
      )
      .trim();
  }
}
