import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Data, Router } from '@angular/router';
import {
  DialogCloseResult,
  DialogRef,
  DialogService
} from '@progress/kendo-angular-dialog';
import { untilDestroyed } from 'app/core';
import {
  ClientAsset,
  ConfiguredSolution,
  DataStoreEventType,
  IProductSelectedEvent,
  OrderType,
  Product,
  SolutionLaborItem,
  SolutionResolvedModel,
  SolutionType
} from 'app/domain';
import { SolutionBuilderLoggerService } from 'app/domain/services/solution-builder-logger.service';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { SbLoanerDialogComponent } from '../common/loaner-dialog/loaner-dialog.component';
import { SolutionBuilderClient } from '../solution-builder/services/solution-builder-client.service';

@Component({
  selector: 'sb-repair-builder',
  templateUrl: './repair-builder.component.html',
  styleUrls: ['./repair-builder.component.scss']
})
export class SbRepairBuilderComponent implements OnInit, OnDestroy {
  /**
   * Helps the template section off which menus to show and for the component to react properly.
   */
  viewState$: BehaviorSubject<TemplateViewState> = new BehaviorSubject<
    TemplateViewState
  >(TemplateViewState.Empty);

  // Make the enum available in the template.
  TemplateViewState = TemplateViewState;

  clientAsset: ClientAsset;

  workOrderId: string;

  activeSolution: ConfiguredSolution;
  activeSolutionType: SolutionType;
  configuredSolutions: ConfiguredSolution[] = [];

  isSaving: boolean;
  isEditable: boolean;
  isModification: boolean;
  showProductLookupDialog: boolean;
  showClientAssetDialog: boolean;
  clientName: string;

  constructor(
    private logger: SolutionBuilderLoggerService,
    private toastrService: ToastrService,
    private dialogService: DialogService,
    private solutionBuilderClient: SolutionBuilderClient,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    // Anything that is not the default view should show a menu
    this.viewState$
      .pipe(untilDestroyed(this))
      .subscribe((state: TemplateViewState) => {
        if (state === TemplateViewState.None) {
          this.hideSideMenu();
        } else {
          this.showSideMenu();
        }
      });

    this.solutionBuilderClient
      .events()
      .subscribe((event: DataStoreEventType) => {
        switch (event) {
          case DataStoreEventType.ProductsChange: {
            this.handleProductsChange();
            break;
          }
          case DataStoreEventType.LaborChange: {
            this.handleLaborChange();
            break;
          }
          case DataStoreEventType.SolutionsChanged: {
            this.handleSolutionsChanged();
            break;
          }
          case DataStoreEventType.SolutionActivated: {
            this.handleSolutionActivated();
            break;
          }
          case DataStoreEventType.ClientAssetChanged: {
            this.clientAsset = this.solutionBuilderClient.getClientAsset();
            if (this.clientAsset) {
              this.showSpareParts();
            } else {
              this.showDefaultView();
            }
            break;
          }

          default: {
            this.logger.warn('DataStore event type not recognized.');
          }
        }
      });

    this.activatedRoute.data.subscribe((resp: Data) => {
      const solution: SolutionResolvedModel = resp.solution;
      if (solution) {
        // Set meta data
        this.isEditable = solution.isEditable;
        this.clientName = solution.clientName;
        this.workOrderId = solution.workOrderId;
        this.solutionBuilderClient.setWorkOrderId(solution.workOrderId);
        this.solutionBuilderClient.setClientAsset(solution.clientAsset);

        this.isModification = solution.orderType === OrderType.Modification;
        if (solution.orderType === OrderType.Repair || this.isModification) {
          // TODO: See if when we save a repair the ST on the SolutionResolvedModel is Repair.
          // if it is we can clean this section up
          const repairSolutionType = this.solutionBuilderClient.getRepairSolutionType();
          this.activeSolutionType = repairSolutionType;
          this.solutionBuilderClient.setActiveSolutionType(repairSolutionType);

          if (solution.hasSolutions()) {
            const solutions: ConfiguredSolution[] = solution.solutions;

            this.solutionBuilderClient.setSolutions(solutions);

            this.solutionBuilderClient.setActiveSolution(solutions[0]);

            // Has discontinued products
            if (solutions.filter(s => s.hasDiscontinuedProducts()).length > 0) {
              this.toastrService.warning(
                'This Quote has discontinued Products.  Please remove or replace them in order to save.',
                'Discontinued Products',
                { closeButton: true, disableTimeOut: true }
              );
            }
          } else {
            this.solutionBuilderClient.addSolution(
              new ConfiguredSolution(),
              true
            );
          }
          // this.solutionBuilderClient.setLoaner(solution.loanerProduct);
          this.solutionBuilderClient.setLaborItems(solution.labor);
        } else {
          this.toastrService.error(
            'This is not a Repair or Modification Work Order.'
          );
        }
      } else {
        this.logger.debug(
          'No solution was resolved when navigating to Solution Builder Repairs.'
        );
      }
    });
  }

  ngOnDestroy(): void {
    this.hideSideMenu();
  }

  toggleClientAssetLookup(show: boolean = true): void {
    this.showClientAssetDialog = show;
  }
  toggleProductLookup(show: boolean = true): void {
    this.showProductLookupDialog = show;
  }

  showLoanerDialog(): void {
    this.dialogService.open({
      content: SbLoanerDialogComponent,
      width: 450,
      height: 300,
      minWidth: 250,
      title: 'Loaner Settings'
    });
  }

  /**
   * Callback by the UI when Labor has been added.
   */
  addLaborItemToSolution(item: SolutionLaborItem): void {
    this.solutionBuilderClient.addLaborItems(item);
  }

  showDefaultView(): void {
    this.viewState$.next(TemplateViewState.Empty);
  }

  showClientAssets(): void {
    this.viewState$.next(TemplateViewState.RepairAssets);
  }

  showSpareParts(): void {
    this.viewState$.next(TemplateViewState.Products);
  }

  showLaborItems(): void {
    this.viewState$.next(TemplateViewState.RepairProductsAndLabor);
  }

  /**
   * Callback by the UI when a Product has been selected.
   */
  addProductToSolution(event: IProductSelectedEvent): void {
    this.solutionBuilderClient.addProduct(event.product);
  }

  /**
   * Callback by the UI when a Product has been removed.
   */
  removeProductFromSolution(product: Product): void {
    this.solutionBuilderClient.removeProduct(product);
  }

  /**
   * Callback by the UI when a Labor Item has been removed.
   */
  removeLaborFromSolution(labor: SolutionLaborItem): void {
    this.solutionBuilderClient.removeLaborItem(labor);
  }

  showAssetMenu(): void {
    this.viewState$.next(TemplateViewState.RepairAssets);
  }

  /**
   * Determines if the user can
   * save the Solution based on what we know
   * are the minimum requirements.
   */
  isAllowedToSave(): boolean {
    const solutions: ConfiguredSolution[] = this.solutionBuilderClient.getSolutions();
    if (this.solutionHasLaborItems()) {
      return true;
    }

    return false;
  }

  solutionHasProducts(): boolean {
    return this.activeSolution && this.activeSolution.hasProducts();
  }

  solutionHasLaborItems(): boolean {
    return this.activeSolution && this.activeSolution.hasLaborItems();
  }

  previousStep(): void {}

  /**
   * Discard the Solution and start from scratch.
   */
  discardSolution(): void {
    const content = `
   Are you sure you'd like to discard this Repair and start over?
    `;
    this.showConfirmDialog(content, () => {
      this.solutionBuilderClient.reset();
      this.solutionBuilderClient.setActiveSolutionType(
        this.solutionBuilderClient.getRepairSolutionType()
      );
      this.solutionBuilderClient.addSolution(new ConfiguredSolution(), true);
    });
  }

  updateQuantity(product: Product, shouldIncrement: boolean = true): void {
    if (shouldIncrement) {
      this.solutionBuilderClient.incrementProductQuantity(product);
    } else {
      this.solutionBuilderClient.decrementProductQuantity(product);
    }
  }

  showSideMenu(): void {
    document.body.classList.add('sb-shift-left', 'sb-no-scroll');
  }
  hideSideMenu(): void {
    document.body.classList.remove('sb-shift-left', 'sb-no-scroll');
  }

  /**
   * Save a quote back to CE.
   */
  saveQuote(): void {
    // Make sure the user is allowed to save.
    if (!this.isAllowedToSave()) {
      return;
    }

    const issues = this.solutionBuilderClient.validateSolution();

    if (issues.length === 0) {
      this.isSaving = true;
      this.toastrService.info('One moment...', 'Saving Quote', {
        disableTimeOut: true
      });
      this.solutionBuilderClient
        .calculateExpectedReimbursement()
        .subscribe(() => {
          this.solutionBuilderClient
            .saveSolution()
            .pipe(finalize(() => (this.isSaving = false)))
            .subscribe(
              () => {
                this.toastrService.clear();
                this.toastrService.success('Solution has been saved.');
                this.solutionBuilderClient.clearLocalData();
                this.router.navigateByUrl(
                  `/builder/${this.workOrderId}/summary`
                );
              },
              () => {
                this.toastrService.clear();
                this.toastrService.error(
                  'There was an issue saving the solution.'
                );
              }
            );
        });
    } else {
      this.showConfirmDialog(issues.join(' '), () => {});
    }
  }

  setClientAsset(asset: ClientAsset): void {
    this.solutionBuilderClient.setClientAsset(asset);
    this.toggleClientAssetLookup(false);
  }

  hasSelectedClientAsset(): boolean {
    return this.clientAsset != null;
  }
  /**
   * In response to a labor change event from
   * the data store
   */
  private handleLaborChange(): void {
    this.activeSolution = this.solutionBuilderClient.getActiveSolution();
  }

  /**
   * In response to a product change event from
   * the data store
   */
  private handleProductsChange(): void {}

  private handleSolutionsChanged(): void {
    this.configuredSolutions = this.solutionBuilderClient.getSolutions();
  }

  private handleSolutionActivated(): void {
    this.activeSolution = this.solutionBuilderClient.getActiveSolution();
  }

  /**
   * Short hand method to proc a simple dialog so the HTML and Component
   * don't become flooded with simplistic "Confirmation" dialogs.
   * @param content - Displayed in the contetn area of the Dialog
   * @param successCallback - The function to call on an affirmative answer
   */
  private showConfirmDialog(
    content: string,
    successCallback: (n: any) => any
  ): void {
    const dialog: DialogRef = this.dialogService.open({
      title: 'Confirm',
      content: content,
      actions: [
        { text: 'Cancel', value: false },
        { text: 'Ok', value: true, primary: true }
      ],
      width: 450,
      minWidth: 250
    });

    dialog.result.subscribe(result => {
      if (result instanceof DialogCloseResult) {
        // User closed the dialog
      } else {
        if (result.text === 'Ok') {
          successCallback.call(this);
        } else {
          // User clicked No
        }
      }
    });
  }
}

enum TemplateViewState {
  None = 'None',
  Empty = 'Empty',
  Products = 'Products',
  RepairAssets = 'Repair Assets',
  RepairProductsAndLabor = 'Repair Products and Labor'
}
