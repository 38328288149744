import * as _ from 'lodash';
import {
  ExpectedReimbursementInfo,
  ExpectedReimbursementLineItem
} from './expected.reimbursement';

export const MIN_SOLUTION_THRESHOLD: number = 40;
export const LOANER_PRODUCT_NUMBER: string = 'A9999999';
export const SEATING_POSITIONING_CODE: string = '12000';
export const PARTS_ONLY_KEY = 'parts_only_key';
/**
 * Scope of visibility for a Template
 */
export enum TemplateAvailability {
  Me = 'Me',
  Branch = 'Branch',
  Everyone = 'Everyone'
}

export enum ProductRanking {
  Unranked = 0
}

export enum WorkOrderType {
  Quote,
  SalesOrder
}

export enum OrderType {
  Sales = 'Sales',
  Repair = 'Repair',
  Modification = 'Modification'
}

export enum ProductNoteSeverity {
  INFO = '1',
  WARN = '2',
  ALERT = '3'
}

/**
 * Represents a Product grouping from F&O
 */
export class Category {
  children: Category[];
  code: string;
  id: number;
  name: string;
  parentId: number;
  rootId: number;
  productCount: number;
  isCanBeHidden: boolean;
  isRequired: boolean;
  containsBaseModels: boolean;
}

/**
 * Represents a Product grouping from F&O
 * specifically aimed at Labor
 */
export class LaborCategory {
  id: number;
  parentId: number;
  name: string;
  code: string;
  rootId: number;
  productCount: number;
  children: LaborCategory[];
  laborCodes: LaborCode[];

  hasChildren(): boolean {
    return this.children && this.children.length > 0;
  }

  hasLaborCodes(): boolean {
    return this.laborCodes && this.laborCodes.length > 0;
  }
}

/**
 * Represents a selectable Labor Item.
 */
export class LaborCode {
  productNumber: string;
  rateTime: number;
  unitOfService: number;
  maxQty: number;
  note: string;
  name: string;
  hcpcsCode1: string;
  hcpcsCode2: string;
  hcpcsCode3: string;
  hcpcsCode4: string;
  reasonCodes: Reason[];
}
/**
 * Represents the Reason for a selected Labor Item.
 */
export class Reason {
  code: string;
  description: string;
  requiresNote: boolean;
}
/**
 * Represents a Product that is tied toa  Category.
 */
export class Product {
  id: number;
  parentId: number;
  productNumber: string;
  categoryId: number;
  categoryName: string;
  isBaseProduct: boolean;
  brandName: string;
  description: string;
  vendorProductNumber: string;
  vendorAccountNumber: string;
  vendorName: string;
  hcpcsCode1: string;
  hcpcsCode2: string;
  hcpcsCode3: string;
  hcpcsCode4: string;
  vendorDiscount1: number;
  vendorDiscount2: number;
  msrp: number;
  costPricePerUnit: number;
  defaultQuantity: number;
  rank: number;
  normalizedRank: string;
  unitOfMeasure: string;
  dragging: boolean;
  externalNote: string;
  internalNote: string;
  notes: string;
  noteSeverity: ProductNoteSeverity;
  isCustomProduct: boolean;
  isAllowPriceOverride: boolean;
  itemGroup: string;
  model: string;
  perItemConversionFactor: number = 1.0;
  isSalesProcessingStopped: boolean;

  // This is set by the ER response
  expectedReimbursement: number;

  attributes: IProductAttribute[];

  hasNotes(): boolean {
    if (this.externalNote || this.internalNote) {
      return true;
    }
    return false;
  }

  hasProductNote(): boolean {
    return this.notes != null;
  }
  isUnranked(): boolean {
    return this.rank === ProductRanking.Unranked;
  }

  requiresCustomization(): boolean {
    return this.isCustomProduct || this.isSemiCustomProduct();
  }

  isSemiCustomProduct(): boolean {
    return this.isAllowPriceOverride;
  }

  matchesDescription(term: string): boolean {
    if (term) {
      term = term.toLowerCase();

      const flattened = `${this.productNumber}
      |${this.description}
      |${this.vendorProductNumber}
      |${this.model}
      |${this.vendorName}
      |${this.hcpcsCode1}
      |${this.hcpcsCode2}
      |${this.hcpcsCode3}
      |${this.hcpcsCode4}`.toLowerCase();

      return flattened.indexOf(term) >= 0;
    }
    return true;
  }
  addToQuantity(amount: number): void {
    this.defaultQuantity += this.defaultQuantity + amount < 0 ? 0 : amount;
  }

  getMargin(): number {
    if (this.expectedReimbursement === 0) {
      return 0;
    }

    return 1 - this.costPricePerUnit / this.expectedReimbursement;
  }

  isFree(): boolean {
    return this.costPricePerUnit === 0 && this.msrp === 0;
  }

  isLoaner(): boolean {
    return this.productNumber === LOANER_PRODUCT_NUMBER;
  }

  hasHcpcs(): boolean {
    if (this.hcpcsCode1) {
      return true;
    }

    return false;
  }

  getTotalCost(): number {
    if (this.isFree()) {
      return 0;
    }
    return this.costPricePerUnit * this.defaultQuantity;
  }

  /**
   * Product Notes are notes from F&O about the business side of the Product.
   * These shouldn't be confused with the internal/external notes of a Product
   * which are entered by the ATP.
   */
  hasInformationalProductNote(): boolean {
    return this.noteSeverity === ProductNoteSeverity.INFO;
  }

  hasWarningProductNote(): boolean {
    return this.noteSeverity === ProductNoteSeverity.WARN;
  }
  hasAlertProductNote(): boolean {
    return this.noteSeverity === ProductNoteSeverity.ALERT;
  }

  hasMatchingAttributes(attrs: IProductAttribute[]): boolean {
    if (this.attributes) {
      if (this.attributes.length !== attrs.length) {
        return false;
      }

      for (var i = 0, max = this.attributes.length; i < max; i++) {
        const attr = this.attributes[i];
        if (
          !attrs.find(at => at.name === attr.name && at.value === attr.value)
        ) {
          return false;
        }
      }
    }
    return true;
  }
}

export class IProductAttribute {
  name: string;
  value: string;
  type: ProductAttributeValueType;
}

export enum ProductAttributeValueType {
  DECIMAL = 3,
  STRING = 5,
  BOOLEAN = 6
}

export class LineItem {
  quantity: number;
  notes: string;
}

/**
 * Represents the model sent up to the API for use writing back to CE.
 */
export class QuoteLineItem extends LineItem {
  msrp: number;
  cost: number;
  productNumber: string;
  lineNumber: number;
  description: string;
  name: string;
  vendorAccountNumber: string;
  vendorProductNumber: string;
  vendorName: string;
  model: string;
  internalNotes: string;
  externalNotes: string;
  hcpcsCode1: string;
  hcpcsCode2: string;
  hcpcsCode3: string;
  hcpcsCode4: string;
  unitOfMeasure: string;
  vendorDiscount1: number;
  vendorDiscount2: number;
  perItemConversionFactor: number;
}

/**
 * Represents a labor line item
 */
export class LaborQuoteLineItem extends LineItem {
  productNumber: string;
  lineNumber: number;
  hcpcsCode1: string;
  hcpcsCode2: string;
  hcpcsCode3: string;
  hcpcsCode4: string;
  reasons: Reason[];
  units: number;
}

/**
 * Represents a line item from a Vendor Hot Form.
 * Comes back during an PDF Import.
 */
export class VendorFormLineItem extends LineItem {
  vendorProductNumber: string;
}

/**
 * Represents the Vendor (also Brand) of a Product.
 */
export class Vendor {
  constructor(public name: string, public accountNumber: string) {}
}

export class ConfiguredField {
  constructor(public product: Product, public field: CpqField) {}
}

export class SolutionTypeConfig {
  needsBaseSelectionField: boolean;
  sections: CpqSection[] = [];
  constructor() {}
}

export class CpqField {
  id: number; // This comes from the model tied all the way back to the server.
  code: string;
  parentId: number;
  parentCategoryName: string;
  productCount: number;
  isRequired: boolean;
  isCanBeHidden: boolean;
  constructor(public categoryName: string, public helpText: string = null) {}
}

export class CpqSection {
  code: string;
  categoryId: number;
  sectionId: string;
  name: string;
  isCanBeHidden: boolean;
  fields: CpqField[];

  // Used to help the UI know if the child categories are showing or not
  isExpanded: boolean;

  constructor() {}

  hasFields(): boolean {
    return this.fields && this.fields.length > 0;
  }

  hasRequiredFields(): boolean {
    return this.hasFields() && this.fields.find(f => f.isRequired) != null;
  }

  getField(code: string): CpqField {
    return this.fields.find(f => f.code === code);
  }
}

export class ChairConfigurationType {
  baseField: CpqField;
  sections: CpqSection[];

  constructor(public id: number, public name: string) {}
}

/**
 * Represents what can be put into an order.  Manual Solution, Power Solution, Individual Parts, etc.
 */
export class Solution {
  id: number;
  name: string;
  code: string;
  rootId: number;
  categoryId: number;
  prerequesiteCategoryId: number;
}

/**
 * Represents a LaborItem that has been selected and configured by the user.
 */
export class SolutionLaborItem {
  productNumber: string;
  name: string;
  code: string;
  notes: string;
  quantity: number;
  units: number;
  hcpcsCode1: string;
  hcpcsCode2: string;
  hcpcsCode3: string;
  hcpcsCode4: string;
  reasons: Reason[];
  isBillable?: boolean;

  getCodes(): string {
    return this.reasons ? this.reasons.map(r => r.code).join(', ') : '';
  }
}

export interface IConfiguredSolution {
  isChairOnly: boolean;
  isPartsOnly: boolean;
  workOrderId: string;
  solutionType: SolutionType;
  base: Product;
  products: Product[];
  laborItems: SolutionLaborItem[];
  requiredCategories: number[];
}
export class ConfiguredSolution {
  isChairOnly: boolean = false;
  workOrderId: string;
  requiredCategories: number[] = [];
  marginThreshold: number = MIN_SOLUTION_THRESHOLD;
  constructor(
    public base: Product = null,
    public products: Product[] = [],
    public laborItems: SolutionLaborItem[] = []
  ) {}

  getKey(): string {
    return this.base ? this.base.productNumber : PARTS_ONLY_KEY;
  }

  isPartsOnly(): boolean {
    return this.base == null && this.hasProducts();
  }

  hasBase(): boolean {
    return this.base != null;
  }
  hasProducts(): boolean {
    return this.products && this.products.length > 0;
  }
  hasLoanerProduct(): boolean {
    return (
      this.hasProducts() &&
      this.products.filter(p => p.productNumber === LOANER_PRODUCT_NUMBER) !=
        null
    );
  }
  hasDiscontinuedProducts(): boolean {
    return (
      this.hasProducts() &&
      this.products.filter(p => p.isSalesProcessingStopped).length > 0
    );
  }
  hasLaborItems(): boolean {
    return this.laborItems && this.laborItems.length > 0;
  }

  getTotalCost(): number {
    let allProducts = [];
    if (this.hasBase()) {
      allProducts.push(this.base);
    }

    if (this.hasProducts()) {
      allProducts = [...allProducts, ...this.products.filter(p => !p.isFree())];
    }

    return _.sumBy(allProducts, p => p.getTotalCost());
  }

  removeProduct(product: Product): void {
    const index = this.products.findIndex(p => p.id === product.id);
    this.products.splice(index, 1);
  }
}

/**
 * Represents a Template built for a Solution.
 * Dates in this class are left as strings.  Letting the
 * Pipe handle visually transforming it.  Also allows
 * the mapping of the Object coming back to be easily mapped.
 */
export class Template {
  id: number;
  name: string;
  solutionTypeId: number;
  products: QuoteLineItem[];
  branch: string;
  scope: string;
  createdBy: string;
  createdByEmail: string;
  creadtedOn: string;
  updatedBy: string;
  updatedByString: string;
  updatedOn: string;
}

export interface ILoadResults<T> {
  products: Product[];
  missingItems: T[];
  lineItems: T[];
  solutionType: SolutionType;
  solutions: SolutionGrouping[];
}

export class LoadResults<T> {
  products: Product[] = [];
  missingItems: T[] = [];
  uncategorizedProducts: Product[] = [];
  lineItems: T[] = [];
  solutionType: SolutionType;
  solutions: SolutionGrouping[] = [];

  hasSolutions(): boolean {
    return this.solutions && this.solutions.length > 0;
  }

  getAsConfiguredSolutions(): ConfiguredSolution[] {
    const solutions: ConfiguredSolution[] = [];
    this.solutions.map((grouping: SolutionGrouping) => {
      const solution = new ConfiguredSolution();
      solution.base = grouping.base;
      solution.products = grouping.products;

      if (
        solution.hasBase() &&
        this.solutionType.flow === SolutionTypeFlowType.Single
      ) {
        solution.isChairOnly = true;
      }

      solutions.push(solution);
    });
    return solutions;
  }
}

export interface ITemplateLoadResults extends ILoadResults<QuoteLineItem> {
  templateId: number;
  name: string;
  template: Template;
}

export class TemplateLoadResults extends LoadResults<QuoteLineItem> {
  templateId: number;
  name: string;
  template: Template;
}

export interface IClientAssetLoadResults {
  clientAssets: ClientAsset[];
  products: Product[];
  missingItems: QuoteLineItem[];
}

export class VendorFormLoadResults extends LoadResults<VendorFormLineItem> {
  fileName: string;
  hasMissingItems(): boolean {
    return this.missingItems && this.missingItems.length > 0;
  }
  isValid(): boolean {
    return (
      (this.products && this.products.length > 0) ||
      (this.missingItems && this.missingItems.length > 0) ||
      (this.lineItems && this.lineItems.length > 0)
    );
  }
}

export class VendorFormValidateResults {
  status: string;
  message: string;
  missingProducts: string[];
  fields: string[];
}

export class CeProductType {
  id: string;
  name: string;
  updMapping: string;
  fnOMapping: string;
  marginThreshold: number;
}

/**
 * Data Store Events
 */

export enum DataStoreEventType {
  SolutionTypeChange = 'SolutionTypeChange',
  ProductsChange = 'ProductsChange',
  ProductQuantityChange = 'ProductQuantityChange',
  LaborChange = 'LaborChange',
  VendorImportChange = 'VendorImportChange',
  SolutionsChanged = 'SolutionsChanged',
  SolutionActivated = 'SolutionActivated',
  Reset = 'Reset',
  ClientAssetChanged = 'ClientAssetChanged'
}

export interface DataStoreEvent {
  type: DataStoreEventType;
}
/**
 * Resolver Model
 */

export class SolutionResolvedModel {
  clientName: string;
  workOrderId: string;
  type: SolutionType;
  labor: SolutionLaborItem[];
  orderType: OrderType;
  marginThreshold: number;
  workOrderType: WorkOrderType;
  solutions: ConfiguredSolution[] = [];
  isEditable: boolean;
  clientAsset: ClientAsset;
  loanerProduct: Product;
  nonProfileBaseExplanation: IExplanationModel;

  hasSolutions(): boolean {
    return this.solutions && this.solutions.length > 0;
  }
}

export class SaveWorkOrderRequest {
  workOrderId: string;
  products: Product[] = [];
  laborItems: SolutionLaborItem[] = [];
  asset: ClientAsset;
  expectedReimbursementLineItems: ExpectedReimbursementLineItem[] = [];
  nonProfileBaseExplanation: IExplanationModel;
  type: string;
  make: string;
  model: string;
}

/**
 * Search Product Models
 */

export class SearchCriteria {
  id: string;
  name: string;
  type: SearchCriteriaType;
}
export class ProductSearchRequest {
  searchString: string;
  searchCriteria: SearchCriteria[] = [];
}

export enum SearchCriteriaType {
  VENDOR = 'vendor',
  HCPCS = 'hcpcs',
  GROUPS = 'groups'
}

/**
 * Solution Type Settings
 */

export enum SolutionTypeFlowType {
  Single,
  Multi,
  Repair
}

export class SolutionType {
  id: number;
  code: string;
  flow: SolutionTypeFlowType;
  name: string;
  rootId: number;
  categoryId: number;
  laborCategories: string[] = [];
  createdByEmail: string;
  createdOn: string;
  updatedBy: string;
  updatedByEmail: string;
  updatedOn: string;

  isRepairFlowSolutionType(): boolean {
    return this.flow === SolutionTypeFlowType.Repair;
  }
  isMultiFlowSolutionType(): boolean {
    return this.flow === SolutionTypeFlowType.Multi;
  }

  isSingleFlowSolutionType(): boolean {
    return this.flow === SolutionTypeFlowType.Single;
  }

  requiresBaseModelSelection(): boolean {
    return !this.isRepairFlowSolutionType();
  }
}

export class SolutionTypeResolvedModel {
  unassignedCategories: Category[];
  solutionType: SolutionType;

  hasUnassignedCategories(): boolean {
    return this.unassignedCategories && this.unassignedCategories.length > 0;
  }
}

/**
 * Base Model Requirements models
 */

export interface IBaseModelRequirements {
  id: number;
  model: string;
  solutionTypeId: number;
  requiredCategories: string[];
  createdBy: string;
  createdByEmail: string;
  createdOn: string;
  updatedBy: string;
  updatedByEmail: string;
  updatedOn: string;
}
export class BaseModelRequirements {
  id: number;
  model: string;
  solutionTypeId: number;
  // These should be the category codes
  requiredCategories: string[];
  createdBy: string;
  createdByEmail: string;
  createdOn: string;
  updatedBy: string;
  updatedByEmail: string;
  updatedOn: string;
}

export interface IClientAsset {
  id: string;
  serialNumber: string;
  wheelChairSerialNumber: string;
  product: Product;
  hasSerialNumber: boolean;
}
export class ClientAsset implements IClientAsset {
  id: string;
  serialNumber: string;
  wheelChairSerialNumber: string;
  product: Product;
  hasSerialNumber: boolean;
  productIdAlternate: string;
}
/**
 * Work Order Load Models
 */

export interface IWorkOrderLoadResults extends ILoadResults<QuoteLineItem> {
  fullName: string;
  isEditable: boolean;
  missing: string[];
  lineItems: QuoteLineItem[];
  laborItems: SolutionLaborItem[];
  expectedReimbursementInfo: ExpectedReimbursementInfo;
  solutions: SolutionGrouping[];
  solution: Solution;
  solutionType: SolutionType;
  productType: string;
  marginThreshold: number;
  orderType: OrderType;
  type: WorkOrderType;
  uncategorizedProducts: Product[];
  clientAsset: ClientAsset;
  clientAssets: ClientAsset[];
  nonProfile: IExplanationModel;
}
export class WorkOrderLoadResults extends LoadResults<QuoteLineItem> {
  fullName: string;
  isEditable: boolean = true;
  missing: string[] = [];
  laborItems: SolutionLaborItem[] = [];
  clientAssets: ClientAsset[] = [];
  clientAsset: ClientAsset;
  expectedReimbursementInfo: ExpectedReimbursementInfo;

  solutionType: SolutionType;
  productType: string;
  marginThreshold: number;
  orderType: OrderType;
  type: WorkOrderType;
  nonProfile: IExplanationModel;

  hasLaborItems(): boolean {
    return this.laborItems && this.laborItems.length > 0;
  }
  hasGroupings(): boolean {
    return this.solutions && this.solutions.length > 0;
  }

  hasMissingProducts(): boolean {
    return this.missingItems && this.missingItems.length > 0;
  }

  hasUncategorizedProducts(): boolean {
    return this.uncategorizedProducts && this.uncategorizedProducts.length > 0;
  }

  hasSemiCustomProducts(): boolean {
    let hasSemiCustomProducts =
      this.hasGroupings() &&
      this.solutions.find(s => s.hasSemiCustomProducts()) != null;

    if (!hasSemiCustomProducts) {
      hasSemiCustomProducts =
        this.uncategorizedProducts.find(p => p.isAllowPriceOverride) != null;
    }

    return hasSemiCustomProducts;
  }

  getSemiCustomProducts(): Product[] {
    let products: Product[] = [];

    if (this.hasGroupings()) {
      products = this.solutions
        .map(s => s.getSemiCustomProducts())
        .reduce((accumulator, value) => accumulator.concat(value), []);
    }

    if (this.hasUncategorizedProducts()) {
      const foundSemiCustomProducts = this.uncategorizedProducts.filter(
        p => p.isAllowPriceOverride
      );
      if (foundSemiCustomProducts.length > 0) {
        products = [...products, ...foundSemiCustomProducts];
      }
    }
    return products;
  }

  hasAftermarketProducts(): boolean {
    return this.uncategorizedProducts && this.uncategorizedProducts.length > 0;
  }
  isRepairOrModification(): boolean {
    return (
      this.orderType === OrderType.Repair ||
      this.orderType === OrderType.Modification
    );
  }
}

export interface ISolutionGrouping {
  base: Product;
  solutionType: SolutionType;
  products: Product[];
}
export class SolutionGrouping {
  base: Product;
  solutionType: SolutionType;
  products: Product[] = [];

  hasSemiCustomProducts(): boolean {
    return (
      this.products && this.products.find(p => p.isAllowPriceOverride) != null
    );
  }

  getSemiCustomProducts(): Product[] {
    if (this.hasProducts()) {
      return this.products.filter(p => p.isAllowPriceOverride);
    }
    return [];
  }

  hasProducts(): boolean {
    return this.products && this.products.length > 0;
  }
}

export interface ICategoryFilters {
  isAftermarket: boolean;
  filter: string;
  vendor: SearchCriteria[];
  hcpcs: SearchCriteria[];
  attributeFilters: IProductAttribute[];
}

export interface IExplanationModel {
  reasonCode: string;
  explanation: string;
}

export interface IProductSelectedEvent {
  product: Product;
  isAftermarket: boolean;
}
