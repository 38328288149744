import {
  Component,
  OnInit,
  HostBinding,
  Output,
  EventEmitter,
  ViewChild,
  TemplateRef
} from '@angular/core';
import {
  FileRestrictions,
  FileSelectComponent
} from '@progress/kendo-angular-upload';
import {
  FormBuilder,
  FormGroup,
  FormControl,
  Validators
} from '@angular/forms';
import { HttpEventType } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { SolutionBuilderLoggerService } from 'app/domain/services/solution-builder-logger.service';
import {
  Product,
  VendorFormLoadResults,
  DocumentType,
  UploadedDocument,
  CeDocument,
  SolutionType,
  ProductRanking,
  ISolutionGrouping,
  SolutionGrouping
} from 'app/domain';
import { tap, filter, switchMap, finalize, catchError } from 'rxjs/operators';
import { ProductsService } from 'app/services/products.service';
import { DynamicsService } from 'app/services/dynamics.service';
import { DmsService } from 'app/services/dms.service';
import {
  DialogService,
  DialogRef,
  DialogCloseResult
} from '@progress/kendo-angular-dialog';
import { SbValidationDialogComponent } from './validation-dialog/validation-dialog.component';
import { SolutionBuilderClient } from 'app/modules/solution-builder/services/solution-builder-client.service';
import { ImportPdfClientService } from './service/import-pdf-client.service';
import { of } from 'rxjs/internal/observable/of';

@Component({
  selector: 'sb-import-pdf-menu',
  templateUrl: './import-pdf-menu.component.html',
  styleUrls: [
    '../base-side-menu.component.scss',
    './import-pdf-menu.component.scss'
  ]
})
export class SbImportPdfMenuComponent implements OnInit {
  @HostBinding('class') class: string = 'sb-selection-menu sb-import-pdf-menu';

  @Output()
  closeMenu: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChild('file')
  fileField: FileSelectComponent;

  /**
   * Contains the state of the view
   */
  viewState$: BehaviorSubject<ViewState> = new BehaviorSubject<ViewState>(
    ViewState.Upload
  );

  /**
   * Make the enum accessible to the HTML template.
   */
  ViewState = ViewState;

  /**
   * Contains the most recent Import before acceptance.
   */
  results$: BehaviorSubject<VendorFormLoadResults> = new BehaviorSubject<
    VendorFormLoadResults
  >(null);

  /**
   * Holds the Vendor Order Form History.  Held here, so any imports
   * can validate if a document exists with the given name already.
   */
  documents$: BehaviorSubject<CeDocument[]> = new BehaviorSubject<CeDocument[]>(
    []
  );

  /**
   * Indicators
   */
  isUploading: boolean;
  isProcessing: boolean;
  isSaving: boolean;
  isLoadingHistory: boolean;

  myRestrictions: FileRestrictions = {
    allowedExtensions: ['.pdf']
  };

  fgImport: FormGroup;
  fcFile: FormControl = new FormControl('', Validators.required);

  progress: number = 0;

  issues: string[] = [];

  constructor(
    private fb: FormBuilder,
    private solutionBuilderClient: SolutionBuilderClient,
    private importClient: ImportPdfClientService,
    private toastrService: ToastrService,
    private productService: ProductsService,
    private dialogService: DialogService,
    private logger: SolutionBuilderLoggerService
  ) {}

  ngOnInit(): void {
    this.fgImport = this.fb.group({
      fcFile: this.fcFile
    });

    this.loadHistory();
  }
  toggleHistory(): void {
    const state = this.viewState$.value;
    if (state === ViewState.Upload) {
      this.viewState$.next(ViewState.HistoryList);
    } else {
      this.viewState$.next(ViewState.Upload);
    }
  }

  onCloseMenu(): void {
    this.closeMenu.emit(true);
  }

  confirmThenImport(documents: CeDocument[], template: TemplateRef<any>): void {
    // The Load results
    const results = this.results$.value;

    // The file that needs to be uploaded.
    const fileToUpload = this.getFileToUpload();

    // The Solution Type id we know about
    const solutionType = this.solutionBuilderClient.getActiveSolutionType();
    const knownSolutionTypeId = solutionType ? solutionType.id : null;

    // Mismatch flag
    const hasSolutionMismatch =
      results.solutionType &&
      knownSolutionTypeId &&
      knownSolutionTypeId !== results.solutionType.id;

    // The existing matching document by name
    const ceDocument: CeDocument = documents
      ? documents.find(d => d.fileName === fileToUpload.name)
      : null;

    const dialogTitle = 'Please Verify';
    const confirmButtonText = 'Import';

    // Expected actions
    let actions = [
      { text: 'Cancel', value: false },
      { text: confirmButtonText, value: true, primary: true }
    ];

    // Reset issues list
    this.issues = [];
    if (!results.solutionType) {
      this.issues.push(
        'We could not determine a Solution Type for this import.  You will have to manually select it, if not already selected.'
      );
    }
    if (ceDocument) {
      this.issues.push(
        'A document with the same name exists on this Work Order and will be overriden.'
      );
    }

    if (hasSolutionMismatch) {
      this.issues.push(
        'Selected Solution Type is different than the one being imported and may alter your base model.'
      );
    }

    const baseProducts = results.products.filter(p => p.isBaseProduct);
    if (baseProducts.find(p => p.rank === ProductRanking.Unranked)) {
      this.issues.push(
        'The selected base model is unranked and may require additional review.'
      );
    }

    if (baseProducts.length > 1) {
      this.issues = [
        'There are multiple Base Models in your import.  Please fix your PDF to continue.'
      ];
      actions = [{ text: 'Cancel', value: false }];
    }

    if (this.issues.length > 0) {
      const dialog: DialogRef = this.dialogService.open({
        title: dialogTitle,
        content: template,
        actions: actions,
        width: 450,
        height: 325,
        minWidth: 250
      });

      dialog.result.subscribe(result => {
        if (result instanceof DialogCloseResult) {
        } else {
          if (result.text === confirmButtonText) {
            if (ceDocument) {
              this.isSaving = true;
              this.importClient
                .deleteDocument(ceDocument)
                .pipe(
                  switchMap(() => {
                    return this.importClient.saveDocument(
                      this.results$.value,
                      this.getFileToUpload()
                    );
                  }),
                  finalize(() => {
                    this.isSaving = false;
                  })
                )
                .subscribe(
                  () => {
                    this.solutionBuilderClient.addImportedResult(
                      this.results$.value
                    );
                    this.resetImport();
                  },
                  () =>
                    this.toastrService.error(
                      'There was an issue saving the Vendor Order form.  Please check the console.'
                    )
                );
            } else {
              this.acceptImport();
            }
          }
        }
      });
    } else {
      this.acceptImport();
    }
  }

  acceptImport(): void {
    this.isSaving = true;
    this.importClient
      .saveDocument(this.results$.value, this.getFileToUpload())
      .pipe(
        finalize(() => {
          this.isSaving = false;
        })
      )
      .subscribe(
        () => {
          this.solutionBuilderClient.addImportedResult(this.results$.value);
          this.resetImport();
        },
        () =>
          this.toastrService.error(
            'There was an issue saving the Vendor Order form.  Please check the console.'
          )
      );
  }

  resetImport(): void {
    this.results$.next(null);
    this.isUploading = false;
    this.isProcessing = false;
    this.isSaving = false;
    this.progress = 0;
    this.fcFile.setValue(null);
    this.fgImport.reset();
  }

  validateFile(): void {
    this.isUploading = true;
    this.productService
      .validateImportFile(this.getFileToUpload())
      .pipe(
        tap((event: any) => {
          if (event.type === HttpEventType.UploadProgress) {
            this.progress = Math.round((100 * event.loaded) / event.total);
            if (this.progress === 100) {
              setTimeout(() => (this.isProcessing = true), 1000);
            }
          }
        }),

        filter((event: any) => {
          return event.type === HttpEventType.Response;
        }),
        finalize(() => {
          this.isUploading = false;
          this.isProcessing = false;
        })
      )
      .subscribe(
        results => {
          const dialog: DialogRef = this.dialogService.open({
            content: SbValidationDialogComponent,
            width: 450,
            height: 300,
            minWidth: 250,
            title: 'Validate Import'
          });

          dialog.content.instance.results = results;
        },
        error => {
          this.toastrService.error(
            'There was an issue validating the file.  Check the console for details.'
          );
          this.logger.error(error);
        }
      );
  }

  importFile(): void {
    this.isUploading = true;
    this.importClient
      .importVendorHotForm(this.getFileToUpload(), (progress: number) => {
        this.progress = progress;
        if (this.progress === 100) {
          setTimeout(() => (this.isProcessing = true), 1000);
        }
      })
      .subscribe(
        results => {
          this.results$.next(results);
        },
        error => {
          this.toastrService.error(
            'There was an issue uploading the file.  Check the console for details.'
          );
          this.logger.error(error);
          this.isUploading = false;
          this.isProcessing = false;
        }
      );
  }

  private loadHistory(): void {
    this.isLoadingHistory = true;
    this.importClient
      .loadDocumentHistory()
      .pipe(
        finalize(() => {
          this.isLoadingHistory = false;
        }),
        catchError(error => {
          this.toastrService.warning(
            'There was an issue loading saved Vendor Hot Forms.'
          );
          return of([]);
        })
      )
      .subscribe((documents: CeDocument[]) => {
        this.documents$.next(documents);
      });
  }

  private getFileToUpload(): File {
    return this.fcFile.value[0] as File;
  }
}

enum ViewState {
  Upload,
  HistoryList
}
