// `.env.ts` is generated by the `npm run env` command
// `npm run env` exposes environment variables as JSON for any usage you might
// want, like displaying the version or getting extra config from your CI bot, etc.
// This is useful for granularity you might need beyond just the environment.
// Note that as usual, any environment variables you expose through it will end up in your
// bundle, and you should not use it for any sensitive information like passwords or keys.
import { env } from './.env';

export const baseApiUrl: string = 'https://appsvc-sb-api-dev.azurewebsites.net';
export const gatewayBaseUrl: string =
  'https://nsmfundingportalapidev.azure-api.net';

export const resourceUrls: string[] = [baseApiUrl, gatewayBaseUrl];
export const environment = {
  production: true,
  hmr: false,
  version: env.npm_package_version,
  defaultLanguage: 'en-US',
  supportedLanguages: ['en-US', 'fr-FR'],
  apiSolutions: `${baseApiUrl}/v1/solutions`,
  apiCategories: `${baseApiUrl}/v1/categories`,
  apiQuotes: `${baseApiUrl}/v1/quotes`,
  apiVendors: `${baseApiUrl}/v1/vendors`,
  apiProducts: `${baseApiUrl}/v1/products`,
  apiTemplates: `${baseApiUrl}/v1/templates`,
  apiLaborCodes: `${baseApiUrl}/v1/laborcodes`,
  apiWorkOrder: `${baseApiUrl}/v1/workOrder`,
  apiSolutionTypes: `${baseApiUrl}/v1/solutionTypes`,
  apiSearchCriteria: `${baseApiUrl}/v1/searchCriteria`,
  apiBaseModelRequirements: `${baseApiUrl}/v1/baseModelRequirements`,
  apiLookups: `${baseApiUrl}/v1/lookup`,
  apiBranches: `${gatewayBaseUrl}/branchapi/v1/Branches`,
  apiDmsApi: `${gatewayBaseUrl}/dmsapi/v1`,
  apiDynamicsApi: `${gatewayBaseUrl}/dynamicsapi/v1`,
  apiExpectedReimbursment: `${gatewayBaseUrl}/upd/v1/er`,
  userAuthorizationApi: `${gatewayBaseUrl}/userauthorizationapi/v1/UserAuthorizations`,
  adminGroupId: 'e6cb70eb-a6bc-4337-b992-dd3373e14296'
};
