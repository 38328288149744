import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'sb-error-page',
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.scss']
})
export class ErrorPageComponent implements OnInit {
  message: string = ` Looks like we've ran into an issue! Please check the console for
    more information. You can additionally contact an Administrator to
    help resolve your issue. If you do, please remain on the page.`;
  constructor(private activatedRoute: ActivatedRoute) {}

  ngOnInit(): void {
    const paramMap = this.activatedRoute.snapshot.queryParamMap;
    this.message = paramMap.get('message');
  }
}
