import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import {
  FieldOption,
  ICategoryFilters,
  IProductAttribute,
  SearchCriteria,
  SearchCriteriaType
} from 'app/domain';
import { CategoriesService } from 'app/services/categories.service';
import { SearchCriteriaService } from 'app/services/search-criteria.service';
import * as _ from 'lodash';
import { v4 } from 'uuid';
import { IAttributeChangedEvent } from './attribute-filters/attribute-filters.component';
@Component({
  selector: 'sb-category-filters-menu',
  templateUrl: './category-filters-menu.component.html',
  styleUrls: [
    '../base-side-menu.component.scss',
    './category-filters-menu.component.scss'
  ]
})
export class SbCategoryFiltersMenu implements OnInit, OnDestroy, AfterViewInit {
  @Input()
  categoryId: number;

  @Output()
  filterChanged: EventEmitter<ICategoryFilters> = new EventEmitter<
    ICategoryFilters
  >();

  @Output()
  close: EventEmitter<void> = new EventEmitter<void>();

  vendorCriteria: FieldOption[] = [];

  hcpcsCriteria: FieldOption[] = [];

  fgFilters: FormGroup;
  fcAftermarket: FormControl = new FormControl(false);
  fcName: FormControl = new FormControl('');
  fcVendor: FormControl = new FormControl('');
  fcHcpcs: FormControl = new FormControl('');

  fgAttributes: FormGroup;

  attributeGroupings: AttributeGrouping[] = [];

  private attributeSource: IProductAttribute[] = [];
  private criteriaSource: SearchCriteria[] = [];
  private selectedAttributes: Map<string, IProductAttribute> = new Map<
    string,
    IProductAttribute
  >();

  constructor(
    private fb: FormBuilder,
    private searchCriteriaService: SearchCriteriaService,
    private categoryService: CategoriesService
  ) {}

  ngOnInit(): void {
    this.fgFilters = this.fb.group({
      fcAftermarket: this.fcAftermarket,
      fcName: this.fcName,
      fcVendor: this.fcVendor,
      fcHcpcs: this.fcHcpcs,
      fgAttributes: null
    });
  }

  ngOnDestroy(): void {}

  ngAfterViewInit(): void {
    this.categoryService
      .getAttributes(this.categoryId)
      .subscribe((attributes: IProductAttribute[]) => {
        this.attributeSource = attributes;
        this.createCustomFilterAttributes();
      });

    this.searchCriteriaService
      .getSearchCriteria('')
      .subscribe((criteria: SearchCriteria[]) => {
        this.criteriaSource = criteria;

        this.vendorCriteria = criteria
          .filter(c => c.type === SearchCriteriaType.VENDOR)
          .map(c => new FieldOption(c.name, c.id));

        this.hcpcsCriteria = criteria
          .filter(c => c.type === SearchCriteriaType.HCPCS)
          .map(c => new FieldOption(c.name, c.id));
      });
  }

  filter(): void {
    const vendorValue = this.fcVendor.value;
    const hcpcsValue = this.fcHcpcs.value;
    const attributes: IProductAttribute[] = [];

    this.selectedAttributes.forEach((value: IProductAttribute, key: string) => {
      attributes.push(value);
    });

    this.filterChanged.emit({
      isAftermarket: this.fcAftermarket.value,
      filter: this.fcName.value,
      vendor: vendorValue
        ? this.criteriaSource.filter(s => s.id === vendorValue)
        : null,
      hcpcs: hcpcsValue
        ? this.criteriaSource.filter(s => s.id === hcpcsValue)
        : null,
      attributeFilters: attributes
    });
  }

  closeFilters(): void {
    this.close.emit();
  }

  updateAttributeFilters(e: IAttributeChangedEvent): void {
    if (this.selectedAttributes.has(e.name) && e.attribute == null) {
      this.selectedAttributes.delete(e.name);
    } else {
      this.selectedAttributes.set(e.name, e.attribute);
    }
  }

  private createCustomFilterAttributes(): void {
    if (this.fgAttributes == null) {
      this.fgAttributes = this.fb.group({});
    }

    // remove controls from group

    this.attributeGroupings = [];

    const groupings = _.groupBy(this.attributeSource, a => a.name);

    Object.keys(groupings).forEach(key => {
      const attributes = groupings[key];

      const id = v4();
      this.attributeGroupings.push({ id, name: key, attributes });
      this.fgAttributes.addControl(id, new FormControl(''));
    });
  }
}

interface AttributeGrouping {
  id: string;
  name: string;
  attributes: IProductAttribute[];
}
