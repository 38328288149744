import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  HostBinding,
  AfterViewInit
} from '@angular/core';
import {
  FieldOption,
  Product,
  ConfiguredSolution,
  TemplateAvailability,
  Template,
  DataStoreEventType,
  TemplateLoadResults,
  SolutionType,
  SolutionTypeFlowType,
  IProductSelectedEvent
} from 'app/domain';
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder
} from '@angular/forms';
import { untilDestroyed } from 'app/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { AutoCompleteComponent } from '@progress/kendo-angular-dropdowns';
import { fadeUpAndIn } from 'app/domain/animations/fade.up.in';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Data, Router } from '@angular/router';
import { SolutionBuilderLoggerService } from 'app/domain/services/solution-builder-logger.service';
import { TemplateService } from 'app/services/template.service';
import { CustomValidators } from 'app/domain/validators/custom.validators';
import { Branch } from 'app/domain/models/core/models';

import { TemplateClient } from './template-client.service';

@Component({
  selector: 'sb-edit-template',
  templateUrl: './edit-template.component.html',
  styleUrls: ['./edit-template.component.scss'],
  animations: [fadeUpAndIn]
})
export class EditTemplateComponent implements OnInit, OnDestroy, AfterViewInit {
  @HostBinding('class') class: string = 'sb-page-edit-template';

  viewState$: BehaviorSubject<TemplateViewState> = new BehaviorSubject<
    TemplateViewState
  >(TemplateViewState.Empty);

  isSavingTemplate$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );

  /**
   * Make the enum accessible to the template
   */
  TemplateViewState = TemplateViewState;

  /**
   * This is set before the user confirms removal of the Product.
   */
  targetProductToRemove: Product;

  solution: ConfiguredSolution = new ConfiguredSolution(null, null);

  isProductListFiltering: boolean;

  templateLoadResults: TemplateLoadResults;

  fgTemplate: FormGroup;
  fcName: FormControl = new FormControl(
    '',
    Validators.compose([
      Validators.required,
      CustomValidators.preventWhitespaceOnly,
      CustomValidators.preventBeginsWithWhitespace,
      CustomValidators.preventTrailingWhitespace
    ])
  );
  fcSolutionType: FormControl = new FormControl('', Validators.required);
  fcAvailableTo: FormControl = new FormControl('', Validators.required);
  fcBranch: FormControl = new FormControl('');
  fcBaseModel: FormControl = new FormControl(
    { value: '', disabled: true },
    Validators.required
  );

  activeSolution: ConfiguredSolution;
  activeSolutionType: SolutionType;

  configuredSolutions: ConfiguredSolution[] = [];

  supportedSolutionTypes: FieldOption[] = [];

  supportedAvailability: FieldOption[] = [
    new FieldOption('Me', TemplateAvailability.Me),
    new FieldOption('Branch', TemplateAvailability.Branch),
    new FieldOption('Everyone', TemplateAvailability.Everyone)
  ];

  // Make this enum available to the template
  TemplateAvailability = TemplateAvailability;

  branches: FieldOption[] = [];

  showReturnToBaseModelMenuConfirmDialog: boolean;
  showRemoveProductConfirmDialog: boolean;
  showProductLookupDialog: boolean;

  private dataStoreSubscription: Subscription;
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
    private templateClient: TemplateClient,
    private toastrService: ToastrService,
    private logger: SolutionBuilderLoggerService,
    private templateService: TemplateService
  ) {}

  ngOnInit(): void {
    this.fgTemplate = this.fb.group({
      fcName: this.fcName,
      fcBaseModel: this.fcBaseModel,
      fcSolutionType: this.fcSolutionType,
      fcAvailableTo: this.fcAvailableTo,
      fcBranch: this.fcBranch
    });

    //  Store solution type id in the data store
    this.fcSolutionType.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe((solutionTypeId: number) => {
        const solutionType = this.templateClient.getSolutionType(
          solutionTypeId
        );
        this.templateClient.setActiveSolutionType(solutionType);
      });

    this.fcAvailableTo.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe((value: TemplateAvailability) => {
        if (value === TemplateAvailability.Branch) {
          this.fcBranch.setValidators(Validators.required);
        } else {
          this.fcBranch.clearValidators();
        }

        this.fgTemplate.updateValueAndValidity();
      });

    this.viewState$
      .pipe(untilDestroyed(this))
      .subscribe((state: TemplateViewState) => {
        if (state === TemplateViewState.Settings) {
          this.hideSideMenu();
        } else {
          this.showSideMenu();
        }
      });

    // The timing of letting untilDestroy handle the unsubscribe
    // and the datastore cleanup don't come in in an order
    // that allows the side menu to cleanly hide.
    // So we manage to subscription manually.
    this.dataStoreSubscription = this.templateClient
      .events()
      .subscribe((event: DataStoreEventType) => {
        switch (event) {
          case DataStoreEventType.SolutionTypeChange: {
            this.handleSolutionTypeChange();
            break;
          }
          case DataStoreEventType.SolutionsChanged: {
            this.handleSolutionsChanged();
            break;
          }
          case DataStoreEventType.SolutionActivated: {
            this.handleSolutionActivated();
            break;
          }

          default: {
            this.logger.warn('DataStore event type not recognized.');
          }
        }

        // Update whether the solution type is editable
        this.updateSolutionTypeControlState();

        // Update the menu
        this.handleMenuDisplay();
      });

    this.activatedRoute.data.subscribe((resp: Data) => {
      // Set solution type options
      const solutionTypes: SolutionType[] = resp.solutionTypes;
      this.supportedSolutionTypes = solutionTypes
        .filter(st => st.requiresBaseModelSelection())
        .map(st => new FieldOption(st.name, st.id));

      // Set branch options
      const branches: Branch[] = resp.branches;

      if (branches) {
        this.branches = branches.map(b => new FieldOption(b.name, b.branchId));
      }

      // Check for existing template
      this.templateLoadResults = resp.template;
      const template: Template = this.templateLoadResults
        ? this.templateLoadResults.template
        : null;

      if (template) {
        this.logger.info('Loaded Template.');

        this.fcName.setValue(template.name);
        this.fcSolutionType.setValue(template.solutionTypeId);
        this.fcAvailableTo.setValue(template.scope);

        if (template.scope === TemplateAvailability.Branch) {
          this.fcBranch.setValue(Number.parseInt(template.branch));
        }

        if (this.templateLoadResults.hasSolutions()) {
          const solutions: ConfiguredSolution[] = this.templateLoadResults.getAsConfiguredSolutions();
          this.templateClient.addSolutions(solutions);
        }
      }
    });
  }

  ngAfterViewInit(): void {}
  ngOnDestroy(): void {
    this.dataStoreSubscription.unsubscribe();
    this.templateClient.reset();
    this.hideSideMenu();
  }

  isAllowedToSave(): boolean {
    return this.fgTemplate.valid;
  }

  solutionHasProducts(): boolean {
    return this.activeSolution && this.activeSolution.hasProducts();
  }

  solutionHasBaseModel(): boolean {
    return this.activeSolution && this.activeSolution.hasBase();
  }

  showDefaultView(): void {
    this.viewState$.next(TemplateViewState.Settings);
  }

  shouldShowProductsHelp(): boolean {
    if (!this.solutionHasProducts()) {
      return true;
    }

    if (!this.solutionHasBaseModel()) {
      return true;
    }
    return false;
  }

  shouldShowBaseModelSection(): boolean {
    return this.isSolutionSingleWorkflow() || this.isSolutionRepairWorkflow();
  }

  removeBaseModel(): void {
    // Removing the Base model means we are going to remove
    // it as a solution including its products
    this.templateClient.removeSolution(this.activeSolution);
    this.showBaseModelView();
  }

  saveTemplate(): void {
    if (this.fgTemplate.valid) {
      const template = new Template();
      template.id = this.templateLoadResults
        ? this.templateLoadResults.templateId
        : -1;
      template.name = this.fcName.value;
      template.solutionTypeId = this.fcSolutionType.value;
      template.scope = this.fcAvailableTo.value;
      if (template.scope == TemplateAvailability.Branch) {
        template.branch = this.fcBranch.value;
      } else {
        template.branch = null;
      }

      this.templateClient.saveTemplate(template).subscribe(
        (templateId: number) => {
          this.toastrService.success('Template was saved successfully.');
          this.router.navigateByUrl(`/admin/templates/${templateId}`);
        },
        () => {
          this.toastrService.error(
            'There was an issue saving the Template.  Please check the console.'
          );
        }
      );
    }
  }

  toggleProductLookup(show: boolean = true): void {
    this.showProductLookupDialog = show;
  }
  showConfirmRemoveProductDialog(product: Product): void {
    this.targetProductToRemove = product;
    this.showRemoveProductConfirmDialog = true;
  }

  hideConfirmRemoveProductDialog(): void {
    this.showRemoveProductConfirmDialog = false;
    this.targetProductToRemove = null;
  }

  removeProduct(): void {
    this.hideConfirmRemoveProductDialog();
  }

  setBaseModel(product: Product): void {
    if (this.activeSolution) {
      this.activeSolution.base = product;
    } else {
      const solution = new ConfiguredSolution(product);
      solution.isChairOnly = true;
      this.templateClient.addSolution(solution);
    }
  }

  showBaseModelView(): void {
    if (this.isSolutionSingleWorkflow()) {
      if (this.fcSolutionType.value) {
        this.showSideMenu();
        this.viewState$.next(TemplateViewState.BaseModel);
      } else {
        this.toastrService.info(
          'Selecting a Base Model requires first selecting the Solution Type'
        );
      }
    }
  }
  showProductsView(): void {
    this.viewState$.next(TemplateViewState.Products);
  }

  showMultiFlowView(): void {
    this.viewState$.next(TemplateViewState.Multi);
  }

  showSettingsView(): void {
    this.viewState$.next(TemplateViewState.Settings);
  }

  shouldShowSettingsView(): boolean {
    return this.viewState$.value === TemplateViewState.Settings;
  }

  shouldShowBaseModelView(): boolean {
    return this.viewState$.value === TemplateViewState.BaseModel;
  }

  shouldShowProductsView(): boolean {
    return this.viewState$.value === TemplateViewState.Products;
  }

  shouldShowSingleBaseModelMenu(): boolean {
    const viewState = this.viewState$.value;
    const solutionType = this.templateClient.getSolutionType(
      this.fcSolutionType.value
    );
    return (
      viewState === TemplateViewState.BaseModel &&
      solutionType.flow === SolutionTypeFlowType.Single
    );
  }

  shouldShowMultiBaseModelMenu(): boolean {
    const viewState = this.viewState$.value;

    if (viewState === TemplateViewState.Multi) {
      return true;
    }

    return false;
  }

  showSideMenu(): void {
    document.body.classList.add('sb-shift-left', 'sb-no-scroll');
  }
  hideSideMenu(): void {
    document.body.classList.remove('sb-shift-left', 'sb-no-scroll');
  }

  /**
   * Callback by the UI when a Product has been selected.
   */
  addProductToSolution(event: IProductSelectedEvent): void {
    const product = event.product;

    if (product.isBaseProduct) {
      const solution = new ConfiguredSolution(product, null, null);
      this.templateClient.addSolution(solution);
    } else {
      this.templateClient.addProduct(product, event.isAftermarket);
    }
  }

  /**
   * Callback by the UI when a Product has been removed.
   */
  removeProductFromSolution(event: IProductSelectedEvent): void {
    this.templateClient.removeProduct(event.product, event.isAftermarket);
  }

  updateQuantity(product: Product, shouldIncrement: boolean = true): void {
    if (shouldIncrement) {
      this.templateClient.incrementProductQuantity(product);
    } else {
      this.templateClient.decrementProductQuantity(product);
    }
  }

  isSolutionSingleWorkflow(): boolean {
    const value = this.fcSolutionType.value;
    if (value) {
      const solutionType = this.templateClient.getSolutionType(value);

      return solutionType.flow === SolutionTypeFlowType.Single;
    }
    return false;
  }

  isSolutionMultiWorkflow(): boolean {
    const solutionType = this.templateClient.getSolutionType(
      this.fcSolutionType.value
    );
    return solutionType && solutionType.flow === SolutionTypeFlowType.Multi;
  }

  isSolutionRepairWorkflow(): boolean {
    const value = this.fcSolutionType.value;
    if (value) {
      return this.templateClient.isRepairSolutionType(value);
    }
    return false;
  }

  private updateSolutionTypeControlState(): void {
    const hasBase = this.solutionHasBaseModel();
    const hasProducts = this.solutionHasProducts();

    this.fcSolutionType.enable({ emitEvent: false });

    if (this.isSolutionSingleWorkflow()) {
      if (hasBase) {
        this.fcSolutionType.disable({ emitEvent: false });
      }
    } else if (this.isSolutionMultiWorkflow()) {
      if (hasProducts) {
        this.fcSolutionType.disable({ emitEvent: false });
      }
    } else if (this.isSolutionRepairWorkflow()) {
      if (hasBase || hasProducts) {
        this.fcSolutionType.disable({ emitEvent: false });
      }
    } else {
      // Do nothing
    }
  }

  /**
   * Handles making sure that we're displaying the right menu
   * based on the state.
   */
  private handleMenuDisplay(): void {
    const hasBaseModel = this.solutionHasBaseModel();
    const solutionType: SolutionType = this.activeSolutionType;

    if (!solutionType) {
      this.showDefaultView();
      return;
    }

    switch (solutionType.flow) {
      case SolutionTypeFlowType.Single: {
        if (!hasBaseModel) {
          this.showBaseModelView();
        } else {
          this.showMultiFlowView();
        }
        break;
      }
      case SolutionTypeFlowType.Multi: {
        this.showMultiFlowView();
        break;
      }
      default: {
        this.showProductsView();
      }
    }
  }

  getSolutionTypeName(): string {
    const type = this.activeSolutionType;
    if (type) {
      return type.name;
    } else {
      return null;
    }
  }

  setActiveSolution(solution: ConfiguredSolution): void {
    this.templateClient.setActiveSolution(solution);
  }

  /**
   * In response to a solution type change event from
   * the data store
   */
  private handleSolutionTypeChange(): void {
    this.activeSolutionType = this.templateClient.getActiveSolutionType();
    this.templateClient.removePartsOnlySolution();

    if (this.activeSolutionType && this.isSolutionMultiWorkflow()) {
      this.templateClient.createPartsOnlySolution();
    }
  }

  private handleSolutionsChanged(): void {
    this.configuredSolutions = this.templateClient.getSolutions();
  }

  private handleSolutionActivated(): void {
    const activeSolution = this.templateClient.getActiveSolution();

    if (this.activeSolution != activeSolution) {
      this.activeSolution = activeSolution;
    }
  }
}

enum TemplateViewState {
  None = 'None',
  Empty = 'Empty',
  Settings = 'Settings',
  BaseModel = 'BaseModel',
  Multi = 'Multi',
  Products = 'Products'
}
