import { Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { OAuthErrorEvent, OAuthService } from 'angular-oauth2-oidc';
import {
  BehaviorSubject,
  combineLatest,
  Observable,
  ReplaySubject
} from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { SolutionBuilderLoggerService } from 'app/domain/services/solution-builder-logger.service';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  private isAuthenticatedSubject$ = new BehaviorSubject<boolean>(false);
  public isAuthenticated$ = this.isAuthenticatedSubject$.asObservable();

  private isDoneLoadingSubject$ = new ReplaySubject<boolean>();
  public isDoneLoading$ = this.isDoneLoadingSubject$.asObservable();

  /**
   * Publishes `true` if and only if (a) all the asynchronous initial
   * login calls have completed or errorred, and (b) the user ended up
   * being authenticated.
   *
   * In essence, it combines:
   *
   * - the latest known state of whether the user is authorized
   * - whether the ajax calls for initial log in have all been done
   */
  public canActivateProtectedRoutes$: Observable<boolean> = combineLatest([
    this.isAuthenticated$,
    this.isDoneLoading$
  ]).pipe(map(values => values.every(b => b)));

  constructor(
    private oauthService: OAuthService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private logger: SolutionBuilderLoggerService
  ) {
    // Useful for debugging:
    this.oauthService.events.subscribe(event => {
      if (event instanceof OAuthErrorEvent) {
        this.logger.error('OAuth Error', event);
      } else {
        this.logger.warn(`OAuth Warning: ${event.type}`);
      }
    });

    // This is tricky, as it might cause race conditions (where access_token is set in another
    // tab before everything is said and done there.
    // TODO: Improve this setup.
    window.addEventListener('storage', event => {
      // The `key` is `null` if the event was caused by `.clear()`
      if (event.key !== 'access_token' && event.key !== null) {
        return;
      }

      this.logger.warn(
        'Noticed changes to access_token (most likely from another tab), updating isAuthenticated'
      );
      this.isAuthenticatedSubject$.next(
        this.oauthService.hasValidAccessToken()
      );

      if (!this.oauthService.hasValidAccessToken()) {
        this.navigateToLoginPage();
      }
    });

    this.oauthService.events.subscribe(_ => {
      this.isAuthenticatedSubject$.next(
        this.oauthService.hasValidAccessToken()
      );
    });

    this.oauthService.events
      .pipe(filter(e => ['token_received'].includes(e.type)))
      .subscribe(e => this.oauthService.loadUserProfile());

    this.oauthService.events
      .pipe(
        filter(e => ['session_terminated', 'session_error'].includes(e.type))
      )
      .subscribe(e => this.navigateToLoginPage());

    this.oauthService.setupAutomaticSilentRefresh();
  }

  public runInitialLoginSequence(): Promise<void> {
    if (location.hash) {
      this.logger.info('Encountered hash fragment, plotting as table...');
    }

    // 0. LOAD CONFIG:
    // First we have to check to see how the IdServer is
    // currently configured:
    return this.oauthService
      .loadDiscoveryDocumentAndTryLogin()
      .then(() => {
        if (this.oauthService.hasValidAccessToken()) {
          return Promise.resolve();
        } else {
          this.login(window.location.pathname);
        }
      })
      .then(() => {
        this.isDoneLoadingSubject$.next(true);

        // Check for the strings 'undefined' and 'null' just to be sure. Our current
        // login(...) should never have this, but in case someone ever calls
        // initImplicitFlow(undefined | null) this could happen.
        if (
          this.oauthService.state &&
          this.oauthService.state !== 'undefined' &&
          this.oauthService.state !== 'null'
        ) {
          let stateUrl = this.oauthService.state;
          if (stateUrl.startsWith('/') === false) {
            stateUrl = decodeURIComponent(stateUrl);
          }
          this.logger.info(
            `There was state of ${this.oauthService.state}, so we are sending you to: ${stateUrl}`
          );
          this.logger.warn('Navigating from saved sate.');
          this.router.navigateByUrl(stateUrl);
        } else {
          this.logger.warn('Navigating from initial navigation.');
          this.router.initialNavigation();
        }
      })
      .catch(() => this.isDoneLoadingSubject$.next(true));
  }

  public login(targetUrl?: string): void {
    // Note: before version 9.1.0 of the library you needed to
    // call encodeURIComponent on the argument to the method.
    this.oauthService.initLoginFlow(targetUrl || this.router.url);
  }

  public logout(): void {
    this.oauthService.logOut();
  }
  public refresh(): void {
    this.oauthService.silentRefresh();
  }
  public hasValidToken(): boolean {
    return this.oauthService.hasValidAccessToken();
  }

  // These normally won't be exposed from a service like this, but
  // for debugging it makes sense.
  public get accessToken(): string {
    return this.oauthService.getAccessToken();
  }
  public get refreshToken(): string {
    return this.oauthService.getRefreshToken();
  }
  public get identityClaims(): object {
    return this.oauthService.getIdentityClaims();
  }
  public get idToken(): string {
    return this.oauthService.getIdToken();
  }
  public get logoutUrl(): string {
    return this.oauthService.logoutUrl;
  }

  private navigateToLoginPage(): void {
    this.router.navigateByUrl('/landing/requires-login');
  }
}
