import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SolutionBuilderDomainModule } from 'app/domain';
import { SolutionBuilderSharedModule } from 'app/shared';
import { ReactiveFormsModule } from '@angular/forms';
import { SortablejsModule } from 'ngx-sortablejs';
import { SbGlobalFiltersFormComponent } from './global-filters-form/global-filters-form.component';
import { SbProductLookupDialogComponent } from './product-lookup-dialog/product-lookup-dialog.component';
import { SbLoanerDialogComponent } from './loaner-dialog/loaner-dialog.component';

@NgModule({
  declarations: [
    SbGlobalFiltersFormComponent,
    SbProductLookupDialogComponent,
    SbLoanerDialogComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    SolutionBuilderDomainModule,
    SolutionBuilderSharedModule,
    SortablejsModule.forRoot({ animation: 150 })
  ],
  exports: [
    SbGlobalFiltersFormComponent,
    SbProductLookupDialogComponent,
    SbLoanerDialogComponent
  ],
  providers: []
})
export class SolutionBuilderCommonViewsModule {}
