<div class="field-control-wrapper" [class.has-errors]="shouldShowErrors()">
  <label [attr.for]="id" class="field-label"
    >{{ label }}
    <sb-required-marker *ngIf="required"> </sb-required-marker>
  </label>
  <div class="field-control-group" [class.has-icon]="iconClass != null">
    <span *ngIf="iconClass" class="icon {{ iconClass }}"></span>

    <kendo-numerictextbox
      class="field-control"
      [formControl]="control"
      [format]="'c'"
      [decimals]="2"
      [min]="0"
    ></kendo-numerictextbox>
  </div>
</div>
