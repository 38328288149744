import { Injectable } from '@angular/core';
import {
  Resolve,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router
} from '@angular/router';
import { Observable, of, TimeoutError } from 'rxjs';
import {
  QuoteLineItem,
  WorkOrderLoadResults,
  SolutionResolvedModel,
  Product,
  ConfiguredSolution,
  SolutionTypeFlowType,
  SolutionGrouping,
  LOANER_PRODUCT_NUMBER
} from 'app/domain';
import { switchMap, catchError, timeout } from 'rxjs/operators';
import * as _ from 'lodash';
import { SolutionBuilderLoggerService } from 'app/domain/services/solution-builder-logger.service';
import { HttpErrorResponse } from '@angular/common/http';

import { WorkOrderService } from 'app/services/work-order.service';
import { SolutionBuilderClient } from '../services/solution-builder-client.service';

@Injectable({ providedIn: 'root' })
export class ChairBuilderResolver
  implements Resolve<Observable<SolutionResolvedModel>> {
  constructor(
    private solutionBuilderClient: SolutionBuilderClient,
    private workOrderService: WorkOrderService,
    private loggerService: SolutionBuilderLoggerService,
    private router: Router
  ) {}
  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<SolutionResolvedModel> {
    const workOrderId = route.paramMap.get('workOrderId');

    this.solutionBuilderClient.reset();

    // Otherwise read in from CE
    return this.workOrderService.getWorkOrder(workOrderId).pipe(
      timeout(120000),
      catchError((err: any) => {
        if (err instanceof TimeoutError) {
          this.loggerService.error(
            'The request to retrieve a Work Order from CE has timed out.'
          );
        } else if (err instanceof HttpErrorResponse) {
          if (err.status === 404) {
            this.loggerService.warn(
              'The WO being requested does not exist in CE'
            );
            this.router.navigate(['/landing/error'], {
              queryParams: {
                message: 'The Work Order cannot be found.'
              }
            });
          }
        }
        return of(null);
      }),
      switchMap((response: WorkOrderLoadResults) => {
        if (!response) {
          this.router.navigate([`/landing/error`], {
            queryParams: {
              message: 'There seems to be an issue with loading the Work Order.'
            }
          });
        }

        if (response.isRepairOrModification()) {
          this.router.navigateByUrl(`/repair/${workOrderId}`);
          return;
        } else {
          const lineItems = response.lineItems;
          const solutionType = response.solutionType;

          // Always set ER and editable
          this.solutionBuilderClient.setExpectedReimbursement(
            response.expectedReimbursementInfo
          );

          // Try loading locally.
          // This should only pull the configured solutions, not additional meta
          let resolvedData: SolutionResolvedModel = this.solutionBuilderClient.loadFromLocal(
            workOrderId
          );

          if (!resolvedData) {
            resolvedData = new SolutionResolvedModel();
            resolvedData.type = solutionType;
          }

          resolvedData.isEditable = response.isEditable;
          resolvedData.workOrderId = workOrderId;
          resolvedData.orderType = response.orderType;
          resolvedData.workOrderType = response.type;
          resolvedData.clientName = response.fullName;
          resolvedData.nonProfileBaseExplanation = response.nonProfile;

          if (resolvedData.hasSolutions()) {
            // We've loaded from cache,
            // the model should be good to return
            return of(resolvedData);
          }

          /*if (response.hasMissingProducts()) {
            const loaner: QuoteLineItem = response.missingItems.find(
              p => p.productNumber === LOANER_PRODUCT_NUMBER
            );

            if (loaner) {
              const loanerProduct = new Product();
              loanerProduct.productNumber = loaner.productNumber;
              loanerProduct.defaultQuantity = loaner.quantity;
              loanerProduct.externalNote = loaner.externalNotes;

              resolvedData.loanerProduct = loanerProduct;
            }
          }*/

          // Handle Semi-Custom Products
          if (response.hasSemiCustomProducts()) {
            const semiCustomProducts = response.getSemiCustomProducts();
            semiCustomProducts.forEach((p: Product) => {
              const lineItem = response.lineItems.find(
                i => i.productNumber === p.productNumber
              );
              if (lineItem) {
                p.msrp = lineItem.msrp;
                p.costPricePerUnit = lineItem.cost;
              } else {
                this.loggerService.warn(
                  `Product ${p.productNumber} is missing an associated line item.`
                );
              }
            });
          }

          if (response.hasGroupings()) {
            response.solutions.map((grouping: SolutionGrouping) => {
              const solution = new ConfiguredSolution();
              solution.base = grouping.base;
              solution.products = grouping.products;

              if (
                solution.hasBase() &&
                solutionType.flow === SolutionTypeFlowType.Single
              ) {
                solution.isChairOnly = true;
              }
              if (solution.products) {
                solution.products.forEach((p: Product) => {
                  const lineItem: QuoteLineItem = lineItems.find(
                    l => l.productNumber === p.productNumber
                  );

                  if (lineItem) {
                    p.externalNote = lineItem.externalNotes;
                    p.internalNote = lineItem.internalNotes;
                    p.defaultQuantity = lineItem.quantity;
                  }
                });
              }

              resolvedData.solutions.push(solution);
            });
          }

          // Any uncategorized products are aftermarket products
          // or just general products depending upon the solution type
          if (response.hasAftermarketProducts()) {
            const solution = new ConfiguredSolution();
            solution.products = response.uncategorizedProducts;
            solution.products.forEach((p: Product) => {
              const lineItem: QuoteLineItem = lineItems.find(
                l => l.productNumber === p.productNumber
              );

              if (lineItem) {
                p.externalNote = lineItem.externalNotes;
                p.internalNote = lineItem.internalNotes;
              }
            });

            resolvedData.solutions.push(solution);
          }

          resolvedData.labor = response.laborItems;
          resolvedData.type = response.solutionType;

          return of(resolvedData);
        }
      })
    );
  }
}
