<div [formGroup]="fgLoadTemplate" class="sb-form-wrapper">
  <div class="sb-form-field">
    <sb-combobox-field
      label="Templates"
      [options]="templates$ | async"
      [control]="fcAvailableTemplates"
    ></sb-combobox-field>
  </div>
</div>
<kendo-dialog-actions>
  <button kendoButton (click)="closeDialog()">Cancel</button>
  <button
    kendoButton
    (click)="onConfirmAction()"
    [primary]="true"
    [disabled]="!fgLoadTemplate.valid"
  >
    <span *ngIf="isLoading" class="icon fas fa-spinner fa-spin"></span>Load
    Template
  </button>
</kendo-dialog-actions>
