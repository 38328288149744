import { Injectable } from '@angular/core';
import {
  Resolve,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router
} from '@angular/router';
import { Observable, of, throwError } from 'rxjs';
import { switchMap, tap, catchError, map } from 'rxjs/operators';
import {
  Solution,
  SolutionTypeResolvedModel,
  SolutionType,
  Category
} from 'app/domain';
import { HttpErrorResponse } from '@angular/common/http';
import { SolutionTypeService } from 'app/services/solution-type.service';

/**
 * Resolver to grab the Solution Types from the server and store them.  If we have them cached,
 * we will return those.
 */
@Injectable({ providedIn: 'root' })
export class SolutionTypeSettingsResolver
  implements Resolve<Observable<SolutionTypeResolvedModel>> {
  constructor(
    private router: Router,
    private solutionTypeService: SolutionTypeService
  ) {}
  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<SolutionTypeResolvedModel> {
    const solutionTypeId = route.paramMap.get('solutionTypeId');

    return this.solutionTypeService.getUnassignedSolutionTypes().pipe(
      catchError((error: HttpErrorResponse) => {
        this.router.navigate(['/landing/errors'], {
          queryParams: {
            message:
              'There was an issue retrieving the Unassigned Solution Types for this page.'
          }
        });
        return throwError;
      }),
      switchMap((categories: Category[]) => {
        return this.solutionTypeService
          .getSolutionType(Number.parseInt(solutionTypeId))
          .pipe(
            catchError((e: HttpErrorResponse) => {
              if (e.status === 404) {
                return of(null);
              } else {
                return throwError(e);
              }
            }),
            map((solutionType: SolutionType) => {
              const resolvedModel = new SolutionTypeResolvedModel();
              resolvedModel.unassignedCategories = categories;
              resolvedModel.solutionType = solutionType;

              return resolvedModel;
            })
          );
      })
    );
  }
}
