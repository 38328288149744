<div class="sb-product-item-wrapper">
  <div
    class="sb-product-item"
    [class.is-not-selectable]="!selectable"
    [class.is-selected]="selected"
    [class.has-warning]="product.hasWarningProductNote()"
    [class.has-alert]="product.hasAlertProductNote()"
    [class.is-discontinued]="isDiscontinued"
  >
    <div class="sb-product-item-content">
      <header>
        <h5 class="vendor-name">
          <span>{{ product.vendorName | capitalize }}</span>
        </h5>
        <span
          class="vendor-product-number"
          kendoTooltip
          [attr.title]="product.vendorProductNumber"
          >{{ product.vendorProductNumber }}</span
        >
        <sb-rank-indicator [rank]="product.rank"> </sb-rank-indicator>
      </header>
      <div class="sb-product-content">
        <div
          class="product-description"
          kendoTooltip
          [attr.title]="product.description"
        >
          {{ product.description }}
        </div>

        <div class="hcpcs" *ngIf="product.hasHcpcs()">
          <span kendoTooltip [attr.title]="product | hcpcCodes">{{
            product | hcpcCodes
          }}</span>
        </div>
        <sb-tag *ngIf="isDiscontinued" [danger]="true">Discontinued</sb-tag>
      </div>

      <footer *ngIf="!hidePricing">
        <div class="pricing">
          <span class="price" title="MSRP"
            >{{ product.msrp | currency }} /{{ product.unitOfMeasure }}
          </span>
          <label>msrp</label>
        </div>
        <div class="pricing">
          <span class="price" title="Unit Cost"
            >{{ product.costPricePerUnit | currency }} /{{
              product.unitOfMeasure
            }}
          </span>
          <label>unit cost</label>
        </div>
      </footer>

      <div
        *ngIf="selected || product.notes"
        class="sb-product-item-bottom-banner"
      >
        <p *ngIf="selected">Currently added to Solution.</p>
        <p *ngIf="product.notes">{{ product.notes }}</p>
      </div>
    </div>
  </div>
</div>
