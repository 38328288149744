import { Component, OnInit, Input, HostBinding } from '@angular/core';

@Component({
  selector: 'sb-rank-indicator',
  templateUrl: './rank-indicator.component.html',
  styleUrls: ['./rank-indicator.component.scss']
})
export class RankIndicatorComponent implements OnInit {
  @HostBinding('class') class = 'sb-product-rank';

  @Input()
  rank: number;

  constructor() {}

  ngOnInit(): void {}
}
