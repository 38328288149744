import { environment } from 'environments/environment';

export interface IBranch {
  id: number;
  name: string;
  isActive: boolean;
  branchId: number;
}

export class Branch {
  constructor(
    public id: number,
    public name: string,
    public isActive: boolean,
    public branchId: number
  ) {}
}

export interface ISolutionBuilderUser {
  id: number;
  displayName: string;
  firstName: string;
  lastName: string;
  email: string;
  activeDirectoryId: string;
  groups: Array<string>;
}

export class SolutionBuilderUser {
  id: number;
  displayName: string;
  firstName: string;
  lastName: string;
  email: string;
  activeDirectoryId: string;
  groups: Array<string> = [];

  isAdmin(): boolean {
    return (
      this.groups.find(
        (groupId: string) => groupId === environment.adminGroupId
      ) != null
    );
  }

  getAdUsername(): string {
    if (this.email.includes('@'))
      return this.email.substr(0, this.email.indexOf('@'));
    return this.email;
  }
}
