import { Component, OnInit } from '@angular/core';
import {
  TemplateAvailability,
  FieldOption,
  Template,
  Vendor,
  BaseModelRequirements
} from 'app/domain';
import { BehaviorSubject, of } from 'rxjs';
import { FormGroup, FormControl, FormBuilder } from '@angular/forms';
import { TemplateService } from 'app/services/template.service';
import { Router, Data, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import {
  DialogService,
  DialogRef,
  DialogCloseResult
} from '@progress/kendo-angular-dialog';
import { debounceTime, catchError, map } from 'rxjs/operators';
import { untilDestroyed } from 'app/core';
import * as _ from 'lodash';
import { BaseModelRequirementsService } from 'app/services/base-model-requirements.service';

@Component({
  selector: 'app-definitions-list',
  templateUrl: './definitions-list.component.html',
  styleUrls: ['./definitions-list.component.scss']
})
export class DefinitionsListComponent implements OnInit {
  fgDefinitionFilter: FormGroup;
  fcFilter: FormControl = new FormControl('');

  source$: BehaviorSubject<BaseModelRequirements[]> = new BehaviorSubject<
    BaseModelRequirements[]
  >([]);

  definitions$: BehaviorSubject<BaseModelRequirements[]> = new BehaviorSubject<
    BaseModelRequirements[]
  >([]);

  constructor(
    private baseModelRequirementsService: BaseModelRequirementsService,
    private fb: FormBuilder,
    private router: Router,
    private toastrService: ToastrService,
    private dialogService: DialogService,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.fetchDefinitions();

    this.fgDefinitionFilter = this.fb.group({
      fcFilter: this.fcFilter
    });

    this.fcFilter.valueChanges
      .pipe(debounceTime(600), untilDestroyed(this))
      .subscribe((term: string) => {
        const templates = this.source$.value;
        const toLowerTerm = term ? term.toLowerCase() : '';

        if (templates && templates.length > 0) {
          const filtered = templates.filter(
            (template: BaseModelRequirements) => {
              const name = template.model;
              if (name) {
                return name.toLowerCase().indexOf(toLowerTerm) > -1;
              }
              return false;
            }
          );
          this.definitions$.next(filtered);
        }
      });

    this.activatedRoute.data.subscribe((resp: Data) => {
      const vendors: Vendor[] = resp.vendors;
    });
  }

  fetchDefinitions(): void {
    this.baseModelRequirementsService
      .getRequirements()
      .subscribe((requirements: BaseModelRequirements[]) => {
        this.source$.next(requirements);
        this.definitions$.next(requirements);
      });
  }

  ngOnDestroy(): void {}
  startNewDefinition(): void {
    this.router.navigateByUrl('/definitions/-1');
  }

  editTemplateItem(definition: BaseModelRequirements): void {
    this.router.navigateByUrl(`/definitions/${definition.id}`);
  }

  removeSelectedTemplates(definition: BaseModelRequirements): void {
    // this.templateService.deleteTemplates([template.id]).subscribe(
    //   () => {
    //     this.toastrService.success('Successfully deleted Template.');
    //     this.fetchDefinitions();
    //   },
    //   () => {
    //     this.toastrService.error(
    //       'There was an error deleting the Template.  Please check the console.'
    //     );
    //   }
    // );
  }

  showConfirmRemoveDialog(templateItem: BaseModelRequirements): void {
    const dialog: DialogRef = this.dialogService.open({
      title: 'Remove Template',
      content: `Are you sure you want to remove the ${templateItem.model} definition?`,
      actions: [
        { text: 'No', value: false },
        { text: 'Yes', value: true, primary: true }
      ],
      width: 450,
      height: 175,
      minWidth: 250
    });

    dialog.result.subscribe(result => {
      if (result instanceof DialogCloseResult) {
      } else {
        if (result.text === 'Yes') {
          this.removeSelectedTemplates(templateItem);
        } else {
          // User cancelled
        }
      }
    });
  }
}
