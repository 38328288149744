import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormControl,
  Validators
} from '@angular/forms';
import { DialogContentBase, DialogRef } from '@progress/kendo-angular-dialog';
import {
  ConfiguredSolution,
  FieldOption,
  Product,
  QuoteLineItem,
  Template,
  TemplateAvailability
} from 'app/domain';
import { ToastrService } from 'ngx-toastr';
import { SolutionDataStore } from 'app/modules/solution-builder/services/solution-data-store.service';
import * as _ from 'lodash';
import { finalize, map } from 'rxjs/operators';
import { TemplateService } from 'app/services/template.service';
import { untilDestroyed } from 'app/core';
import { Observable } from 'rxjs';
import { Branch } from 'app/domain/models/core/models';
import { BranchService } from 'app/services/branch.service';
import { SolutionBuilderOptionsDataStore } from '../../services/solution-options-data-store.service';
import { SolutionBuilderClient } from '../../services/solution-builder-client.service';
import { QuoteLineItemMapper } from 'app/services/quote-line-item.mapper';

@Component({
  selector: 'sb-save-template-dialog-component',
  templateUrl: './save-template-dialog.component.html',
  styleUrls: ['./save-template-dialog.component.scss']
})
export class SbSaveTemplateDialogComponent extends DialogContentBase
  implements OnInit, OnDestroy {
  fgTemplate: FormGroup;
  fcName: FormControl = new FormControl('New Template', Validators.required);
  fcAvailableTo: FormControl = new FormControl('', Validators.required);
  fcBranch: FormControl = new FormControl('');

  templates$: Observable<FieldOption[]>;

  branches: FieldOption[];

  supportedAvailability: FieldOption[] = [];

  // Makes the enum availbale to the template
  TemplateAvailability = TemplateAvailability;

  isLoading: boolean;

  constructor(
    private fb: FormBuilder,
    private templateService: TemplateService,
    private solutionBuilderClient: SolutionBuilderClient,
    private toastrService: ToastrService,
    private optionsDataStore: SolutionBuilderOptionsDataStore,
    dialog: DialogRef
  ) {
    super(dialog);
  }

  ngOnInit(): void {
    // Template Form Group
    this.fgTemplate = this.fb.group({
      fcName: this.fcName,
      fcAvailableTo: this.fcAvailableTo,
      fcBranch: this.fcBranch
    });

    this.fcAvailableTo.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe((value: TemplateAvailability) => {
        if (value === TemplateAvailability.Branch) {
          this.fcBranch.setValidators(Validators.required);
        } else {
          this.fcBranch.clearValidators();
        }

        this.fgTemplate.updateValueAndValidity();
      });

    // The available templates in the system
    this.templates$ = this.templateService.getTemplates().pipe(
      map((templates: Template[]) => {
        return _.sortBy(
          templates.map(t => new FieldOption(t.name, t.id)),
          (t: FieldOption) => t.text.toLowerCase()
        );
      })
    );

    this.branches = this.optionsDataStore
      .getBranches()
      .map((b: Branch) => new FieldOption(b.name, b.branchId));

    // Set available scopes
    this.supportedAvailability = this.optionsDataStore.getTemplateAvailability();
  }

  ngOnDestroy(): void {
    // Needed for untilDestroyed
  }

  closeDialog(): void {
    this.dialog.close();
  }

  onConfirmAction(): void {
    if (this.fgTemplate.valid) {
      this.saveSolutionAsTemplate();
    } else {
      this.toastrService.warning(
        'The form is invalid.  Please fill out all fields before saving.'
      );
    }
  }

  private saveSolutionAsTemplate(): void {
    const solutionType = this.solutionBuilderClient.getActiveSolutionType();

    if (this.fgTemplate.valid) {
      const template = new Template();
      template.name = this.fcName.value;
      template.solutionTypeId = solutionType.id;
      template.branch = this.fcBranch.value;
      template.scope = this.fcAvailableTo.value;

      const solutions: ConfiguredSolution[] = this.solutionBuilderClient.getSolutions();
      let products: Product[] = [];

      solutions.forEach((solution: ConfiguredSolution) => {
        if (solution.hasBase()) {
          products.push(solution.base);
        }
        if (solution.hasProducts()) {
          products = [...products, ...solution.products];
        }
      });

      template.products = QuoteLineItemMapper.mapToLineItem(products);

      this.isLoading = true;
      this.templateService
        .create(template)
        .pipe(finalize(() => (this.isLoading = false)))
        .subscribe(
          () => {
            this.toastrService.success('Template was saved successfully.');
            this.closeDialog();
          },
          () => {
            this.toastrService.error(
              'There was an issue saving the Template.  Please check the console.'
            );
          }
        );
    }
  }
}
