import { Injectable } from '@angular/core';
import {
  Resolve,
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from '@angular/router';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { TemplateLoadResults } from 'app/domain';

import { HttpErrorResponse } from '@angular/common/http';

import * as _ from 'lodash';
import { TemplateService } from 'app/services/template.service';

/**
 * Resolver to grab the Vendors from the server and store them.  If we have them cached,
 * we will return those.
 */
@Injectable({ providedIn: 'root' })
export class TemplateResolver
  implements Resolve<Observable<TemplateLoadResults>> {
  constructor(private templateService: TemplateService) {}
  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<TemplateLoadResults> {
    const routeId = route.paramMap.get('templateId');
    let id = -1;
    if (routeId) {
      id = Number.parseInt(routeId);
    }
    if (id === -1) {
      return of(null);
    }

    return this.templateService.loadTemplate(id).pipe(
      map((results: TemplateLoadResults) => {
        return results;
      }),
      catchError((e: HttpErrorResponse) => {
        if (e.status === 404) {
          return of(null);
        } else {
          return throwError(e);
        }
      })
    );
  }
}
