<div
  class="sb-container"
  kendoTooltip
  *ngIf="{
    shouldDisableActions: shouldDisableActions$ | async,
    isSavingTemplate: isSavingTemplate$ | async,
    isLoadingTemplate: isLoadingTemplate$ | async,
    isRequestingExpectedReimbursement:
      isRequestingExpectedReimbursement$ | async,
    shouldShowPricingInformation: shouldShowPricingInformation$ | async,
    viewState: viewState$ | async,
    expectedThreshold: expectedThreshold$ | async,
    actualThreshold: actualThreshold$ | async
  } as solutionData"
  kendoDialogContainer
>
  <div #spacer></div>
  <header class="sb-header" stickyThing [spacer]="spacer">
    <div class="sb-header-layout grid-x">
      <div class="small-4 cell">
        <ul class="sb-tab-menu">
          <li>
            <a class="is-active">Solution Builder</a>
          </li>
        </ul>
      </div>
      <div class="small-8 cell align-self-middle text-right">
        <div class="sb-solution-builder-actions" *ngIf="isEditable">
          <kendo-buttongroup look="bare">
            <!--button
              kendoButton
              iconClass="fab fa-accessible-icon"
              title="Loaner Settings"
              (click)="showLoanerDialog()"
            ></button-->
            <button
              kendoButton
              iconClass="fas fa-search"
              title="Product Lookup"
              (click)="toggleProductLookup()"
            ></button>
            <button
              kendoButton
              iconClass="fas fa-filter"
              title="Global Filters"
              (click)="showGlobalFiltersDialog()"
            ></button>
            <button
              kendoButton
              iconClass="fas fa-chair-office"
              title="Seating & Positioning"
              (click)="showSeatingAndPositioningMenu()"
            ></button>
          </kendo-buttongroup>
        </div>
      </div>
    </div>
  </header>
  <section class="sb-bars">
    <div
      class="sb-pricing-bar"
      *ngIf="solutionData.shouldShowPricingInformation"
    >
      <div>
        <span class="total-wrapper">
          <span class="total">{{ totalUnitCost | currency }}</span>
          <label>Total Unit Cost</label>
        </span>
      </div>
      <div>
        <span class="total-wrapper">
          <span class="total"
            ><span
              [class.positive]="
                solutionData.actualThreshold > solutionData.expectedThreshold
              "
              [class.negative]="
                solutionData.actualThreshold < solutionData.expectedThreshold
              "
              >{{ solutionData.actualThreshold | percent: '1.2' }}</span
            >
            / {{ solutionData.expectedThreshold | percent }}</span
          ><label>Margin Actual/Threshold</label>
        </span>
      </div>
      <div>
        <span class="total-wrapper"
          ><span class="total"
            ><span
              *ngIf="solutionData.isRequestingExpectedReimbursement"
              class="icon fas fa-spinner fa-spin"
            ></span
            ><span *ngIf="!solutionData.isRequestingExpectedReimbursement">{{
              totalExpectedReimbursementCost | currency
            }}</span></span
          >
          <label>Expected Reimbursement</label>
        </span>
      </div>
    </div>

    <div class="sb-actions-bar">
      <button
        kendoButton
        [look]="'outline'"
        [primary]="false"
        [iconClass]="'icon fas fa-file-pdf'"
        (click)="showImportView()"
      >
        Import From PDF
      </button>

      <button
        *ngIf="!isAllowedToSave()"
        kendoButton
        [look]="'outline'"
        [primary]="false"
        [iconClass]="'icon fas fa-ballot-check'"
        (click)="openSaveTemplateDialog()"
      >
        Save as Template
      </button>
      <button
        *ngIf="!solutionHasBaseModel()"
        kendoButton
        [look]="'outline'"
        [primary]="false"
        [iconClass]="'icon fas fa-ballot'"
        (click)="openLoadFromTemplateDialog()"
      >
        Start from Template
      </button>
      <button
        kendoButton
        [look]="'outline'"
        [primary]="false"
        [iconClass]="'icon fas fa-trash'"
        (click)="discardSolution()"
      >
        Discard
      </button>
      <button
        kendoButton
        [disabled]="!isAllowedToSave() || solutionData.shouldDisableActions"
        [primary]="true"
        [iconClass]="'icon fas fa-save'"
        (click)="saveQuote()"
      >
        Save
      </button>
    </div>
  </section>
  <main
    class="sb-content"
    [formGroup]="fgChairBuilder"
    #solutionContentContainer
  >
    <div class="sb-content-overflow-wrapper">
      <!-- Page Header Section-->
      <header class="sb-content-header">
        <div class="grid-x  align-middle">
          <div class="small-12 cell">
            <ul class="sb-breadcrumbs">
              <li class="is-parent"><a>Solution Builder</a></li>
              <li class="is-divider"><span>/</span></li>
              <li class="is-active">
                <span>Solutions</span>
              </li>
              <li class="is-divider"><span>/</span></li>
              <li class="is-active">
                <span class="">{{ workOrderId }}</span>
              </li>
            </ul>
            <h1 class="sb-content-heading">
              {{ clientName ? clientName : 'New Client' }}
              <small>{{ workOrderId }}</small>
            </h1>
            <small *ngIf="lastSavedLocallyDate" class="sb-last-saved"
              >Last Saved {{ lastSavedLocallyDate | date: 'short' }}</small
            >

            <div>
              <sb-tag *ngIf="quoteHasLoaner()" [warn]="true">Loaner</sb-tag>
            </div>
          </div>
        </div>
      </header>
      <!-- Select Solution Type -->
      <section
        class="sb-content-section sb-general-settings-section sb-form-wrapper"
        [@fadeUpAndIn]="{
          value: 'in',
          params: { delay: '0ms' }
        }"
      >
        <div class="grid-x">
          <div class="small-4 cell align-self-middle">
            <sb-dropdown-field
              label="Solution Type"
              placeholder="Please select..."
              [control]="fcSolutionType"
              [options]="solutionTypes"
            >
            </sb-dropdown-field>
          </div>
          <div class="small-8 cell text-right align-self-middle">
            <input
              type="checkbox"
              kendoCheckBox
              (click)="togglePricingInformation()"
            />
            <label>Show Pricing</label>
          </div>
        </div>
      </section>

      <sb-non-profile-base-explanation
        *ngIf="shouldShowProductExplanation()"
        [explanation]="getNonProfileBaseExplanation()"
      ></sb-non-profile-base-explanation>

      <div class="sb-configured-solution-tabs">
        <ul class="menu">
          <li *ngFor="let solution of configuredSolutions">
            <a
              (click)="setActiveSolution(solution)"
              [class.is-active]="activeSolution == solution"
              [attr.title]="
                solution.base ? solution.base.description : 'Products Only'
              "
              >{{
                solution.base
                  ? solution.base.description
                  : getSolutionTypeName() + ' Products'
              }}</a
            >
          </li>
        </ul>
      </div>
      <section class="sb-multi-solution-view" *ngIf="activeSolution">
        <div class="sb-flow-item-list no-hover">
          <div class="sb-flow-item-wrapper">
            <div *ngIf="activeSolution.hasBase()">
              <h3 class="sb-solution-heading">Base</h3>
              <sb-selected-product-item
                [product]="activeSolution.base"
                [showPricing]="solutionData.shouldShowPricingInformation"
                [showMsrp]="!isSolutionRepairWorkflow()"
                [editable]="isEditable"
                (remove)="removeBaseModel()"
              ></sb-selected-product-item>
            </div>
            <div *ngIf="activeSolution.hasProducts()">
              <h3 class="sb-solution-heading">Products</h3>
              <sb-selected-product-item
                *ngFor="let product of activeSolution?.products"
                [product]="product"
                [showPricing]="solutionData.shouldShowPricingInformation"
                [showMsrp]="!isSolutionRepairWorkflow()"
                [editable]="isEditable"
                (increment)="updateQuantity($event)"
                (decrement)="updateQuantity($event, false)"
                (remove)="
                  removeProductFromSolution({
                    product: $event,
                    isAftermarket: false
                  })
                "
              ></sb-selected-product-item>
            </div>
            <div *ngIf="activeSolution.laborItems?.length > 0">
              <h3 class="sb-solution-heading">Labor</h3>
              <sb-selected-labor-item
                *ngFor="let labor of activeSolution?.laborItems"
                [labor]="labor"
                [editable]="isEditable"
                (remove)="showConfirmRemoveLaborDialog($event)"
              ></sb-selected-labor-item>
            </div>
          </div>
        </div>
      </section>
    </div>
  </main>
  <!--Side Menus-->
  <sb-empty-menu
    *ngIf="solutionData.viewState === TemplateViewState.Empty"
    [title]="''"
  ></sb-empty-menu>
  <sb-base-model-menu
    *ngIf="shouldShowSingleBaseModelMenu()"
    [solutionTypeId]="fcSolutionType.value"
    (closeMenu)="showDefaultView()"
    (completeSelection)="setBaseModel($event)"
  ></sb-base-model-menu>

  <!--Multi Flow Category + Product Selection View-->
  <sb-multi-flow-menu
    *ngIf="shouldShowMultiBaseModelMenu()"
    [solution]="activeSolution"
    [solutionType]="activeSolutionType"
    [knownProducts]="getKnownProducts()"
    (closeMenu)="showDefaultView()"
    (removeSelection)="removeProductFromSolution($event)"
    (completeSelection)="addProductToSolution($event)"
  ></sb-multi-flow-menu>

  <!-- Seating & Positioning Menu -->
  <sb-seating-positioning-menu
    *ngIf="solutionData.viewState === TemplateViewState.SeatingAndPositioning"
    [solutionType]="seatingPositioningSolutionType"
    (removeSelection)="removeProductFromSolution($event)"
    (completeSelection)="addProductToSolution($event)"
  ></sb-seating-positioning-menu>

  <!--Import Order Form-->
  <sb-import-pdf-menu
    *ngIf="solutionData.viewState == TemplateViewState.Import"
    (closeMenu)="showDefaultView()"
  ></sb-import-pdf-menu>

  <!-- Notes Dialog Template-->
  <ng-template #notesDialog>
    <div [formGroup]="fgNotes" class="sb-form-wrapper">
      <div class="sb-form-field">
        <input type="hidden" formControlName="fcProduct" />
        <sb-text-field
          label="Notes"
          placeholder="Notes"
          multiline="true"
          [control]="fcNote"
        >
          <sb-field-error *ngIf="fcNote.errors?.maxlength"
            >Note may not be longer than 1000 characters.
          </sb-field-error>
        </sb-text-field>
      </div>
    </div>
  </ng-template>

  <!--Product Lookup Dialog-->
  <sb-product-lookup-dialog
    *ngIf="showProductLookupDialog"
    (close)="toggleProductLookup(false)"
  ></sb-product-lookup-dialog>
</div>
