import { Injectable } from '@angular/core';
import {
  Resolve,
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { BranchService } from '../branch.service';
import { FieldOption, TemplateAvailability } from 'app/domain';
import { SolutionBuilderOptionsDataStore } from 'app/modules/solution-builder/services/solution-options-data-store.service';

@Injectable({ providedIn: 'root' })
export class TemplateAvailabilityResolver
  implements Resolve<Observable<FieldOption[]>> {
  constructor(private optionDataStore: SolutionBuilderOptionsDataStore) {}
  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<FieldOption[]> {
    return of(this.optionDataStore.getTemplateAvailability());
  }
}
