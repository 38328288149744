import { Component, OnInit, Input } from '@angular/core';
import { Product, VendorFormLineItem, VendorFormLoadResults } from 'app/domain';

@Component({
  selector: 'sb-import-results',
  templateUrl: './import-results.component.html',
  styleUrls: ['./import-results.component.scss']
})
export class SbImportResultsComponent implements OnInit {
  @Input()
  results: VendorFormLoadResults;

  constructor() {}

  ngOnInit(): void {}
}
