import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { extract } from 'app/core';
import { Shell } from 'app/shell/shell.service';
import { SolutionTypesResolver } from 'app/services/resolvers/solution-types.resolver';
import { VendorsResolver } from 'app/services/resolvers/vendors.resolver';
import { RepairBuilderResolver } from './resolvers/repair-builder.resolver';
import { BranchResolver } from 'app/services/resolvers/branch.resolver';
import { SbRepairBuilderComponent } from './repair-builder.component';

const routes: Routes = [
  Shell.childRoutes([
    {
      path: 'repair',
      children: [
        {
          path: ':workOrderId',
          data: { title: extract('Repair Builder') },
          component: SbRepairBuilderComponent,
          resolve: {
            solutionTypes: SolutionTypesResolver,
            vendors: VendorsResolver,
            solution: RepairBuilderResolver,
            branches: BranchResolver
          }
        },
        {
          path: '**',
          redirectTo: '/landing/error'
        }
      ]
    }
  ])
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: []
})
export class RepairBuilderRoutingModule {}
