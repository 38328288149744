<div class="sb-container" kendoTooltip kendoDialogContainer>
  <div #spacer></div>
  <header class="sb-header" stickyThing [spacer]="spacer">
    <div class="sb-header-layout grid-x">
      <div class="small-4 cell">
        <ul class="sb-tab-menu">
          <li>
            <a class="is-active">Summary</a>
          </li>
        </ul>
      </div>
      <div class="small-8 cell align-self-middle text-right"></div>
    </div>
  </header>

  <main class="sb-content">
    <div class="sb-content-overflow-wrapper">
      <!-- Page Header Section-->
      <header class="sb-content-header">
        <div class="grid-x  align-middle">
          <div class="small-12 large-6 cell">
            <sb-callout>
              Your changes have been saved. Please close this window.
            </sb-callout>
            <ul class="sb-breadcrumbs">
              <li class="is-parent"><a>Solution Builder</a></li>
              <li class="is-divider"><span>/</span></li>
              <li class="is-parent">
                <span>Solutions</span>
              </li>
              <li class="is-divider"><span>/</span></li>
              <li class="is-parent">
                <span class="">{{ workOrderId }}</span>
              </li>
              <li class="is-divider"><span>/</span></li>
              <li class="is-active">
                <span class="">Summary</span>
              </li>
            </ul>
            <h1 class="sb-content-heading">
              Summary
              <small>{{ workOrderId }}</small>
            </h1>
          </div>
        </div>
      </header>
      <!-- Select Solution Type -->
    </div>

    <sb-quote-summary-solution
      *ngFor="let solution of solutions"
      [solution]="solution"
    ></sb-quote-summary-solution>
  </main>
</div>
