/**
 * Expected Reimbursement coming from SB API
 */
export class ClientDetail {
  clientId: string;
  state: string;
  postalCode: string;
  hardshipDiscount: number;
}

export class Amounts {
  amount: number;
  met: number;
  remaining: number;
}

export class BenefitBase {
  payerCode: string;
  beginDate: string;
  expirationDate: string;
  benefit: number;
  coIns: number;
  coPay: number;
}
export class BenefitGroup {
  family: Amounts;
  individual: Amounts;
}
export class BenefitArea extends BenefitBase {
  deductible: BenefitGroup;
  outOfPocket: BenefitGroup;
}

export class BenefitTime {}

export class NonCommercialBenefit extends BenefitBase {
  deductible: Amounts;
}

export class CommercialBenefit {
  isInNetwork: boolean;
  inNetwork: BenefitArea;
  outNetwork: BenefitArea;
  durableMedicalEquipment: BenefitTime;
}

export class EligibilityDetail {
  isCommercial: boolean;
}

export class PayerPlanDetail {
  payerCode: string;
  ordinal: number;
  clientEligibilityDetail: EligibilityDetail;
  hcpcLines: HcpcLineItem[] = [];
}

export class EstimatedReimbursementDetail {
  ordinal: number;
  payerOrdinal: number;
  payerCode: string;
  payerPlanName: string;
  hcpc: string;
  allowable: number = 0;
  billable: number = 0;
  deductible: number = 0;
  upgrades: number = 0;
  maxOutOfPocketOverage: number = 0;
  maxDurableMedicalEquipmentOverage: number = 0;
  discount: number = 0;
  benefitPercent: number = 0;
  coInsPercent: number = 0;
  insuranceRatio: string;
  benefitAmount: number;
  coInsAmount: number;
  difference: number;
  lesserOf: number;
  payerDue: number;
  clientResponsibility: number;
  payerPays: number;
  clientPays: number;
  cob: number;
  method: string = 'Line';
  modifier: string;
  term?: number;
  lesserOfBillableAndAllowable: number;
}

export class ExpectedReimbursementInfo {
  workOrderId: string;
  shipping: number = 0.0;
  salesTaxPercent: number = 0.0;
  client: ClientDetail;
  payers: PayerPlanDetail[] = [];
}

/**
 * Models that get sent to the ER API
 */
export class HcpcLineItem {
  hcpcCode1: string = '';
  hcpcCode2: string = '';
  hcpcCode3: string = '';
  ordinal: number = 0;
  ucrAmount: number = 0;
  msrpAmount: number = 0;
  quantity: number = 0;

  pricingModifier: string = '';
  infoModifier: string = '';
  vendorDiscount1: number = 0;
  vendorDiscount2: number = 0;
  bundleWith: string = '';
  includeWith: string = '';
  upgradeFrom: string = '';
  upgradeModifier: string = '';
  downcodeTo: string = '';
  authorizationStatus: string = '';
  perItemConversionFactor: number = 1.0;
  isTaxable: boolean;
}
export class PayerLineItem {
  payerCode: string;
  hcpcLines: HcpcLineItem[] = [];
}

export class ExpectedReimbursementRequest {
  client: ClientDetail;
  payers: PayerLineItem[];
}

/**
 * ER Response Models
 */
export class ExpectedReimbursementLineItemNote {
  type: number;
  note: string;
}
export class ExpectedReimbursementLineItem {
  ordinal: number;
  payerOrdinal: number;
  payerCode: string;
  payerName: string;
  payerPlanName: string;
  hcpc: string;
  hcpc2: string;
  hcpc3: string;
  allowable: number;
  adjustedAllowable: number;
  billedChargesMethod: string;
  billable: number;
  copay: number;
  deductible: number;
  upgrades: number;
  maxOutOfPocketOverage: number;
  maxDurableMedicalEquipmentOverage: number;
  discount: number;
  benefitPercent: number;
  coInsPercent: number;
  insuranceRatio: string;
  benefitAmount: number;
  coInsAmount: number;
  difference: number;
  lesserOf: number;
  payerDue: number;
  clientResponsibility: number;
  payerPays: number;
  clientPays: number;
  cob: number;
  writeoff: number;
  hardship: number;
  authorization: string;
  method: string = 'Line';
  modifier: string;
  term: string;
  notes: ExpectedReimbursementLineItemNote[] = [];

  isGenericFeeSchedule: boolean;
  isTaxable: boolean;
  isSetAllowable: boolean;
  billingQuantity: number;
  billingUnitOfMeasure: string;
}
export class PayerTotals {
  payerCode: string;
  subtotal: number;
  partsTotal: number;
  laborTotal: number;
  clientTotal: number;
}
export class ExpectedReimbursementTotals {
  deniedPartsTotal: number;
  deniedLaborTotal: number;
  deniedClientTotal: number;
  partsTotal: number;
  laborTotal: number;
  clientTotal: number;
  grandTotal: number;
}
export class ExpectedReimbursementCob {
  lineOrdinal: number;
  hcpc: string;
  total: number;
}
export class ExpectedReimbursementResponse {
  lines: ExpectedReimbursementLineItem[] = [];
  payerTotals: PayerTotals[] = [];
  totals: ExpectedReimbursementTotals;
  coordinationOfBenefits: ExpectedReimbursementCob[];
}
