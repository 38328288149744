import { Component, OnInit, Input, HostBinding } from '@angular/core';

@Component({
  selector: 'sb-strike',
  templateUrl: './strike.component.html',
  styleUrls: ['./strike.component.scss']
})
export class StrikeComponent implements OnInit {
  @HostBinding('class') class: string = 'sb-strike-wrapper';

  @Input()
  upperHeading: string;

  @Input()
  heading: string;

  @Input()
  block: boolean;

  @Input()
  showIndicator: boolean;

  @Input()
  default: boolean;

  @Input()
  current: boolean;

  @Input()
  valid: boolean;

  constructor() {}

  ngOnInit(): void {}
}
