import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CpqField, CpqSection, Product } from 'app/domain';

@Component({
  selector: 'sb-collapsing-category-section',
  templateUrl: './collapsing-category-section.component.html',
  styleUrls: ['./collapsing-category-section.component.scss']
})
export class SbCollapsingCategorySectionComponent implements OnInit {
  @Input()
  section: CpqSection;

  @Input()
  products: Product[] = [];

  @Input()
  selectedField: CpqField;

  @Output()
  onCategorySelection: EventEmitter<CpqField> = new EventEmitter<CpqField>();

  constructor() {}

  ngOnInit(): void {}

  setCategorySelected(field: CpqField): void {
    this.selectedField = field;
    this.onCategorySelection.emit(field);
  }

  hasSelectedProduct(field: CpqField): boolean {
    if (!this.products) {
      return false;
    }

    return (
      this.products.find(
        p => p.categoryName === field.categoryName || p.categoryId === field.id
      ) != null
    );
  }
}
