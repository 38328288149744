<div
  *ngIf="{
    viewState: viewState$ | async,
    field: field$ | async,
    isLoading: isLoading$ | async
  } as data"
>
  <sb-category-filters-menu
    *ngIf="data.viewState === ViewState.Product && showFilters"
    class="sb-selection-menu sb-selection-menu-first"
    [categoryId]="data.field.id"
    (filterChanged)="updateFilters($event)"
    (close)="closeFilters()"
  ></sb-category-filters-menu>
  <sb-empty-menu
    *ngIf="!showFilters"
    class="sb-selection-menu sb-products-menu"
    [class.sb-selection-menu-first]="data.viewState === ViewState.Product"
    [title]="displayTitle | categoryName"
    [canClose]="false"
    [footer]="false"
  >
    <sb-side-menu-action>
      <button
        (click)="toggleExpandAll()"
        class="sb-category-filter-trigger"
        kendoButton
        [toggleable]="true"
        [iconClass]="'icon fas fa-expand'"
      ></button>
    </sb-side-menu-action>
    <sb-side-menu-content>
      <ng-template
        ngFor
        let-section
        [ngForOf]="sections"
        let-parentIndex="index"
      >
        <sb-collapsing-category-section
          [section]="section"
          [products]="products"
          [selectedField]="data.field"
          (onCategorySelection)="setTargetProduct($event)"
        ></sb-collapsing-category-section>
      </ng-template>
      <sb-callout *ngIf="data.isLoading" class="sb-loading-wrapper"
        ><span class="fas fa-spinner fa-spin icon"></span>
        <span class="loading-text"> Loading Configurable Categories</span>
      </sb-callout>
      <div *ngIf="!data.isLoading && (!sections || sections.length === 0)">
        <sb-callout>No Configurable Categories</sb-callout>
      </div>
    </sb-side-menu-content>
  </sb-empty-menu>
  <div
    *ngIf="data.viewState === ViewState.Product"
    class="sb-product-search-container"
  >
    <sb-empty-menu
      *ngIf="data.viewState === ViewState.Product"
      [title]="data.field.categoryName | categoryName"
      class="sb-selection-menu sb-category-products-menu  sb-selection-menu-second sb-selection-product-lookup"
      [canClose]="true"
      [footer]="false"
      (closeMenu)="showCategoryView()"
    >
      <sb-side-menu-action>
        <button
          (click)="showFilters = !showFilters"
          class="sb-category-filter-trigger"
          kendoButton
          [toggleable]="true"
          [iconClass]="'icon fas fa-filter'"
        ></button>
      </sb-side-menu-action>
      <sb-side-menu-content #container>
        <sb-products-lookup
          [field]="data.field"
          [filters]="filters$ | async"
          (removeSelection)="productRemoved($event)"
          (completeSelection)="productSelected($event)"
          [container]="container"
          [preload]="true"
        >
        </sb-products-lookup>
      </sb-side-menu-content>
    </sb-empty-menu>
  </div>
</div>
