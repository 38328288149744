import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import {
  ClientAsset,
  ConfiguredSolution,
  IExplanationModel,
  Product,
  QuoteLineItem,
  SolutionType
} from 'app/domain/models/solution-builder/models';
import { shareReplay } from 'rxjs/operators';
import {
  ExpectedReimbursementInfo,
  ExpectedReimbursementLineItem
} from 'app/domain/models/solution-builder/expected.reimbursement';
import * as _ from 'lodash';

/**
 * Stateful service responsible for holding the currently configured
 * Solution.
 */
@Injectable({
  providedIn: 'root'
})
export class SolutionDataStore {
  private solutionsMap: Map<string, ConfiguredSolution> = new Map<
    string,
    ConfiguredSolution
  >();

  private loanerProduct: Product;

  /**
   * The work order Id we are storing data for.
   */
  private workOrderId: string;

  /**
   * The Solution Type
   */
  private solutionType: SolutionType;

  /**
   * Margin threshold for the solution.  Defaulting to 40.
   */

  private threshold: number = 40;

  /**
   * Expected Reimbursement coming back from the WO Load
   */

  private expectedReimbursement: ExpectedReimbursementInfo;

  /**
   * Expected dollar amount coming back from the ER API
   */
  private expectedReimbursementCost: number;

  /*
   * Explanation for selecting a non-profile base
   */

  private nonProfileBaseExplanation: IExplanationModel;

  /**
   * Expected Reimbursement lines coming back from the UPD Api
   */
  private workOrderExpectedReimbursementLines: ExpectedReimbursementLineItem[];

  /**
   * The repair's client asset
   */
  private clientAsset: ClientAsset;

  constructor() {}

  clearSolutions(): void {
    this.solutionsMap.clear();
  }

  addSolution(storageKey: string, solution: ConfiguredSolution): void {
    this.solutionsMap.set(storageKey, solution);
  }

  /*setLoanerProduct(product: Product): void {
    this.loanerProduct = product;
  }*/

  getLoanerProduct(): Product {
    return this.loanerProduct;
  }

  removeSolution(key: string): boolean {
    return this.solutionsMap.delete(key);
  }

  getSolutions(): ConfiguredSolution[] {
    return Array.from(this.solutionsMap.values());
  }

  setMarginThreshold(margin: number): void {
    this.threshold = margin;
  }

  getMarginThreshold(): number {
    return this.threshold;
  }

  getExpectedReimbursementLinesValue(): ExpectedReimbursementLineItem[] {
    return this.workOrderExpectedReimbursementLines;
  }
  setExpectedReimbursementLines(
    expected: ExpectedReimbursementLineItem[]
  ): void {
    this.workOrderExpectedReimbursementLines = expected;
  }
  getExpectedReimbursement(): ExpectedReimbursementInfo {
    return this.expectedReimbursement;
  }

  setExpectedReimbursement(expected: ExpectedReimbursementInfo): void {
    this.expectedReimbursement = expected;
  }

  getExpectedReimbursementCost(): number {
    return this.expectedReimbursementCost;
  }

  setExpectedReimbursementCost(cost: number) {
    this.expectedReimbursementCost = cost;
  }

  setWorkOrderId(workOrderId: string): void {
    this.workOrderId = workOrderId;
  }

  getWorkOrderId(): string {
    return this.workOrderId;
  }

  setSolutionType(solutionType: SolutionType): void {
    this.solutionType = solutionType;
  }
  getSolutionType(): SolutionType {
    return this.solutionType;
  }

  setNonProfileBaseExplanation(explanation: IExplanationModel) {
    this.nonProfileBaseExplanation = explanation;
  }

  getNonProfileBaseExplanation(): IExplanationModel {
    return this.nonProfileBaseExplanation;
  }

  setClientAsset(asset: ClientAsset): void {
    this.clientAsset = asset;
  }

  getClientAsset(): ClientAsset {
    return this.clientAsset;
  }

  reset(): void {
    this.setSolutionType(null);
    this.clearSolutions();
    this.clientAsset = null;
  }
}
