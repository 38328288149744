<div class="field-control-wrapper" [class.has-errors]="shouldShowErrors()">
  <label [attr.for]="id" class="field-label" [class.hide]="!label"
    >{{ label }} <sb-required-marker *ngIf="required"> </sb-required-marker
  ></label>

  <kendo-buttongroup
    look="outline"
    [selection]="'single'"
    [disabled]="control.disabled"
  >
    <button
      *ngFor="let option of options; let i = index"
      kendoButton
      toggleable="true"
      [primary]="option === selectedOption"
      [selected]="option === selectedOption"
      (click)="setValue(option)"
    >
      {{ option.text }}
    </button>
  </kendo-buttongroup>
</div>
