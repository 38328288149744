import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { map } from 'rxjs/operators';
import { LaborCategory } from 'app/domain/models/solution-builder/models';

@Injectable({ providedIn: 'root' })
export class LaborReasonService {
  constructor(private http: HttpClient) {}

  getLaborCategories(): Observable<LaborCategory[]> {
    return this.http
      .get<LaborCategory[]>(`${environment.apiLaborCodes}/categories`)
      .pipe(
        map((result: LaborCategory[]) => {
          if (result && result.length > 0) {
            return result.map((category: LaborCategory) => {
              return this.createLaborCategory(category);
            });
          }
          return [];
        })
      );
  }

  private createLaborCategory(category: LaborCategory): LaborCategory {
    const c = Object.assign(new LaborCategory(), category);
    if (c.hasChildren()) {
      c.children = category.children.map(ca => this.createLaborCategory(ca));
    }

    return c;
  }
}
