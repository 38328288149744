import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { DialogContentBase, DialogRef } from '@progress/kendo-angular-dialog';
import { untilDestroyed } from 'app/core';
import { LOANER_PRODUCT_NUMBER, Product } from 'app/domain';
import { SolutionBuilderClient } from 'app/modules/solution-builder/services/solution-builder-client.service';

@Component({
  selector: 'sb-loaner-dialog',
  templateUrl: './loaner-dialog.component.html'
})
export class SbLoanerDialogComponent extends DialogContentBase
  implements OnInit {
  fgLoaner: FormGroup;
  fcNotes: FormControl = new FormControl('');
  fcInclude: FormControl = new FormControl(false);

  constructor(
    private solutionBuilderClient: SolutionBuilderClient,
    private fb: FormBuilder,
    dialog: DialogRef
  ) {
    super(dialog);
  }

  ngOnInit(): void {
    this.fgLoaner = this.fb.group({
      fcInclude: this.fcInclude,
      fcNotes: this.fcNotes
    });

    if (this.solutionBuilderClient.quoteHasLoaner()) {
      const product = this.solutionBuilderClient.getLoaner();
      this.fcInclude.setValue(true);
      this.fcNotes.setValue(product.externalNote);
    }
  }

  onCancelAction(): void {
    this.dialog.close();
  }
  onConfirmAction(): void {
    const product = new Product();
    product.description = 'Loaner';
    product.productNumber = LOANER_PRODUCT_NUMBER;
    product.defaultQuantity = 1;
    product.externalNote = this.fcNotes.value;
    this.solutionBuilderClient.setLoaner(product);
    this.dialog.close();
  }
}
