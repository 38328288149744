<div>
  <header class="sb-selection-menu-header">
    <h3 class="sb-selection-menu-header-title">
      Available Categories
      <a class="sb-selection-menu-header-close"
        ><span class="fal fa-times"></span></a
      ><span></span>
    </h3>
  </header>
  <div class="sb-selection-menu-content">
    <a (click)="toggleExpandAll()">Expand All</a>
    <a (click)="toggleExpandAll()">Collapse All</a>
    <ng-template ngFor let-section [ngForOf]="sections" let-parentIndex="index">
      <sb-collapsing-category-section
        [section]="section"
        (onCategorySelection)="toggleRequirement($event)"
      ></sb-collapsing-category-section>
    </ng-template>
  </div>
</div>
