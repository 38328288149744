<label class="sb-section-label">Select A Client Asset</label>
<div class="sb-asset-list-wrapper">
  <div *ngIf="isLoading" class="sb-section-spinner">
    <span class="icon fad fa-circle-notch fa-spin"></span>
  </div>
  <ul class="client-asset-list sb-options-list" *ngIf="!isLoading">
    <li *ngFor="let asset of assets">
      <sb-product-item
        *ngIf="asset.product"
        [product]="asset.product"
        [hidePricing]="true"
        (click)="selectAsset(asset)"
      ></sb-product-item>
      <sb-card *ngIf="!asset.product" (click)="selectAsset(asset)">
        <h3>
          {{
            asset.productIdAlternate
              ? asset.productIdAlternate
              : 'Unknown Asset'
          }}
        </h3>
        <p>NSM does not have an associated Product for this Asset.</p>
      </sb-card>
      <div class="sb-product-item-wrapper">
        <div class="sb-product-item">
          <div class="sb-product-item-content">
            <div class="sb-product-content"></div>
          </div>
        </div>
      </div>
    </li>
    <sb-callout
      class="no-assets-found"
      *ngIf="!isLoading && !addClientAssetItem"
    >
      <span class="sb-set-asset-label">No Client Assets found.</span>
      <span class="sb-set-asset-button">
        <button kendoButton primary="true" (click)="addClientAsset()">
          Add an Asset
        </button>
      </span>
    </sb-callout>
    <sb-card class="client-asset-card" *ngIf="addClientAssetItem">
      <sb-base-model-lookup
        [solutionTypeId]="solutionType"
        (completeSelection)="setBaseModel($event)"
      ></sb-base-model-lookup>
    </sb-card>
  </ul>
</div>
