<sb-empty-menu
  [title]="'Import from PDF'"
  canClose="false"
  footer="true"
  *ngIf="{
    results: results$ | async,
    documents: documents$ | async,
    viewState: viewState$ | async
  } as data"
>
  <sb-side-menu-action>
    <button
      kendoButton
      (click)="toggleHistory()"
      [toggleable]="true"
      [primary]="true"
      [iconClass]="'icon fas fa-history'"
    ></button>
  </sb-side-menu-action>

  <sb-side-menu-content>
    <div [class.hide]="data.viewState === ViewState.HistoryList">
      <div
        class="sb-form-wrapper"
        [formGroup]="fgImport"
        [class.hide]="data.results != null"
      >
        <div class="sb-form-field sb-file-field">
          <label>Order Form<sb-required-marker></sb-required-marker></label>
          <p class="sb-field-field-help">
            Select the Order Form or Drag & Drop it below.
          </p>
          <div [class.hide]="isUploading || isProcessing">
            <kendo-fileselect
              #file
              accept="image/*:"
              [restrictions]="myRestrictions"
              formControlName="fcFile"
            >
              <kendo-fileselect-messages
                dropFilesHere="Drag & Drop PDF Order Form"
                select="Select PDF"
              ></kendo-fileselect-messages>
            </kendo-fileselect>
          </div>
          <div *ngIf="isUploading">
            <kendo-progressbar
              [animation]="true"
              [value]="progress"
            ></kendo-progressbar>
          </div>

          <div *ngIf="isProcessing" class="sb-is-processing">
            <span class="fas fa-spinner-third fa-spin icon"></span>Processing
            Order Form
          </div>
        </div>
      </div>
      <div class="sb-import-results" *ngIf="data.results">
        <sb-callout *ngIf="!data.results.isValid()" [warn]="true">
          The uploaded PDF contained no matching Products.
        </sb-callout>
        <sb-callout *ngIf="data.results.hasMissingItems()" [warn]="true">
          We did not recognize several of the items selected on the PDF.
          <span *ngIf="data.results.hasSolutions()"
            >The ones we did are listed immediately below.</span
          >
        </sb-callout>
        <sb-callout
          *ngIf="data.results.hasSolutions() && !data.results.hasMissingItems()"
          [success]="true"
        >
          Import was successful. Please confirm the list below.
        </sb-callout>
        <sb-import-results
          *ngIf="data.results.hasSolutions()"
          [results]="data.results"
        ></sb-import-results>
      </div>
    </div>
    <sb-import-history
      [isLoading]="isLoadingHistory"
      [documents]="data.documents"
      [class.hide]="data.viewState == ViewState.Upload"
    ></sb-import-history>
  </sb-side-menu-content>
  <sb-side-menu-footer>
    <ul class="menu">
      <li>
        <button
          (click)="validateFile()"
          kendoButton
          [class.hide]="data.results"
          [disabled]="fgImport.invalid || isUploading"
        >
          Validate
        </button>
      </li>
      <li>
        <button
          (click)="importFile()"
          kendoButton
          [class.hide]="data.results"
          [primary]="true"
          [disabled]="fgImport.invalid || isUploading"
        >
          Import
        </button>
      </li>

      <li>
        <button
          (click)="resetImport()"
          [class.hide]="!data.results"
          kendoButton
          class="warning"
          [disabled]="isSaving"
        >
          Try Again
        </button>
      </li>
      <li>
        <button
          (click)="confirmThenImport(data.documents, confirmImportDialog)"
          [class.hide]="!data.results"
          kendoButton
          [primary]="true"
          [disabled]="isSaving"
        >
          <span *ngIf="isSaving" class="icon fas fa-spinner fa-spin"></span>
          Accept Import
        </button>
      </li>
    </ul>
  </sb-side-menu-footer>
</sb-empty-menu>
<ng-template #confirmImportDialog>
  <div>
    We found one or more issues with your import.
    <ul class="sb-import-issues-list">
      <li *ngFor="let issue of issues">
        {{ issue }}
      </li>
    </ul>
  </div>
</ng-template>
