<div *ngIf="results">
  <div *ngFor="let solution of results.solutions">
    <span class="sb-category-group">Products</span>
    <ul class="sb-options-list">
      <li class="disabled" *ngIf="solution.base">
        <a>{{ solution.base.description }}</a>
      </li>
      <li *ngFor="let product of solution.products" class="disabled">
        <a>{{ product.description }}</a>
      </li>
    </ul>
  </div>
  <div *ngIf="results.hasMissingItems()">
    <span class="sb-category-group">Missing Items</span>
    <ul class="sb-options-list">
      <li *ngFor="let item of results.missingItems" class="disabled">
        <a>{{ item.vendorProductNumber }}</a>
      </li>
    </ul>
  </div>
</div>
