<span
  kendoTooltip
  class="sb-product-rank-indicator"
  title="Profile Rank"
  [class.unranked]="rank === 0"
  [class.low]="rank === 4"
  [class.average]="rank === 3"
  [class.medium]="rank === 2"
  [class.high]="rank === 1"
  >{{ rank }}</span
>
