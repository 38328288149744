import { Injectable } from '@angular/core';
import {
  Resolve,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router
} from '@angular/router';
import { Observable, of, TimeoutError } from 'rxjs';
import {
  QuoteLineItem,
  WorkOrderLoadResults,
  SolutionResolvedModel,
  Product,
  ConfiguredSolution,
  SolutionTypeFlowType,
  SolutionGrouping,
  LOANER_PRODUCT_NUMBER
} from 'app/domain';
import { switchMap, catchError, timeout } from 'rxjs/operators';
import * as _ from 'lodash';
import { SolutionBuilderLoggerService } from 'app/domain/services/solution-builder-logger.service';
import { HttpErrorResponse } from '@angular/common/http';

import { WorkOrderService } from 'app/services/work-order.service';
import { SolutionBuilderClient } from 'app/modules/solution-builder/services/solution-builder-client.service';

@Injectable({ providedIn: 'root' })
export class RepairBuilderResolver
  implements Resolve<Observable<SolutionResolvedModel>> {
  constructor(
    private solutionBuilderClient: SolutionBuilderClient,
    private workOrderService: WorkOrderService,
    private loggerService: SolutionBuilderLoggerService,
    private router: Router
  ) {}
  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<SolutionResolvedModel> {
    const workOrderId = route.paramMap.get('workOrderId');

    this.solutionBuilderClient.reset();

    // Otherwise read in from CE
    return this.workOrderService.getWorkOrder(workOrderId).pipe(
      timeout(120000),
      catchError((err: any) => {
        if (err instanceof TimeoutError) {
          this.loggerService.error(
            'The request to retrieve a Work Order from CE has timed out.'
          );
        } else if (err instanceof HttpErrorResponse) {
          if (err.status === 404) {
            this.loggerService.warn(
              'The WO being requested does not exist in CE'
            );
            this.router.navigate(['/landing/error'], {
              queryParams: {
                message: 'The Work Order cannot be found.'
              }
            });
          }
        } else {
        }
        return of([]);
      }),
      switchMap((response: WorkOrderLoadResults) => {
        const lineItems = response.lineItems;
        const solutionType = response.solutionType;

        // Always set ER and editable
        this.solutionBuilderClient.setExpectedReimbursement(
          response.expectedReimbursementInfo
        );

        let resolvedData: SolutionResolvedModel = null;

        // this.solutionBuilderClient.loadFromLocal(
        //   workOrderId
        // );

        if (!resolvedData) {
          resolvedData = new SolutionResolvedModel();
          resolvedData.type = solutionType;
        }

        resolvedData.isEditable = response.isEditable;
        resolvedData.workOrderId = workOrderId;
        resolvedData.orderType = response.orderType;
        resolvedData.workOrderType = response.type;
        resolvedData.clientName = response.fullName;
        resolvedData.clientAsset = response.clientAsset;

        if (resolvedData.hasSolutions()) {
          // We've loaded from cache,
          // the model should be good to return
          return of(resolvedData);
        }

        const solution = new ConfiguredSolution();
        if (response.hasUncategorizedProducts()) {
          solution.products = response.uncategorizedProducts;
          solution.products.forEach((p: Product) => {
            const lineItem: QuoteLineItem = lineItems.find(
              l => l.productNumber === p.productNumber
            );

            if (lineItem) {
              p.externalNote = lineItem.externalNotes;
              p.internalNote = lineItem.internalNotes;
            }
          });
        }

        // Load in labor items.
        if (response.hasLaborItems()) {
          solution.laborItems = response.laborItems;
        }

        /*if (response.hasMissingProducts()) {
          const loaner: QuoteLineItem = response.missingItems.find(
            p => p.productNumber === LOANER_PRODUCT_NUMBER
          );

          const loanerProduct = new Product();
          loanerProduct.productNumber = loaner?.productNumber;
          loanerProduct.defaultQuantity = loaner?.quantity;
          loanerProduct.externalNote = loaner?.externalNotes;

          resolvedData.loanerProduct = loanerProduct;
        }*/

        resolvedData.labor = response.laborItems;
        resolvedData.type = response.solutionType;
        resolvedData.solutions.push(solution);

        return of(resolvedData);
      })
    );
  }
}
