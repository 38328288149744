import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ServiceWorkerModule } from '@angular/service-worker';
import { TranslateModule } from '@ngx-translate/core';

import { environment } from '../environments/environment';
import { CoreModule, ErrorHandlerInterceptor } from 'app/core';
import { SolutionBuilderSharedModule } from 'app/shared';

import { ShellModule } from './shell/shell.module';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { SolutionBuilderModule } from './modules/solution-builder/solution-builder.modules';

import { UploadModule } from '@progress/kendo-angular-upload';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ProgressBarModule } from '@progress/kendo-angular-progressbar';
import { LandingPagesModule } from './modules/landing-pages/landing-pages.module';

import { SolutionBuilderAdminModule } from './modules/admin/admin.module';
import { RepairBuilderModule } from './modules/repair-builder/repair-builder.modules';

@NgModule({
  imports: [
    BrowserModule,
    ServiceWorkerModule.register('./ngsw-worker.js', {
      enabled: environment.production
    }),
    FormsModule,
    HttpClientModule,
    TranslateModule.forRoot(),
    CoreModule.forRoot(),
    SolutionBuilderSharedModule,
    ShellModule,
    SolutionBuilderModule,
    SolutionBuilderAdminModule,
    RepairBuilderModule,
    LandingPagesModule,
    AppRoutingModule,
    UploadModule,
    BrowserAnimationsModule,
    ProgressBarModule
  ],
  declarations: [AppComponent],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorHandlerInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
