import { Injectable } from '@angular/core';
import {
  Resolve,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router
} from '@angular/router';
import { Observable, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { BaseModelRequirements } from 'app/domain';
import { HttpErrorResponse } from '@angular/common/http';
import { BaseModelRequirementsService } from 'app/services/base-model-requirements.service';

/**
 * Resolver to grab the Base Model Requirement that we are trying to edit.
 */
@Injectable({ providedIn: 'root' })
export class BaseModelRequirementsResolver
  implements Resolve<Observable<BaseModelRequirements>> {
  constructor(
    private baseModelRequirementsService: BaseModelRequirementsService
  ) {}
  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<BaseModelRequirements> {
    const paramId = route.paramMap.get('definitionId');
    let bmrId = -1;
    if (bmrId) {
      bmrId = Number.parseInt(paramId);
    }
    return this.baseModelRequirementsService.getRequirement(bmrId).pipe(
      catchError((e: HttpErrorResponse) => {
        if (e.status === 404) {
          return of(null);
        } else {
          return throwError(e);
        }
      })
    );
  }
}
