<div
  *ngIf="{
    isLoading: isLoading$ | async,
    products: products$ | async
  } as data"
  class="sb-product-view-content"
  [style.height.px]="height"
  (scroll)="onScroll($event)"
  #scrollContainer
>
  <div *ngIf="showScrollToTop" class="sb-scroll-to-top-wrapper">
    <a class="sb-scroll-to-top" (click)="scrollToTop(scrollContainer)"
      ><span class="fas fa-arrow-alt-up"></span
    ></a>
  </div>
  <!-- Product Lookup View-->
  <section class="sb-product-view">
    <div>
      <sb-callout *ngIf="data.isLoading" class="sb-loading-wrapper"
        ><span class="fas fa-spinner fa-spin icon"></span>
        <span class="loading-text">
          Loading Products...
        </span>
      </sb-callout>

      <sb-callout *ngIf="!data.isLoading && showNoProductsFound">
        No Products found.
      </sb-callout>
      <ul class="sb-options-list" *ngIf="!data.isLoading">
        <li *ngFor="let option of data.products | rankProducts; index as i">
          <sb-product-item
            [product]="option"
            [selected]="hasAlreadySelectedProduct(option)"
            (click)="selectProduct(option)"
          ></sb-product-item>
        </li>
      </ul>
      <div class="sb-load-more-wrapper">
        <a
          class="sb-load-more"
          *ngIf="allProducts.length > 0"
          (click)="loadMore()"
          >Load More</a
        >
      </div>
    </div>
  </section>
</div>
