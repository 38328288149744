import { Component, OnInit, AfterViewInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormControl,
  Validators
} from '@angular/forms';
import { DialogContentBase, DialogRef } from '@progress/kendo-angular-dialog';
import { Product } from 'app/domain';
import { SolutionBuilderLoggerService } from 'app/domain/services/solution-builder-logger.service';
import { CustomValidators } from 'app/domain/validators/custom.validators';
import { ToastrService } from 'ngx-toastr';
import { SolutionDataStore } from 'app/modules/solution-builder/services/solution-data-store.service';
import { SolutionBuilderClient } from '../../services/solution-builder-client.service';

@Component({
  selector: 'sb-product-notes-dialog-component',
  templateUrl: './product-notes-dialog.component.html',
  styleUrls: ['./product-notes-dialog.component.scss']
})
export class SbNoteFormDialogComponent extends DialogContentBase
  implements OnInit, AfterViewInit {
  fgNote: FormGroup;

  fcInternalNotes: FormControl = new FormControl(
    '',
    Validators.compose([
      CustomValidators.preventWhitespaceOnly,
      Validators.maxLength(1000)
    ])
  );

  fcExternalNotes: FormControl = new FormControl(
    '',
    Validators.compose([
      CustomValidators.preventWhitespaceOnly,
      Validators.maxLength(500)
    ])
  );

  product: Product;

  constructor(
    private fb: FormBuilder,
    private solutionBuilderClient: SolutionBuilderClient,
    private logger: SolutionBuilderLoggerService,
    private toastr: ToastrService,
    dialog: DialogRef
  ) {
    super(dialog);
  }

  ngOnInit(): void {
    this.fgNote = this.fb.group({
      fcInternalNotes: this.fcInternalNotes,
      fcExternalNotes: this.fcExternalNotes
    });
  }

  ngAfterViewInit(): void {
    if (this.product) {
      this.fcExternalNotes.setValue(this.product.externalNote);
      this.fcInternalNotes.setValue(this.product.internalNote);
    } else {
      this.logger.debug('No Product was passed to the dialog.');
    }
  }

  onCancelAction(): void {
    this.dialog.close();
  }

  onConfirmAction(): void {
    if (this.fgNote.valid && this.product) {
      this.product.externalNote = this.fcExternalNotes.value;
      this.product.internalNote = this.fcInternalNotes.value;
      // Need to explicitly call this so
      // the changes are saved locally.
      this.solutionBuilderClient.saveLocally();
      this.onCancelAction();
    } else {
      this.toastr.warning(
        'The form is invalid.  Please fill out all fields before saving.'
      );
    }
  }
}
