import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './core/authentication/auth-guard.service';
import { AuthGuardWithForcedLogin } from './core/authentication/auth-guard-with-forced-login.service';

const routes: Routes = [
  // Fallback when no prior route is matched
  {
    path: '',
    redirectTo: '/builder',
    pathMatch: 'full',
    canActivate: [AuthGuardWithForcedLogin]
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'disabled'
    })
  ],
  exports: [RouterModule],
  providers: []
})
export class AppRoutingModule {}
