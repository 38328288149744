import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { LaborCategory } from 'app/domain';
import { fadeUpAndIn } from 'app/domain/animations/fade.up.in';
import { LaborReasonService } from 'app/services/labor-reason.service';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'sb-pick-labor-item',
  templateUrl: './pick-labor-item.component.html',
  styleUrls: ['./pick-labor-item.component.scss'],
  animations: [fadeUpAndIn]
})
export class PickLaborItemComponent implements OnInit {
  @Output()
  completeSelection: EventEmitter<LaborCategory> = new EventEmitter<
    LaborCategory
  >();

  /**
   * Contains the source array of LaborCategories
   */
  source$: BehaviorSubject<LaborCategory[]> = new BehaviorSubject<
    LaborCategory[]
  >([]);

  /**
   * Holds the currently selected Category.  In the case we are at the root
   * of the tree, this will be null;
   */
  selectedCategory$: BehaviorSubject<LaborCategory> = new BehaviorSubject<
    LaborCategory
  >(null);

  /**
   * Holds the displayable children of the selected Category.  If the selected
   * Category is null, we assume we are at the root and the source needs to be displayed.
   */
  children$: BehaviorSubject<LaborCategory[]> = new BehaviorSubject<
    LaborCategory[]
  >([]);

  constructor(private laborReasonService: LaborReasonService) {}

  ngOnInit(): void {
    this.laborReasonService
      .getLaborCategories()
      .subscribe((categories: LaborCategory[]) => {
        this.source$.next(
          categories.filter(c => c.children && c.children.length > 0)
        );
        this.children$.next(this.source$.value);
      });
  }

  selectGroup(category: LaborCategory): void {
    // If the Category has labor codes, we assume this is the lowest
    // level we can go and pass it as selection.
    if (category.hasLaborCodes() || !category.hasChildren()) {
      this.selectLaborItemForSolution(category);
    } else {
      // In the other case, we continue to display selectable
      // categories.
      this.selectedCategory$.next(category);
      this.children$.next(category.children);
    }
  }

  returnToParent(): void {
    const category = this.selectedCategory$.value;
    const parentNode = this.findParentCategory(
      this.source$.value,
      category.parentId
    );

    this.selectedCategory$.next(parentNode);

    if (parentNode) {
      this.children$.next(parentNode.children);
    } else {
      this.children$.next(this.source$.value);
    }
  }

  selectLaborItemForSolution(item: LaborCategory): void {
    this.completeSelection.next(item);
  }

  private findParentCategory(
    elements: LaborCategory[],
    parentId: number
  ): LaborCategory {
    let result: LaborCategory = null;

    for (let i = 0, max = elements.length; i < max; i++) {
      const element = elements[i];

      // Immediate match, break and return
      if (element.id === parentId) {
        result = element;
        break;
      }

      // Continue searching a level down
      if (element.children) {
        result = this.findParentCategory(element.children, parentId);
      }
    }
    return result;
  }
}
