import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, catchError, switchMap, shareReplay } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { DocumentType } from 'app/domain';
import { Cacheable } from 'ngx-cacheable';

@Injectable({ providedIn: 'root' })
export class DynamicsService {
  private readonly VENDOR_DOC_TYPE_NAME = 'Vendor Order Forms';

  constructor(private http: HttpClient) {}

  @Cacheable({ maxCacheCount: 100 })
  getDocumentTypes(): Observable<DocumentType[]> {
    return this.http
      .get<Array<object>>(
        `${environment.apiDynamicsApi}/WorkOrder/documentTypes`
      )
      .pipe(
        map((response: Array<object>) => {
          let items: DocumentType[] = [];
          if (response && response instanceof Array) {
            items = response.map(v => Object.assign(new DocumentType(), v));
          }
          return items;
        }),
        catchError((e: HttpErrorResponse) => {
          return [];
        })
      );
  }
  @Cacheable({ maxCacheCount: 100 })
  getVendorHotFormDocumentType(): Observable<DocumentType> {
    return this.getDocumentTypes().pipe(
      map((types: DocumentType[]) => {
        const documentType: DocumentType = types.find(
          f => f.name.toLowerCase() === this.VENDOR_DOC_TYPE_NAME.toLowerCase()
        );

        return documentType;
      })
    );
  }
}
