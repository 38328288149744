import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot
} from '@angular/router';
import { SolutionBuilderUser } from 'app/domain/models/core/models';
import { UserService } from 'app/services/user.service';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Injectable()
export class AdminGuard implements CanActivate {
  constructor(
    private userService: UserService,
    private routerService: Router
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.userService.getUser().pipe(
      switchMap((user: SolutionBuilderUser) => {
        if (!user.isAdmin()) {
          this.routerService.navigateByUrl('/landing/access-denied');
          return of(false);
        }
        return of(true);
      })
    );
  }
}
