import { Component, OnInit, Input, HostBinding } from '@angular/core';
import { FormControl, AbstractControl } from '@angular/forms';

@Component({
  selector: 'sb-required-marker',
  templateUrl: './required-marker.component.html',
  styleUrls: ['./required-marker.component.scss']
})
export class SbRequiredMarkerComponent implements OnInit {
  @HostBinding('class') class: string = 'sb-required-marker-wrapper';

  constructor() {}

  ngOnInit(): void {}
}
