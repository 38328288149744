<div
  class="sb-strike"
  [class.is-block]="block"
  [class.has-indicator]="showIndicator"
>
  <div class="sb-indicator" *ngIf="showIndicator">
    <span
      class="icon"
      [ngClass]="{
        'is-default fas fa-circle': default,
        'is-success fad fa-check-circle': valid
      }"
    ></span>
    <span class="sb-blinking-marker" *ngIf="current"></span>
  </div>
  <span *ngIf="upperHeading" class="sb-strike-upper-heading">{{
    upperHeading
  }}</span>
  <span class="sb-strike-heading">{{ heading }}</span>
</div>
