import { Component, OnInit, OnDestroy } from '@angular/core';
import { TemplateAvailability, FieldOption, Template } from 'app/domain';
import { BehaviorSubject, of } from 'rxjs';
import { FormGroup, FormControl, FormBuilder } from '@angular/forms';
import { untilDestroyed } from 'app/core';
import { Router } from '@angular/router';
import { TemplateService } from 'app/services/template.service';
import { map, catchError, debounceTime } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import {
  DialogService,
  DialogRef,
  DialogCloseResult
} from '@progress/kendo-angular-dialog';
import * as _ from 'lodash';

@Component({
  selector: 'sb-templates',
  templateUrl: './templates-list.component.html',
  styleUrls: ['./templates-list.component.scss']
})
export class TemplatesListComponent implements OnInit, OnDestroy {
  vendors$: BehaviorSubject<FieldOption[]> = new BehaviorSubject<FieldOption[]>(
    []
  );
  availability$: BehaviorSubject<FieldOption[]> = new BehaviorSubject<
    FieldOption[]
  >([]);

  branches$: BehaviorSubject<FieldOption[]> = new BehaviorSubject<
    FieldOption[]
  >([]);

  supportedAvailability: FieldOption[] = [
    new FieldOption('Me', TemplateAvailability.Me),
    new FieldOption('Branch', TemplateAvailability.Branch),
    new FieldOption('Everyone', TemplateAvailability.Everyone)
  ];

  branches: FieldOption[] = [new FieldOption('Chattanooga', 'Chattanooga')];

  fgTemplateFilter: FormGroup;
  fcFilter: FormControl = new FormControl('');
  fcVendor: FormControl = new FormControl('');
  fcAvailability: FormControl = new FormControl('');
  fcBranch: FormControl = new FormControl('');

  source$: BehaviorSubject<TemplateItem[]> = new BehaviorSubject<
    TemplateItem[]
  >([]);

  templates$: BehaviorSubject<TemplateItem[]> = new BehaviorSubject<
    TemplateItem[]
  >([]);

  constructor(
    private templateService: TemplateService,
    private fb: FormBuilder,
    private router: Router,
    private toastrService: ToastrService,
    private dialogService: DialogService
  ) {}

  ngOnInit(): void {
    this.fetchTemplates();

    this.fgTemplateFilter = this.fb.group({
      fcFilter: this.fcFilter,
      fcVendor: this.fcVendor,
      fcAvailability: this.fcAvailability,
      fcBranch: this.fcBranch
    });

    this.fcFilter.valueChanges
      .pipe(debounceTime(600), untilDestroyed(this))
      .subscribe((term: string) => {
        const templates = this.source$.value;
        const toLowerTerm = term ? term.toLowerCase() : '';

        if (templates && templates.length > 0) {
          const filtered = templates.filter((template: TemplateItem) => {
            const name = template.name;
            if (name) {
              return name.toLowerCase().indexOf(toLowerTerm) > -1;
            }
            return false;
          });
          this.setTemplates(filtered);
        }
      });

    this.availability$.next([
      new FieldOption('Select Availability', ''),
      ...this.supportedAvailability
    ]);

    this.branches$.next([
      new FieldOption('Select Branch', ''),
      ...this.branches
    ]);
  }

  fetchTemplates(): void {
    this.templateService
      .getTemplates()
      .pipe(
        catchError(() => {
          return of([]);
        }),
        map((templates: []) => {
          this.vendors$.next([
            new FieldOption('Select Vendor', ''),
            ...templates.map((t: Template) => new FieldOption(t.name, t.name))
          ]);

          if (templates && templates.length > 0) {
            return templates.map((t: Template) => {
              return {
                id: t.id,
                name: t.name,
                scope: TemplateAvailability.Everyone,
                baseModelDescription: 'NOT MAPPED',
                numberOfProducts: 0,
                vendorName: '',
                isPowerChair: true,
                isSelected: false
              };
            });
          } else {
            return [];
          }
        })
      )
      .subscribe((items: TemplateItem[]) => {
        this.source$.next(items);
        this.setTemplates(items);
      });
  }

  ngOnDestroy(): void {}

  hasSelectedTemplates(templates: TemplateItem[] = []): boolean {
    return templates.find(t => t.isSelected) != null; // todo implement
  }

  removeSelectedTemplates(template: TemplateItem): void {
    this.templateService.deleteTemplates([template.id]).subscribe(
      () => {
        this.toastrService.success('Successfully deleted Template.');
        this.fetchTemplates();
      },
      () => {
        this.toastrService.error(
          'There was an error deleting the Template.  Please check the console.'
        );
      }
    );
  }

  showConfirmRemoveDialog(templateItem: TemplateItem): void {
    const dialog: DialogRef = this.dialogService.open({
      title: 'Remove Template',
      content: `Are you sure you want to remove the ${templateItem.name} template?`,
      actions: [
        { text: 'No', value: false },
        { text: 'Yes', value: true, primary: true }
      ],
      width: 450,
      height: 175,
      minWidth: 250
    });

    dialog.result.subscribe(result => {
      if (result instanceof DialogCloseResult) {
      } else {
        if (result.text === 'Yes') {
          this.removeSelectedTemplates(templateItem);
        } else {
          // User cancelled
        }
      }
    });
  }

  private setTemplates(templates: TemplateItem[]): void {
    this.templates$.next(
      _.sortBy(templates, t => (t.name ? t.name.toLowerCase() : ''))
    );
  }
}

interface TemplateItem {
  id: number;
  name: string;
  scope: TemplateAvailability;
  baseModelDescription: string;
  numberOfProducts: number;
  branchName?: string;
  vendorName: string;
  isPowerChair: boolean;
  isSelected: boolean;
}
