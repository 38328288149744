import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SolutionBuilderAdminRoutingModule } from './admin-routing.module';
import { CoreModule } from 'app/core';
import { SolutionBuilderDomainModule } from 'app/domain';
import { SolutionBuilderSharedModule } from 'app/shared';
import { SolutionTypesListComponent } from './solution-types/solution-types-list/solution-types-list.component';
import { SolutionTypeSettingsComponent } from './solution-types/solution-type-settings/solution-type-settings.component';
import { AdminSettingsComponent } from './admin-settings/admin-settings.component';
import { TemplatesListComponent } from './templates/templates-list/templates-list.component';
import { EditTemplateComponent } from './templates/edit-template/edit-template.component';
import { CategoryNamePipe } from 'app/domain/pipes/category-name.pipe';
import { DefinitionsListComponent } from './definitions/definitions-list/definitions-list.component';
import { EditDefinitionComponent } from './definitions/edit-definition/edit-definition.component';
import { CategorySelectionMenuComponent } from './definitions/category-selection-menu/category-selection-menu.component';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { SolutionBuilderCommonViewsModule } from '../common/solution-builder-common-views.module';

@NgModule({
  declarations: [
    SolutionTypesListComponent,
    SolutionTypeSettingsComponent,
    AdminSettingsComponent,
    TemplatesListComponent,
    EditTemplateComponent,
    DefinitionsListComponent,
    EditDefinitionComponent,
    CategorySelectionMenuComponent
  ],
  imports: [
    CommonModule,
    CoreModule,
    TranslateModule,
    ReactiveFormsModule,
    SolutionBuilderAdminRoutingModule,
    SolutionBuilderSharedModule,
    SolutionBuilderDomainModule,
    SolutionBuilderCommonViewsModule
  ],
  exports: [],
  providers: [CategoryNamePipe]
})
export class SolutionBuilderAdminModule {}
