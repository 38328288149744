import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { ClientAsset, IClientAssetLoadResults } from 'app/domain';
import { WorkOrderService } from 'app/services/work-order.service';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'sb-client-asset-list',
  templateUrl: './client-asset-list.component.html',
  styleUrls: [
    './client-asset-list.component.scss',
    '../client-asset-dialog.component.scss'
  ]
})
export class SbClientAssetListComponent implements OnInit, AfterViewInit {
  @Input()
  workOrderId: string;

  @Output()
  selected: EventEmitter<ClientAsset> = new EventEmitter<ClientAsset>();

  isLoading: boolean = true;

  assets: ClientAsset[] = [];
  addClientAssetItem: boolean;
  solutionType: string;

  constructor(private workOrderService: WorkOrderService) {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.workOrderService
      .getClientAssets(this.workOrderId)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe((results: IClientAssetLoadResults) => {
        if (results && results.clientAssets) {
          this.assets = [];
          this.assets = results.clientAssets.filter(
            ca => ca.product == null || ca.product.isBaseProduct
          );
        }
      });
  }

  selectAsset(asset: ClientAsset): void {
    this.selected.emit(asset);
  }

  setBaseModel(product: any) {
    let newAsset = new ClientAsset();
    newAsset.id = '';
    newAsset.serialNumber = '';
    newAsset.wheelChairSerialNumber = '';
    newAsset.product = product;
    newAsset.hasSerialNumber = false;
    newAsset.productIdAlternate = '';
    this.selected.emit(newAsset);
  }

  addClientAsset() {
    this.solutionType = this.workOrderService.loadedResults.solutionType.id;
    this.addClientAssetItem = true;
  }
}
