<div [formGroup]="fgCustomProduct" class="container">
  <!--Description-->
  <sb-text-field
    label="Description"
    [control]="fcDescription"
    multiline="true"
    required="true"
  >
    <sb-field-error *ngIf="fcDescription.errors?.whitespace"
      >Description may not be whitespace only.
    </sb-field-error>
    <sb-field-error
      *ngIf="fcDescription.dirty && fcDescription.errors?.required"
      >Description is required.
    </sb-field-error>
  </sb-text-field>

  <!-- Notes -->
  <sb-text-field
    label="Notes"
    [control]="fcNotes"
    multiline="true"
    required="true"
  >
    <sb-field-error *ngIf="fcNotes.errors?.whitespace"
      >Note may not be whitespace only.
    </sb-field-error>
    <sb-field-error *ngIf="fcNotes.dirty && fcNotes.errors?.required"
      >Note is required.
    </sb-field-error>
    <sb-field-error *ngIf="fcNotes.errors?.maxlength"
      >Note must be less than {{ fcNotes.errors.maxlength.requiredLength }}.
    </sb-field-error>
  </sb-text-field>
  <!-- Quantity-->
  <p>
    <sb-number-field
      label="Quantity"
      [control]="fcQuantity"
      [min]="0"
      [max]="1000"
      required="true"
    ></sb-number-field>
  </p>
  <!-- MSRP -->
  <p>
    <sb-currency-field
      label="MSRP"
      [control]="fcMsrp"
      required="true"
    ></sb-currency-field>
  </p>
  <!-- Cost -->
  <p>
    <sb-currency-field
      label="Cost"
      [control]="fcCost"
      required="true"
    ></sb-currency-field>
  </p>
</div>
<kendo-dialog-actions>
  <button kendoButton (click)="onCancelAction()">Cancel</button>
  <button
    kendoButton
    (click)="onConfirmAction()"
    [primary]="true"
    [disabled]="!fgCustomProduct.valid"
  >
    Update
  </button>
</kendo-dialog-actions>
