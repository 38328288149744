import {
  NgModule,
  Optional,
  SkipSelf,
  ModuleWithProviders
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule
} from '@angular/common/http';
import { RouteReuseStrategy, RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { RouteReusableStrategy } from './route-reusable-strategy';
import { HttpService } from './http/http.service';
import {
  OAuthModule,
  AuthConfig,
  OAuthModuleConfig,
  OAuthStorage,
  JwksValidationHandler,
  ValidationHandler
} from 'angular-oauth2-oidc';
import { AuthGuard } from './authentication/auth-guard.service';
import { AuthGuardWithForcedLogin } from './authentication/auth-guard-with-forced-login.service';
import { AuthenticationService } from './authentication/authentication.service';
import { authModuleConfig } from './authentication/auth-module-config';
import { authConfig } from 'environments/auth.config';
import { JwtInterceptor } from './http/jwt.interceptor';
import { AdminGuard } from './guards/admin.guard';

// We need a factory since localStorage is not available at AOT build time
export function storageFactory(): OAuthStorage {
  return localStorage;
}

@NgModule({
  imports: [CommonModule, TranslateModule, RouterModule, OAuthModule.forRoot()],
  providers: [
    AuthenticationService,
    AuthGuard,
    AdminGuard,
    AuthGuardWithForcedLogin,
    {
      provide: HttpClient,
      useClass: HttpService
    },
    {
      provide: RouteReuseStrategy,
      useClass: RouteReusableStrategy
    }
  ]
})
export class CoreModule {
  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        { provide: AuthConfig, useValue: authConfig },
        { provide: OAuthModuleConfig, useValue: authModuleConfig },
        { provide: OAuthStorage, useFactory: storageFactory },
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true }
      ]
    };
  }

  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    // Import guard
    if (parentModule) {
      throw new Error(
        `${parentModule} has already been loaded. Import Core module in the AppModule only.`
      );
    }
  }
}
