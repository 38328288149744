import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { OAuthService } from 'angular-oauth2-oidc';
import { Router } from '@angular/router';
@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(private oAuthService: OAuthService, private router: Router) {}
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (
      this.oAuthService.hasValidIdToken() &&
      !request.headers.has('Authorization')
    ) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${this.oAuthService.getIdToken()}`
        }
      });
    }

    return next.handle(request);
  }
}
