import { Pipe, PipeTransform } from '@angular/core';
import { FieldOption } from '../models';
import * as _ from 'lodash';

@Pipe({ name: 'sortOptions' })
export class SortFieldOptionsPipe implements PipeTransform {
  transform(
    options: FieldOption[],
    direction: SortDir = SortDir.ASC
  ): FieldOption[] {
    let sortedOptions: FieldOption[] = [];
    if (options) {
      sortedOptions = _.orderBy(options, o => o.text, [direction]);
    }
    return sortedOptions;
  }
}

enum SortDir {
  ASC = 'asc',
  DESC = 'desc'
}
