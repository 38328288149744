import { Pipe, PipeTransform } from '@angular/core';
import { Product } from '../models/solution-builder/models';

@Pipe({
  name: 'hcpcCodes'
})
export class HcpcCodePipes implements PipeTransform {
  transform(product: Product): string {
    if (product) {
      const values = [
        product.hcpcsCode1,
        product.hcpcsCode2,
        product.hcpcsCode3,
        product.hcpcsCode4
      ];
      return values.filter(Boolean).join('; ');
    }
    return null;
  }
}
