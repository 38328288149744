import { FormControl, Validators } from '@angular/forms';

// setup simple regex for white listed characters
const validCharacters = /[^\s\w,.:&\/()+%'`@-]/;

// create your class that extends the angular validator class
export class CustomValidators extends Validators {
  // create a static method for your validation
  static validateCharacters(control: FormControl): { [s: string]: boolean } {
    // first check if the control has a value
    if (control.value && control.value.length > 0) {
      // match the control value against the regular expression
      const matches = control.value.match(validCharacters);

      // if there are matches return an object, else return null.
      return matches && matches.length ? { invalid_characters: matches } : null;
    } else {
      return null;
    }
  }

  static preventWhitespaceOnly(control: FormControl): { [s: string]: boolean } {
    const value: string = control.value;
    if (value && value.length > 0) {
      const isWhitespace = value.trim().length === 0;
      return isWhitespace ? { whitespace: true } : null;
    }
    return null;
  }

  static preventBeginsWithWhitespace(
    control: FormControl
  ): { [s: string]: boolean } {
    const value: string = control.value;
    if (value && value.length > 0) {
      const isWhitespace = value.indexOf(' ') === 0;
      return isWhitespace ? { beginsWithWhitespace: true } : null;
    }
    return null;
  }

  static preventTrailingWhitespace(
    control: FormControl
  ): { [s: string]: boolean } {
    const value: string = control.value;
    if (value && value.length > 0) {
      return value.endsWith(' ') ? { endsWithWhitespace: true } : null;
    }
    return null;
  }
}
