import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SolutionType, Category, Product } from 'app/domain';
import { environment } from 'environments/environment';
import { map } from 'rxjs/operators';
import { ProductHelperService } from './product-helper.service';
import { Cacheable } from 'ngx-cacheable';

@Injectable({
  providedIn: 'root'
})
export class SolutionTypeService {
  constructor(
    private http: HttpClient,
    private productMapper: ProductHelperService
  ) {}

  @Cacheable()
  getSolutionTypes(): Observable<SolutionType[]> {
    return this.http
      .get<SolutionType[]>(`${environment.apiSolutionTypes}`)
      .pipe(
        map((response: SolutionType[]) => {
          return response.map(value =>
            Object.assign(new SolutionType(), value)
          );
        })
      );
  }

  getSolutionType(id: number): Observable<SolutionType> {
    return this.http
      .get<SolutionType>(`${environment.apiSolutionTypes}/${id}`)
      .pipe(
        map((response: SolutionType) => {
          return response ? Object.assign(new SolutionType(), response) : null;
        })
      );
  }

  createSolutionType(solutionType: SolutionType): Observable<SolutionType> {
    return this.http
      .post<SolutionType>(`${environment.apiSolutionTypes}`, solutionType)
      .pipe(
        map((response: SolutionType) => {
          return response ? Object.assign(new SolutionType(), response) : null;
        })
      );
  }

  updateSolutionType(solutionType: SolutionType): Observable<void> {
    return this.http.put<void>(
      `${environment.apiSolutionTypes}/${solutionType.id}`,
      solutionType
    );
  }

  getSolutionTypeModels(id: number): Observable<string[]> {
    return this.http.get<string[]>(
      `${environment.apiSolutionTypes}/${id}/models`
    );
  }

  /**
   * Returns a list of base models for the given solution type
   * @param id SolutionType id
   */
  getSolutionTypeBases(id: number): Observable<Product[]> {
    return this.http
      .get<Product[]>(`${environment.apiSolutionTypes}/${id}/bases`)
      .pipe(
        map((result: Product[]) => {
          if (result && result.length > 0) {
            return this.productMapper.mapProducts(result);
          }
          return [];
        })
      );
  }

  getUnassignedSolutionTypes(): Observable<Category[]> {
    return this.http.get<Category[]>(
      `${environment.apiSolutionTypes}/unassigned`
    );
  }

  deleteSolutionType(solutionType: SolutionType): Observable<void> {
    return this.http.delete<void>(
      `${environment.apiSolutionTypes}/${solutionType.id}`
    );
  }
}
