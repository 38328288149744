<div [formGroup]="fgNote" class="container">
  <!-- External Notes -->
  <p>
    <sb-text-field
      label="External Notes"
      [control]="fcExternalNotes"
      multiline="true"
      required="true"
    >
      <div errors>
        <sb-field-error *ngIf="fcExternalNotes.errors?.whitespace"
          >Note may not be whitespace only.
        </sb-field-error>
        <sb-field-error
          *ngIf="fcExternalNotes.dirty && fcExternalNotes.errors?.required"
          >Note is required.
        </sb-field-error>
        <sb-field-error *ngIf="fcExternalNotes.errors?.maxlength"
          >Note must be less than
          {{ fcExternalNotes.errors.maxlength.requiredLength }}.
        </sb-field-error>
      </div>
    </sb-text-field>
  </p>

  <p>
    <sb-text-field
      label="Internal Notes"
      [control]="fcInternalNotes"
      multiline="true"
      required="true"
    >
      <div errors>
        <sb-field-error *ngIf="fcInternalNotes.errors?.whitespace"
          >Note may not be whitespace only.
        </sb-field-error>
        <sb-field-error
          *ngIf="fcInternalNotes.dirty && fcInternalNotes.errors?.required"
          >Note is required.
        </sb-field-error>
        <sb-field-error *ngIf="fcInternalNotes.errors?.maxlength"
          >Note must be less than
          {{ fcInternalNotes.errors.maxlength.requiredLength }}.
        </sb-field-error>
      </div>
    </sb-text-field>
  </p>
</div>
<kendo-dialog-actions>
  <button kendoButton (click)="onCancelAction()">Cancel</button>
  <button
    kendoButton
    (click)="onConfirmAction()"
    [primary]="true"
    [disabled]="!fgNote.valid"
  >
    Update
  </button>
</kendo-dialog-actions>
