import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';

import { ScrollSpyDirective } from './directives/scrollSpy';

import { CategoryNamePipe } from './pipes/category-name.pipe';
import { HcpcCodePipes } from './pipes/hcpc-codes.pipe';
import { StickyThingDirective } from './directives/sticky';
import { RankProductsPipe } from './pipes/rank-products.pipe';
import { CategoryCodePipe } from './pipes/category-code.pipe';
import { LaborCategorySortByNamePipe } from './pipes/labor-category-number.pipe';
import { CapitalizePipes } from './pipes/capitalize.pipe';
import { SortFieldOptionsPipe } from './pipes/sort-field-options.pipe';

@NgModule({
  declarations: [
    ScrollSpyDirective,
    CategoryNamePipe,
    StickyThingDirective,

    HcpcCodePipes,
    RankProductsPipe,
    CategoryCodePipe,
    LaborCategorySortByNamePipe,
    CapitalizePipes,
    SortFieldOptionsPipe
  ],
  imports: [CommonModule],
  exports: [
    ScrollSpyDirective,
    CategoryNamePipe,
    StickyThingDirective,
    HcpcCodePipes,
    RankProductsPipe,
    CategoryCodePipe,
    LaborCategorySortByNamePipe,
    CapitalizePipes,
    SortFieldOptionsPipe
  ],
  providers: []
})
export class SolutionBuilderDomainModule {}
