import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { map } from 'rxjs/operators';

import { environment } from 'environments/environment';
import { Branch, IBranch } from 'app/domain/models/core/models';
import { Observable, of } from 'rxjs';
import { BaseModelRequirements, IBaseModelRequirements } from 'app/domain';

@Injectable({ providedIn: 'root' })
export class BaseModelRequirementsService {
  constructor(private http: HttpClient) {}

  getRequirements(): Observable<BaseModelRequirements[]> {
    return this.http
      .get<IBaseModelRequirements[]>(environment.apiBaseModelRequirements)
      .pipe(
        map((response: IBaseModelRequirements[]) => {
          return response ? response.map(bmr => this.mapToObject(bmr)) : [];
        })
      );
  }

  getRequirement(id: number): Observable<BaseModelRequirements> {
    return this.http
      .get<IBaseModelRequirements>(
        `${environment.apiBaseModelRequirements}/${id}`
      )
      .pipe(
        map((response: IBaseModelRequirements) => {
          return this.mapToObject(response);
        })
      );
  }

  deleteRequirement(id: number): Observable<void> {
    return this.http.delete<void>(
      `${environment.apiBaseModelRequirements}/${id}`
    );
  }

  create(bmr: BaseModelRequirements): Observable<BaseModelRequirements> {
    if (!bmr) {
      return of(null);
    }
    return this.http
      .post<IBaseModelRequirements>(environment.apiBaseModelRequirements, bmr)
      .pipe(
        map((response: IBaseModelRequirements) => {
          return this.mapToObject(response);
        })
      );
  }

  update(bmr: BaseModelRequirements): Observable<BaseModelRequirements> {
    if (!bmr) {
      return of(null);
    }
    return this.http
      .put<IBaseModelRequirements>(
        `${environment.apiBaseModelRequirements}/${bmr.id}`,
        bmr
      )
      .pipe(
        map(() => {
          return bmr;
        })
      );
  }

  createOrUpdate(
    bmr: BaseModelRequirements
  ): Observable<BaseModelRequirements> {
    if (bmr && bmr.id > 0) {
      return this.update(bmr);
    } else {
      return this.create(bmr);
    }
  }

  private mapToObject(response: IBaseModelRequirements): BaseModelRequirements {
    if (response) {
      return Object.assign(new BaseModelRequirements(), response);
    }
    return null;
  }
}
