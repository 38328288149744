import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ClientAsset } from 'app/domain';

@Component({
  selector: 'sb-selected-client-asset',
  templateUrl: './selected-client-asset.component.html',
  styleUrls: ['./selected-client-asset.component.scss']
})
export class SbSelectedClientAssetComponent implements OnInit {
  @Input()
  asset: ClientAsset;

  @Output()
  edit: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  remove: EventEmitter<void> = new EventEmitter<void>();

  constructor() {}

  ngOnInit(): void {}

  removeAsset(): void {
    this.remove.emit();
  }

  editAsset(): void {
    this.edit.emit();
  }
}
