import { Injectable } from '@angular/core';
import { SolutionBuilderOptionsDataStore } from './solution-options-data-store.service';
import {
  ClientAsset,
  ConfiguredSolution,
  DataStoreEventType,
  IConfiguredSolution,
  IExplanationModel,
  PARTS_ONLY_KEY,
  Product,
  Reason,
  SaveWorkOrderRequest,
  SEATING_POSITIONING_CODE,
  SolutionLaborItem,
  SolutionResolvedModel,
  SolutionType,
  SolutionTypeFlowType,
  VendorFormLoadResults
} from 'app/domain/models/';

import * as _ from 'lodash';
import { Observable, of, Subject, throwError } from 'rxjs';
import { shareReplay, switchMap } from 'rxjs/operators';
import { SolutionDataStore } from './solution-data-store.service';
import { SolutionBuilderLoggerService } from 'app/domain/services/solution-builder-logger.service';
import {
  ExpectedReimbursementCob,
  ExpectedReimbursementInfo,
  ExpectedReimbursementLineItem,
  ExpectedReimbursementResponse,
  HcpcLineItem,
  PayerPlanDetail,
  PayerTotals
} from 'app/domain/models/solution-builder/expected.reimbursement';
import { WorkOrderService } from 'app/services/work-order.service';
import { ExpectedReimbursementService } from 'app/services/expected-reimbursement.service';
import { DiscontinuedSaveValidator } from '../validation/discontinued-save.validator';
import { BaseModelRequirementsSaveValidator } from '../validation/base-model-requirements-save.validator';
import { CustomProductsSaveValidator } from '../validation/custom-products-save.validator';
import { UnrankedBasesSaveValidator } from '../validation/unranked-bases-save.validator';
import { RepairSaveValidator } from '../validation/repair-save.validator';
import { MarginThresholdSaveValidator } from '../validation/margin-threshold-save.validator';
import { forEach } from 'lodash';

const SOLUTION_BUILDER_LOCALSTORAGE_KEY = 'sb_local_key';

@Injectable({ providedIn: 'root' })
export class SolutionBuilderClient {
  private activeSolution: ConfiguredSolution = null;

  private eventsSubject$: Subject<DataStoreEventType> = new Subject<
    DataStoreEventType
  >();

  private events$: Observable<
    DataStoreEventType
  > = this.eventsSubject$.asObservable().pipe(shareReplay());

  /**
   * Emits a value when the data store has saved to localStorage.
   */
  private onSave$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private solutionDataStore: SolutionDataStore,
    private optionsDataStore: SolutionBuilderOptionsDataStore,
    private workOrderService: WorkOrderService,
    private expectedReimbursementService: ExpectedReimbursementService,
    private logger: SolutionBuilderLoggerService
  ) {}

  getWorkOrderSolutionType() {
    return this.workOrderService.loadedResults?.solutionType?.name;
  }

  events(): Observable<DataStoreEventType> {
    return this.events$;
  }

  quoteHasLoaner(): boolean {
    return this.solutionDataStore.getLoanerProduct() != null;
  }

  getLoaner(): Product {
    return this.solutionDataStore.getLoanerProduct();
  }

  setLoaner(product: Product): void {
    // console.log('setLoaner, keep this console', product);
    // this.solutionDataStore.setLoanerProduct(product);
  }

  getActiveSolutionType(): SolutionType {
    return this.solutionDataStore.getSolutionType();
  }
  setActiveSolutionType(solutionType: SolutionType): void {
    this.solutionDataStore.setSolutionType(solutionType);
    this.sendEvent(DataStoreEventType.SolutionTypeChange);
  }

  setWorkOrderId(workOrderId: string): void {
    this.solutionDataStore.setWorkOrderId(workOrderId);
  }

  getWorkOrderId(): string {
    return this.solutionDataStore.getWorkOrderId();
  }
  /**
   * Get the SolutionType model from its Category Id.
   * @param solutionTypeId The id of the Solution.
   */
  getSolutionType(solutionTypeId: number): SolutionType {
    return this.optionsDataStore
      .getSolutionTypes()
      .find(solutionType => solutionType.id === solutionTypeId);
  }

  /**
   * Gets the Repair Solution Type.
   * When we load the WO from the API, there's really no
   * Solution with the load.  Given the OrderType on that load,
   * we can grab the Solution for Repair/Modifications and set it.
   */
  getRepairSolutionType(): SolutionType {
    return this.optionsDataStore
      .getSolutionTypes()
      .find(s => s.flow === SolutionTypeFlowType.Repair);
  }

  isRepairSolutionType(solutionTypeId: number): boolean {
    const solutionTypes = this.optionsDataStore.getSolutionTypes();
    if (solutionTypes) {
      const solutionType = solutionTypes.find(s => s.id === solutionTypeId);

      if (!solutionType) {
        return false;
      }

      // Yes, we have to target it this way.
      return solutionType.flow === SolutionTypeFlowType.Repair;
    } else {
      return false;
    }
  }

  setNonProfileBaseExplanation(explanation: IExplanationModel): void {
    this.solutionDataStore.setNonProfileBaseExplanation(explanation);
  }

  getNonProfileBaseExplanation(): IExplanationModel {
    return this.solutionDataStore.getNonProfileBaseExplanation();
  }

  setClientAsset(asset: ClientAsset): void {
    this.solutionDataStore.setClientAsset(asset);
    this.sendEvent(DataStoreEventType.ClientAssetChanged);
  }

  getClientAsset(): ClientAsset {
    return this.solutionDataStore.getClientAsset();
  }

  getOnSave(): Observable<boolean> {
    return this.onSave$;
  }

  /**
   * Activate Solutions Functionality
   */

  clearSolutions(): void {
    this.solutionDataStore.clearSolutions();
    this.sendEvent(DataStoreEventType.SolutionsChanged);
  }
  setActiveSolution(solution: ConfiguredSolution): void {
    this.activeSolution = solution;
    this.setProducts(this.activeSolution ? this.activeSolution.products : []);
    this.setLaborItems(
      this.activeSolution ? this.activeSolution.laborItems : []
    );
    this.sendEvent(DataStoreEventType.SolutionActivated);
  }

  getActiveSolution(): ConfiguredSolution {
    return this.activeSolution;
  }

  addSolutions(solutions: ConfiguredSolution[]): void {
    if (solutions && solutions.length > 0) {
      solutions.forEach((solution: ConfiguredSolution, index: number) => {
        this.solutionDataStore.addSolution(solution.getKey(), solution);
      });

      this.sendEvent(DataStoreEventType.SolutionsChanged);

      // Set first solution as active
      this.setActiveSolution(solutions[0]);
    }
  }

  addSolution(solution: ConfiguredSolution, setAsActive: boolean = true): void {
    this.solutionDataStore.addSolution(solution.getKey(), solution);
    this.sendEvent(DataStoreEventType.SolutionsChanged);
    if (setAsActive) {
      this.setActiveSolution(solution);
    }
  }

  setSolutions(solutions: ConfiguredSolution[]): void {
    if (solutions) {
      solutions.forEach((solution: ConfiguredSolution) => {
        this.addSolution(solution);
      });
    }
  }

  createPartsOnlySolution(): void {
    const solutions = this.solutionDataStore.getSolutions();
    if (!solutions.find(s => s.isPartsOnly)) {
      this.addSolution(new ConfiguredSolution(), true);
    }
  }

  removePartsOnlySolution(): void {
    this.solutionDataStore.removeSolution(PARTS_ONLY_KEY);
  }

  removeSolution(solution: ConfiguredSolution): void {
    if (solution) {
      // Remove the configured solution
      this.solutionDataStore.removeSolution(solution.getKey());

      this.sendEvent(DataStoreEventType.SolutionsChanged);

      const solutions = this.solutionDataStore.getSolutions();

      let activeSolution = null;
      if (solutions.length > 0) {
        // Set the first configured solution as active
        activeSolution = solutions[0];
      }
      this.setActiveSolution(activeSolution);
    }
  }

  getSolutions(): ConfiguredSolution[] {
    return _.orderBy(
      Array.from(this.solutionDataStore.getSolutions()),
      s => s.isPartsOnly,
      'desc'
    );
  }

  getPartsOnlySolution(): ConfiguredSolution {
    const solutions = this.getSolutions();
    return solutions.find(s => s.isPartsOnly());
  }

  getSeatingAndPositioningSolutionType(): SolutionType {
    return this.optionsDataStore
      .getSolutionTypes()
      .find(s => s.code === SEATING_POSITIONING_CODE);
  }

  /**
   * End Active Solutions Functionality
   */

  saveLocally(): void {
    const models = this.solutionDataStore.getSolutions();
    const hasBase = models.find(s => s.base != null) != null;

    const solutionType = this.solutionDataStore.getSolutionType();

    if (solutionType) {
      // Guarantee for Single workflow we have a base
      if (solutionType.flow === SolutionTypeFlowType.Single) {
        if (!hasBase) {
          return;
        }
      }

      const key = this.getCompositeKey();
      localStorage.setItem(
        key,
        JSON.stringify({
          type: solutionType,
          solutions: models
          // loaner: this.getLoaner()
        })
      );

      // Notify listeners of a save
      this.onSave$.next(true);
    }
  }

  addProduct(product: Product, isAftermarket: boolean = false): void {
    if (isAftermarket) {
      const partsOnlySolution = this.getSolutions().find(s => s.isPartsOnly());
      if (!partsOnlySolution) {
        this.addSolution(new ConfiguredSolution(null, [product]), false);
      } else {
        partsOnlySolution.products.push(product);
      }
    } else if (this.hasActiveSolution()) {
      if (!this.activeSolution.products) {
        this.activeSolution.products = [];
      }
      this.activeSolution.products.push(product);
    }

    this.sendEvent(DataStoreEventType.ProductsChange);
  }

  removeProduct(product: Product, isAftermarket: boolean = false): void {
    if (isAftermarket) {
      const partsOnlySolution = this.getPartsOnlySolution();
      partsOnlySolution.removeProduct(product);
    } else if (this.hasActiveSolution()) {
      this.activeSolution.removeProduct(product);
    }
    this.sendEvent(DataStoreEventType.ProductsChange);
  }

  setProducts(products: Product[]): void {
    if (this.hasActiveSolution()) {
      this.activeSolution.products = products;
      this.sendEvent(DataStoreEventType.ProductsChange);
    }
  }
  /**
   * If the store has Products.
   */
  hasProduct(product: Product): boolean {
    if (this.hasActiveSolution()) {
      if (!product) {
        return false;
      }

      const products = this.activeSolution.products;
      if (!products) {
        return false;
      }
      return (
        products.find(
          p =>
            p.productNumber === product.productNumber &&
            p.vendorAccountNumber === product.vendorAccountNumber
        ) != null
      );
    }
    return false;
  }
  hasProducts(): boolean {
    return this.hasActiveSolution() && this.activeSolution.hasProducts();
  }

  incrementProductQuantity(product: Product): void {
    product.defaultQuantity = product.defaultQuantity + 1;
    this.sendEvent(DataStoreEventType.ProductQuantityChange);
  }

  decrementProductQuantity(product: Product): void {
    const quantity = product.defaultQuantity - 1;
    product.defaultQuantity = quantity >= 0 ? quantity : 0;
    this.sendEvent(DataStoreEventType.ProductQuantityChange);
  }

  setLaborItems(labor: SolutionLaborItem[]): void {
    if (this.hasActiveSolution()) {
      this.activeSolution.laborItems = labor;
      this.updateSolution(this.activeSolution);
      this.sendEvent(DataStoreEventType.LaborChange);
    }
  }

  addLaborItems(labor: SolutionLaborItem): void {
    if (this.hasActiveSolution()) {
      if (!this.activeSolution.hasLaborItems()) {
        this.activeSolution.laborItems = [];
      }
      this.activeSolution.laborItems.push(labor);
      this.updateSolution(this.activeSolution);
      this.sendEvent(DataStoreEventType.LaborChange);
    }
  }

  removeLaborItem(labor: SolutionLaborItem): void {
    if (this.hasActiveSolution()) {
      const selectedLabor = this.activeSolution.laborItems;
      const index = selectedLabor.findIndex(
        l => l.productNumber === labor.productNumber
      );
      selectedLabor.splice(index, 1);
      this.updateSolution(this.activeSolution);
      this.sendEvent(DataStoreEventType.LaborChange);
    }
  }

  addImportedResult(result: VendorFormLoadResults): void {
    if (result.solutionType) {
      //The load results model's solution type is returning 0 for
      //category id, but the options lists correctly returns it.
      //Once this gets resolved we can remove this.
      const solutionType = this.getSolutionType(result.solutionType.id);
      this.setActiveSolutionType(solutionType);
    }

    if (result.hasSolutions()) {
      this.addSolutions(result.getAsConfiguredSolutions());
    }

    this.sendEvent(DataStoreEventType.VendorImportChange);
  }

  getExpectedReimbursementValue(): ExpectedReimbursementInfo {
    return this.solutionDataStore.getExpectedReimbursement();
  }
  setExpectedReimbursement(expected: ExpectedReimbursementInfo): void {
    this.solutionDataStore.setExpectedReimbursement(expected);
  }

  getExpectedReimbursementLinesValue(): ExpectedReimbursementLineItem[] {
    return this.solutionDataStore.getExpectedReimbursementLinesValue();
  }
  setExpectedReimbursementLines(
    expected: ExpectedReimbursementLineItem[]
  ): void {
    this.solutionDataStore.setExpectedReimbursementLines(expected);
  }

  setMarginThreshold(margin: number): void {
    this.solutionDataStore.setMarginThreshold(margin);
  }

  getMarginThresholdValue(): number {
    return this.solutionDataStore.getMarginThreshold();
  }
  /**
   * Loads a solution for a Work Order from localStorage.  Returns true if it successfully
   * loaded, false otherwise.
   * @param workOrderId The Work Order identifier that should be looked up locally
   */
  loadFromLocal(workOrderId: string): SolutionResolvedModel {
    if (!workOrderId) {
      this.logger.warn(
        'Tried to load a local Work Order with null or undefined workOrderId'
      );
      return null;
    }
    this.solutionDataStore.setWorkOrderId(workOrderId);

    const resolved: SolutionResolvedModel = this.getLocalSavedData();
    if (!resolved) {
      this.logger.info(`No available local data for ${workOrderId}.`);
    }
    return resolved;
  }

  reset(): void {
    this.solutionDataStore.reset();
    this.clearSolutions();
    this.setActiveSolution(null);
    this.setActiveSolutionType(null);
    this.setClientAsset(null);
    this.setLoaner(null);
    this.clearLocalData();
  }
  clearLocalData(): void {
    localStorage.removeItem(this.getCompositeKey());
  }

  calculateSolutionMargin(): number {
    const solutions: ConfiguredSolution[] = this.getSolutions();

    let totalCost = _.sumBy(solutions, s => s.getTotalCost());

    const totalReimbursement = this.solutionDataStore.getExpectedReimbursementCost();
    let margin = 0;
    if (!totalReimbursement || totalReimbursement === 0) {
      // TODO: WHAT TO DO IN THIS CASE
    } else {
      margin = 1 - totalCost / totalReimbursement;
    }

    return margin;
  }

  quoteRequiresProductExplanation(): boolean {
    const solutions = this.getSolutions();
    if (solutions && solutions.length > 0) {
      return solutions.find(s => s.hasBase() && s.base.isUnranked()) != null;
    }
    return false;
  }

  calculateExpectedReimbursement(): Observable<number> {
    const solutions = this.getSolutions();
    const solutionType = this.getActiveSolutionType();

    if (!solutionType) {
      return throwError(
        'Missing Solution Type information to calculate expected reimbursement.'
      );
    }

    const expectedFromLoad: ExpectedReimbursementInfo = this.solutionDataStore.getExpectedReimbursement();

    if (
      !expectedFromLoad ||
      (!expectedFromLoad.client && !expectedFromLoad.payers)
    ) {
      return throwError(
        'Missing information required to calculate expected reimbursement.'
      );
    }

    const isRepairSolutionType =
      solutionType.flow === SolutionTypeFlowType.Repair;

    expectedFromLoad.payers.forEach((payerPlanDetail: PayerPlanDetail) => {
      let hcpcLines: HcpcLineItem[] = [];

      // ER is 1 based.
      let ordinal = 1;

      solutions.forEach((solution: ConfiguredSolution) => {
        // For Non-Repairs only
        if (solution.hasBase() && !isRepairSolutionType) {
          const base = solution.base;

          const baseLine = new HcpcLineItem();
          baseLine.hcpcCode1 = base.hcpcsCode1;
          baseLine.hcpcCode2 = base.hcpcsCode2;
          baseLine.hcpcCode3 = base.hcpcsCode3;
          baseLine.quantity = base.defaultQuantity;
          baseLine.msrpAmount = base.msrp;
          baseLine.vendorDiscount1 = base.vendorDiscount1;
          baseLine.vendorDiscount2 = base.vendorDiscount2;
          baseLine.perItemConversionFactor = base.perItemConversionFactor;
          baseLine.ordinal = ordinal++;
          hcpcLines.push(baseLine);
        }

        if (solution.hasProducts()) {
          hcpcLines = [
            ...hcpcLines,
            ...solution.products.map(pr => {
              const item = new HcpcLineItem();
              item.hcpcCode1 = pr.hcpcsCode1;
              item.hcpcCode2 = pr.hcpcsCode2;
              item.hcpcCode3 = pr.hcpcsCode3;
              item.quantity = pr.defaultQuantity;
              item.msrpAmount = pr.msrp;
              item.vendorDiscount1 = pr.vendorDiscount1;
              item.vendorDiscount2 = pr.vendorDiscount2;
              item.perItemConversionFactor = pr.perItemConversionFactor;
              item.ordinal = ordinal++;
              return item;
            })
          ];
        }

        if (solution.laborItems?.length) {
          let quantity = _.sum(
            solution.laborItems.filter(l => l.isBillable).map(l => l.quantity)
          );
          let existing = _.find(hcpcLines, { hcpcCode1: 'K0739' });
          if (existing) {
            existing.quantity = quantity;
          } else {
            const line = new HcpcLineItem();
            line.ordinal = 10000;
            line.hcpcCode1 = 'K0739';
            line.quantity = quantity;
            line.msrpAmount = 35.5;

            hcpcLines = [line, ...hcpcLines];
          }
        }
      });

      payerPlanDetail.hcpcLines = hcpcLines;
    });
    return this.expectedReimbursementService
      .getExpectedReimbursement(expectedFromLoad)
      .pipe(
        switchMap((response: ExpectedReimbursementResponse) => {
          if (!response) {
            return throwError(
              'No response returned from Expected Reimbursement API'
            );
          }

          this.mapBenefitsToProducts(response.coordinationOfBenefits);

          if (response.lines) {
            this.solutionDataStore.setExpectedReimbursementLines(
              response.lines
            );
          } else {
            this.logger.debug('No lines found on ER response.');
          }

          let subTotal = 0;
          if (response.payerTotals) {
            response.payerTotals.forEach(
              (p: PayerTotals) => (subTotal += p.subtotal)
            );
          } else {
            this.logger.debug('No payerTotals founds in ER response.');
          }

          this.solutionDataStore.setExpectedReimbursementCost(subTotal);
          return of(subTotal);
        })
      );
  }

  validateSolution(bypassMarginCheck: boolean = true): string[] {
    const issues: string[] = [];

    new DiscontinuedSaveValidator().validate(issues, this);

    // Verify that the user acknowledges any issues in low margins
    if (!bypassMarginCheck) {
      new MarginThresholdSaveValidator().validate(issues, this);
    }

    new BaseModelRequirementsSaveValidator().validate(issues, this);
    new CustomProductsSaveValidator().validate(issues, this);
    new UnrankedBasesSaveValidator().validate(issues, this);

    const solutionType = this.getActiveSolutionType();
    if ((solutionType && solutionType.flow) == SolutionTypeFlowType.Repair) {
      new RepairSaveValidator().validate(issues, this);
    }

    return issues;
  }

  saveSolution(): Observable<boolean> {
    let solutions = this.solutionDataStore.getSolutions();

    // Guarantee that the parts only solution is last.
    // This is mostly for multi-flow
    solutions = _.sortBy(solutions, s => s.isPartsOnly);
    let allSelectedProducts: Product[] = [];
    for (let i = 0, max = solutions.length; i < max; i++) {
      const solution = solutions[i];
      // Add base as the terminator in the list
      if (solution.hasBase()) {
        allSelectedProducts.push(solution.base);
      }

      // Unpack products first
      if (solution.hasProducts()) {
        allSelectedProducts = [...allSelectedProducts, ...solution.products];
      }
    }

    if (this.quoteHasLoaner()) {
      allSelectedProducts.push(this.getLoaner());
    }

    const request = new SaveWorkOrderRequest();
    request.workOrderId = this.solutionDataStore.getWorkOrderId();
    request.products = allSelectedProducts;
    request.laborItems = this.setDefaultLaborItemBillable(
      this.validateLaborUnits(this.activeSolution.laborItems)
    );

    request.expectedReimbursementLineItems = this.solutionDataStore.getExpectedReimbursementLinesValue();
    request.asset = this.getClientAsset();
    request.nonProfileBaseExplanation = this.solutionDataStore.getNonProfileBaseExplanation();
    request.type = this.getWorkOrderSolutionType()
      ? this.getWorkOrderSolutionType()
      : this.getActiveSolutionType().name;
    request.model = request.asset?.product?.model;
    request.make = request.asset?.product?.vendorName;

    return this.workOrderService.updateWorkOrder(request);
  }

  validateLaborUnits(laborItems: SolutionLaborItem[]) {
    laborItems.forEach((item: any) => {
      if (item.units < item.quantity) {
        item.units = item.quantity;
      }
    });
    return laborItems;
  }

  setDefaultLaborItemBillable(laborItems: SolutionLaborItem[]) {
    if (laborItems?.length) {
      laborItems.forEach(item => {
        item.isBillable = true;
      });
      return laborItems;
    }
    return [];
  }

  getMarginThreshold(): number {
    return this.solutionDataStore.getMarginThreshold();
  }

  getExpectedReimbursementCost(): number {
    return this.solutionDataStore.getExpectedReimbursementCost();
  }

  private hasActiveSolution(): boolean {
    return this.activeSolution != null;
  }

  private getCompositeKey(): string {
    return `${SOLUTION_BUILDER_LOCALSTORAGE_KEY}-${this.solutionDataStore.getWorkOrderId()}`;
  }

  private mapBenefitsToProducts(lineItems: ExpectedReimbursementCob[]) {
    const solutions = this.getSolutions();
    const solutionType = this.getActiveSolutionType();

    const isRepairSolutionType =
      solutionType.flow === SolutionTypeFlowType.Repair;

    let products: Product[] = [];
    solutions.forEach((solution: ConfiguredSolution) => {
      if (solution.hasBase() && !isRepairSolutionType) {
        products.push(solution.base);
      }

      if (solution.hasProducts()) {
        products = [...products, ...solution.products];
      }
    });

    products.forEach((p: Product, index: number) => {
      p.expectedReimbursement = lineItems[index].total;
    });
  }

  private updateSolution(solution: ConfiguredSolution): void {
    this.solutionDataStore.addSolution(solution.getKey(), solution);
  }

  private getLocalSavedData(): SolutionResolvedModel {
    const key = this.getCompositeKey();
    const savedString = localStorage.getItem(key);
    const savedData: ICachedSolutions = JSON.parse(savedString);

    if (savedData) {
      const resolved = new SolutionResolvedModel();
      resolved.type = Object.assign(new SolutionType(), savedData.type);
      resolved.loanerProduct = Object.assign(new Product(), savedData.loaner);
      if (Array.isArray(savedData.solutions)) {
        savedData.solutions.forEach((o: IConfiguredSolution) => {
          const base = o.base ? Object.assign(new Product(), o.base) : null;

          const products = o.products
            ? o.products.map((value: object) => {
                return Object.assign(new Product(), value);
              })
            : [];
          const labor = o.laborItems
            ? o.laborItems.map((value: object) => {
                const item = Object.assign(new SolutionLaborItem(), value);
                if (item.reasons) {
                  item.reasons = item.reasons.map(r =>
                    Object.assign(new Reason(), r)
                  );
                }
                return item;
              })
            : [];

          const model = new ConfiguredSolution(base, products, labor);
          model.isChairOnly = o.isChairOnly;
          model.requiredCategories = o.requiredCategories;

          resolved.solutions.push(model);
        });
      }

      return resolved;
    }
    return null;
  }

  private sendEvent(type: DataStoreEventType): void {
    this.saveLocally();
    this.eventsSubject$.next(type);
  }
}

interface ICachedSolutions {
  type: SolutionType;
  solutions: IConfiguredSolution[];
  loaner: Product;
}
