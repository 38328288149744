import { Product } from 'app/domain';
import { SolutionBuilderClient } from '../services/solution-builder-client.service';
import { ISaveValidator } from './models';

export class CustomProductsSaveValidator implements ISaveValidator {
  validate(messages: string[], client: SolutionBuilderClient): void {
    const solutions = client.getSolutions();

    let allSelectedProducts: Product[] = [];
    for (let i = 0, max = solutions.length; i < max; i++) {
      const solution = solutions[i];

      // Unpack products first
      if (solution.hasProducts()) {
        allSelectedProducts = [...allSelectedProducts, ...solution.products];
      }

      // Add base as the terminator in the list
      if (solution.hasBase()) {
        allSelectedProducts.push(solution.base);
      }
    }

    // Enforce custom products have notes
    const customProductWithoutNote = allSelectedProducts.find(
      p => p.isCustomProduct && !p.externalNote
    );
    if (customProductWithoutNote) {
      messages.push(`
          There are Custom Products added to this Quote without notes.  Please
          enter a note for each Custom Product.
          `);
    }
  }
}
