import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute, Data } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import {
  Solution,
  FieldOption,
  LaborCategory,
  SolutionTypeFlowType,
  SolutionType,
  SolutionTypeResolvedModel,
  Category
} from 'app/domain';
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators
} from '@angular/forms';
import { LaborReasonService } from 'app/services/labor-reason.service';
import { ToastrService } from 'ngx-toastr';
import { SolutionTypeService } from 'app/services/solution-type.service';

@Component({
  selector: 'sb-solution-type-settings',
  templateUrl: './solution-type-settings.component.html',
  styleUrls: ['./solution-type-settings.component.scss']
})
export class SolutionTypeSettingsComponent implements OnInit, OnDestroy {
  unassignedSolutionTypes: Category[];
  solutionType: SolutionType;

  fgSettings: FormGroup;
  fcCategory: FormControl = new FormControl('', Validators.required);
  fcFlow: FormControl = new FormControl('', Validators.required);
  fcLaborCategory: FormControl = new FormControl('');

  categoryOptions: FieldOption[] = [];

  flowOptions: FieldOption[] = [
    new FieldOption('Single Base', SolutionTypeFlowType.Single),
    new FieldOption('Multiple Bases', SolutionTypeFlowType.Multi),
    new FieldOption('Repair', SolutionTypeFlowType.Repair)
  ];

  laborCategories: FieldOption[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private solutionTypeService: SolutionTypeService,
    private toastService: ToastrService,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.fgSettings = this.formBuilder.group({
      fcFlow: this.fcFlow,
      fcCategory: this.fcCategory,
      fcLaborCategory: this.fcLaborCategory
    });

    this.activatedRoute.data.subscribe((resp: Data) => {
      const resolvedModel: SolutionTypeResolvedModel = resp.settings;
      const solutionType: SolutionType = resolvedModel.solutionType;
      const laborReasons: LaborCategory[] = resp.laborReasons;

      this.unassignedSolutionTypes = resolvedModel.unassignedCategories;
      this.solutionType = resolvedModel.solutionType;

      if (resolvedModel.hasUnassignedCategories()) {
        this.categoryOptions = resolvedModel.unassignedCategories.map(
          c => new FieldOption(c.name, c.code)
        );
      }

      this.laborCategories = laborReasons
        ? laborReasons
            .filter(c => c.children && c.children.length > 0)
            .map(c => new FieldOption(c.name, c.id))
        : [];

      if (solutionType) {
        this.fcFlow.setValue(solutionType.flow);

        this.fcLaborCategory.setValue(
          solutionType.laborCategories
            ? Number.parseInt(solutionType.laborCategories[0])
            : null
        );

        // If we are editing a solution type
        // it will not have the value in the options since
        // the options are populated from the unassigned endpoint.
        // Add it, set it, and disabled the control.
        if (solutionType.code) {
          this.categoryOptions.push(
            new FieldOption(solutionType.name, solutionType.code)
          );
          this.fcCategory.setValue(solutionType.code);
          this.fcCategory.disable();
        }
      }

      // This runs change detection after the values have been set
      // Though the controls have their values, some controls
      // don't seem to reflect it till the next change runs.
      this.changeDetectorRef.detectChanges();
    });
  }

  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
  }

  ngOnDestroy(): void {}

  saveSolutionType(): void {
    if (this.fgSettings.valid) {
      let solutionType: SolutionType = this.solutionType;
      if (!solutionType) {
        solutionType = new SolutionType();

        const categories: Category[] = this.unassignedSolutionTypes;
        const category = categories.find(c => c.code === this.fcCategory.value);
        solutionType.name = category.name;
        solutionType.code = category.code;
      }

      solutionType.flow = this.fcFlow.value;
      solutionType.laborCategories = this.fcLaborCategory.value
        ? [this.fcLaborCategory.value]
        : [];

      if (solutionType.id) {
        this.solutionTypeService.updateSolutionType(solutionType).subscribe(
          () => {
            this.toastService.success('Settings updated.');
          },
          error => this.toastService.error('Failed to update Settings.')
        );
      } else {
        this.solutionTypeService.createSolutionType(solutionType).subscribe(
          (solutionType: SolutionType) => {
            this.toastService.success('Settings updated.');
            this.router.navigateByUrl(
              `/admin/solution-types/${solutionType.id}`
            );
          },
          error => this.toastService.error('Failed to update Settings.')
        );
      }
    } else {
      this.toastService.warning(
        'The form is invalid, please verify your selections.'
      );
    }
  }
}
