<sb-callout *ngIf="!isLoading && documents.length == 0">
  No upload history available.
</sb-callout>
<ul class="sb-options-list">
  <li *ngFor="let document of documents">
    <a
      [attr.href]="document.url"
      target="_blank"
      [attr.title]="'Download ' + document.fileName"
    >
      <span class="icon fas fa-download"></span>{{ document.fileName }}
    </a>
  </li>
</ul>
