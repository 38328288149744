import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { extract } from 'app/core';
import { Shell } from 'app/shell/shell.service';
import { AuthGuardWithForcedLogin } from 'app/core/authentication/auth-guard-with-forced-login.service';
import { SolutionTypeSettingsComponent } from './solution-types/solution-type-settings/solution-type-settings.component';
import { SolutionTypesListComponent } from './solution-types/solution-types-list/solution-types-list.component';
import { SolutionTypeSettingsResolver } from './solution-types/solution-type-settings/solution-type-settings.resolver';
import { SolutionTypesResolver } from 'app/services/resolvers/solution-types.resolver';
import { AdminSettingsComponent } from './admin-settings/admin-settings.component';
import { EditTemplateComponent } from './templates/edit-template/edit-template.component';
import { VendorsResolver } from 'app/services/resolvers/vendors.resolver';
import { TemplateResolver } from './templates/resolvers/template.resolver';
import { TemplatesListComponent } from './templates/templates-list/templates-list.component';
import { EditDefinitionComponent } from './definitions/edit-definition/edit-definition.component';
import { DefinitionsListComponent } from './definitions/definitions-list/definitions-list.component';
import { LaborCategoriesResolver } from 'app/services/resolvers/labor-categories.resolver';
import { BranchResolver } from 'app/services/resolvers/branch.resolver';
import { TemplateAvailabilityResolver } from 'app/services/resolvers/template-availability.resolver';
import { BaseModelRequirementsResolver } from './definitions/edit-definition/base-model-requirements.resolver';
import { AdminGuard } from 'app/core/guards/admin.guard';

const routes: Routes = [
  Shell.childRoutes([
    {
      path: 'admin',
      canActivate: [AuthGuardWithForcedLogin, AdminGuard],
      children: [
        {
          path: 'solution-types',
          children: [
            {
              path: ':solutionTypeId',
              component: SolutionTypeSettingsComponent,
              data: { title: extract('Solution Type Settings') },
              resolve: {
                settings: SolutionTypeSettingsResolver,
                laborReasons: LaborCategoriesResolver
              }
            },
            {
              path: '',
              component: SolutionTypesListComponent,
              data: { title: extract('Solution Types List') }
            }
          ]
        },
        {
          path: 'templates',
          children: [
            {
              path: ':templateId',
              component: EditTemplateComponent,
              data: { title: extract('Edit Template') },
              resolve: {
                solutionTypes: SolutionTypesResolver,
                vendors: VendorsResolver,
                template: TemplateResolver,
                branches: BranchResolver,
                scopes: TemplateAvailabilityResolver
              }
            },
            {
              path: '',
              component: TemplatesListComponent,
              data: { title: extract('Templates List') }
            }
          ]
        },
        {
          path: 'definitions',
          children: [
            {
              path: ':definitionId',
              component: EditDefinitionComponent,
              resolve: {
                solutionTypes: SolutionTypesResolver,
                requirement: BaseModelRequirementsResolver
              },
              data: {
                title: extract('Edit Base Model Requirements Definition')
              }
            },
            {
              path: '',
              component: DefinitionsListComponent,
              resolve: {
                vendors: VendorsResolver
              },
              data: {
                title: extract('Base Model Requirements Definition List')
              }
            }
          ]
        },
        {
          path: '',
          component: AdminSettingsComponent,
          data: { title: extract('Admin Settings') }
        }
      ]
    }
  ])
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: []
})
export class SolutionBuilderAdminRoutingModule {}
