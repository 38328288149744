import { Component, OnInit } from '@angular/core';
import { ConfiguredSolution } from 'app/domain';
import { SolutionBuilderClient } from '../services/solution-builder-client.service';

@Component({
  selector: 'sb-quote-summary',
  templateUrl: './quote-summary.component.html',
  styleUrls: ['./quote-summary.component.scss']
})
export class SbQuoteSummaryComponent implements OnInit {
  workOrderId: string;

  solutions: ConfiguredSolution[] = [];
  clientName: string;

  constructor(private solutionBuilderClient: SolutionBuilderClient) {}

  ngOnInit(): void {
    this.workOrderId = this.solutionBuilderClient.getWorkOrderId();
    this.solutions = this.solutionBuilderClient.getSolutions();
  }
}
