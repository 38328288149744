import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { CoreModule } from 'app/core';
import { SolutionBuilderSharedModule } from 'app/shared';
import { SolutionBuilderDomainModule } from 'app/domain/solution-builder-domain.module';
import { ReactiveFormsModule } from '@angular/forms';
import { ToastrModule } from 'ngx-toastr';

import { CategoryNamePipe } from 'app/domain/pipes/category-name.pipe';

import { SolutionBuilderCommonViewsModule } from '../common/solution-builder-common-views.module';

import { RepairBuilderRoutingModule } from './repair-builder-routing.module';
import { SbRepairBuilderComponent } from './repair-builder.component';
import { AddProductAndLaborStepComponent } from './add-product-and-labor-step/add-product-and-labor-step.component';
import { EditLaborItemComponent } from './add-product-and-labor-step/views/edit-labor-item/edit-labor-item.component';
import { PickLaborItemComponent } from './add-product-and-labor-step/views/pick-labor-item/pick-labor-item.component';
import { SbClientAssetDialogComponent } from './client-asset-dialog/client-asset-dialog.component';
import { SbClientAssetEditComponent } from './client-asset-dialog/client-asset-edit/client-asset-edit.component';
import { SbClientAssetListComponent } from './client-asset-dialog/client-asset-list/client-asset-list.component';
import { SbClientAssetSearchComponent } from './client-asset-dialog/client-asset-search/client-asset-search.component';
import { SbSelectedClientAssetComponent } from './selected-client-asset/selected-client-asset.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslateModule,
    CoreModule,
    SolutionBuilderSharedModule,
    RepairBuilderRoutingModule,
    SolutionBuilderDomainModule,
    ToastrModule.forRoot({
      positionClass: 'toast-bottom-right',
      preventDuplicates: true
    }),
    SolutionBuilderCommonViewsModule
  ],
  declarations: [
    SbRepairBuilderComponent,
    AddProductAndLaborStepComponent,
    EditLaborItemComponent,
    PickLaborItemComponent,
    SbClientAssetDialogComponent,
    SbClientAssetEditComponent,
    SbClientAssetListComponent,
    SbClientAssetSearchComponent,
    SbSelectedClientAssetComponent
  ],
  providers: [CategoryNamePipe]
})
export class RepairBuilderModule {}
