<div class="sb-empty-menu-content-wrapper">
  <header class="sb-selection-menu-header">
    <h3 class="sb-selection-menu-header-title">
      {{ title }}
    </h3>
    <ul class="sb-selection-menu-actions">
      <li>
        <ng-content select="sb-side-menu-action"></ng-content>
      </li>
      <li *ngIf="canClose">
        <button
          kendoButton
          iconClass="fal fa-times"
          (click)="onCloseMenu()"
        ></button>
      </li>
    </ul>
  </header>
  <ng-content select="sb-side-menu-toolbar"></ng-content>
  <div
    class="sb-selection-menu-content"
    [class.has-footer]="footer"
    [class.alt]="alt"
  >
    <ng-content select="sb-side-menu-content"></ng-content>
    <ng-content select="sb-side-menu-footer"></ng-content>
  </div>
</div>
