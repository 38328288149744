<sb-empty-menu
  [title]="'Filters'"
  [canClose]="false"
  footer="true"
  alt="true"
  (closeMenu)="closeFilters()"
>
  <sb-side-menu-content [formGroup]="fgFilters">
    <p>
      <input
        type="checkbox"
        kendoCheckBox
        formControlName="fcAftermarket"
      /><label class="k-checkbox-label">Aftermarket</label>
    </p>
    <p>
      <sb-text-field
        label="Name or Description"
        iconClass="fal fa-search"
        [control]="fcName"
      ></sb-text-field>
    </p>

    <p>
      <sb-dropdown-field
        label="Vendors"
        [control]="fcVendor"
        [filterable]="true"
        placeholder="Select Vendor"
        [options]="vendorCriteria | sortOptions"
      >
      </sb-dropdown-field>
    </p>

    <p>
      <sb-dropdown-field
        label="HCPCS"
        [control]="fcHcpcs"
        [filterable]="true"
        placeholder="Select HCPCS"
        [options]="hcpcsCriteria | sortOptions"
      >
      </sb-dropdown-field>
    </p>
    <div *ngFor="let grouping of attributeGroupings">
      <sb-attribute-filter
        [control]="fgAttributes.get(grouping.id)"
        [name]="grouping.name"
        [attributes]="grouping.attributes"
        (onSelected)="updateAttributeFilters($event)"
      ></sb-attribute-filter>
    </div>
  </sb-side-menu-content>
  <sb-side-menu-footer>
    <button kendoButton primary="true" (click)="filter()">
      Filter
    </button>
  </sb-side-menu-footer>
</sb-empty-menu>
