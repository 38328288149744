<div class="sb-selected-client-asset-form" [formGroup]="fgAsset" *ngIf="asset">
  <sb-product-item
    *ngIf="asset.product"
    class="sb-selected-client-asset"
    [product]="asset.product"
    [hidePricing]="true"
    [selectable]="false"
  ></sb-product-item>
  <p *ngIf="!asset.product">
    <span>No associated Product for this Asset.</span>
  </p>
  <p>
    <sb-text-field
      label="NSM Serial Number"
      [control]="fcNsmSerialNumber"
    ></sb-text-field>
  </p>
  <p>
    <sb-text-field
      label="Wheelchair Serial Number"
      [control]="fcSerialNumber"
    ></sb-text-field>
  </p>
</div>
