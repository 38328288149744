import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'sb-access-denied',
  templateUrl: './access-denied.component.html',
  styleUrls: ['./access-denied.component.scss']
})
export class AccessDeniedPageComponent implements OnInit {
  message: string = `Looks like you don't have permission to access this area. If you're supposed to have permission, please
  contact your administrator to help resolve your issue.`;
  constructor(private activatedRoute: ActivatedRoute) {}

  ngOnInit(): void {}
}
