<div class="container">
  <div>
    <h5>Status</h5>
    <label>{{ results.status }}</label>
    <p *ngIf="results.status === 'valid'">
      The file uploaded is a PDF and we are able to read fields from the PDF.
      This does not mean those fields find matching data.
    </p>
    <p *ngIf="results.status === 'invalid'">
      The file uploaded is not a fillable PDF. Please make sure that the file is
      not an image of the fillable PDF. If you verify that it is a fillable PDF,
      the PDF may have an internal field format that is not valid for a
      successful import.
    </p>
  </div>
  <div>
    <h5>Fields</h5>
    <ul>
      <li *ngFor="let field of results.fields">
        <label>{{ field }}</label>
      </li>
    </ul>
  </div>
</div>
<kendo-dialog-actions>
  <button kendoButton (click)="onCancelAction()">Close</button>
  <button kendoButton (click)="onConfirmAction()" [primary]="true">
    Ok
  </button>
</kendo-dialog-actions>
