<section class="sb-edit-labor-view">
  <span class="sb-category-group">{{ category.name }}</span>
  <ul class="sb-options-list">
    <li>
      <a (click)="returnToLaborItems()">
        <span class="icon fas fa-long-arrow-left"></span>Back to Labor
      </a>
    </li>
  </ul>
  <div class="sb-form-wrapper" [formGroup]="fgEditLabor">
    <p class="sb-form-field">
      <sb-dropdown-field
        label="Labor Code"
        [options]="laborCodeOptions"
        [control]="fcLaborCode"
      ></sb-dropdown-field>
    </p>
    <p class="sb-form-field">
      <label class="sb-form-field-label"
        >Units of Service (min: {{ minQuantity }} / max:
        {{ maxQuantity }})</label
      >
      <sb-number-field
        [control]="fcQuantity"
        [min]="minQuantity"
        [max]="maxQuantity"
      >
        <div errors>
          <sb-field-error *ngIf="fcQuantity.errors?.max"
            >The maximum allowed is {{ maxQuantity }}.</sb-field-error
          >
          <sb-field-error *ngIf="fcQuantity.errors?.min"
            >The maximum allowed is {{ minQuantity }}.</sb-field-error
          >
        </div>
      </sb-number-field>
    </p>
    <div
      *ngIf="faReasons && faReasons.controls?.length > 0"
      class="sb-form-field"
    >
      <p class="sb-form-field">
        <label class="sb-form-field-label">Reason Codes</label>
      </p>
      <ul class="sb-options-list" formArrayName="faReasons">
        <li *ngFor="let control of faReasons.controls; let i = index">
          <a
            class="sb-reason-code-option"
            (click)="control.setValue(!control.value)"
          >
            <input type="hidden" [formControl]="control" />
            <sb-square-check-indicator
              [enabled]="control.value"
              enabledTooltip="Reason Code Selected"
              disabledTooltip="Reason Code Not Selected"
            >
            </sb-square-check-indicator>
            {{ reasonOptions[i].text }}
          </a>
        </li>
      </ul>
    </div>
    <p class="sb-form-field">
      <sb-text-field
        label="Notes"
        [control]="fcNotes"
        multiline="true"
        [required]="requiresNote"
      >
        <div errors>
          <sb-field-error *ngIf="fcNotes.errors?.required"
            >This field is required.</sb-field-error
          >
          <sb-field-error *ngIf="fcNotes.errors?.preventWhitespaceOnly"
            >Please enter a valid explanation.</sb-field-error
          >
        </div>
      </sb-text-field>
    </p>
    <button kendoButton primary="true" (click)="addLaborItem()">
      Add Labor
    </button>
  </div>
</section>
