import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormGroup, FormControl, FormBuilder } from '@angular/forms';
import {
  FieldOption,
  Product,
  ProductSearchRequest,
  SolutionTypeFlowType
} from 'app/domain';
import { SolutionBuilderOptionsDataStore } from 'app/modules/solution-builder/services/solution-options-data-store.service';
import { SolutionTypeService } from 'app/services/solution-type.service';
import * as _ from 'lodash';
import { debounceTime, finalize } from 'rxjs/operators';

@Component({
  selector: 'sb-client-asset-search',
  templateUrl: './client-asset-search.component.html',
  styleUrls: ['../client-asset-dialog.component.scss']
})
export class SbClientAssetSearchComponent implements OnInit {
  @Output()
  selected: EventEmitter<Product> = new EventEmitter<Product>();

  fgSearch: FormGroup;
  fcFilter: FormControl = new FormControl('');
  fcSolutionType: FormControl = new FormControl('');

  baseModels: FieldOption[] = [];

  allProducts: Product[] = [];

  assetProducts: Product[] = [];

  filteredProducts: Product[] = [];

  displayProducts: Product[] = [];

  isLoading: boolean = false;

  solutionTypeOptions: FieldOption[] = [];

  showNoProductsFound: boolean = false;

  private readonly PAGE_SIZE: number = 100;

  constructor(
    private solutionTypeService: SolutionTypeService,
    private optionsDataStore: SolutionBuilderOptionsDataStore,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit(): void {
    const solutionTypes = this.optionsDataStore.getSolutionTypes();
    this.solutionTypeOptions = _.sortBy(
      solutionTypes
        .filter(s => s.flow != SolutionTypeFlowType.Repair)
        .map(s => new FieldOption(s.name, s.id)),
      o => o.text
    );

    this.fgSearch = this.formBuilder.group({
      fcFilter: this.fcFilter,
      fcSolutionType: this.fcSolutionType
    });
  }

  noProductsFound(): boolean {
    const isLoading = this.isLoading;
    const products = this.allProducts;
    const searchTerm = this.fcFilter.value;

    if (isLoading) {
      return false;
    }

    if (searchTerm && searchTerm.length >= 3 && products.length === 0) {
      return true;
    }

    return false;
  }

  searchProducts(): void {
    const solutionTypeCategoryId: string = this.fcSolutionType.value;

    // Build search request
    const request = new ProductSearchRequest();

    // Extract any "Custom" criteria
    // and apply it as the search string.
    request.searchString = this.fcFilter.value
      ? this.fcFilter.value.trim()
      : null;

    this.isLoading = true;
    this.solutionTypeService
      .getSolutionTypeBases(Number.parseInt(solutionTypeCategoryId))
      .pipe(
        debounceTime(300),
        finalize(() => (this.isLoading = false))
      )
      .subscribe((products: Product[]) => {
        // Store all products so we can pull
        // more data from it
        this.allProducts = Object.assign([], products);

        this.filteredProducts = this.allProducts.filter(p =>
          p.matchesDescription(this.fcFilter.value)
        );

        // Take the first pages worth of products
        // to display
        this.displayProducts = this.filteredProducts.splice(0, this.PAGE_SIZE);

        // Determine if we should show
        if (this.allProducts.length > 0) {
          this.showNoProductsFound = false;
        } else {
          this.showNoProductsFound = true;
        }
      });
  }

  loadMore(): void {
    const products = this.filteredProducts.splice(0, this.PAGE_SIZE);
    this.displayProducts = [...this.displayProducts, ...products];
  }

  selectProduct(product: Product): void {
    this.selected.emit(product);
  }
}
