import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { extract } from 'app/core';
import { Shell } from 'app/shell/shell.service';
import { ChairBuilderComponent } from './solution-builder.component';
import { SolutionTypesResolver } from 'app/services/resolvers/solution-types.resolver';
import { VendorsResolver } from 'app/services/resolvers/vendors.resolver';
import { ChairBuilderResolver } from './resolvers/solution-builder.resolver';
import { BranchResolver } from 'app/services/resolvers/branch.resolver';
import { TemplateAvailabilityResolver } from 'app/services/resolvers/template-availability.resolver';
import { SbQuoteSummaryComponent } from './quote-summary/quote-summary.component';

const routes: Routes = [
  Shell.childRoutes([
    {
      path: 'builder',
      children: [
        {
          path: ':workOrderId',
          data: { title: extract('Solution Builder') },
          component: ChairBuilderComponent,
          resolve: {
            solutionTypes: SolutionTypesResolver,
            vendors: VendorsResolver,
            solution: ChairBuilderResolver,
            branches: BranchResolver,
            scopes: TemplateAvailabilityResolver
          }
        },
        {
          path: ':workOrderId/summary',
          component: SbQuoteSummaryComponent
        },
        {
          path: '**',
          redirectTo: '/landing/error'
        }
      ]
    }
  ])
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: []
})
export class SolutionBuilderRoutingModule {}
