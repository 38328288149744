<div
  class="sb-product-lookup-overlay"
  *ngIf="{
    isLoading: isLoading$ | async,
    products: products$ | async
  } as data"
>
  <div class="sb-product-lookup-dialog" #container>
    <header class="sb-product-lookup-dialog-header">
      <h3 class="sb-product-lookup-dialog-header-title">
        Product Lookup
        <a (click)="closeDialog()" class="sb-close-dialog"
          ><span class="fal fa-times-circle"></span
        ></a>
      </h3>
    </header>
    <div class="grid-x">
      <div class="small-5 cell" [formGroup]="fgSearch">
        <div class="sb-product-lookup-form">
          <div class="sb-product-lookup-form-fields">
            <p>
              <sb-dropdown-field
                label="Show Products Compatible With"
                placeholder="Select a Base Model"
                [control]="fcBaseModel"
                [options]="baseModels"
                [class.hide]="baseModels?.length === 0"
              >
              </sb-dropdown-field>
            </p>
            <p>
              <input
                type="checkbox"
                kendoCheckBox
                formControlName="fcAfterMarket"
              />
              <label>Aftermarket</label>
            </p>
            <p>
              <sb-text-field
                label="Part Number or Description"
                [control]="fcFilter"
                [placeholder]="'Product description or number contains...'"
              ></sb-text-field>
            </p>
            <p>
              <sb-dropdown-field
                label="Vendors"
                [options]="vendorOptions"
                [control]="fcVendor"
                [placeholder]="'Find by code or description'"
              ></sb-dropdown-field>
            </p>
            <p>
              <sb-dropdown-field
                label="Groups"
                [options]="groupOptions"
                [control]="fcGroup"
                [placeholder]="'Find by code or description'"
              ></sb-dropdown-field>
            </p>
            <p>
              <sb-dropdown-field
                label="HCPCS"
                [options]="hcpcsOptions"
                [control]="fcHcpcs"
                [placeholder]="'Find by code or description'"
              ></sb-dropdown-field>
            </p>
          </div>

          <div class="sb-product-lookup-form-footer">
            <button
              kendoButton
              [primary]="true"
              [disabled]="fgSearch.invalid"
              (click)="searchProducts()"
            >
              Search
            </button>
          </div>
        </div>
      </div>
      <div class="small-7 cell">
        <div
          class="sb-product-lookup-scroll-content"
          [style.height.px]="height"
        >
          <!-- Product Lookup View-->
          <section class="sb-product-view">
            <div>
              <sb-callout *ngIf="data.isLoading" class="sb-loading-wrapper"
                ><span class="fas fa-spinner fa-spin icon"></span>
                <span class="loading-text">{{
                  fcFilter.value
                    ? 'Searching Products...'
                    : 'Loading Products..'
                }}</span>
              </sb-callout>

              <sb-callout *ngIf="!data.isLoading && showNoProductsFound">
                No Products found.
              </sb-callout>
              <ul class="sb-options-list" *ngIf="!data.isLoading">
                <li
                  *ngFor="
                    let option of data.products | rankProducts;
                    index as i
                  "
                >
                  <sb-product-item
                    [product]="option"
                    [selected]="hasAlreadySelectedProduct(option)"
                    (click)="selectProduct(option)"
                  ></sb-product-item>
                </li>
              </ul>
              <div class="sb-load-more-wrapper">
                <a
                  class="sb-load-more"
                  *ngIf="allProducts.length > 0"
                  (click)="loadMore()"
                  >Load More</a
                >
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  </div>
</div>
