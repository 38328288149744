import { Injectable } from '@angular/core';
import {
  Resolve,
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from '@angular/router';
import { Observable } from 'rxjs';
import { BranchService } from '../branch.service';
import { Branch } from 'app/domain/models/core/models';
import { tap } from 'rxjs/operators';
import { SolutionBuilderOptionsDataStore } from 'app/modules/solution-builder/services/solution-options-data-store.service';

/**
 * Resolver to grab the Branches from the server.
 */
@Injectable({ providedIn: 'root' })
export class BranchResolver implements Resolve<Observable<Branch[]>> {
  constructor(
    private branchService: BranchService,
    private optionsDataStore: SolutionBuilderOptionsDataStore
  ) {}
  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<Branch[]> {
    return this.branchService.getBranches().pipe(
      tap((branches: Branch[]) => {
        this.optionsDataStore.setBranches(branches);
      })
    );
  }
}
