import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Data, Router } from '@angular/router';
import { Solution, SolutionType } from 'app/domain';
import { BehaviorSubject, Observable } from 'rxjs';
import { SolutionTypeService } from 'app/services/solution-type.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'sb-solution-types-list',
  templateUrl: './solution-types-list.component.html',
  styleUrls: ['./solution-types-list.component.scss']
})
export class SolutionTypesListComponent implements OnInit {
  solutionTypes$: BehaviorSubject<SolutionType[]> = new BehaviorSubject<
    SolutionType[]
  >([]);

  constructor(
    private solutionTypeService: SolutionTypeService,
    private toastService: ToastrService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.loadSolutionTypes();
  }

  confirmRemoveSolutionType(solutionType: SolutionType): void {
    this.solutionTypeService.deleteSolutionType(solutionType).subscribe(
      () => {
        this.toastService.success('Successfully removed Solution Type.');
        this.loadSolutionTypes();
      },
      () => {
        this.toastService.error(
          'Failed to remove Solution Type. Please check the console for more information.'
        );
      }
    );
  }

  private loadSolutionTypes(): void {
    this.solutionTypeService.getSolutionTypes().subscribe(
      (solutionTypes: SolutionType[]) => {
        this.solutionTypes$.next(solutionTypes);
      },
      () => {
        this.toastService.error(
          'Failed to remove Solution Type. Please check the console for more information.'
        );
      }
    );
  }
}
