import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';

import { CoreModule } from 'app/core';

import { ErrorPageComponent } from './error-page/error-page.component';
import { LandingPagesRoutingModule } from './landing-pages-routing.module';
import { SolutionBuilderSharedModule } from 'app/shared';
import { RequiresLoginPageComponent } from './requires-login-page/requires-login-page.component';
import { AccessDeniedPageComponent } from './access-denied/access-denied.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslateModule,
    CoreModule,
    LandingPagesRoutingModule,
    SolutionBuilderSharedModule
  ],
  declarations: [
    ErrorPageComponent,
    RequiresLoginPageComponent,
    AccessDeniedPageComponent
  ]
})
export class LandingPagesModule {}
