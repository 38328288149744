import { HttpEvent, HttpEventType, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  CeDocument,
  DocumentType,
  ISolutionGrouping,
  Product,
  SolutionGrouping,
  SolutionType,
  UploadedDocument,
  VendorFormLoadResults,
  VendorFormValidateResults
} from 'app/domain';
import { SolutionBuilderClient } from 'app/modules/solution-builder/services/solution-builder-client.service';
import { DmsService } from 'app/services/dms.service';
import { DynamicsService } from 'app/services/dynamics.service';
import { ProductsService } from 'app/services/products.service';
import { Observable, of } from 'rxjs';
import { catchError, filter, switchMap, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ImportPdfClientService {
  private vendorHotFormDocumentType: DocumentType;

  constructor(
    private dmsService: DmsService,
    private dynamicsSerivce: DynamicsService,
    private productService: ProductsService,
    private solutionBuilderClient: SolutionBuilderClient
  ) {
    this.loadVendorHotFormDocumentType();
  }

  loadDocumentHistory(): Observable<CeDocument[]> {
    const workOrderId = this.solutionBuilderClient.getWorkOrderId();
    return this.dmsService.getSavedHotForms(workOrderId);
  }

  deleteDocument(document: CeDocument): Observable<void> {
    return this.dmsService.deleteCeDocument(document);
  }

  downloadDocument(document: CeDocument): void {}

  validateVendorHotForm(
    file: File,
    progressCallback: Function
  ): Observable<VendorFormValidateResults> {
    return this.productService.validateImportFile(file).pipe(
      tap((event: any) => {
        if (event.type === HttpEventType.UploadProgress) {
          progressCallback(Math.round((100 * event.loaded) / event.total));
        }
      }),

      filter((event: any) => {
        return event.type === HttpEventType.Response;
      }),
      switchMap((event: HttpResponse<VendorFormValidateResults>) => {
        return of(event.body);
      })
    );
  }

  importVendorHotForm(
    file: File,
    progressCallback: Function
  ): Observable<VendorFormLoadResults> {
    return this.productService.loadProductsFromFile(file).pipe(
      tap((event: any) => {
        if (event.type === HttpEventType.UploadProgress) {
          progressCallback(Math.round((100 * event.loaded) / event.total));
        }
      }),

      filter((event: any) => {
        return event.type === HttpEventType.Response;
      }),
      switchMap((event: HttpResponse<VendorFormLoadResults>) => {
        const results: VendorFormLoadResults = new VendorFormLoadResults();

        if (!event.body) {
          return of(results);
        }

        results.fileName = event.body.fileName;

        // Map Solution
        if (event.body.solutionType) {
          results.solutionType = Object.assign(
            new SolutionType(),
            event.body.solutionType
          );
        }

        if (event.body.solutions) {
          results.solutions = event.body.solutions.map(
            (grouping: ISolutionGrouping) => {
              const model = new SolutionGrouping();
              model.base = Object.assign(new Product(), grouping.base);
              model.solutionType = Object.assign(
                new SolutionType(),
                grouping.solutionType
              );
              model.products = grouping.products.map(p =>
                Object.assign(new Product(), p)
              );
              return model;
            }
          );
        }

        // Map missing items
        if (event.body.missingItems) {
          results.missingItems = event.body.missingItems;
        }

        // Uncategorized Porducts
        if (event.body.uncategorizedProducts) {
          results.uncategorizedProducts = event.body.uncategorizedProducts.map(
            (p: Product) => Object.assign(new Product(), p)
          );
        }

        // Map Line items
        if (event.body.lineItems) {
          results.lineItems = event.body.lineItems;
        }

        return of(results);
      })
    );
  }

  saveDocument(
    results: VendorFormLoadResults,
    file: File
  ): Observable<UploadedDocument> {
    let vendorAccountNumber: string = '';
    if (
      results.uncategorizedProducts &&
      results.uncategorizedProducts.length > 0
    ) {
      vendorAccountNumber =
        results.uncategorizedProducts[0].vendorAccountNumber;
    } else if (results.hasSolutions()) {
      const solution = results.solutions[0];
      if (solution.base) {
        vendorAccountNumber = solution.base.vendorAccountNumber;
      } else if (solution.hasProducts()) {
        vendorAccountNumber = solution.products[0].vendorAccountNumber;
      }
    }

    return this.dmsService.saveDocumentToCe(
      this.solutionBuilderClient.getWorkOrderId(),
      vendorAccountNumber,
      this.vendorHotFormDocumentType,
      null,
      file
    );
  }

  private loadVendorHotFormDocumentType(): void {
    this.dynamicsSerivce
      .getVendorHotFormDocumentType()
      .subscribe((documentType: DocumentType) => {
        this.vendorHotFormDocumentType = documentType;
      });
  }
}
