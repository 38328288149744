import { Component, OnInit, HostBinding } from '@angular/core';

@Component({
  selector: 'sb-field-error',
  templateUrl: './sb-field-error.component.html',
  styleUrls: ['./sb-field-error.component.scss']
})
export class SbFieldErrorComponent implements OnInit {
  @HostBinding('class') class: string = 'sb-field-error';

  constructor() {}

  ngOnInit(): void {}
}
