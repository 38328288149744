<div
  class="sb-container"
  kendoDialogContainer
  *ngIf="{ definitions: definitions$ | async } as data"
>
  <div #spacer></div>
  <header class="sb-header" stickyThing [spacer]="spacer">
    <div class="grid-x">
      <div class="small-7 cell">
        <ul class="sb-tab-menu">
          <li>
            <a class="is-active">Base Model Definitions</a>
          </li>
        </ul>
      </div>

      <div class="small-5 cell align-self-middle"></div>
    </div>
  </header>
  <main class="sb-content">
    <!-- Page Header Section-->
    <header class="sb-content-header">
      <div class="grid-x align-middle">
        <div class="small-8 cell">
          <ul class="sb-breadcrumbs">
            <li class="is-parent"><span>Solution Builder</span></li>
            <li class="is-divider"><span>/</span></li>
            <li class="is-parent"><a [routerLink]="'/admin'">Settings</a></li>
            <li class="is-divider"><span>/</span></li>
            <li class="is-active"><span class="">Definitions</span></li>
          </ul>
          <h1 class="sb-content-heading">Definitions</h1>
        </div>
        <div class="small-4 cell text-right">
          <div class="sb-flow-list-actions">
            <button kendoButton primary="true" [routerLink]="['./', -1]">
              New Definition
            </button>
          </div>
        </div>
      </div>
    </header>
    <!-- Filter Section-->
    <section class="sb-filter-section">
      <div class="grid-x grid-padding-x" [formGroup]="fgDefinitionFilter">
        <div class="small-9 cell">
          <sb-text-field
            placeholder="Filter Definitions"
            iconClass="fal fa-search"
            [control]="fcFilter"
          ></sb-text-field>
        </div>
        <div class="small-3 cell"></div>
      </div>
    </section>
    <!-- Template List Section-->
    <section class="sb-flow-list-section">
      <div class="grid-x">
        <div class="cell">
          <div class="sb-flow-item-list">
            <sb-callout *ngIf="data.definitions.length === 0" warn="true"
              >No Definitions found.</sb-callout
            >
            <div class="grid-x">
              <div
                class="small-12 cell"
                *ngFor="let item of data.definitions; index as i"
              >
                <div class="sb-flow-item-wrapper">
                  <div
                    class="sb-flow-item"
                    [class.is-selected]="item.isSelected"
                    kendoTooltip
                  >
                    <div class="sb-flow-item-content">
                      <span
                        class="sb-flow-item-name"
                        [attr.title]="item.name"
                        >{{ item.model }}</span
                      >
                      <div class="sb-flow-actions">
                        <button
                          kendoButton
                          [primary]="true"
                          [routerLink]="['./', item.id]"
                        >
                          Edit
                        </button>
                        <button
                          kendoButton
                          class="warning"
                          (click)="showConfirmRemoveDialog(item)"
                        >
                          Remove
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</div>
