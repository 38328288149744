import {
  Component,
  OnInit,
  Input,
  HostBinding,
  Output,
  EventEmitter
} from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { SolutionTypeConfig, CpqField, CpqSection } from 'app/domain';

@Component({
  selector: 'sb-category-selection-menu',
  templateUrl: './category-selection-menu.component.html',
  styleUrls: ['./category-selection-menu.component.scss']
})
export class CategorySelectionMenuComponent implements OnInit {
  @HostBinding('class') class: string =
    'sb-selection-menu sb-category-selection-menu';

  @Input()
  sections: CpqSection[] = [];

  @Input() set selected(value: string[]) {
    this.selectedCategoriesByCode = new Set<string>(value);
  }

  @Output()
  selectionChanged: EventEmitter<string[]> = new EventEmitter<string[]>();

  selectedCategoriesByCode: Set<string> = new Set<string>();

  expandAll: boolean;
  constructor() {}

  ngOnInit(): void {}

  isSelected(field: CpqField): boolean {
    const code = field.code;
    return (
      this.selectedCategoriesByCode && this.selectedCategoriesByCode.has(code)
    );
  }

  toggleRequirement(field: CpqField): void {
    const code = field.code;
    if (this.selectedCategoriesByCode.has(code)) {
      this.selectedCategoriesByCode.delete(code);
    } else {
      this.selectedCategoriesByCode.add(code);
    }

    this.selectionChanged.emit(Array.from(this.selectedCategoriesByCode));
  }

  toggleExpandAll(): void {
    this.expandAll = !this.expandAll;
    this.sections.forEach(s => (s.isExpanded = this.expandAll));
  }
}
