import { Product } from 'app/domain';
import { SolutionBuilderClient } from '../services/solution-builder-client.service';
import { ISaveValidator } from './models';

export class UnrankedBasesSaveValidator implements ISaveValidator {
  validate(messages: string[], client: SolutionBuilderClient): void {
    const solutions = client.getSolutions();

    let allSelectedProducts: Product[] = [];
    for (let i = 0, max = solutions.length; i < max; i++) {
      const solution = solutions[i];

      // Unpack products first
      if (solution.hasProducts()) {
        allSelectedProducts = [...allSelectedProducts, ...solution.products];
      }

      // Add base as the terminator in the list
      if (solution.hasBase()) {
        allSelectedProducts.push(solution.base);
      }
    }

    if (
      client.quoteRequiresProductExplanation() &&
      !client.getNonProfileBaseExplanation()
    ) {
      messages.push(
        'Missing explanation details for Non-Profile Base selection.'
      );
    }
  }
}
