import { Output } from '@angular/core';
import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { CeDocument } from 'app/domain';
import { DmsService } from 'app/services/dms.service';
import { ToastrService } from 'ngx-toastr';
import { Observable, of } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { ImportPdfClientService } from '../service/import-pdf-client.service';

@Component({
  selector: 'sb-import-history',
  templateUrl: './import-history.component.html',
  styleUrls: ['./import-history.component.scss']
})
export class SbImportHistoryComponent implements OnInit {
  @Input()
  isLoading: boolean;

  @Input()
  documents: CeDocument[];

  constructor() {}

  ngOnInit(): void {}
}
