import { Injectable } from '@angular/core';
import {
  ConfiguredSolution,
  DataStoreEventType,
  Product,
  QuoteLineItem,
  SolutionType,
  SolutionTypeFlowType,
  Template
} from 'app/domain/models/';

import * as _ from 'lodash';
import { Observable, of, Subject } from 'rxjs';
import { shareReplay, switchMap } from 'rxjs/operators';
import { EditTemplateDataStore } from './edit-template-data-store.service';
import { SolutionBuilderOptionsDataStore } from 'app/modules/solution-builder/services/solution-options-data-store.service';
import { TemplateService } from 'app/services/template.service';
import { QuoteLineItemMapper } from 'app/services/quote-line-item.mapper';

const PARTS_ONLY_KEY = 'parts_only_key';

@Injectable({ providedIn: 'root' })
export class TemplateClient {
  private activeSolution: ConfiguredSolution = null;

  private eventsSubject$: Subject<DataStoreEventType> = new Subject<
    DataStoreEventType
  >();

  private events$: Observable<
    DataStoreEventType
  > = this.eventsSubject$.asObservable().pipe(shareReplay());

  constructor(
    private solutionDataStore: EditTemplateDataStore,
    private optionsDataStore: SolutionBuilderOptionsDataStore,
    private templateService: TemplateService
  ) {}

  events(): Observable<DataStoreEventType> {
    return this.events$;
  }

  getActiveSolutionType(): SolutionType {
    return this.solutionDataStore.getSolutionType();
  }
  setActiveSolutionType(solutionType: SolutionType): void {
    this.solutionDataStore.setSolutionType(solutionType);
    this.sendEvent(DataStoreEventType.SolutionTypeChange);
  }

  /**
   * Get the SolutionType model from its Category Id.
   * @param solutionTypeId The id of the Solution.
   */
  getSolutionType(solutionTypeId: number): SolutionType {
    return this.optionsDataStore
      .getSolutionTypes()
      .find(solutionType => solutionType.id === solutionTypeId);
  }

  /**
   * Gets the Repair Solution Type.
   * When we load the WO from the API, there's really no
   * Solution with the load.  Given the OrderType on that load,
   * we can grab the Solution for Repair/Modifications and set it.
   */
  getRepairSolutionType(): SolutionType {
    return this.optionsDataStore
      .getSolutionTypes()
      .find(s => s.flow === SolutionTypeFlowType.Repair);
  }

  isRepairSolutionType(solutionTypeId: number): boolean {
    const solutionTypes = this.optionsDataStore.getSolutionTypes();
    if (solutionTypes) {
      const solutionType = solutionTypes.find(s => s.id === solutionTypeId);

      if (!solutionType) {
        return false;
      }

      // Yes, we have to target it this way.
      return solutionType.flow === SolutionTypeFlowType.Repair;
    } else {
      return false;
    }
  }

  /**
   * Activate Solutions Functionality
   */

  clearSolutions(): void {
    this.solutionDataStore.clearSolutions();
    this.sendEvent(DataStoreEventType.SolutionsChanged);
  }
  setActiveSolution(solution: ConfiguredSolution): void {
    this.activeSolution = solution;
    this.setProducts(this.activeSolution ? this.activeSolution.products : []);

    this.sendEvent(DataStoreEventType.SolutionActivated);
  }

  getActiveSolution(): ConfiguredSolution {
    return this.activeSolution;
  }

  addSolutions(solutions: ConfiguredSolution[]): void {
    if (solutions && solutions.length > 0) {
      solutions.forEach((solution: ConfiguredSolution, index: number) => {
        this.solutionDataStore.addSolution(solution.getKey(), solution);
      });

      this.sendEvent(DataStoreEventType.SolutionsChanged);

      // Set first solution as active
      this.setActiveSolution(solutions[0]);
    }
  }

  addSolution(solution: ConfiguredSolution, setAsActive: boolean = true): void {
    this.solutionDataStore.addSolution(solution.getKey(), solution);
    this.sendEvent(DataStoreEventType.SolutionsChanged);

    if (setAsActive) {
      this.setActiveSolution(solution);
    }
  }
  setSolutions(solutions: ConfiguredSolution[]): void {
    if (solutions) {
      solutions.forEach((solution: ConfiguredSolution) => {
        this.addSolution(solution);
      });
    }
  }

  createPartsOnlySolution(): void {
    const solutions = this.solutionDataStore.getSolutions();
    if (!solutions.find(s => s.isPartsOnly)) {
      this.addSolution(new ConfiguredSolution(), true);
    }
  }

  removePartsOnlySolution(): void {
    this.solutionDataStore.removeSolution(PARTS_ONLY_KEY);
  }

  removeSolution(solution: ConfiguredSolution): void {
    if (solution) {
      // Remove the configured solution
      this.solutionDataStore.removeSolution(
        solution.base ? solution.base.productNumber : PARTS_ONLY_KEY
      );

      this.sendEvent(DataStoreEventType.SolutionsChanged);

      const solutions = this.solutionDataStore.getSolutions();

      let activeSolution = null;
      if (solutions.length > 0) {
        // Set the first configured solution as active
        activeSolution = solutions[0];
      }
      this.setActiveSolution(activeSolution);
    }
  }

  getSolutions(): ConfiguredSolution[] {
    return _.orderBy(
      Array.from(this.solutionDataStore.getSolutions()),
      s => s.isPartsOnly,
      'desc'
    );
  }

  /**
   * End Active Solutions Functionality
   */

  addProduct(product: Product, isAftermarket: boolean = false): void {
    if (isAftermarket) {
      const partsOnlySolution = this.getSolutions().find(s => s.isPartsOnly());
      if (!partsOnlySolution) {
        this.addSolution(new ConfiguredSolution(null, [product]), false);
      } else {
        partsOnlySolution.products.push(product);
      }
    } else if (this.hasActiveSolution()) {
      if (!this.activeSolution.products) {
        this.activeSolution.products = [];
      }
      this.activeSolution.products.push(product);
    }

    this.sendEvent(DataStoreEventType.ProductsChange);
  }

  removeProduct(product: Product, isAftermarket: boolean = false): void {
    if (isAftermarket) {
      const partsOnlySolution = this.getPartsOnlySolution();
      partsOnlySolution.removeProduct(product);
    } else if (this.hasActiveSolution()) {
      this.activeSolution.removeProduct(product);
    }
    this.sendEvent(DataStoreEventType.ProductsChange);
  }

  setProducts(products: Product[]): void {
    if (this.hasActiveSolution()) {
      this.activeSolution.products = products;
      this.sendEvent(DataStoreEventType.ProductsChange);
    }
  }
  /**
   * If the store has Products.
   */
  hasProduct(product: Product): boolean {
    if (this.hasActiveSolution()) {
      if (!product) {
        return false;
      }

      const products = this.activeSolution.products;

      return (
        products.find(
          p =>
            p.productNumber === product.productNumber &&
            p.vendorAccountNumber === product.vendorAccountNumber
        ) != null
      );
    }
    return false;
  }
  hasProducts(): boolean {
    return this.hasActiveSolution() && this.activeSolution.hasProducts();
  }

  incrementProductQuantity(product: Product): void {
    product.defaultQuantity = product.defaultQuantity + 1;
    this.sendEvent(DataStoreEventType.ProductsChange);
  }

  decrementProductQuantity(product: Product): void {
    const quantity = product.defaultQuantity - 1;
    product.defaultQuantity = quantity >= 0 ? quantity : 0;
    this.sendEvent(DataStoreEventType.ProductsChange);
  }
  getPartsOnlySolution(): ConfiguredSolution {
    const solutions = this.getSolutions();
    return solutions.find(s => s.isPartsOnly());
  }
  reset(): void {
    this.solutionDataStore.reset();
    this.clearSolutions();
    this.setActiveSolution(null);
    this.setActiveSolutionType(null);
  }

  validateSolution(): string[] {
    const issues: string[] = [];

    let solutions = this.getSolutions();

    // Guarantee that the parts only solution is last.
    // This is mostly for multi-flow
    solutions = _.sortBy(solutions, s => s.isPartsOnly);

    let allSelectedProducts: Product[] = [];
    for (let i = 0, max = solutions.length; i < max; i++) {
      const solution = solutions[i];

      // Unpack products first
      if (solution.hasProducts()) {
        allSelectedProducts = [...allSelectedProducts, ...solution.products];
      }

      // Add base as the terminator in the list
      if (solution.hasBase()) {
        allSelectedProducts.push(solution.base);
      }
    }

    solutions.forEach((solution: ConfiguredSolution) => {
      let requiredCategoryIds: number[] = solution.requiredCategories;

      // Validate that there's a product per required category.
      const requiredCategorySet = new Set<number>(requiredCategoryIds);
      if (requiredCategorySet.size > 0) {
        for (let i = 0, max = allSelectedProducts.length; i < max; i++) {
          requiredCategorySet.delete(allSelectedProducts[i].categoryId);
        }

        if (requiredCategorySet.size > 0) {
          issues.push(
            `${solution.base.description} requirements are not met. Make sure one product from each required category is selected.`
          );
        }
      }
    });

    // Enforce custom products have notes
    const customProductWithoutNote = allSelectedProducts.find(
      p => p.isCustomProduct && !p.externalNote
    );
    if (customProductWithoutNote) {
      issues.push(`
        There are Custom Products added to this Solution without notes.  Please
        enter a note for each Custom Product.
        `);
    }

    return issues;
  }

  saveTemplate(template: Template = new Template()): Observable<number> {
    let solutions = this.solutionDataStore.getSolutions();

    // Guarantee that the parts only solution is last.
    // This is mostly for multi-flow
    solutions = _.sortBy(solutions, s => s.isPartsOnly);

    let allSelectedProducts: Product[] = [];
    for (let i = 0, max = solutions.length; i < max; i++) {
      const solution = solutions[i];

      // Unpack products first
      if (solution.hasProducts()) {
        allSelectedProducts = [...allSelectedProducts, ...solution.products];
      }

      // Add base as the terminator in the list
      if (solution.hasBase()) {
        allSelectedProducts.push(solution.base);
      }
    }

    template.products = QuoteLineItemMapper.mapToLineItem(allSelectedProducts);

    return this.templateService.createOrUpdate(template);
  }

  private hasActiveSolution(): boolean {
    return this.activeSolution != null;
  }

  private sendEvent(type: DataStoreEventType): void {
    this.eventsSubject$.next(type);
  }
}
