<div class="sb-category-group-wrapper">
  <span
    (click)="section.isExpanded = !section.isExpanded"
    class="sb-category-group"
    [class.is-expanded]="section.isExpanded"
    [class.hide]="section.isCanBeHidden"
    [class.has-required-fields]="section.hasRequiredFields()"
    ><span
      class="icon fas"
      [class.fa-caret-right]="!section.isExpanded"
      [class.fa-caret-down]="section.isExpanded"
    ></span
    >{{ section.name | categoryName
    }}<span class="required-fields-title">Required</span></span
  >

  <section class="possible-categories" [class.hide]="!section.isExpanded">
    <div>
      <ul class="sb-options-list" kendoTooltip>
        <li *ngFor="let field of section.fields | categoryCode; index as i">
          <a
            (click)="setCategorySelected(field)"
            [class.is-active]="selectedField?.id === field.id"
          >
            <sb-square-check-indicator
              [enabled]="hasSelectedProduct(field)"
              enabledTooltip="Product Added to Solution"
              disabledTooltip="Product Not Added to Solution"
            >
            </sb-square-check-indicator>

            {{ field.categoryName | categoryName }}
            <span *ngIf="field.isRequired" class="sb-error">*</span>
          </a>
        </li>
      </ul>
    </div>
  </section>
</div>
