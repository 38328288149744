import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'sb-tag',
  templateUrl: './tag.component.html',
  styleUrls: ['./tag.component.scss']
})
export class SbTagComponent implements OnInit {
  @Input()
  info: boolean;
  @Input()
  warn: boolean;
  @Input()
  danger: boolean;
  @Input()
  success: boolean;

  constructor() {}

  ngOnInit(): void {}
}
