import { Component, OnInit, AfterViewInit } from '@angular/core';
import { DialogContentBase, DialogRef } from '@progress/kendo-angular-dialog';
import { VendorFormValidateResults } from 'app/domain';

@Component({
  selector: 'sb-validation-dialog-component',
  templateUrl: './validation-dialog.component.html',
  styleUrls: ['./validation-dialog.component.scss']
})
export class SbValidationDialogComponent extends DialogContentBase
  implements OnInit, AfterViewInit {
  results: VendorFormValidateResults;

  constructor(dialog: DialogRef) {
    super(dialog);
  }

  ngOnInit(): void {}

  ngAfterViewInit(): void {}

  onCancelAction(): void {
    this.dialog.close();
  }

  onConfirmAction(): void {
    this.dialog.close();
  }
}
