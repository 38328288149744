import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import {
  Category,
  IProductAttribute,
  Product,
  ProductSearchRequest
} from 'app/domain/models/solution-builder/models';
import { environment } from '../../environments/environment';
import { catchError, map, tap } from 'rxjs/operators';
import { ProductHelperService } from './product-helper.service';
import { Cacheable } from 'ngx-cacheable';
import { SolutionBuilderLoggerService } from 'app/domain/services/solution-builder-logger.service';
import { ApiUrlBuilder } from './api-url.builder';

@Injectable({
  providedIn: 'root'
})
export class CategoriesService {
  constructor(
    private http: HttpClient,
    private productMapper: ProductHelperService,
    private logger: SolutionBuilderLoggerService
  ) {}

  getCompatibleProductsForField(
    categoryId: number,
    productNumber: string
  ): Observable<Product[]> {
    return this.http
      .get<Product[]>(
        `${environment.apiCategories}/${categoryId}/compatible/${productNumber}`
      )
      .pipe(
        map((result: Product[]) => {
          return this.productMapper.mapProducts(result);
        })
      );
  }

  @Cacheable({ maxCacheCount: 100 })
  getCategoriesForId(
    categoryId: number,
    product: Product
  ): Observable<Category[]> {
    const url: string = new ApiUrlBuilder(product)
      .addPathPart(`${environment.apiCategories}/${categoryId}`)
      .build();

    let params: HttpParams = null;
    if (product) {
      params = new HttpParams()
        .append('productNumber', product.productNumber)
        .append('vendorAccountNumber', product.vendorAccountNumber)
        .append('model', product.model);
    }

    return this.http
      .get<Category[]>(url, { params })
      .pipe(
        map((result: Category[]) => {
          if (result && result.length > 0) {
            return result.map((category: Category) => {
              return Object.assign(new Category(), category);
            });
          }
          return [];
        })
      );
  }

  searchProducts(
    categoryId: number,
    product: Product,
    request: ProductSearchRequest,
    isAftermarket: boolean
  ): Observable<Product[]> {
    let base = null;

    if (product != null) {
      if (isAftermarket) {
        base = `${environment.apiCategories}/${categoryId}/aftermarket`;
      } else {
        base = `${environment.apiCategories}/${categoryId}/compatible`;
      }
    } else {
      base = `${environment.apiCategories}/${categoryId}/products`;
    }

    let builder = new ApiUrlBuilder(product).addPathPart(base);
    if (!isAftermarket) {
      builder = builder
        .includeVendorProductNumber()
        .includeProductNumber()
        .includeModel();
    } else {
      builder = builder.includeProductNumber();
    }
    const url: string = builder.addPathPart('search').build();

    return this.http.post<Product[]>(url, request).pipe(
      map((result: Product[]) => {
        return this.productMapper.mapProducts(result);
      })
    );
  }

  @Cacheable({ maxCacheCount: 100 })
  getAttributes(categoryId: number): Observable<IProductAttribute[]> {
    return this.http
      .get<IProductAttribute[]>(
        `${environment.apiCategories}/${categoryId}/attributes`
      )
      .pipe(
        catchError(() => {
          return of([]);
        })
      );
  }
}
