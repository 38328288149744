import {
  Component,
  OnInit,
  HostBinding,
  Input,
  Output,
  EventEmitter
} from '@angular/core';
import { Product } from 'app/domain';

@Component({
  selector: 'sb-base-model-menu',
  templateUrl: './base-model-menu.component.html',
  styleUrls: [
    '../base-side-menu.component.scss',
    './base-model-menu.component.scss'
  ]
})
export class SbBaseModelMenuComponent implements OnInit {
  @HostBinding('class') class: string = 'sb-selection-menu sb-base-model-menu';

  @Input()
  solutionTypeId: string;

  @Output()
  completeSelection: EventEmitter<Product> = new EventEmitter<Product>();

  @Output()
  closeMenu: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor() {}

  ngOnInit(): void {}

  complete(product: Product): void {
    this.completeSelection.emit(product);
  }
  onCloseMenu(): void {
    this.closeMenu.emit(true);
  }
}
