import {
  Component,
  OnInit,
  HostBinding,
  Input,
  Output,
  EventEmitter,
  OnDestroy
} from '@angular/core';
import {
  Product,
  CpqField,
  Category,
  CpqSection,
  ConfiguredSolution,
  SolutionType,
  SolutionTypeFlowType,
  ICategoryFilters,
  IProductSelectedEvent,
  IProductAttribute
} from 'app/domain';
import { BehaviorSubject } from 'rxjs';
import { switchMap, filter, tap } from 'rxjs/operators';
import { CpqFieldHelperService } from 'app/services/cpq-field-helper.service';
import { CategoriesService } from 'app/services/categories.service';
import { untilDestroyed } from 'app/core';
import { CategoryTreeBuilderService } from 'app/services/category-tree-builder.serivce';
import * as _ from 'lodash';

@Component({
  selector: 'sb-multi-flow-menu',
  templateUrl: './multi-flow-menu.component.html',
  styleUrls: [
    '../base-side-menu.component.scss',
    './multi-flow-menu.component.scss'
  ]
})
export class MultiFlowMenuComponent implements OnInit, OnDestroy {
  @HostBinding('class') class: string = 'sb-multi-flow-menu';

  solution$: BehaviorSubject<ConfiguredSolution> = new BehaviorSubject<
    ConfiguredSolution
  >(null);
  @Input()
  set solution(value: ConfiguredSolution) {
    this.solution$.next(value);
  }

  @Input()
  knownProducts: Product[] = [];

  @Output()
  completeSelection: EventEmitter<IProductSelectedEvent> = new EventEmitter<
    IProductSelectedEvent
  >();

  @Output()
  removeSelection: EventEmitter<IProductSelectedEvent> = new EventEmitter<
    IProductSelectedEvent
  >();

  @Output()
  closeMenu: EventEmitter<boolean> = new EventEmitter<boolean>();

  solutionType$: BehaviorSubject<SolutionType> = new BehaviorSubject<
    SolutionType
  >(null);

  @Input() set solutionType(value: SolutionType) {
    this.solutionType$.next(value);

    // Trigger the category display if only solution type was changed
    this.solution$.next(this.solution$.value);
  }

  /**
   * Holds the base model categories.
   */
  categories$: BehaviorSubject<Category[]> = new BehaviorSubject<Category[]>(
    []
  );

  /**
   * Holds the sent state of the request
   */
  isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  field$: BehaviorSubject<CpqField> = new BehaviorSubject<CpqField>(null);

  viewState$: BehaviorSubject<ViewState> = new BehaviorSubject<ViewState>(
    ViewState.Categories
  );

  ViewState = ViewState;

  sections: CpqSection[] = [];

  displayTitle: string = 'Product Categories';

  showFilters: boolean;

  filters$: BehaviorSubject<ICategoryFilters> = new BehaviorSubject<
    ICategoryFilters
  >(null);

  attributes$: BehaviorSubject<IProductAttribute[]> = new BehaviorSubject<
    IProductAttribute[]
  >(null);

  /*
  * Stores loaded compatability trees where the key is the
  category id of the base model category.
  */
  compatabilityMap: Map<number, CpqField[]> = new Map<number, CpqField[]>();

  expandAll: boolean;

  products: Product[];
  constructor(
    private cpqFieldHelper: CpqFieldHelperService,
    private categoryService: CategoriesService,
    private categoryTreeBuilder: CategoryTreeBuilderService
  ) {}
  ngOnDestroy(): void {
    document.body.classList.remove('sb-has-double-menus');
  }

  ngOnInit(): void {
    this.solution$
      .pipe(
        filter((solution: ConfiguredSolution) => {
          return solution != null && this.solutionType$.value != null;
        }),
        tap(() => {
          this.showCategoryView();
        }),
        switchMap((solution: ConfiguredSolution) => {
          this.sections = [];
          this.products = solution.products;

          var solutionType = this.solutionType$.value;

          let categoryId = solutionType.categoryId;

          return this.categoryService.getCategoriesForId(
            categoryId,
            solutionType.flow === SolutionTypeFlowType.Repair
              ? null
              : solution.base
          );
        }),
        untilDestroyed(this)
      )
      .subscribe((categories: Category[]) => {
        let rootCategory = categories ? categories[0] : null;
        const solution = this.solution$.value;

        if (rootCategory && rootCategory.children.length > 0) {
          this.displayTitle = rootCategory.name;

          if (
            this.solutionType$.value.flow === SolutionTypeFlowType.Single ||
            solution.hasBase()
          ) {
            this.categoryTreeBuilder.buildConciseTree(
              this.sections,
              rootCategory.children
            );
          } else {
            this.categoryTreeBuilder.buildFullTree(
              this.sections,
              rootCategory.children
            );
          }

          this.sections = _.orderBy(this.sections, s => s.code);

          // Add the required category ids
          // to the solution
          solution.requiredCategories = [];
          this.sections.forEach((section: CpqSection) => {
            if (section.hasFields()) {
              section.fields.forEach((field: CpqField) => {
                if (field.isRequired) {
                  solution.requiredCategories.push(field.id);
                }
              });
            }
          });

          this.isLoading$.next(false);
        }
      });

    this.viewState$.pipe(untilDestroyed(this)).subscribe((state: ViewState) => {
      if (state === ViewState.Product) {
        document.body.classList.add('sb-has-double-menus');
      } else {
        document.body.classList.remove('sb-has-double-menus');
        this.showFilters = false;
      }
    });
  }

  updateFilters(filters: ICategoryFilters): void {
    this.filters$.next(filters);
  }

  buildOperatableCategories(
    sections: CpqSection[],
    categories: Category[]
  ): void {
    for (let i = 0, max = categories.length; i < max; i++) {
      const category = categories[i];

      if (this.canOperateOnCategory(category)) {
        const section = this.cpqFieldHelper.buildCpqSection(category);
        section.fields.push(this.cpqFieldHelper.buildCpqField(category));
        sections.push(section);
      } else {
        this.buildOperatableCategories(sections, category.children);
      }
    }
  }

  canOperateOnCategory(category: Category): boolean {
    const containsBaseModel = category.containsBaseModels;
    const isCanBeHidden = category.isCanBeHidden;

    if (containsBaseModel) {
      return true;
    }

    if (!containsBaseModel && !isCanBeHidden) {
      return true;
    }

    if (!containsBaseModel && isCanBeHidden && category.productCount > 0) {
      return true;
    }

    return false;
  }

  onCloseMenu(): void {
    this.closeMenu.emit(true);
  }

  showCategoryView(): void {
    this.viewState$.next(ViewState.Categories);
  }

  showProductSearch(): void {
    this.viewState$.next(ViewState.Search);
  }

  showProductView(): void {
    this.viewState$.next(ViewState.Product);
  }

  closeFilters(): void {
    this.showProductView();
  }

  setTargetProduct(field: CpqField): void {
    this.filters$.next(null);
    this.field$.next(field);
    this.showProductView();
  }

  productSelected(event: IProductSelectedEvent): void {
    // Emit the product
    this.completeSelection.emit(event);
  }

  productRemoved(event: IProductSelectedEvent): void {
    this.removeSelection.emit(event);
  }

  hasSelectedProduct(field: CpqField): boolean {
    if (!this.products) {
      return false;
    }

    return (
      this.products.find(
        p => p.categoryName === field.categoryName || p.categoryId === field.id
      ) != null
    );
  }

  productSearchComplete(attributes: IProductAttribute[]): void {
    this.attributes$.next(attributes);
  }

  toggleExpandAll(): void {
    this.expandAll = !this.expandAll;
    this.sections.forEach(s => (s.isExpanded = this.expandAll));
  }
}

enum ViewState {
  Categories,
  Search,
  Product
}
