import {
  Component,
  OnInit,
  Input,
  HostBinding,
  ViewEncapsulation
} from '@angular/core';
import { Product } from 'app/domain/models/solution-builder/models';

@Component({
  selector: 'sb-product-item',
  templateUrl: './product-item.component.html',
  styleUrls: ['./product-item.component.scss']
})
export class ProductItemComponent implements OnInit {
  @HostBinding('class') class: string = 'sb-product-item';
  @Input()
  product: Product;

  /**
   * True if the Product currently exists on the Solution.
   */
  @Input()
  selected: boolean;

  @Input()
  selectable: boolean = true;

  @Input()
  hidePricing: boolean = false;

  isDiscontinued: boolean = false;

  constructor() {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.isDiscontinued = this.product && this.product.isSalesProcessingStopped;
    if (this.isDiscontinued) {
      this.selectable = false;
    }
  }
}
