import { Pipe, PipeTransform } from '@angular/core';
import { Product } from '../models';
import * as _ from 'lodash';

@Pipe({ name: 'rankProducts' })
export class RankProductsPipe implements PipeTransform {
  transform(products: Product[], direction: SortDir = SortDir.ASC): Product[] {
    let sortedProducts: Product[] = [];
    if (products) {
      sortedProducts = _.orderBy(
        products,
        ['normalizedRank', 'costPricePerUnit'],
        [direction]
      );
    }
    return sortedProducts;
  }
}

enum SortDir {
  ASC = 'asc',
  DESC = 'desc'
}
