import { Component, OnInit, HostBinding } from '@angular/core';

@Component({
  selector: 'sb-side-menu-content',
  templateUrl: './side-menu-content.component.html',
  styleUrls: ['./side-menu-content.component.scss']
})
export class SideMenuContentComponent implements OnInit {
  @HostBinding('class') class: string = 'sb-side-menu-content';
  constructor() {}

  ngOnInit(): void {}
}
