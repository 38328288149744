import {
  Component,
  OnInit,
  HostBinding,
  Input,
  Output,
  EventEmitter
} from '@angular/core';
import { Product } from 'app/domain';

@Component({
  selector: 'sb-empty-menu',
  templateUrl: './empty-menu.component.html',
  styleUrls: ['../base-side-menu.component.scss', './empty-menu.component.scss']
})
export class SbEmptyMenuComponent implements OnInit {
  @HostBinding('class') class: string = 'sb-selection-menu sb-empty-menu';

  @Input()
  title: string;

  @Input()
  canClose: boolean = true;

  @Input()
  footer: boolean;

  @Input()
  alt: boolean;

  @Output()
  closeMenu: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor() {}

  ngOnInit(): void {}

  onCloseMenu(): void {
    this.closeMenu.emit(true);
  }
}
