import { Component, OnInit, HostBinding, ViewChild } from '@angular/core';
import {
  SolutionTypeConfig,
  Product,
  ConfiguredSolution,
  FieldOption,
  CpqField,
  SolutionType,
  BaseModelRequirements,
  CpqSection
} from 'app/domain';
import { Data, ActivatedRoute, Router } from '@angular/router';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';
import { SolutionBuilderLoggerService } from 'app/domain/services/solution-builder-logger.service';
import { SolutionDataStore } from 'app/modules/solution-builder/services/solution-data-store.service';

import { BehaviorSubject, forkJoin, of } from 'rxjs';
import { untilDestroyed } from 'app/core';
import { fadeUpAndIn } from 'app/domain/animations/fade.up.in';
import { CategorySelectionMenuComponent } from '../category-selection-menu/category-selection-menu.component';
import {
  DialogService,
  DialogRef,
  DialogCloseResult
} from '@progress/kendo-angular-dialog';
import { switchMap } from 'rxjs/operators';
import { SolutionTypeService } from 'app/services/solution-type.service';
import { ToastrService } from 'ngx-toastr';
import { BaseModelRequirementsService } from 'app/services/base-model-requirements.service';
import { CategoriesService } from 'app/services/categories.service';
import { CategoryTreeBuilderService } from 'app/services/category-tree-builder.serivce';

@Component({
  selector: 'app-edit-definition',
  templateUrl: './edit-definition.component.html',
  styleUrls: ['./edit-definition.component.scss'],
  animations: [fadeUpAndIn]
})
export class EditDefinitionComponent implements OnInit {
  @HostBinding('class') class: string = 'sb-page-edit-definition';

  @ViewChild(CategorySelectionMenuComponent)
  categorySelectionComponent: CategorySelectionMenuComponent;

  viewState$: BehaviorSubject<TemplateViewState> = new BehaviorSubject<
    TemplateViewState
  >(TemplateViewState.Categories);

  isSavingDefinition$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );

  /**
   * Make the enum accessible to the template
   */
  TemplateViewState = TemplateViewState;

  solution: ConfiguredSolution = new ConfiguredSolution(null, null);

  isProductListFiltering: boolean;

  baseModelRequirement: BaseModelRequirements;

  fgDefinition: FormGroup;
  fcSolutionType: FormControl = new FormControl('', Validators.required);
  fcBaseModel: FormControl = new FormControl('', Validators.required);

  sections: CpqSection[] = [];

  solutionTypes: FieldOption[];

  selectedFields$: BehaviorSubject<CpqField[]> = new BehaviorSubject<
    CpqField[]
  >([]);

  selectedSolutionType: SolutionType;

  supportedSolutionTypes: FieldOption[] = [];

  selectedCodes: string[];

  baseModelSource: Product[] = [];
  baseModelOptions$: BehaviorSubject<FieldOption[]> = new BehaviorSubject<
    FieldOption[]
  >([]);

  showReturnToBaseModelMenuConfirmDialog: boolean;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
    private solutionTypeService: SolutionTypeService,
    private logger: SolutionBuilderLoggerService,
    private solutionDataStore: SolutionDataStore,
    private baseModelRequirementsService: BaseModelRequirementsService,
    private categoryTreeBuilder: CategoryTreeBuilderService,
    private categoryService: CategoriesService,
    private toastService: ToastrService,
    private dialogService: DialogService
  ) {}

  ngOnInit(): void {
    this.fgDefinition = this.fb.group({
      fcBaseModel: this.fcBaseModel,
      fcSolutionType: this.fcSolutionType
    });

    //  Store solution type id in the data store
    this.fcSolutionType.valueChanges
      .pipe(
        untilDestroyed(this),
        switchMap((solutionTypeId: number) => {
          if (solutionTypeId > 0) {
            // Assum non-blank value selected
            return this.solutionTypeService.getSolutionType(solutionTypeId);
          }
          return of(null);
        })
      )
      .subscribe((solutionType: SolutionType) => {
        this.handleSolutionTypeChange(solutionType);
      });

    this.activatedRoute.data.subscribe((resp: Data) => {
      const types: SolutionType[] = resp.solutionTypes;

      if (types && types.length > 0) {
        this.solutionTypes = types
          .filter(t => t.requiresBaseModelSelection())
          .map(st => new FieldOption(st.name, st.id));
      } else {
        this.showNeedSolutionTypesDialog();
        return;
      }

      const requirement: BaseModelRequirements = resp.requirement;
      if (requirement) {
        this.logger.info('Loaded Base Model Requirement.');
        this.baseModelRequirement = requirement;
        this.fcSolutionType.setValue(requirement.solutionTypeId);
        this.fcBaseModel.setValue(requirement.model);

        this.selectedCodes = requirement.requiredCategories;
      }
    });

    this.showSideMenu();
  }

  ngAfterViewInit(): void {}

  ngOnDestroy(): void {
    this.solutionDataStore.reset();
    this.hideSideMenu();
  }

  isAllowedToSave(): boolean {
    return this.fgDefinition.valid;
  }

  solutionHasProducts(): boolean {
    // TODO: Implement
    return false;
  }

  solutionHasBaseModel(): boolean {
    if (this.fcBaseModel.value) {
      return true;
    } else {
      return false;
    }
  }

  showDefaultView(): void {
    this.viewState$.next(TemplateViewState.Settings);
  }

  confirmBaseRemoval(): void {
    const fields = this.selectedFields$.value;
    if (fields && fields.length > 0) {
      this.showReturnToBaseModelMenuConfirmDialog = true;
    } else {
      this.removeBaseModel();
    }
  }

  showNeedSolutionTypesDialog(): void {
    const primaryText = 'Go to Solution Type';
    const dialog: DialogRef = this.dialogService.open({
      title: 'Setup Required Solution Types',
      content: `To create a Base Model Requirement Definition you first need to configure Solution Types.`,
      width: 350,
      height: 200,
      minWidth: 250,
      actions: [
        { text: 'Cancel', value: false },
        { text: primaryText, value: true, primary: true }
      ]
    });

    dialog.result.subscribe(result => {
      if (result instanceof DialogCloseResult) {
        // User closed the dialog
      } else {
        if (result.text === primaryText) {
          this.router.navigateByUrl('/admin/solutionTypes');
        } else {
          this.router.navigateByUrl('/admin');
        }
      }
    });
  }

  confirmSolutionTypeChange(): void {
    if (
      !this.solutionHasBaseModel() &&
      this.selectedFields$.value &&
      this.selectedFields$.value.length === 0
    ) {
      this.removeSolutionType();
      return;
    }

    const dialog: DialogRef = this.dialogService.open({
      title: 'Change Solution Type',
      content: `Changing the Solution Type will remove any selected Base Model and Categories`,
      width: 450,
      height: 400,
      minWidth: 250,
      actions: [
        { text: 'Cancel', value: false },
        { text: 'Remove', value: true, primary: true }
      ]
    });

    dialog.result.subscribe(result => {
      if (result instanceof DialogCloseResult) {
        // User closed the dialog
      } else {
        if (result.text === 'Remove') {
          this.removeSolutionType();
        } else {
          // User clicked Cancel
        }
      }
    });
  }
  removeSolutionType(): void {
    this.fcSolutionType.setValue(null);
    this.removeBaseModel();
    this.selectedFields$.next([]);
  }
  removeBaseModel(): void {
    this.fcBaseModel.setValue(null);
    this.showReturnToBaseModelMenuConfirmDialog = false;
  }

  isEditingExisting(): boolean {
    return (
      this.baseModelRequirement != null && this.baseModelRequirement.id > 0
    );
  }

  removeDefinition(): void {
    const dialog: DialogRef = this.dialogService.open({
      title: 'Remove Definition',
      content: `Are you sure you'd like to remove this definition?`,
      width: 350,
      height: 200,
      minWidth: 250,
      actions: [
        { text: 'Cancel', value: false },
        { text: 'Remove', value: true, primary: true }
      ]
    });

    dialog.result.subscribe(result => {
      if (result instanceof DialogCloseResult) {
        // User closed the dialog
      } else {
        if (result.text === 'Remove') {
          this.baseModelRequirementsService
            .deleteRequirement(this.baseModelRequirement.id)
            .subscribe(() => {
              this.toastService.success('Successfully removed definition.');
              this.router.navigateByUrl('/admin/definitions');
            });
        } else {
          // User clicked Cancel
        }
      }
    });
  }

  saveDefinition(): void {
    if (this.isAllowedToSave()) {
      const bmr = new BaseModelRequirements();
      bmr.id = this.baseModelRequirement ? this.baseModelRequirement.id : -1;
      bmr.model = this.fcBaseModel.value;
      bmr.solutionTypeId = this.fcSolutionType.value;

      const fields = this.selectedFields$.value;
      if (fields && fields.length > 0) {
        bmr.requiredCategories = fields.map(f => f.code);
      } else {
        this.toastService.warning(
          'Please select at least one category before saving.'
        );
        return;
      }

      this.baseModelRequirementsService
        .createOrUpdate(bmr)
        .subscribe((savedBmr: BaseModelRequirements) => {
          this.toastService.success('Successfully saved requirements.');
          this.router.navigateByUrl(`/admin/definitions/${savedBmr.id}`);
        });
    } else {
      this.toastService.warning(
        'The form is not valid.  Please verify your selections.'
      );
    }
  }

  requiredCategoriesChanged(codes: string[]): void {
    this.selectedFields$.next(codes.map(code => this.getFieldByCode(code)));
  }

  removeRequirement(field: CpqField): void {
    this.categorySelectionComponent.toggleRequirement(field);
  }

  showSideMenu(): void {
    document.body.classList.add('sb-shift-left', 'sb-no-scroll');
  }
  hideSideMenu(): void {
    document.body.classList.remove('sb-shift-left', 'sb-no-scroll');
  }

  /**
   * When the Solution Type changes from the dropdown
   */
  private handleSolutionTypeChange(solutionType: SolutionType): void {
    this.selectedSolutionType = solutionType;

    if (solutionType) {
      forkJoin([
        // Get models for SolutionType
        this.solutionTypeService.getSolutionTypeModels(solutionType.id),
        // Get categories for SolutionType
        this.categoryService.getCategoriesForId(solutionType.categoryId, null)
      ]).subscribe(([models, categories]) => {
        if (models && models.length > 0) {
          this.baseModelOptions$.next(models.map(m => new FieldOption(m, m)));
        } else {
          // TODO need to inform user.
          this.logger.warn(
            `No models found for solution type ${solutionType.name}`
          );
        }

        this.sections = [];
        if (categories) {
          const sections: CpqSection[] = [];
          this.categoryTreeBuilder.buildFullTree(
            sections,
            categories[0].children
          );

          this.sections = sections;
        }

        // Show categories
        this.viewState$.next(TemplateViewState.Categories);

        this.requiredCategoriesChanged(this.selectedCodes);
      });
    }
  }

  private getFieldByCode(code: string): CpqField {
    if (this.sections) {
      for (let i = 0, max = this.sections.length; i < max; i++) {
        const section = this.sections[i];
        const field = section.getField(code);
        if (field) {
          return field;
        }
      }
    } else {
      return null;
    }
  }
}

enum TemplateViewState {
  None = 'None',
  Empty = 'Empty',
  Settings = 'Settings',
  Categories = 'Categories'
}
