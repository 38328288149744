import {
  Component,
  OnInit,
  HostBinding,
  Input,
  Output,
  EventEmitter,
  OnDestroy
} from '@angular/core';
import {
  Product,
  CpqField,
  Category,
  CpqSection,
  SolutionType,
  ICategoryFilters,
  IProductSelectedEvent
} from 'app/domain';
import { BehaviorSubject } from 'rxjs';
import { switchMap, filter, tap } from 'rxjs/operators';
import { CpqFieldHelperService } from 'app/services/cpq-field-helper.service';
import { CategoriesService } from 'app/services/categories.service';
import { untilDestroyed } from 'app/core';
import { CategoryTreeBuilderService } from 'app/services/category-tree-builder.serivce';
import * as _ from 'lodash';

@Component({
  selector: 'sb-seating-positioning-menu',
  templateUrl: './seating-positioning-menu.component.html',
  styleUrls: [
    '../base-side-menu.component.scss',
    './seating-positioning-menu.component.scss'
  ]
})
export class SbSeatingPositioningMenuComponent implements OnInit, OnDestroy {
  @HostBinding('class') class: string = 'sb-seating-positioning-menu';

  @Output()
  completeSelection: EventEmitter<IProductSelectedEvent> = new EventEmitter<
    IProductSelectedEvent
  >();

  @Output()
  removeSelection: EventEmitter<IProductSelectedEvent> = new EventEmitter<
    IProductSelectedEvent
  >();

  @Output()
  closeMenu: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input() solutionType: SolutionType;

  /**
   * Holds the base model categories.
   */
  categories$: BehaviorSubject<Category[]> = new BehaviorSubject<Category[]>(
    []
  );

  /**
   * Holds the sent state of the request
   */
  isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  viewState$: BehaviorSubject<ViewState> = new BehaviorSubject<ViewState>(
    ViewState.Categories
  );

  field$: BehaviorSubject<CpqField> = new BehaviorSubject<CpqField>(null);

  ViewState = ViewState;

  sections: CpqSection[] = [];

  displayTitle: string = 'Seating & Positioning';

  showFilters: boolean;

  filters$: BehaviorSubject<ICategoryFilters> = new BehaviorSubject<
    ICategoryFilters
  >(null);

  expandAll: boolean;

  products: Product[];
  constructor(
    private cpqFieldHelper: CpqFieldHelperService,
    private categoryService: CategoriesService,
    private categoryTreeBuilder: CategoryTreeBuilderService
  ) {}
  ngOnDestroy(): void {
    document.body.classList.remove('sb-has-double-menus');
  }

  ngOnInit(): void {
    this.viewState$.pipe(untilDestroyed(this)).subscribe((state: ViewState) => {
      if (state === ViewState.Product) {
        document.body.classList.add('sb-has-double-menus');
      } else {
        document.body.classList.remove('sb-has-double-menus');
        this.showFilters = false;
      }
    });
  }

  ngAfterViewInit(): void {
    this.sections = [];

    let categoryId = this.solutionType.categoryId;

    this.categoryService
      .getCategoriesForId(categoryId, null)
      .subscribe((categories: Category[]) => {
        let rootCategory = categories ? categories[0] : null;

        if (rootCategory && rootCategory.children.length > 0) {
          this.displayTitle = rootCategory.name;

          this.categoryTreeBuilder.buildFullTree(
            this.sections,
            rootCategory.children
          );

          this.sections = _.orderBy(this.sections, s => s.code);

          this.isLoading$.next(false);
        }
      });
  }

  updateFilters(filters: ICategoryFilters): void {
    this.filters$.next(filters);
  }

  buildOperatableCategories(
    sections: CpqSection[],
    categories: Category[]
  ): void {
    for (let i = 0, max = categories.length; i < max; i++) {
      const category = categories[i];

      if (this.canOperateOnCategory(category)) {
        const section = this.cpqFieldHelper.buildCpqSection(category);
        section.fields.push(this.cpqFieldHelper.buildCpqField(category));
        sections.push(section);
      } else {
        this.buildOperatableCategories(sections, category.children);
      }
    }
  }

  canOperateOnCategory(category: Category): boolean {
    const containsBaseModel = category.containsBaseModels;
    const isCanBeHidden = category.isCanBeHidden;

    if (containsBaseModel) {
      return true;
    }

    if (!containsBaseModel && !isCanBeHidden) {
      return true;
    }

    if (!containsBaseModel && isCanBeHidden && category.productCount > 0) {
      return true;
    }

    return false;
  }

  onCloseMenu(): void {
    this.closeMenu.emit(true);
  }

  showCategoryView(): void {
    this.viewState$.next(ViewState.Categories);
  }

  showProductSearch(): void {
    this.viewState$.next(ViewState.Search);
  }

  showProductView(): void {
    this.viewState$.next(ViewState.Product);
  }

  closeFilters(): void {
    this.showProductView();
  }

  setTargetProduct(field: CpqField): void {
    this.filters$.next(null);
    this.field$.next(field);
    this.showProductView();
  }

  productSelected(event: IProductSelectedEvent): void {
    // Emit the product
    this.completeSelection.emit(event);
  }

  productRemoved(event: IProductSelectedEvent): void {
    this.removeSelection.emit(event);
  }

  hasSelectedProduct(field: CpqField): boolean {
    if (!this.products) {
      return false;
    }

    return (
      this.products.find(
        p => p.categoryName === field.categoryName || p.categoryId === field.id
      ) != null
    );
  }

  toggleExpandAll(): void {
    this.expandAll = !this.expandAll;
    this.sections.forEach(s => (s.isExpanded = this.expandAll));
  }
}

enum ViewState {
  Categories,
  Search,
  Product
}
