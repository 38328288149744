import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LoaderComponent } from './loader/loader.component';
import { SbRadioGroupFieldComponent } from './fields/radio-group-field/sb-radio-group-field.component';

import {
  AutoCompleteModule,
  MultiSelectModule,
  ComboBoxModule,
  DropDownsModule
} from '@progress/kendo-angular-dropdowns';

import { ReactiveFormsModule } from '@angular/forms';

import { TooltipModule } from '@progress/kendo-angular-tooltip';
import { PopupModule } from '@progress/kendo-angular-popup';

import { MenusModule } from '@progress/kendo-angular-menu';
import { DialogModule } from '@progress/kendo-angular-dialog';

import {
  NumericTextBoxModule,
  SwitchModule,
  SliderModule,
  InputsModule
} from '@progress/kendo-angular-inputs';
import { GridModule } from '@progress/kendo-angular-grid';
import {
  ButtonModule,
  ButtonGroupModule,
  DropDownButtonModule
} from '@progress/kendo-angular-buttons';
import { StepBackButtonComponent } from './ui-elements/step-back-button/step-back-button.component';
import { BaseModelLookupComponent } from 'app/shared/lookups/base-model-lookup/base-model-lookup.component';
import { StrikeComponent } from './ui-elements/strike/strike.component';
import { SbProductLookupComponent } from 'app/shared/lookups/products-lookup/products-lookup.component';
import { ProductItemComponent } from './ui-elements/product-item/product-item.component';
import { SbCalloutComponent } from './ui-elements/callout/sb-callout.component';
import { SolutionBuilderDomainModule } from 'app/domain';
import { SbCircleCheckIndicatorComponent } from './ui-elements/indicators/circle-check-indicator/circle-check-indicator.component';
import { SbSquareCheckIndicatorComponent } from './ui-elements/indicators/square-check-indicator/square-check-indicator.component';
import { SbRequiredMarkerComponent } from './ui-elements/required-marker/required-marker.component';
import { SbDropdownFieldComponent } from './fields/dropdown-field/sb-dropdown-field.component';
import { SbTextFieldComponent } from './fields/text-field/sb-text-field.component';
import { SbCheckboxGroupComponent } from './fields/checkbox-group/sb-checkbox-group.component';
import { SbBaseModelMenuComponent } from './side-menus/base-model-menu/base-model-menu.component';
import { SbImportPdfMenuComponent } from './side-menus/import-pdf-menu/import-pdf-menu.component';
import { UploadModule, FileSelectModule } from '@progress/kendo-angular-upload';
import { SbEmptyMenuComponent } from './side-menus/empty-menu/empty-menu.component';
import { ProgressBarModule } from '@progress/kendo-angular-progressbar';
import { SideMenuContentComponent } from './side-menus/common/side-menu-content/side-menu-content.component';
import { SideMenuFooterComponent } from './side-menus/common/side-menu-footer/side-menu-footer.component';
import { SideMenuToolbarComponent } from './side-menus/common/side-menu-toolbar/side-menu-toolbar.component';
import { SbImportResultsComponent } from './side-menus/import-pdf-menu/import-results/import-results.component';
import { SbFieldErrorComponent } from './ui-elements/field-error/sb-field-error.component';
import { SbCurrencyFieldComponent } from './fields/currency-field/sb-currency-field.component';
import { SbNumberFieldComponent } from './fields/number-field/sb-number-field.component';
import { RankIndicatorComponent } from './ui-elements/indicators/rank-indicator/rank-indicator.component';
import { SbValidationDialogComponent } from './side-menus/import-pdf-menu/validation-dialog/validation-dialog.component';
import { SbComboBoxFieldComponent } from './fields/combobox-field/sb-combobox-field.component';
import { SbMultiSelectFieldComponent } from './fields/multiselect-field/sb-multiselect-field.component';
import { MultiFlowMenuComponent } from './side-menus/multi-flow-menu/multi-flow-menu.component';
import { SbSelectedProductItemComponent } from './ui-elements/selected-product-item/selected-product-item.component';
import { SbSelectedLaborItemComponent } from './ui-elements/selected-labor-item/selected-labor-item.component';
import { SbTagComponent } from './ui-elements/tag/tag.component';
import { SbCollapsingCategorySectionComponent } from './ui-elements/collapsing-category-section/collapsing-category-section.component';
import { SbCategoryFiltersMenu } from './side-menus/category-filters-menu/category-filters-menu.component';
import { SbAutoCompleteFieldComponent } from './fields/autocomplete-field/sb-autocomplete-field.component';
import { SideMenuActionComponent } from './side-menus/common/side-menu-action/side-menu-action.component';
import { SbImportHistoryComponent } from './side-menus/import-pdf-menu/import-history/import-history.component';
import { SbNonProfileBaseExplanationComponent } from './ui-elements/non-profile-base-explanation/non-profile-base-explanation.component';
import { SbCardComponent } from './ui-elements/card/card.component';
import { SbSeatingPositioningMenuComponent } from './side-menus/seating-positioning-menu/seating-positioning-menu.component';
import { SbAttributeFilterComponent } from './side-menus/category-filters-menu/attribute-filters/attribute-filters.component';
@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    SwitchModule,
    AutoCompleteModule,
    MultiSelectModule,
    ButtonGroupModule,
    ButtonModule,
    ComboBoxModule,
    AutoCompleteModule,
    DropDownsModule,
    DropDownButtonModule,
    ComboBoxModule,
    GridModule,
    TooltipModule,
    UploadModule,
    FileSelectModule,
    ProgressBarModule,
    InputsModule,
    DialogModule,
    SolutionBuilderDomainModule
  ],
  declarations: [
    LoaderComponent,
    SbRadioGroupFieldComponent,
    SbCalloutComponent,
    ProductItemComponent,
    SbProductLookupComponent,
    StrikeComponent,
    BaseModelLookupComponent,
    StepBackButtonComponent,
    SbCircleCheckIndicatorComponent,
    SbSquareCheckIndicatorComponent,
    SbRequiredMarkerComponent,
    SbDropdownFieldComponent,
    SbMultiSelectFieldComponent,
    SbComboBoxFieldComponent,
    SbTextFieldComponent,
    SbNumberFieldComponent,
    SbCheckboxGroupComponent,
    SbCurrencyFieldComponent,
    SbFieldErrorComponent,
    SbBaseModelMenuComponent,
    SbImportPdfMenuComponent,
    SbImportResultsComponent,
    SbImportHistoryComponent,
    SbEmptyMenuComponent,
    SideMenuContentComponent,
    SideMenuFooterComponent,
    SideMenuToolbarComponent,
    RankIndicatorComponent,
    SbValidationDialogComponent,
    SbSelectedProductItemComponent,
    SbSelectedLaborItemComponent,
    MultiFlowMenuComponent,
    SbCollapsingCategorySectionComponent,
    SbTagComponent,
    SbAutoCompleteFieldComponent,
    SbCategoryFiltersMenu,
    SbNonProfileBaseExplanationComponent,
    SideMenuActionComponent,
    SbCardComponent,
    SbSeatingPositioningMenuComponent,
    SbAttributeFilterComponent
  ],
  exports: [
    LoaderComponent,
    SbRadioGroupFieldComponent,
    SbCalloutComponent,
    ProductItemComponent,
    SbProductLookupComponent,
    StrikeComponent,
    BaseModelLookupComponent,
    StepBackButtonComponent,
    SbRequiredMarkerComponent,
    SbTextFieldComponent,
    SbNumberFieldComponent,
    SbFieldErrorComponent,
    SbCurrencyFieldComponent,
    DropDownsModule,
    DropDownButtonModule,
    ComboBoxModule,
    PopupModule,
    TooltipModule,
    ButtonModule,
    ButtonGroupModule,
    SwitchModule,
    DialogModule,
    ComboBoxModule,
    NumericTextBoxModule,
    MultiSelectModule,
    UploadModule,
    FileSelectModule,
    SbCircleCheckIndicatorComponent,
    SbSquareCheckIndicatorComponent,
    SbDropdownFieldComponent,
    SbMultiSelectFieldComponent,
    SbComboBoxFieldComponent,
    SbCheckboxGroupComponent,
    SbBaseModelMenuComponent,
    SbImportPdfMenuComponent,
    SbImportHistoryComponent,
    SbEmptyMenuComponent,
    SideMenuContentComponent,
    SideMenuFooterComponent,
    SideMenuToolbarComponent,
    SliderModule,
    MenusModule,
    GridModule,
    ProgressBarModule,
    InputsModule,
    RankIndicatorComponent,
    SbSelectedProductItemComponent,
    SbSelectedLaborItemComponent,
    MultiFlowMenuComponent,
    SbTagComponent,
    SbCollapsingCategorySectionComponent,
    SbAutoCompleteFieldComponent,
    SbCategoryFiltersMenu,
    SbNonProfileBaseExplanationComponent,
    SideMenuActionComponent,
    SbCardComponent,
    SbSeatingPositioningMenuComponent
  ]
})
export class SolutionBuilderSharedModule {}
