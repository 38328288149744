<div class="sb-explanation-form-wrapper">
  <div class="grid-x">
    <div class="cell small-6">
      <h4>Base Model Explanation</h4>
      <p>Selecting a non-profile ranked base model requires an explanation.</p>
    </div>
    <div class="cell small-6 text-right">
      <button
        kendoButton
        (click)="isExpanded = !isExpanded"
        [togglable]="true"
        [iconClass]="isExpanded ? 'fas fa-chevron-up' : 'fas fa-chevron-down'"
      ></button>
    </div>
  </div>

  <div class="grid-x" [class.hide]="!isExpanded">
    <div class="large-6 cell">
      <p>
        <sb-dropdown-field
          label="Reason"
          required="true"
          [control]="fcReason"
          [options]="reasons$ | async"
        ></sb-dropdown-field>
      </p>

      <p>
        <sb-text-field
          label="Explanation"
          [control]="fcExplanation"
          [multiline]="true"
          [required]="true"
        >
          <div errors>
            <sb-field-error *ngIf="fcExplanation.errors?.required"
              >This field is required.</sb-field-error
            >
            <sb-field-error *ngIf="fcExplanation.errors?.preventWhitespaceOnly"
              >Please enter a valid explanation.</sb-field-error
            >
          </div>
        </sb-text-field>
      </p>
    </div>
  </div>
</div>
