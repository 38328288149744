<div [formGroup]="fgLoaner" class="sb-form-wrapper">
  <div class="sb-form-field">
    <input type="checkbox" kendoCheckBox formControlName="fcInclude" />
    <label>Include Loaner</label>
  </div>
  <div class="sb-form-field">
    <!-- Notes -->
    <sb-text-field label="Notes" [control]="fcNotes" multiline="true">
      <sb-field-error *ngIf="fcNotes.errors?.whitespace"
        >Note may not be whitespace only.
      </sb-field-error>
      <sb-field-error *ngIf="fcNotes.dirty && fcNotes.errors?.required"
        >Note is required.
      </sb-field-error>
      <sb-field-error *ngIf="fcNotes.errors?.maxlength"
        >Note must be less than {{ fcNotes.errors.maxlength.requiredLength }}.
      </sb-field-error>
    </sb-text-field>
  </div>
</div>
<kendo-dialog-actions>
  <button kendoButton (click)="onCancelAction()">Cancel</button>
  <button
    kendoButton
    (click)="onConfirmAction()"
    [primary]="true"
    [disabled]="!fgLoaner.valid"
  >
    Save
  </button>
</kendo-dialog-actions>
