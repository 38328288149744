import { Component, OnInit, OnDestroy } from '@angular/core';
import {
  Router,
  NavigationEnd,
  ActivatedRoute,
  Data,
  RouterEvent,
  ResolveStart,
  ResolveEnd
} from '@angular/router';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { merge } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';

import { environment } from '../environments/environment';
import { Logger, untilDestroyed, AuthenticationService } from 'app/core';

const log = new Logger('App');

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  loading: boolean = false;
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private translateService: TranslateService,
    private authService: AuthenticationService
  ) {
    this.authService.runInitialLoginSequence();
  }

  ngOnInit(): void {
    // Setup logger
    if (environment.production) {
      Logger.enableProductionMode();
    }

    const onNavigationEnd = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    );

    this.router.events
      .pipe(
        filter(
          event => event instanceof ResolveStart || event instanceof ResolveEnd
        )
      )
      .subscribe((event: RouterEvent) => {
        if (event instanceof ResolveStart) {
          this.loading = true;
        } else if (event instanceof ResolveEnd) {
          this.loading = false;
        }
      });

    // Change page title on navigation or language change, based on route data
    merge(this.translateService.onLangChange, onNavigationEnd)
      .pipe(
        map(() => {
          let route = this.activatedRoute;
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        filter(route => route.outlet === 'primary'),
        switchMap(route => route.data),
        untilDestroyed(this)
      )
      .subscribe((event: Data) => {
        const title = event.title;
        if (title) {
          this.titleService.setTitle(
            'CPQ: ' + this.translateService.instant(title)
          );
        }
      });
  }

  ngOnDestroy(): void {}
}
