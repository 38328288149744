import { Injectable } from '@angular/core';
import {
  Resolve,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router
} from '@angular/router';
import { Observable, of, throwError } from 'rxjs';
import { switchMap, tap, catchError } from 'rxjs/operators';
import { Vendor } from 'app/domain';
import { VendorService } from '../vendor.service';
import { HttpErrorResponse } from '@angular/common/http';

/**
 * Resolver to grab the Vendors from the server and store them.  If we have them cached,
 * we will return those.
 */
@Injectable({ providedIn: 'root' })
export class VendorsResolver implements Resolve<Observable<Vendor[]>> {
  constructor(private router: Router, private vendorService: VendorService) {}
  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<Vendor[]> {
    return this.vendorService.getVendors().pipe(
      catchError((error: HttpErrorResponse) => {
        this.router.navigate(['/landing/errors'], {
          queryParams: {
            message: 'There was an issue retrieving the Vendors for this page.'
          }
        });
        return throwError;
      })
    );
  }
}
