import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'sb-admin-settings',
  templateUrl: './admin-settings.component.html',
  styleUrls: ['./admin-settings.component.scss']
})
export class AdminSettingsComponent implements OnInit {
  settings: AdminSettingsItem[] = [
    {
      name: 'Solution Types',
      icon: 'fad fa-wheelchair',
      routerUrl: './solution-types'
    },
    {
      name: 'Base Model Requirements',
      icon: 'fad fa-clipboard-list-check',
      routerUrl: './definitions'
    },
    {
      name: 'Templates',
      icon: 'fad fa-file-invoice',
      routerUrl: './templates'
    }
  ];

  constructor() {}

  ngOnInit(): void {}
}

interface AdminSettingsItem {
  name: string;
  icon: string;
  routerUrl: string;
}
