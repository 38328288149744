import { Pipe, PipeTransform } from '@angular/core';
import { CpqField, Product } from '../models';
import * as _ from 'lodash';

@Pipe({ name: 'categoryCode' })
export class CategoryCodePipe implements PipeTransform {
  transform(fields: CpqField[], direction: SortDir = SortDir.ASC): CpqField[] {
    let sortedFields: CpqField[] = [];
    if (fields) {
      sortedFields = _.orderBy(
        fields,
        (f: CpqField) => {
          try {
            return Number.parseInt(f.code);
          } catch {
            return f.code;
          }
        },
        [direction]
      );
    }
    return sortedFields;
  }
}

enum SortDir {
  ASC = 'asc',
  DESC = 'desc'
}
