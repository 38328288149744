<div [formGroup]="fgTemplate" class="sb-form-wrapper">
  <div class="sb-form-field">
    <sb-text-field
      label="Template Name"
      placeholder="Template Name"
      [control]="fcName"
    ></sb-text-field>
  </div>
  <div class="sb-form-field">
    <sb-radio-group-field
      label="Available To"
      [control]="fcAvailableTo"
      [horizontal]="true"
      [options]="supportedAvailability"
    ></sb-radio-group-field>
  </div>
  <div
    class="sb-form-field"
    [class.hide]="fcAvailableTo.value !== TemplateAvailability.Branch"
  >
    <sb-dropdown-field
      label="Branch"
      required="true"
      [options]="branches"
      [control]="fcBranch"
    ></sb-dropdown-field>
  </div>
</div>
<kendo-dialog-actions>
  <button kendoButton (click)="closeDialog()">Cancel</button>
  <button
    kendoButton
    (click)="onConfirmAction()"
    [primary]="true"
    [disabled]="!fgTemplate.valid"
  >
    <span *ngIf="isLoading" class="icon fas fa-spinner fa-spin"></span>Save
    Template
  </button>
</kendo-dialog-actions>
