import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { map } from 'rxjs/operators';
import { Vendor } from 'app/domain/models/solution-builder/models';
import { Cacheable } from 'ngx-cacheable';

@Injectable({ providedIn: 'root' })
export class VendorService {
  constructor(private http: HttpClient) {}

  @Cacheable()
  getVendors(): Observable<Vendor[]> {
    return this.http.get<Vendor[]>(environment.apiVendors).pipe(
      map((result: Vendor[]) => {
        return result && result.length > 0
          ? result.map(v => new Vendor(v.name, v.accountNumber))
          : [];
      })
    );
  }
}
