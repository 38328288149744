<div class="field-control-wrapper" [class.has-errors]="shouldShowErrors()">
  <label [attr.for]="id" class="field-label"
    >{{ label }}
    <sb-required-marker *ngIf="required"> </sb-required-marker>
  </label>
  <kendo-combobox
    [data]="options"
    [textField]="'text'"
    [valueField]="'value'"
    [formControl]="control"
    [allowCustom]="false"
    [valuePrimitive]="true"
    [virtual]="virtual"
    [suggest]="true"
    class="field-control"
    [class.filled]="filled"
    (valueChange)="valueChange($event)"
    [popupSettings]="{ popupClass: 'k-dropdown-popup' }"
    (blur)="handleBlur($event)"
  >
  </kendo-combobox>
</div>
