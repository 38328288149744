<div class="field-control-wrapper" [class.has-errors]="shouldShowErrors()">
  <label [attr.for]="id" class="field-label"
    >{{ label }}
    <sb-required-marker *ngIf="required"> </sb-required-marker>
  </label>
  <kendo-dropdownlist
    [data]="filteredData"
    [textField]="'text'"
    [valueField]="'value'"
    [valuePrimitive]="true"
    [filterable]="filterable"
    (filterChange)="handleFilter($event)"
    [virtual]="virtual"
    [formControl]="control"
    [defaultItem]="defaultItem"
    class="field-control"
    [class.filled]="filled"
    (valueChange)="valueChange($event)"
    [popupSettings]="{ popupClass: 'k-dropdown-popup' }"
  >
  </kendo-dropdownlist>
</div>
