<div
  *ngIf="{
    items: children$ | async,
    selectedCategory: selectedCategory$ | async
  } as data"
>
  <span class="sb-category-group">Labor Items</span>
  <ul class="sb-options-list">
    <li *ngIf="data.selectedCategory != null">
      <a (click)="returnToParent()">
        <span class="icon fas fa-long-arrow-left"></span>Back
      </a>
    </li>
    <li *ngFor="let item of data.items | laborCategorySortByName; index as i">
      <a (click)="selectGroup(item)">
        {{ item.name | categoryName }}
      </a>
    </li>
  </ul>
</div>
