<div class="sb-container" kendoDialogContainer>
  <div #spacer></div>
  <header class="sb-header" stickyThing [spacer]="spacer">
    <div class="grid-x">
      <div class="small-7 cell">
        <ul class="sb-tab-menu">
          <li>
            <a class="is-active">Settings</a>
          </li>
        </ul>
      </div>

      <div class="small-5 cell align-self-middle"></div>
    </div>
  </header>
  <main class="sb-content">
    <!-- Page Header Section-->
    <header class="sb-content-header">
      <div class="grid-x align-middle">
        <div class="cell">
          <ul class="sb-breadcrumbs">
            <li class="is-parent"><span>Solution Builder</span></li>
            <li class="is-divider"><span>/</span></li>
            <li class="is-active"><span>Settings</span></li>
          </ul>
          <h1 class="sb-content-heading">Settings</h1>
        </div>
      </div>
    </header>
    <section class="sb-flow-list-section">
      <div class="grid-x">
        <div class="cell">
          <div class="sb-flow-item-list">
            <sb-callout *ngIf="settings.length === 0" warn="true"
              >No Solution Types found.</sb-callout
            >
            <div class="grid-x grid-padding-x">
              <div
                class="small-6 cell"
                *ngFor="let item of settings; index as i"
              >
                <div class="sb-flow-item-wrapper">
                  <div
                    class="sb-flow-item"
                    [routerLink]="item.routerUrl"
                    kendoTooltip
                  >
                    <div class="sb-flow-item-content">
                      <span
                        class="sb-flow-item-icon"
                        [ngClass]="item.icon"
                      ></span>
                      <span
                        class="sb-flow-item-name"
                        [attr.title]="item.name"
                        >{{ item.name }}</span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- <section class="sb-template-list-section">
      <div class="grid-x grid-padding-x">
        <div class="cell">
          <div class="sb-admin-settings-item-list">
            <div class="grid-x grid-padding-x">
              <div class="small-3 cell">
                <div class="sb-admin-settings-item-wrapper">
                  <div
                    class="sb-admin-settings-item"
                    [routerLink]="['/admin/solution-types']"
                  >
                    <div class="sb-admin-settings-item-content">
                      <span
                        class="sb-admin-settings-item-icon fad fa-cogs"
                      ></span>
                      <span class="sb-admin-settings-item-name"
                        >Solution Types</span
                      >
                    </div>
                  </div>
                </div>
              </div>
              <div class="small-3 cell">
                <div class="sb-admin-settings-item-wrapper">
                  <div
                    class="sb-admin-settings-item"
                    [routerLink]="['/definitions']"
                  >
                    <div class="sb-admin-settings-item-content">
                      <span
                        class="sb-admin-settings-item-icon fad fa-clipboard-list-check"
                      ></span>
                      <span class="sb-admin-settings-item-name"
                        >Base Model Requirements</span
                      >
                    </div>
                  </div>
                </div>
              </div>
              <div class="small-3 cell">
                <div class="sb-admin-settings-item-wrapper">
                  <div
                    class="sb-admin-settings-item"
                    [routerLink]="['/templates']"
                  >
                    <div class="sb-admin-settings-item-content">
                      <span
                        class="sb-admin-settings-item-icon fad fa-file-invoice"
                      ></span>
                      <span class="sb-admin-settings-item-name">Templates</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section> -->
  </main>
</div>
