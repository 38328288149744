import {
  AfterViewInit,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { ClientAsset } from 'app/domain';
import * as _ from 'lodash';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'sb-client-asset-dialog',
  templateUrl: './client-asset-dialog.component.html',
  styleUrls: ['./client-asset-dialog.component.scss']
})
export class SbClientAssetDialogComponent
  implements OnInit, AfterViewInit, OnDestroy {
  height: number;

  @Input()
  workOrderId: string;

  @Input()
  clientAsset: ClientAsset;

  @ViewChild('container')
  container: HTMLElement;

  @HostListener('window:resize') onResize() {
    // guard against resize before view is rendered
    if (this.container) {
      this.height = this.container.offsetHeight;
    }
  }

  @Output()
  close: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  selected: EventEmitter<ClientAsset> = new EventEmitter<ClientAsset>();

  viewState$: BehaviorSubject<ViewState> = new BehaviorSubject<ViewState>(
    ViewState.Assets
  );

  ViewState = ViewState;

  asset: ClientAsset = new ClientAsset();

  constructor() {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    if (this.clientAsset) {
      this.asset = Object.assign(new ClientAsset(), this.clientAsset);
      this.viewState$.next(ViewState.EditAsset);
    }
  }

  ngOnDestroy(): void {}

  closeDialog(): void {
    this.close.emit();
  }

  selectProduct(asset: ClientAsset): void {
    this.asset = asset;
    this.viewState$.next(ViewState.EditAsset);
  }

  lookupAsset(): void {
    this.viewState$.next(ViewState.FindAsset);
  }

  showAssetList(): void {
    this.viewState$.next(ViewState.Assets);
  }

  removeAsset(): void {
    this.clientAsset = null;
    this.viewState$.next(ViewState.Assets);
  }

  setAsAsset(): void {
    this.selected.emit(this.asset);
  }
}

enum ViewState {
  Assets,
  FindAsset,
  EditAsset
}
