<div class="field-control-wrapper" [class.has-errors]="shouldShowErrors()">
  <label [attr.for]="id" class="field-label"
    >{{ label }}
    <sb-required-marker *ngIf="required"></sb-required-marker>
  </label>
  <div class="field-control-group" [class.has-icon]="iconClass != null">
    <span *ngIf="iconClass" class="icon {{ iconClass }}"></span>
    <input
      *ngIf="!multiline"
      type="text"
      class="field-control"
      [formControl]="control"
      [placeholder]="placeholder"
    />
    <textarea
      *ngIf="multiline"
      class="field-control field-control-textarea"
      [formControl]="control"
      [placeholder]="placeholder"
    ></textarea>
    <div class="sb-errors" *ngIf="shouldShowErrors()">
      <ng-content select="[errors]"></ng-content>
    </div>
  </div>
</div>
