<div class="field-control-wrapper" [class.has-errors]="shouldShowErrors()">
  <label [attr.for]="id" class="field-label"
    >{{ label }}
    <sb-required-marker *ngIf="required"> </sb-required-marker>
  </label>
  <div class="field-control-group" [class.has-icon]="iconClass != null">
    <span *ngIf="iconClass" class="icon {{ iconClass }}"></span>

    <kendo-numerictextbox
      class="field-control"
      [formControl]="control"
      [format]="'#'"
      [decimals]="0"
      [min]="min"
      [max]="max"
    ></kendo-numerictextbox>

    <div class="sb-errors" *ngIf="shouldShowErrors()">
      <ng-content select="[errors]"></ng-content>
    </div>
  </div>
</div>
