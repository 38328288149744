import { SolutionBuilderClient } from '../services/solution-builder-client.service';
import { ISaveValidator } from './models';

export class RepairSaveValidator implements ISaveValidator {
  validate(messages: string[], client: SolutionBuilderClient): void {
    const solutions = client.getSolutions();
    if (solutions.filter(s => s.hasLaborItems()).length === 0) {
      messages.push('Labor needs to be added to the Quote.');
    }

    if (!client.getClientAsset()) {
      messages.push('A Client Asset is required.  Please add the asset in CE.');
    }
  }
}
