<section *ngIf="solution">
  <h3 class="sb-solution-heading">
    Solution - {{ solution.hasBase() ? solution.base.description : 'Parts' }}
  </h3>
  <table class="sb-table">
    <thead>
      <tr>
        <th>Type</th>
        <th>Name</th>
        <th>Rank</th>
        <th>Quantity</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngIf="solution.hasBase()">
        <td>Base</td>
        <td>{{ solution.base.description }}</td>
        <td>{{ solution.base.rank }}</td>

        <td>{{ solution.base.defaultQuantity }}</td>
      </tr>
      <tr *ngFor="let product of solution.products">
        <td>Product</td>
        <td>{{ product.description }}</td>
        <td>{{ product.rank }}</td>
        <td>{{ product.defaultQuantity }}</td>
      </tr>
    </tbody>
  </table>
  <table class="sb-table sb-labor-table" *ngIf="hasLabor()">
    <thead>
      <tr>
        <th>Code</th>
        <th>Item</th>
        <th>Reasons</th>
        <th>Notes</th>
        <th>Unit of Service</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of solution.laborItems">
        <td>{{ item.getCodes() }}</td>
        <td>{{ item.name }}</td>
        <td>
          <ul>
            <li *ngFor="let reason of item.reasons">
              {{ reason.description }}
            </li>
          </ul>
        </td>
        <td>{{ item.notes }}</td>

        <td>{{ item.quantity }}</td>
      </tr>
    </tbody>
  </table>
</section>
