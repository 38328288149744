<sb-empty-menu
  [title]="'Select Base Model'"
  [canClose]="false"
  [footer]="false"
  (closeMenu)="onCloseMenu()"
>
  <sb-side-menu-content>
    <sb-base-model-lookup
      [solutionTypeId]="solutionTypeId"
      (completeSelection)="complete($event)"
    ></sb-base-model-lookup>
  </sb-side-menu-content>
</sb-empty-menu>
