<div
  class="sb-product-lookup-overlay"
  *ngIf="{
    viewState: viewState$ | async
  } as data"
>
  <div
    class="sb-product-lookup-dialog"
    #container
    [class.is-assets]="data.viewState === ViewState.Assets"
    [class.is-search]="data.viewState === ViewState.FindAsset"
    [class.is-edit]="data.viewState === ViewState.EditAsset"
  >
    <header class="sb-product-lookup-dialog-header">
      <h3 class="sb-product-lookup-dialog-header-title">
        Client Assets
        <a (click)="closeDialog()" class="sb-close-dialog"
          ><span class="fal fa-times-circle"></span
        ></a>
      </h3>
    </header>

    <sb-client-asset-list
      *ngIf="data.viewState === ViewState.Assets"
      [workOrderId]="workOrderId"
      (selected)="selectProduct($event)"
    ></sb-client-asset-list>

    <sb-client-asset-edit
      *ngIf="data.viewState === ViewState.EditAsset"
      [(asset)]="asset"
    ></sb-client-asset-edit>

    <sb-client-asset-search
      *ngIf="data.viewState === ViewState.FindAsset"
      (selected)="selectProduct($event)"
    ></sb-client-asset-search>

    <footer class="sb-product-lookup-dialog-footer">
      <div class="sb-asset-list-actions">
        <button
          *ngIf="data.viewState === ViewState.Assets"
          kendoButton
          primary="true"
          (click)="lookupAsset()"
          class="hide"
        >
          Look-Up
        </button>
        <button
          *ngIf="data.viewState === ViewState.FindAsset"
          kendoButton
          primary="true"
          (click)="showAssetList()"
        >
          Asset List
        </button>

        <button
          *ngIf="data.viewState === ViewState.EditAsset"
          kendoButton
          (click)="removeAsset()"
        >
          Remove Asset
        </button>
        <button
          *ngIf="data.viewState === ViewState.EditAsset"
          kendoButton
          primary="true"
          (click)="setAsAsset()"
        >
          Save Asset
        </button>
      </div>
    </footer>
  </div>
</div>
