import { Injectable } from '@angular/core';
import { Category, CpqSection, CpqField } from 'app/domain';

@Injectable({
  providedIn: 'root'
})
export class CpqFieldHelperService {
  constructor() {}

  buildCpqSection(category: Category): CpqSection {
    const section = new CpqSection();
    section.code = category.code;
    section.categoryId = category.id;
    section.name = category.name;
    section.sectionId = `section-${category.id}`;
    section.fields = [];
    return section;
  }

  buildCpqField(category: Category): CpqField {
    const field = new CpqField(category.name);
    field.id = category.id;
    field.code = category.code;
    field.parentId = category.parentId;
    field.productCount = field.productCount;
    field.isRequired = category.isRequired;
    field.isCanBeHidden = category.isCanBeHidden && category.productCount === 0;
    return field;
  }
}
