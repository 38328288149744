import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpParams,
  HttpErrorResponse
} from '@angular/common/http';
import { map, catchError, switchMap } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { Observable, of, throwError } from 'rxjs';
import { DocumentType, UploadedDocument, CeDocument, Vendor } from 'app/domain';
import { DynamicsService } from './dynamics.service';

@Injectable({ providedIn: 'root' })
export class DmsService {
  constructor(
    private http: HttpClient,
    private dynamicsService: DynamicsService
  ) {}

  deleteCeDocument(document: CeDocument): Observable<void> {
    const params = new HttpParams()
      .append('guid', document.blobGuid)
      .append('fileName', document.fileName);
    return this.http.delete<void>(`${environment.apiDmsApi}/Ce`, { params });
  }

  getSavedHotForms(workOrderNumber: string): Observable<CeDocument[]> {
    return this.dynamicsService.getVendorHotFormDocumentType().pipe(
      switchMap((documentType: DocumentType) => {
        const params = new HttpParams().append(
          'workOrderNumber',
          workOrderNumber
        );
        return this.http
          .post(`${environment.apiDmsApi}/Ce/GetDocuments`, null, { params })
          .pipe(
            map((value: CeDocument[]) => {
              if (value && value instanceof Array) {
                return value
                  .filter(
                    v => v.documentTypes.indexOf(documentType.description) > -1
                  )
                  .map(v => Object.assign(new CeDocument(), v));
              }
            }),
            catchError((error: HttpErrorResponse) => {
              if (error.status === 404) {
                return [];
              } else {
                return throwError(error);
              }
            })
          );
      })
    );
  }

  saveDocumentToCe(
    workOrderNumber: string,
    vendorAccountNumber: string,
    documentType: DocumentType,
    clientId: string,
    file: any
  ): Observable<UploadedDocument> {
    const data: object = {
      workOrderNumber: workOrderNumber,
      documentTypes: [documentType],
      clientId: clientId,
      vendors: [new Vendor('', vendorAccountNumber)],
      payers: null,
      uploadedBy: null
    };

    const formData = new FormData();
    formData.append('pdfFile', file);
    formData.append('params', JSON.stringify(data));

    return this.http
      .post(`${environment.apiDmsApi}/document/uploadToCE`, formData)
      .pipe(
        map((resp: UploadedDocument) =>
          Object.assign(new UploadedDocument(), resp)
        )
      );
  }
}
