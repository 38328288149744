import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { FormGroup, FormControl, FormBuilder } from '@angular/forms';
import { untilDestroyed } from 'app/core';
import { ClientAsset } from 'app/domain';

@Component({
  selector: 'sb-client-asset-edit',
  templateUrl: './client-asset-edit.component.html',
  styleUrls: ['../client-asset-dialog.component.scss']
})
export class SbClientAssetEditComponent
  implements OnInit, OnDestroy, AfterViewInit {
  @Input()
  asset: ClientAsset;

  @Output()
  assetChange: EventEmitter<ClientAsset> = new EventEmitter<ClientAsset>();

  fgAsset: FormGroup;
  fcSerialNumber: FormControl = new FormControl('');
  fcNsmSerialNumber: FormControl = new FormControl('');

  constructor(private formBuilder: FormBuilder) {}

  ngOnInit(): void {
    this.fgAsset = this.formBuilder.group({
      fcSerialNumber: this.fcSerialNumber,
      fcNsmSerialNumber: this.fcNsmSerialNumber
    });
  }
  ngOnDestroy(): void {}

  ngAfterViewInit(): void {
    if (this.asset) {
      this.fcSerialNumber.setValue(this.asset.serialNumber);
      this.fcNsmSerialNumber.setValue(this.asset.wheelChairSerialNumber);
    }

    this.fgAsset.valueChanges.pipe(untilDestroyed(this)).subscribe(() => {
      if (this.asset) {
        this.asset.serialNumber = this.fcSerialNumber.value;
        this.asset.wheelChairSerialNumber = this.fcNsmSerialNumber.value;
        this.assetChange.emit(this.asset);
      }
    });
  }
}
