import {
  AfterViewInit,
  Component,
  EventEmitter,
  HostListener,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import {
  ConfiguredSolution,
  FieldOption,
  Product,
  ProductSearchRequest,
  SearchCriteria,
  SearchCriteriaType,
  SolutionType,
  Vendor
} from 'app/domain';
import { SolutionBuilderClient } from 'app/modules/solution-builder/services/solution-builder-client.service';
import { SolutionDataStore } from 'app/modules/solution-builder/services/solution-data-store.service';
import { CategoriesService } from 'app/services/categories.service';
import { GloablFilterService } from 'app/services/global-filter.service';
import { ProductsService } from 'app/services/products.service';
import { SearchCriteriaService } from 'app/services/search-criteria.service';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { debounceTime, finalize } from 'rxjs/operators';

@Component({
  selector: 'sb-product-lookup-dialog',
  templateUrl: './product-lookup-dialog.component.html',
  styleUrls: ['./product-lookup-dialog.component.scss']
})
export class SbProductLookupDialogComponent
  implements OnInit, AfterViewInit, OnDestroy {
  height: number;

  @ViewChild('container')
  container: HTMLElement;

  @HostListener('window:resize') onResize() {
    // guard against resize before view is rendered
    if (this.container) {
      this.height = this.container.offsetHeight;
    }
  }

  @Output()
  close: EventEmitter<void> = new EventEmitter<void>();

  fgSearch: FormGroup;
  fcBaseModel: FormControl = new FormControl('');
  fcFilter: FormControl = new FormControl('');
  fcVendor: FormControl = new FormControl('');
  fcGroup: FormControl = new FormControl('');
  fcHcpcs: FormControl = new FormControl('');
  fcAfterMarket: FormControl = new FormControl(false);

  baseModels: FieldOption[] = [];

  allProducts: Product[] = [];

  products$: BehaviorSubject<Product[]> = new BehaviorSubject<Product[]>([]);

  isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private baseModelMap: Map<string, Product> = new Map<string, Product>();

  /**
   * Contains the source SearchCriteria models
   */
  searchCriteriaSource$: BehaviorSubject<
    SearchCriteria[]
  > = new BehaviorSubject<SearchCriteria[]>([]);

  showNoProductsFound: boolean = false;

  vendorOptions: FieldOption[] = [];
  groupOptions: FieldOption[] = [];
  hcpcsOptions: FieldOption[] = [];

  private solutionType: SolutionType;

  private globalVendorSearchCriteria: SearchCriteria = null;
  private readonly PAGE_SIZE: number = 100;

  constructor(
    private productService: ProductsService,
    private categoryService: CategoriesService,
    private solutionBuilderClient: SolutionBuilderClient,
    private searchCriteriaService: SearchCriteriaService,
    private globalFilterService: GloablFilterService,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit(): void {
    this.fgSearch = this.formBuilder.group({
      fcBaseModel: this.fcBaseModel,
      fcFilter: this.fcFilter,
      fcVendor: this.fcVendor,
      fcGroup: this.fcGroup,
      fcHcpcs: this.fcHcpcs,
      fcAfterMarket: this.fcAfterMarket
    });

    this.setGlobalVendorFilter();
    this.loadCompatibilityOptions();
  }

  ngAfterViewInit(): void {
    // Pull global filters in
    this.setGlobalVendorFilter();

    this.solutionType = this.solutionBuilderClient.getActiveSolutionType();

    this.searchCriteriaService
      .getSearchCriteria('')
      .subscribe((criteria: SearchCriteria[]) => {
        this.searchCriteriaSource$.next(criteria);

        const options = criteria.map(
          c => new FieldOption(c.name, c.id, c.type)
        );

        this.vendorOptions = _.sortBy(
          options.filter(f => f.group === SearchCriteriaType.VENDOR),
          (o: FieldOption) => o.text
        );
        this.groupOptions = _.sortBy(
          options.filter(f => f.group === SearchCriteriaType.GROUPS),
          (o: FieldOption) => o.text
        );
        this.hcpcsOptions = _.sortBy(
          options.filter(f => f.group === SearchCriteriaType.HCPCS),
          (o: FieldOption) => o.text
        );
      });
  }

  ngOnDestroy(): void {}

  closeDialog(): void {
    this.close.emit();
  }

  searchProducts(): void {
    const vendorId: string = this.fcVendor.value;
    const groupId: string = this.fcGroup.value;
    const hcpcsId: string = this.fcHcpcs.value;

    const options: FieldOption[] = [];

    if (vendorId) {
      options.push(new FieldOption('', vendorId, SearchCriteriaType.VENDOR));
    }
    if (groupId) {
      options.push(new FieldOption('', groupId, SearchCriteriaType.GROUPS));
    }
    if (hcpcsId) {
      options.push(new FieldOption('', hcpcsId, SearchCriteriaType.HCPCS));
    }

    // This is set if there's a base model products should be
    // compatible with.
    const hasCompatability = this.fcBaseModel.value ? true : false;

    //  Aftermarket flag
    const isAfterMarket: boolean = this.fcAfterMarket.value;

    // All the search criteria the system knows about
    const criteria = this.searchCriteriaSource$.value;

    // Flag for if the user has selected items to search on
    const hasOptions = options && options.length > 0;

    // Build search request
    const request = new ProductSearchRequest();
    request.searchCriteria = hasOptions
      ? options.map(o => criteria.find(c => c.id === o.value))
      : [];

    // Push global vendor filter
    if (this.globalVendorSearchCriteria) {
      request.searchCriteria.push(this.globalVendorSearchCriteria);
    }

    // Extract any "Custom" criteria
    // and apply it as the search string.
    request.searchString = this.fcFilter.value
      ? this.fcFilter.value.trim()
      : null;

    this.isLoading$.next(true);

    // The call that will ultimately produce the products
    // response
    let productSearchCall: Observable<Product[]> = null;

    // No compatability is passed in, no limiting category is passed in
    // just do a direct search
    if (!hasCompatability) {
      productSearchCall = this.categoryService.searchProducts(
        this.solutionType.categoryId,
        null,
        request,
        isAfterMarket
      );
    } else {
      // We know a user is just doing a compatible product lookup
      productSearchCall = this.productService.searchCompatibleProductsForBase(
        this.baseModelMap.get(this.fcBaseModel.value),
        request,
        isAfterMarket
      );
    }

    productSearchCall
      .pipe(
        debounceTime(300),
        finalize(() => this.isLoading$.next(false))
      )
      .subscribe(
        (products: Product[]) => {
          // Store all products so we can pull
          // more data from it
          this.allProducts = Object.assign([], products);

          // Need to check here, because we
          // splice them for paginated loading
          const hasProducts = this.allProducts.length > 0;

          // Take the first pages worth of products
          // to display
          this.products$.next(this.allProducts.splice(0, this.PAGE_SIZE));

          // Determine if we should show
          if (hasProducts) {
            this.showNoProductsFound = false;
          } else {
            this.showNoProductsFound = true;
          }
        },
        () => {
          // If an error occurs inside of the main chain.
          this.isLoading$.next(false);
        }
      );
  }

  loadMore(): void {
    const products = this.allProducts.splice(0, this.PAGE_SIZE);
    this.products$.next([...this.products$.value, ...products]);
  }

  selectProduct(product: Product): void {
    if (this.solutionBuilderClient.hasProduct(product)) {
      this.solutionBuilderClient.removeProduct(product);
    } else {
      this.solutionBuilderClient.addProduct(product);
    }
  }

  hasAlreadySelectedProduct(product: Product): boolean {
    return this.solutionBuilderClient.hasProduct(product);
  }

  noProductsFound(): boolean {
    const isLoading = this.isLoading$.value;
    const products = this.products$.value;
    const searchTerm = this.fcFilter.value;

    if (isLoading) {
      return false;
    }

    if (searchTerm && searchTerm.length >= 3 && products.length === 0) {
      return true;
    }

    return false;
  }

  private loadCompatibilityOptions(): void {
    const solutions = this.solutionBuilderClient.getSolutions();

    // Reset map
    this.baseModelMap.clear();

    let products: Product[] = [];
    solutions.forEach((solution: ConfiguredSolution) => {
      if (solution.hasBase()) {
        products.push(solution.base);
        this.baseModelMap.set(solution.base.productNumber, solution.base);
      }
      if (solution.hasProducts()) {
        products = [...products, ...solution.products];
      }
    });

    this.baseModels = products
      .filter(p => p.isBaseProduct)
      .map(p => new FieldOption(p.description, p.productNumber));

    const activeSolution: ConfiguredSolution = this.solutionBuilderClient.getActiveSolution();
    if (activeSolution && activeSolution.base) {
      this.fcBaseModel.setValue(activeSolution.base.productNumber);
      this.fcBaseModel.disable();
    }
  }

  private setGlobalVendorFilter(): void {
    const vendor: Vendor = this.globalFilterService.getVendor();
    let criteria: SearchCriteria = null;
    if (vendor) {
      const searchCriteria: SearchCriteria[] = this.searchCriteriaSource$.value;
      criteria = searchCriteria.find(c => c.id === vendor.accountNumber);
    }
    this.globalVendorSearchCriteria = criteria;
  }
}
