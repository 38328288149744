import { Injectable } from '@angular/core';
import { FieldOption, Vendor } from 'app/domain';
import { Observable, Subject } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { SolutionBuilderLoggerService } from 'app/domain/services/solution-builder-logger.service';

/**
 * Stateful service responsible for storing the selected
 * filters that should be applied contextually.
 */
@Injectable({ providedIn: 'root' })
export class GloablFilterService {
  private vendor: Vendor;

  private eventsSubject$: Subject<boolean> = new Subject<boolean>();
  private events$: Observable<
    boolean
  > = this.eventsSubject$.asObservable().pipe(shareReplay());

  constructor(private logger: SolutionBuilderLoggerService) {}

  events(): Observable<boolean> {
    return this.events$;
  }

  setVendor(vendor: Vendor): void {
    this.vendor = vendor;
    this.eventsSubject$.next(true);
  }

  getVendor(): Vendor {
    return this.vendor;
  }
}
