import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { OAuthService } from 'angular-oauth2-oidc';

import { Cacheable } from 'ngx-cacheable';
import {
  SolutionBuilderUser,
  ISolutionBuilderUser
} from 'app/domain/models/core/models';
import { environment } from 'environments/environment';

@Injectable({ providedIn: 'root' })
export class UserService {
  constructor(private oAuthService: OAuthService, private http: HttpClient) {}

  @Cacheable({ maxCacheCount: 100 })
  getUser(): Observable<SolutionBuilderUser> {
    const claims = this.oAuthService.getIdentityClaims();
    const userObjectId = claims ? claims['oid'] : '';
    return this.http
      .get<SolutionBuilderUser>(
        environment.userAuthorizationApi + `/${userObjectId}`
      )
      .pipe(
        map((response: ISolutionBuilderUser) => {
          return Object.assign(new SolutionBuilderUser(), response);
        })
      );
  }
}
