import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import {
  Product,
  Vendor,
  Solution,
  SolutionType,
  TemplateAvailability
} from 'app/domain/models/solution-builder/models';
import { Branch } from 'app/domain/models/core/models';
import { FieldOption } from 'app/domain';

/**
 * Stateful service responsible for holding categories, products, etc as needed.
 * This may mutate over time as we get a clearer idea of the requirements (i.e. how often does
 * the tree structure really change. May only need to be loaded once.)
 */
@Injectable({
  providedIn: 'root'
})
export class SolutionBuilderOptionsDataStore {
  private vendors$: BehaviorSubject<Vendor[]> = new BehaviorSubject<Vendor[]>(
    null
  );

  private solutionTypes$: BehaviorSubject<SolutionType[]> = new BehaviorSubject<
    SolutionType[]
  >(null);

  private branches$: BehaviorSubject<Branch[]> = new BehaviorSubject<Branch[]>(
    []
  );

  private templateAvailability: FieldOption[] = [
    new FieldOption('Me', TemplateAvailability.Me),
    new FieldOption('Branch', TemplateAvailability.Branch),
    new FieldOption('Everyone', TemplateAvailability.Everyone)
  ];

  constructor() {}

  setVendors(vendors: Vendor[]): void {
    this.vendors$.next(vendors);
  }
  getVendorsValue(): Vendor[] {
    return this.vendors$.value;
  }

  setSolutionTypes(solutionTypes: SolutionType[]): void {
    this.solutionTypes$.next(solutionTypes);
  }

  getSolutionTypes(): SolutionType[] {
    return this.solutionTypes$.value;
  }

  setBranches(branches: Branch[]): void {
    this.branches$.next(branches);
  }

  getBranches(): Branch[] {
    return this.branches$.value;
  }

  getTemplateAvailability(): FieldOption[] {
    return this.templateAvailability;
  }
}
