<div
  class="sb-container"
  *ngIf="{
    viewState: viewState$ | async,
    isSavingTemplate: isSavingTemplate$ | async
  } as templateData"
  [formGroup]="fgTemplate"
  kendoTooltip
  kendoDialogContainer
>
  <header class="sb-header">
    <div class="grid-x">
      <div class="small-7 cell">
        <ul class="sb-tab-menu">
          <li>
            <a class="is-active">Templates</a>
          </li>
        </ul>
      </div>
      <div class="small-5 cell align-self-middle text-right">
        <div class="sb-solution-builder-actions">
          <kendo-buttongroup look="bare">
            <button
              kendoButton
              iconClass="fas fa-search"
              title="Product Lookup"
              (click)="toggleProductLookup()"
            ></button>
          </kendo-buttongroup>
        </div>
      </div>
    </div>
  </header>
  <main class="sb-content">
    <!-- Page Header Section-->
    <header class="sb-content-header">
      <div class="grid-x  align-middle">
        <div class="small-8 cell">
          <ul class="sb-breadcrumbs">
            <li class="is-parent"><span>Solution Builder</span></li>
            <li class="is-divider"><span>/</span></li>
            <li class="is-parent"><a [routerLink]="'/admin'">Settings</a></li>
            <li class="is-divider"><span>/</span></li>
            <li class="is-active">
              <a routerLink="/admin/templates">Templates</a>
            </li>
            <li class="is-divider"><span>/</span></li>
            <li class="is-active"><span class="">Create New Template</span></li>
          </ul>
          <h1 class="sb-content-heading">
            {{ fcName.value ? fcName.value : 'Create New Template' }}
          </h1>
        </div>
        <div class="small-4 cell text-right">
          <div class="sb-template-list-actions">
            <button
              id="sbSaveSolutionButton"
              kendoButton
              [disabled]="!isAllowedToSave()"
              [primary]="true"
              (click)="saveTemplate()"
            >
              <span
                *ngIf="templateData.isSavingTemplate"
                class="fas fa-spinner fa-spin icon"
              ></span
              >{{
                templateData.isSavingTemplate
                  ? 'Saving Template'
                  : 'Save Template'
              }}
            </button>
          </div>
        </div>
      </div>
    </header>

    <section
      class="sb-content-section sb-general-settings-section sb-form-wrapper"
      [@fadeUpAndIn]="{
        value: 'in',
        params: { delay: '0ms' }
      }"
    >
      <header class="sb-content-section-header">
        <sb-strike
          heading="Settings"
          block="true"
          showIndicator="true"
          [current]="fgTemplate.invalid"
          [valid]="fgTemplate.valid"
        ></sb-strike>
      </header>
      <div class="sb-content-section-content">
        <ul class="sb-settings-menu menu">
          <li>
            <div class="sb-form-field">
              <sb-text-field
                label="Template Name"
                placeholder="Template Name"
                [control]="fcName"
              >
                <sb-field-error
                  *ngIf="
                    fcName.errors?.whitespace ||
                    fcName.errors?.beginsWithWhitespace ||
                    fcName.errors?.endsWithWhitespace
                  "
                  >Name must not begind, end, or be only
                  whitespace.</sb-field-error
                >
              </sb-text-field>
            </div>
          </li>
          <li>
            <div class="sb-form-field">
              <sb-radio-group-field
                label="Solution Type"
                [control]="fcSolutionType"
                [horizontal]="true"
                [options]="supportedSolutionTypes"
              ></sb-radio-group-field>
            </div>
          </li>
          <li>
            <div class="sb-form-field">
              <sb-radio-group-field
                label="Available To"
                [control]="fcAvailableTo"
                [horizontal]="true"
                [options]="supportedAvailability"
              ></sb-radio-group-field>
            </div>
          </li>
          <li>
            <div
              class="sb-form-field"
              [class.hide]="fcAvailableTo.value !== TemplateAvailability.Branch"
            >
              <sb-dropdown-field
                label="Branch"
                [options]="branches"
                [control]="fcBranch"
              ></sb-dropdown-field>
            </div>
          </li>
        </ul>
      </div>
    </section>
    <div class="sb-configured-solution-tabs">
      <ul class="menu">
        <li *ngFor="let solution of configuredSolutions">
          <a
            (click)="setActiveSolution(solution)"
            [class.is-active]="activeSolution == solution"
            [attr.title]="
              solution.base ? solution.base.description : 'Products Only'
            "
            >{{
              solution.base
                ? solution.base.description
                : getSolutionTypeName() + ' Products'
            }}</a
          >
        </li>
      </ul>
    </div>
    <section class="sb-multi-solution-view" *ngIf="activeSolution">
      <div class="sb-flow-item-list no-hover">
        <div class="sb-flow-item-wrapper">
          <div *ngIf="activeSolution.hasBase()">
            <h3 class="sb-solution-heading">Base</h3>
            <sb-selected-product-item
              *ngIf="activeSolution?.base"
              [product]="activeSolution.base"
              [supportsNotes]="false"
              (remove)="removeBaseModel()"
            ></sb-selected-product-item>
          </div>
          <div *ngIf="activeSolution.hasProducts()">
            <h3 class="sb-solution-heading">Products</h3>
            <sb-selected-product-item
              *ngFor="let product of activeSolution?.products"
              [product]="product"
              [supportsNotes]="false"
              (increment)="updateQuantity($event)"
              (decrement)="updateQuantity($event, false)"
              (remove)="
                removeProductFromSolution({
                  product: $event,
                  isAftermarket: false
                })
              "
            ></sb-selected-product-item>
          </div>
        </div>
      </div>
    </section>
  </main>
  <!--Side Menus-->
  <sb-empty-menu
    *ngIf="templateData.viewState === TemplateViewState.Empty"
    [title]="''"
  ></sb-empty-menu>
  <sb-base-model-menu
    *ngIf="shouldShowSingleBaseModelMenu()"
    [solutionTypeId]="fcSolutionType.value"
    (closeMenu)="showDefaultView()"
    (completeSelection)="setBaseModel($event)"
  ></sb-base-model-menu>

  <!--Multi Flow Category + Product Selection View-->
  <sb-multi-flow-menu
    *ngIf="shouldShowMultiBaseModelMenu()"
    [solution]="activeSolution"
    [solutionType]="activeSolutionType"
    (closeMenu)="showDefaultView()"
    (removeSelection)="removeProductFromSolution($event)"
    (completeSelection)="addProductToSolution($event)"
  ></sb-multi-flow-menu>
</div>
<kendo-dialog
  title="Confirm"
  *ngIf="showRemoveProductConfirmDialog"
  (close)="hideConfirmRemoveProductDialog()"
  [minWidth]="250"
  [width]="451"
>
  <p style="margin: 30px; text-align: center;">
    Are you sure you want to remove
    <strong>{{ targetProductToRemove.description }}</strong> from the solution?
  </p>
  <kendo-dialog-actions>
    <button kendoButton (click)="hideConfirmRemoveProductDialog()">No</button>
    <button kendoButton (click)="removeProduct()" primary="true">
      Yes
    </button>
  </kendo-dialog-actions>
</kendo-dialog>
<!--Product Lookup Dialog-->
<sb-product-lookup-dialog
  *ngIf="showProductLookupDialog"
  (close)="toggleProductLookup(false)"
></sb-product-lookup-dialog>
