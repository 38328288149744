import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { FieldOption, IProductAttribute } from 'app/domain';

@Component({
  selector: 'sb-attribute-filter',
  templateUrl: './attribute-filters.component.html',
  styleUrls: ['./attribute-filters.component.scss']
})
export class SbAttributeFilterComponent implements OnInit, AfterViewInit {
  @Input()
  control: FormControl;

  @Input()
  name: string;

  @Input()
  attributes: IProductAttribute[];

  @Output()
  onSelected: EventEmitter<IAttributeChangedEvent> = new EventEmitter<
    IAttributeChangedEvent
  >();

  options: FieldOption[] = [];

  constructor() {}

  ngOnInit(): void {
    this.control.valueChanges.subscribe((value: string) => {
      this.onSelected.emit({
        name: this.name,
        attribute: this.attributes.find(a => a.value === value)
      });
    });
  }

  ngAfterViewInit(): void {
    this.attributes.forEach((attr: IProductAttribute) => {
      this.options.push(new FieldOption(attr.value, attr.value));
    });
  }
}

export interface IAttributeChangedEvent {
  name: string;
  attribute: IProductAttribute;
}
