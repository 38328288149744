import {
  Component,
  OnInit,
  Input,
  OnDestroy,
  Output,
  EventEmitter,
  ViewEncapsulation,
  HostBinding
} from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { debounceTime, filter, switchMap, tap } from 'rxjs/operators';
import { fadeUpAndIn } from 'app/domain/animations/fade.up.in';
import { FormControl, FormGroup, FormBuilder } from '@angular/forms';
import { untilDestroyed } from 'app/core';
import * as _ from 'lodash';
import { fadeDownAndIn } from 'app/domain/animations/fade.down.in';
import { slideInLeft } from 'app/domain/animations/slide.in.left';
import {
  Product,
  ProductRanking
} from 'app/domain/models/solution-builder/models';
import { FieldOption } from 'app/domain';
import { ProductHelperService } from 'app/services/product-helper.service';
import { DialogService } from '@progress/kendo-angular-dialog';
import { SolutionTypeService } from 'app/services/solution-type.service';

@Component({
  selector: 'sb-base-model-lookup',
  templateUrl: './base-model-lookup.component.html',
  styleUrls: ['./base-model-lookup.component.scss'],

  animations: [fadeUpAndIn, fadeDownAndIn, slideInLeft]
})
export class BaseModelLookupComponent implements OnInit, OnDestroy {
  @HostBinding('class') class: string = 'sb-base-model-lookup';

  viewState$: BehaviorSubject<TemplateView> = new BehaviorSubject<TemplateView>(
    TemplateView.Groupings
  );

  /**
   * Holds the originally returned values from the query.
   */
  allProducts$: BehaviorSubject<Product[]> = new BehaviorSubject<Product[]>([]);

  /**
   * Holds the original unfiltered values of a grouping
   */
  source$: BehaviorSubject<Product[]> = new BehaviorSubject<Product[]>([]);

  /**
   * Holds the filtered values from the source.
   */
  options$: BehaviorSubject<Product[]> = new BehaviorSubject<Product[]>([]);

  /**
   * Holds the sent state of the request
   */
  isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  /**
   * Holds what the user has typed in the ComboBox
   */
  searchTerm$: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  /**
   * Category Id of the Solution Type
   */
  solutionTypeId$: BehaviorSubject<number> = new BehaviorSubject<number>(-1);

  @Input() set solutionTypeId(value: number) {
    this.solutionTypeId$.next(value);
  }

  @Input()
  control: FormControl;

  @Output()
  completeSelection: EventEmitter<Product> = new EventEmitter<Product>();

  TemplateView = TemplateView;

  groupingKeys: FieldOption[] = [];
  groupings: _.Dictionary<Product[]>;
  showProducts: boolean = false;
  selectedKey: FieldOption;

  fgSearch: FormGroup;

  /**
   * Contains the currently typed in value.  This isn't immediately passed
   * to the searchTerm$ because any changes in it triggrers a new search
   */
  fcSearchField: FormControl = new FormControl('');

  constructor(
    private solutionTypeService: SolutionTypeService,
    private productHelperService: ProductHelperService,
    private dialogService: DialogService,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit(): void {
    this.fgSearch = this.formBuilder.group({
      fcSearchField: this.fcSearchField
    });

    this.fcSearchField.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe((value: string) => {
        if (!value) {
          this.searchTerm$.next(null);
        }
      });

    this.solutionTypeId$
      .pipe(
        filter((solutionTypeId: number) => {
          return solutionTypeId > 0;
        }),
        tap(() => {
          this.allProducts$.next([]);
          this.source$.next([]);
          this.options$.next([]);
        }),
        switchMap((solutionTypeId: number) => {
          return this.solutionTypeService.getSolutionTypeBases(solutionTypeId);
        }),
        untilDestroyed(this)
      )
      .subscribe((products: Product[]) => {
        this.groupings = _.groupBy(products, p => p.categoryName);
        const unorderedKeys = Object.keys(this.groupings);

        // String the numeric before the category
        const options = unorderedKeys.map((k: string) => {
          return new FieldOption(k.substr(k.indexOf('- ') + 2), k);
        });

        // Store original results
        this.allProducts$.next(products);

        // Sort the keys
        this.groupingKeys = _.sortBy(options, o => o.text);

        this.isLoading$.next(false);
        this.showGroupings();
      });

    // Handle filtering of the options shown when typing
    this.searchTerm$
      .pipe(debounceTime(1000), untilDestroyed(this))
      .subscribe((searchTerm: string) => {
        let products = this.source$.value;

        if (!products || (products && products.length === 0)) {
          products = this.allProducts$.value;
        }

        products = _.sortBy(products, 'productNumber');

        if (!products) {
          this.options$.next([]);
        } else {
          this.source$.next(products);

          const foundProducts = searchTerm
            ? products.filter(product => product.matchesDescription(searchTerm))
            : this.source$.value;

          this.options$.next(
            this.productHelperService.sortProducts(foundProducts)
          );
        }

        if (searchTerm) {
          this.viewState$.next(TemplateView.Products);
        } else if (!searchTerm && this.selectedKey) {
          this.viewState$.next(TemplateView.Products);
        } else {
          this.viewState$.next(TemplateView.Groupings);
        }

        this.isLoading$.next(false);
      });
  }

  showGroupings(): void {
    this.selectedKey = null;
    this.viewState$.next(TemplateView.Groupings);
    this.fcSearchField.setValue(null);
  }

  showGroup(option: FieldOption): void {
    const products = this.groupings[option.value];

    this.selectedKey = option;
    this.source$.next(products);
    this.options$.next(products);
    this.viewState$.next(TemplateView.Products);
  }

  ngOnDestroy(): void {}

  filterResults(filterTerm: string): void {
    this.isLoading$.next(true);
    this.searchTerm$.next(filterTerm);
    this.showProducts = true;
  }
  onProductSelected(product: Product): void {
    if (product && product.isUnranked()) {
      const products = this.options$.value;

      // Determine if the only Product available was an unranked product
      const shouldShowPrompt = products
        ? products.find(p => p.rank > ProductRanking.Unranked)
        : null;

      if (shouldShowPrompt) {
        this.showConfirmBaseSelectionDialog();
      }
    }
    this.completeSelection.emit(product);
  }

  private showConfirmBaseSelectionDialog(): void {
    this.dialogService.open({
      title: 'Base Model Selection',
      content: `You have chosen a Non-Profile base. 
      Proceeding with this selection will result in Product Discovery Review and will require management approval. 
      To avoid the delay in processing your order, please select a Profile Ranked base alternative.`,
      actions: [{ text: 'Ok', value: true, primary: true }],
      width: 450,
      minWidth: 250
    });
  }
}

enum TemplateView {
  Groupings,
  Products
}
