import {
  Component,
  OnInit,
  Input,
  OnDestroy,
  EventEmitter,
  Output,
  HostBinding
} from '@angular/core';
import { fadeUpAndIn } from 'app/domain/animations/fade.up.in';
import { BehaviorSubject, Observable } from 'rxjs';

import { Product, SolutionLaborItem, LaborCategory } from 'app/domain';
import { tap } from 'rxjs/operators';
import { slideInLeft } from 'app/domain/animations/slide.in.left';
import { untilDestroyed } from 'app/core';

@Component({
  selector: 'sb-add-product-and-labor-step',
  templateUrl: './add-product-and-labor-step.component.html',
  styleUrls: ['./add-product-and-labor-step.component.scss'],
  animations: [fadeUpAndIn, slideInLeft]
})
export class AddProductAndLaborStepComponent implements OnInit, OnDestroy {
  @HostBinding('class') class: string =
    'sb-selection-menu sb-products-labor-menu';

  @Input()
  allowLabor: boolean;

  @Output()
  laborComplete: EventEmitter<SolutionLaborItem> = new EventEmitter<
    SolutionLaborItem
  >();

  @Output()
  previousStep: EventEmitter<boolean> = new EventEmitter<boolean>();

  /**
   * Derived from the targetLaborItem BehaviorSubject
   */
  targetLaborItemObs$: Observable<LaborCategory>;

  viewState$: BehaviorSubject<ViewState> = new BehaviorSubject<ViewState>(
    ViewState.Labor
  );

  /**
   * Holds the LaborItem that should be used for editing.
   */
  private targetLaborItem$: BehaviorSubject<
    LaborCategory
  > = new BehaviorSubject<LaborCategory>(null);

  constructor() {}

  ngOnInit(): void {
    // Anytime a new valid LaborItem is set, show the edit view.
    this.targetLaborItemObs$ = this.targetLaborItem$.asObservable().pipe(
      untilDestroyed(this),
      tap((item: LaborCategory) => {
        if (item) {
          this.showLaborEditView();
        }
      })
    );
  }

  ngOnDestroy(): void {}

  editLaborItem(item: LaborCategory): void {
    this.targetLaborItem$.next(item);
  }

  isLaborView(): boolean {
    return this.viewState$.value === ViewState.Labor;
  }
  isLaborEditView(): boolean {
    return this.viewState$.value === ViewState.LaborEdit;
  }

  isPathView(): boolean {
    return this.viewState$.value === ViewState.Path;
  }

  showLaborView(): void {
    this.viewState$.next(ViewState.Labor);
  }

  showLaborEditView(): void {
    this.viewState$.next(ViewState.LaborEdit);
  }

  showPathView(): void {
    this.viewState$.next(ViewState.Path);
  }

  editLaborItemComplete(item: SolutionLaborItem): void {
    if (item) {
      this.laborComplete.emit(item);
    }
    this.showLaborView();
  }
  returnToPrevious(): void {
    this.previousStep.emit(true);
  }
  onCloseMenu(): void {}
}

enum ViewState {
  Path,
  Labor,
  LaborEdit
}
