import { Component, OnInit, HostBinding } from '@angular/core';

@Component({
  selector: 'sb-side-menu-footer',
  templateUrl: './side-menu-footer.component.html',
  styleUrls: ['./side-menu-footer.component.scss']
})
export class SideMenuFooterComponent implements OnInit {
  @HostBinding('class') class: string = 'side-menu-footer';

  constructor() {}

  ngOnInit(): void {}
}
