import { ConfiguredSolution, Product } from 'app/domain';
import * as _ from 'lodash';
import { SolutionBuilderClient } from '../services/solution-builder-client.service';
import { ISaveValidator } from './models';

export class BaseModelRequirementsSaveValidator implements ISaveValidator {
  validate(messages: string[], client: SolutionBuilderClient): void {
    // Guarantee that the parts only solution is last.
    // This is mostly for multi-flow
    const solutions = _.sortBy(client.getSolutions(), s => s.isPartsOnly);

    let allSelectedProducts: Product[] = [];
    for (let i = 0, max = solutions.length; i < max; i++) {
      const solution = solutions[i];

      // Unpack products first
      if (solution.hasProducts()) {
        allSelectedProducts = [...allSelectedProducts, ...solution.products];
      }

      // Add base as the terminator in the list
      if (solution.hasBase()) {
        allSelectedProducts.push(solution.base);
      }
    }

    // Validate required categories
    solutions.forEach((solution: ConfiguredSolution) => {
      let requiredCategoryIds: number[] = solution.requiredCategories;

      // Validate that there's a product per required category.
      const requiredCategorySet = new Set<number>(requiredCategoryIds);
      if (requiredCategorySet.size > 0) {
        for (let i = 0, max = allSelectedProducts.length; i < max; i++) {
          requiredCategorySet.delete(allSelectedProducts[i].categoryId);
        }

        if (requiredCategorySet.size > 0) {
          messages.push(
            `${solution.base.description} requirements are not met. Make sure one product from each required category is selected.`
          );
        }
      }
    });
  }
}
