<div class="sb-item" kendoTooltip *ngIf="labor">
  <header>
    <div class="grid-x">
      <div class="small-6 cell  align-self-middle">
        <h5 class="sb-item-title">
          {{ labor.name }}
          <br /><small class="sb-item-uos">{{ labor.quantity }} (UoS)</small>
        </h5>
      </div>
      <div class="small-6 cell align-self-middle">
        <button
          (click)="removeLabor()"
          kendoButton
          class="sb-remove-labor-button warning"
          [iconClass]="'fas fa-times'"
          [class.hide]="!editable"
          title="Remove Product"
        ></button>
      </div>
    </div>
  </header>
  <div class="sb-item-content">
    <div class="sb-item-content-section">
      <label>Reasons</label>
      <ul>
        <li *ngFor="let reason of labor.reasons">
          <span class="sb-item-text">{{ reason.description }}</span>
        </li>
      </ul>
    </div>
    <div class="sb-item-content-section" *ngIf="labor.notes">
      <label>Notes</label>
      <span class="sb-item-text">{{ labor.notes }}</span>
    </div>
  </div>
  <footer></footer>
</div>
