import { Component, OnInit, Input, HostBinding } from '@angular/core';
import { SbBaseFieldComponent } from '../sb-base-field.component';

@Component({
  selector: 'sb-text-field',
  templateUrl: './sb-text-field.component.html',
  styleUrls: ['./sb-text-field.component.scss']
})
export class SbTextFieldComponent extends SbBaseFieldComponent
  implements OnInit {
  @HostBinding('class') class: string = 'sb-text-field';

  @Input()
  multiline: boolean;

  constructor() {
    super();
  }

  ngOnInit(): void {}
}
