import { Pipe, PipeTransform } from '@angular/core';
import { Product } from '../models/solution-builder/models';

@Pipe({
  name: 'capitalize'
})
export class CapitalizePipes implements PipeTransform {
  transform(value: String): String {
    if (value) {
      try {
        return value
          .toLowerCase()
          .split(' ')
          .map(word => {
            return word[0].toUpperCase() + word.substring(1);
          })
          .join(' ');
      } catch {
        return value;
      }
    }
    return value;
  }
}
