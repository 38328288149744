/**
 * This class is responsible for helping build the REST URLs
 * give the common structure required for accessing endpoints
 * with vendor number, product number, and model.
 */

import { Product } from 'app/domain';

export class ApiUrlBuilder {
  private readonly SEPERATOR: string = '/';
  private product: Product;
  private pathParts: string[] = [];

  constructor(product: Product) {
    this.product = product;
  }

  addPathPart(part: string): ApiUrlBuilder {
    this.cleanAndStorePathPart(part);
    return this;
  }

  includeVendorProductNumber(): ApiUrlBuilder {
    if (this.hasProduct() && this.product.vendorAccountNumber) {
      this.cleanAndStorePathPart(this.product.vendorAccountNumber);
    }
    return this;
  }

  includeProductNumber(): ApiUrlBuilder {
    if (this.hasProduct() && this.product.productNumber) {
      this.cleanAndStorePathPart(this.product.productNumber);
    }
    return this;
  }

  includeModel(): ApiUrlBuilder {
    if (this.hasProduct() && this.product.model) {
      this.cleanAndStorePathPart(this.product.model);
    }
    return this;
  }

  build(): string {
    return this.pathParts.join(this.SEPERATOR);
  }

  private cleanAndStorePathPart(part: string): void {
    if (part.endsWith(this.SEPERATOR)) {
      part = part.slice(0, -1);
    }
    this.pathParts.push(part);
  }

  private hasProduct(): boolean {
    return this.product != null;
  }
}
