import { Component, Input, OnInit } from '@angular/core';
import { ConfiguredSolution } from 'app/domain';

@Component({
  selector: 'sb-quote-summary-solution',
  templateUrl: './quote-summary-solution.component.html',
  styleUrls: ['./quote-summary-solution.component.scss']
})
export class SbQuoteSummarySolutionComponent implements OnInit {
  @Input()
  solution: ConfiguredSolution;

  constructor() {}

  ngOnInit(): void {}

  hasLabor(): boolean {
    return this.solution && this.solution.hasLaborItems();
  }
}
