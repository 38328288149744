import {
  trigger,
  transition,
  style,
  animate,
  state
} from '@angular/animations';
import { TransitionStates } from './transition.states';

const animateTiming = '1s {{delay}} cubic-bezier(.8,-0.6,0.2,1.5)';

export const fadeDownAndIn = trigger('fadeDownAndIn', [
  state('in', style({ opacity: 1, transform: 'translateY(0%)' })),
  state(
    'out',
    style({ opacity: 0, transform: 'translateY(-30%)', display: 'none' })
  ),
  transition(TransitionStates.OUT + '=>' + TransitionStates.IN, [
    style({ display: 'block' }),
    animate(animateTiming)
  ]),
  transition(TransitionStates.IN + '=>' + TransitionStates.OUT, [
    animate(animateTiming)
  ]),
  transition(':enter', [
    style({ opacity: 0, transform: 'translateY(-30%)' }),
    animate(animateTiming)
  ]),
  transition(':leave', animate(600, style({ opacity: 0 })))
]);
