import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { extract } from 'app/core';
import { Shell } from 'app/shell/shell.service';
import { AccessDeniedPageComponent } from './access-denied/access-denied.component';
import { ErrorPageComponent } from './error-page/error-page.component';
import { RequiresLoginPageComponent } from './requires-login-page/requires-login-page.component';

const routes: Routes = [
  Shell.childRoutes([
    {
      path: 'landing',
      children: [
        {
          path: 'error',
          component: ErrorPageComponent,
          data: { title: extract('Error') }
        },
        {
          path: 'requires-login',
          component: RequiresLoginPageComponent,
          data: { title: extract('Login Required') }
        },
        {
          path: 'access-denied',
          component: AccessDeniedPageComponent,
          data: { title: extract('Access Denied') }
        },
        {
          path: '**',
          component: ErrorPageComponent,
          data: { title: extract('Error') }
        }
      ]
    }
  ])
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: []
})
export class LandingPagesRoutingModule {}
