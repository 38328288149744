<!--<app-header></app-header>-->
<div class="sb-navigation-container collapsed">
  <header class="sb-navigation-header">
    <div class="border"></div>
    <span class="fad fa-wheelchair"></span>
  </header>
  <nav
    class="sb-navigation-list-container"
    kendoTooltip
    tooltipClass="sb-tooltip"
    position="right"
  >
    <ul class="sb-navigation-list">
      <li class="sb-navigation-list-item">
        <a
          class="sb-navigation-list-item-link"
          [routerLink]="['/admin']"
          routerLinkActive="active"
          title="Settings"
          ><span class="icon fad fa-cogs"></span
        ></a>
        <a
          class="sb-navigation-list-item-link"
          (click)="logout()"
          routerLinkActive="active"
          title="Sign Out"
          ><span class="icon fad fa-sign-out"></span
        ></a>
      </li>
    </ul>
  </nav>
</div>
<router-outlet></router-outlet>
