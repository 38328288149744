import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SearchCriteria } from 'app/domain/models/solution-builder/models';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';
import * as _ from 'lodash';
import { Cacheable } from 'ngx-cacheable';

@Injectable({
  providedIn: 'root'
})
export class SearchCriteriaService {
  constructor(private http: HttpClient) {}

  /**
   * Search Criteria that a user will see in the dropdown.
   * @param filter optional filter to limit the results.
   */
  @Cacheable({ maxCacheCount: 200 })
  getSearchCriteria(filter: string): Observable<SearchCriteria[]> {
    const params = new HttpParams().append('filter', filter);
    return this.http
      .get<SearchCriteria[]>(`${environment.apiSearchCriteria}`, {
        params
      })
      .pipe(
        map((response: SearchCriteria[]) => {
          return response.map(s => Object.assign(new SearchCriteria(), s));
        })
      );
  }
}
