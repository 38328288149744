/**
 * This class is responsible for helping map from a Product
 * to a QuoteLineItem model.
 */

import { Product, QuoteLineItem } from 'app/domain';

export class QuoteLineItemMapper {
  static mapToLineItem(products: Product[]): QuoteLineItem[] {
    if (!products || products.length === 0) {
      return [];
    }

    return products.map((p: Product, index: number) => {
      const item = new QuoteLineItem();
      item.name = p.description;
      item.description = p.description;
      item.msrp = p.msrp;
      item.cost = p.costPricePerUnit;
      item.lineNumber = index + 1; // TODO revisit to see if p has lineNumber
      item.productNumber = p.productNumber;
      item.quantity = p.defaultQuantity;
      item.internalNotes = p.internalNote;
      item.vendorAccountNumber = p.vendorAccountNumber;
      item.vendorName = p.vendorName;
      item.model = p.model;
      item.hcpcsCode1 = p.hcpcsCode1;
      item.hcpcsCode2 = p.hcpcsCode2;
      item.hcpcsCode3 = p.hcpcsCode3;
      item.hcpcsCode4 = p.hcpcsCode4;
      item.unitOfMeasure = p.unitOfMeasure;
      item.vendorProductNumber = p.vendorProductNumber;
      item.vendorDiscount1 = p.vendorDiscount1;
      item.vendorDiscount2 = p.vendorDiscount2;
      item.perItemConversionFactor = p.perItemConversionFactor;

      if (p.isCustomProduct) {
        item.externalNotes = p.description;
      } else {
        item.externalNotes = p.externalNote;
      }
      return item;
    });
  }
}
