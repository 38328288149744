<div
  *ngIf="{
    laborItem: targetLaborItemObs$ | async
  } as data"
>
  <header class="sb-selection-menu-header">
    <h3 class="sb-selection-menu-header-title">
      Labor Options
      <a class="sb-selection-menu-header-close" (click)="onCloseMenu()"
        ><span class="fal fa-times"></span></a
      ><span></span>
    </h3>
  </header>
  <div #container>
    <div class="sb-selection-menu-content">
      <!--Sub Views-->
      <div>
        <!-- Labor View-->
        <sb-pick-labor-item
          [class.hide]="!isLaborView()"
          (completeSelection)="editLaborItem($event)"
        ></sb-pick-labor-item>
        <!-- Edit Labor View-->
        <sb-edit-labor-item
          *ngIf="isLaborEditView() && data.laborItem"
          [category]="data.laborItem"
          (completeSelection)="editLaborItemComplete($event)"
        ></sb-edit-labor-item>
      </div>
    </div>
  </div>
</div>
