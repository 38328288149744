import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import {
  Product,
  ConfiguredSolution,
  DataStoreEventType,
  SolutionType
} from 'app/domain/models/solution-builder/models';
import { shareReplay } from 'rxjs/operators';

/**
 * Stateful service responsible for holding the currently configured
 * Template.
 */
@Injectable({
  providedIn: 'root'
})
export class EditTemplateDataStore {
  private solutionsMap: Map<string, ConfiguredSolution> = new Map<
    string,
    ConfiguredSolution
  >();

  /**
   * The Solution Type
   */
  private solutionType: SolutionType;

  constructor() {}

  clearSolutions(): void {
    this.solutionsMap.clear();
  }

  addSolution(storageKey: string, solution: ConfiguredSolution): void {
    this.solutionsMap.set(storageKey, solution);
  }

  removeSolution(key: string): boolean {
    return this.solutionsMap.delete(key);
  }

  getSolutions(): ConfiguredSolution[] {
    return Array.from(this.solutionsMap.values());
  }

  setSolutionType(solutionType: SolutionType): void {
    this.solutionType = solutionType;
  }
  getSolutionType(): SolutionType {
    return this.solutionType;
  }

  reset(): void {
    this.setSolutionType(null);
    this.clearSolutions();
  }
}
