import { AuthConfig } from 'angular-oauth2-oidc';

export const authConfig: AuthConfig = {
  issuer:
    'https://login.microsoftonline.com/ed907452-f178-4336-8e28-497e69d8aba4/v2.0',
  clientId: '79ff9fb0-a072-4602-9cb7-0d8aff780729', // Solution Builder
  responseType: 'id_token token',
  redirectUri: window.location.origin + '/index.html',
  silentRefreshRedirectUri:
    window.location.origin + '/assets/silent-refresh.html',
  scope: 'openid profile email offline_access', // Ask offline_access to support refresh token refreshes
  useSilentRefresh: true, // Needed for Code Flow to suggest using iframe-based refreshes
  timeoutFactor: 0.75, // For faster testing
  sessionChecksEnabled: true,
  showDebugInformation: true, // Also requires enabling "Verbose" level in devtools
  clearHashAfterLogin: false, // https://github.com/manfredsteyer/angular-oauth2-oidc/issues/457#issuecomment-431807040,
  nonceStateSeparator: 'semicolon', // Real semicolon gets mangled by IdentityServer's URI encoding
  strictDiscoveryDocumentValidation: false
};
