import { Injectable } from '@angular/core';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

import { Logger } from './logger.service';
import enUS from '../../translations/en-US.json';
import frFR from '../../translations/fr-FR.json';

const log = new Logger('I18nService');
const languageKey = 'language';

/**
 * Pass-through function to mark a string for translation extraction.
 * Running `npm translations:extract` will include the given string by using this.
 * @param s The string to extract for translation.
 * @return The same string.
 */
export function extract(s: string): string {
  return s;
}

@Injectable({
  providedIn: 'root'
})
export class I18nService {
  defaultLanguage!: string;
  supportedLanguages!: string[];

  constructor() {}

  /**
   * Initializes i18n for the application.
   * Loads language from local storage if present, or sets default language.
   * @param defaultLanguage The default language to use.
   * @param supportedLanguages The list of supported languages.
   */
  init(defaultLanguage: string, supportedLanguages: string[]): void {
    this.defaultLanguage = defaultLanguage;
    this.supportedLanguages = supportedLanguages;
    this.language = '';
  }

  /**
   * Cleans up language change subscription.
   */
  destroy(): void {}

  /**
   * Sets the current language.
   * Note: The current language is saved to the local storage.
   * If no parameter is specified, the language is loaded from local storage (if present).
   * @param language The IETF language code to set.
   */
  set language(language: string) {}

  /**
   * Gets the current language.
   * @return The current language code.
   */
  get language(): string {
    return null;
  }
}
