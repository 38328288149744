import {
  Component,
  OnInit,
  ViewEncapsulation,
  HostBinding,
  Input
} from '@angular/core';

@Component({
  selector: 'sb-callout',
  templateUrl: './sb-callout.component.html',
  styleUrls: ['./sb-callout.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SbCalloutComponent implements OnInit {
  @HostBinding('class') class: string = 'sb-callout-wrapper';

  @Input()
  warn: boolean;

  @Input()
  error: boolean;

  @Input()
  success: boolean;

  constructor() {}

  ngOnInit(): void {}
}
