import { Pipe, PipeTransform } from '@angular/core';
import { CpqField, LaborCategory, Product } from '../models';
import * as _ from 'lodash';

@Pipe({ name: 'laborCategorySortByName' })
export class LaborCategorySortByNamePipe implements PipeTransform {
  private readonly targetTruncString: string = '-';

  transform(
    fields: LaborCategory[],
    direction: SortDir = SortDir.ASC
  ): LaborCategory[] {
    let sortedFields: LaborCategory[] = [];
    if (fields) {
      sortedFields = _.orderBy(
        fields,
        (f: LaborCategory) => {
          return f.name;
        },
        [direction]
      );
    }
    return sortedFields;
  }
}

enum SortDir {
  ASC = 'asc',
  DESC = 'desc'
}
