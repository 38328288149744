import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { map } from 'rxjs/operators';
import { Cacheable } from 'ngx-cacheable';
import { FieldOption } from 'app/domain';

@Injectable({ providedIn: 'root' })
export class LookupService {
  constructor(private http: HttpClient) {}

  @Cacheable()
  getNonProfileReasonCodes(): Observable<FieldOption[]> {
    return this.http
      .get<ILookup[]>(`${environment.apiLookups}/nonprofile/reasoncodes`)
      .pipe(
        map((result: ILookup[]) => {
          return result && result.length > 0
            ? result.map(l => new FieldOption(l.value, l.key))
            : [];
        })
      );
  }
}

interface ILookup {
  key: string;
  value: string;
}
