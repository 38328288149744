import {
  Component,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder
} from '@angular/forms';
import { untilDestroyed } from 'app/core';
import { FieldOption, IExplanationModel, Solution } from 'app/domain';
import { CustomValidators } from 'app/domain/validators/custom.validators';
import { SolutionBuilderClient } from 'app/modules/solution-builder/services/solution-builder-client.service';
import { LookupService } from 'app/services/lookup.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'sb-non-profile-base-explanation',
  templateUrl: './non-profile-base-explanation.component.html',
  styleUrls: ['./non-profile-base-explanation.component.scss']
})
export class SbNonProfileBaseExplanationComponent implements OnInit, OnDestroy {
  @HostBinding('class')
  className: string = 'sb-non-profile-base-explanation';

  @Input()
  explanation: IExplanationModel;

  isExpanded: boolean = true;

  fgProfileExplanation: FormGroup;
  fcReason: FormControl = new FormControl('', Validators.required);
  fcExplanation: FormControl = new FormControl(
    '',
    Validators.compose([
      Validators.required,
      CustomValidators.preventWhitespaceOnly
    ])
  );

  reasons$: Observable<FieldOption[]>;

  constructor(
    private fb: FormBuilder,
    private lookupService: LookupService,
    private solutionBuilderClient: SolutionBuilderClient
  ) {}

  ngOnInit(): void {
    this.reasons$ = this.lookupService.getNonProfileReasonCodes();

    this.fgProfileExplanation = this.fb.group({
      fcReason: this.fcReason,
      fcExplanation: this.fcExplanation
    });

    this.fgProfileExplanation.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.solutionBuilderClient.setNonProfileBaseExplanation({
          reasonCode: this.fcReason.value,
          explanation: this.fcExplanation.value
        });
      });
  }

  ngAfterViewInit(): void {
    if (this.explanation) {
      this.fcExplanation.setValue(this.explanation.explanation);
      this.fcReason.setValue(this.explanation.reasonCode);
    }
  }
  ngOnDestroy(): void {}
}
