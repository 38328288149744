<div
  class="sb-callout"
  [class.warn]="warn"
  [class.error]="error"
  [class.success]="success"
>
  <span class="sb-callout-icon fad fa-info-circle"></span>
  <span class="sb-callout-message">
    <ng-content></ng-content>
  </span>
</div>
