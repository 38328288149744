<div
  class="sb-container"
  *ngIf="{
    viewState: viewState$ | async,
    isSavingDefinition: isSavingDefinition$ | async,
    selectedFields: selectedFields$ | async
  } as definitionData"
  [formGroup]="fgDefinition"
  kendoTooltip
  kendoDialogContainer
>
  <header class="sb-header">
    <div class="grid-x">
      <div class="small-7 cell">
        <ul class="sb-tab-menu">
          <li>
            <a class="is-active">Base Model Requirements Definition</a>
          </li>
        </ul>
      </div>
      <div class="small-5 cell align-self-middle"></div>
    </div>
  </header>
  <main class="sb-content">
    <!-- Page Header Section-->
    <header class="sb-content-header">
      <div class="grid-x  align-middle">
        <div class="small-8 cell">
          <ul class="sb-breadcrumbs">
            <li class="is-parent"><a>Solution Builder</a></li>
            <li class="is-divider"><span>/</span></li>
            <li class="is-parent"><a [routerLink]="'/admin'">Settings</a></li>
            <li class="is-divider"><span>/</span></li>
            <li class="is-active">
              <a [routerLink]="'/admin/definitions'">Definitions</a>
            </li>
            <li class="is-divider"><span>/</span></li>
            <li class="is-active">
              <span class="">Create New Definition</span>
            </li>
          </ul>
          <h1 class="sb-content-heading">
            {{ fcBaseModel.value ? fcBaseModel.value : 'New Definition' }}
          </h1>
        </div>
        <div class="small-4 cell text-right">
          <div class="sb-definition-actions">
            <button
              kendoButton
              [disabled]="!isAllowedToSave()"
              [primary]="true"
              (click)="saveDefinition()"
            >
              <span
                *ngIf="definitionData.isSavingDefinition"
                class="fas fa-spinner fa-spin icon"
              ></span
              >{{
                definitionData.isSavingDefinition
                  ? 'Saving Definition'
                  : 'Save Definition'
              }}
            </button>
            <button
              kendoButton
              [disabled]="!isAllowedToSave()"
              [class.hide]="!isEditingExisting()"
              class="warning"
              (click)="removeDefinition()"
            >
              Delete Definition
            </button>
          </div>
        </div>
      </div>
    </header>

    <section
      class="sb-content-section sb-general-settings-section sb-form-wrapper"
      [@fadeUpAndIn]="{
        value: 'in',
        params: { delay: '0ms' }
      }"
    >
      <header class="sb-content-section-header">
        <sb-strike
          heading="Solution Type"
          block="true"
          showIndicator="true"
          [current]="fgDefinition.invalid"
          [valid]="fgDefinition.valid"
        ></sb-strike>
      </header>
      <div class="sb-content-section-content">
        <sb-dropdown-field
          [control]="fcSolutionType"
          [options]="solutionTypes"
          [class.hide]="fcSolutionType.value > 0"
        >
        </sb-dropdown-field>
        <section class="sb-flow-item-list" *ngIf="fcSolutionType.value > 0">
          <div class="sb-flow-item-wrapper">
            <div class="sb-flow-item" kendoTooltip>
              <div class="sb-flow-item-content">
                <span
                  class="sb-flow-item-name"
                  [attr.title]="selectedSolutionType?.name"
                >
                  {{ selectedSolutionType?.name }}</span
                >
                <div class="sb-flow-actions">
                  <a
                    class=" button hollow warning"
                    (click)="confirmSolutionTypeChange()"
                    [class.hide]="isEditingExisting()"
                    >Remove</a
                  >
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </section>
    <!-- Selected Base Model for Template -->
    <section
      class="sb-content-section sb-base-model"
      [@fadeUpAndIn]="{
        value: 'in',
        params: { delay: '100ms' }
      }"
    >
      <header class="sb-content-section-header">
        <sb-strike
          heading="Base Model"
          block="true"
          showIndicator="true"
          [default]="fcSolutionType.value < 0 && !solutionHasBaseModel()"
          [current]="fcSolutionType.value && !solutionHasBaseModel()"
          [valid]="solutionHasBaseModel()"
        ></sb-strike>
      </header>
      <div class="sb-content-section-content">
        <sb-callout *ngIf="!fcSolutionType.value" warn="true"
          >Requires Solution Type selection.</sb-callout
        >
        <sb-combobox-field
          *ngIf="fcSolutionType.value"
          [options]="baseModelOptions$ | async"
          [control]="fcBaseModel"
          [class.hide]="solutionHasBaseModel()"
        ></sb-combobox-field>
        <section class="sb-flow-item-list" *ngIf="solutionHasBaseModel()">
          <div class="sb-flow-item-wrapper">
            <div class="sb-flow-item" kendoTooltip>
              <div class="sb-flow-item-content">
                <span
                  class="sb-flow-item-name"
                  [attr.title]="fcBaseModel.value"
                >
                  {{ fcBaseModel.value }}</span
                >
                <div class="sb-flow-actions">
                  <button
                    kendoButton
                    class="warning"
                    (click)="confirmBaseRemoval()"
                    [class.hide]="isEditingExisting()"
                  >
                    Remove
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </section>
    <!-- Selected Base Model for Template -->
    <section
      class="sb-content-section sb-base-model"
      [@fadeUpAndIn]="{
        value: 'in',
        params: { delay: '100ms' }
      }"
    >
      <header class="sb-content-section-header">
        <sb-strike
          heading="Categories"
          block="true"
          showIndicator="true"
          [default]="!solutionHasBaseModel()"
          [current]="
            fgDefinition.valid &&
            solutionHasBaseModel() &&
            definitionData.selectedFields?.length === 0
          "
          [valid]="definitionData.selectedFields?.length > 0"
        ></sb-strike>
      </header>
      <div class="sb-content-section-content">
        <sb-callout
          *ngIf="
            solutionHasBaseModel() &&
            definitionData.selectedFields?.length === 0
          "
          >Select required Categories from the right-hand menu.</sb-callout
        >
        <section class="sb-flow-item-list">
          <div *ngFor="let field of definitionData.selectedFields">
            <div class="sb-flow-item-wrapper">
              <div class="sb-flow-item" kendoTooltip>
                <div class="sb-flow-item-content">
                  <span
                    class="sb-flow-item-name"
                    [attr.title]="field.categoryName"
                  >
                    {{ field.categoryName | categoryName }}</span
                  >
                  <div class="sb-flow-actions">
                    <button
                      kendoButton
                      class="warning"
                      (click)="removeRequirement(field)"
                    >
                      Remove
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </section>
  </main>
  <!--Side Menus-->
  <sb-empty-menu
    *ngIf="definitionData.viewState === TemplateViewState.Empty"
    [title]="''"
  ></sb-empty-menu>
  <sb-category-selection-menu
    *ngIf="definitionData.viewState === TemplateViewState.Categories"
    (selectionChanged)="requiredCategoriesChanged($event)"
    [selected]="selectedCodes"
    [sections]="sections"
  ></sb-category-selection-menu>
</div>

<kendo-dialog
  title="Confirm"
  *ngIf="showReturnToBaseModelMenuConfirmDialog"
  (close)="showReturnToBaseModelMenuConfirmDialog = false"
  [minWidth]="250"
  [width]="451"
>
  <p style="margin: 30px; text-align: center;">
    Removing the Base Model will also remove any required categories associated
    to this Definition. Are you sure you want to continue?
  </p>
  <kendo-dialog-actions>
    <button
      kendoButton
      (click)="showReturnToBaseModelMenuConfirmDialog = false"
    >
      No
    </button>
    <button kendoButton (click)="removeBaseModel()" primary="true">
      Yes
    </button>
  </kendo-dialog-actions>
</kendo-dialog>
