import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnDestroy,
  HostBinding,
  AfterViewInit,
  HostListener
} from '@angular/core';
import { BehaviorSubject, of, Observable } from 'rxjs';
import { debounceTime, switchMap, finalize } from 'rxjs/operators';
import { fadeUpAndIn } from 'app/domain/animations/fade.up.in';
import {
  Product,
  CpqField,
  SearchCriteria,
  ProductSearchRequest,
  Vendor,
  ICategoryFilters,
  IProductSelectedEvent
} from 'app/domain/models/solution-builder/models';
import { untilDestroyed } from 'app/core';
import { FieldOption } from 'app/domain';
import { ProductsService } from 'app/services/products.service';
import { Event } from '@angular/router';
import { CategoriesService } from 'app/services/categories.service';
import { GloablFilterService } from 'app/services/global-filter.service';
import { SolutionBuilderClient } from 'app/modules/solution-builder/services/solution-builder-client.service';

/**
 * In a few of the workflows that the user may go through to configure a solution,
 * the ability to lookup Products is fairly common.  This Component is responsible
 * for centralizing that interaction.
 */
@Component({
  selector: 'sb-products-lookup',
  templateUrl: './products-lookup.component.html',
  styleUrls: ['./products-lookup.component.scss'],
  animations: [fadeUpAndIn]
})
export class SbProductLookupComponent
  implements OnInit, OnDestroy, AfterViewInit {
  @HostBinding('class') class: string = 'sb-products-lookup';

  @Input()
  compatibleWith: Product;

  @Input()
  knownProducts: Product[] = [];

  _filters: ICategoryFilters;

  @Input() set filters(value: ICategoryFilters) {
    this._filters = value;
    this.requestProducts$.next(true);
  }

  @Input()
  preload: boolean = false;

  _container: HTMLElement;

  height: number;

  @Input() set container(value: HTMLElement) {
    this._container = value;
    this.height = this._container.offsetHeight;
  }

  @HostListener('window:resize') onResize() {
    // guard against resize before view is rendered
    if (this._container) {
      this.height = this._container.offsetHeight;
    }
  }

  field$: BehaviorSubject<CpqField> = new BehaviorSubject<CpqField>(null);

  @Input() set field(value: CpqField) {
    this.field$.next(value);
  }

  @Output()
  removeSelection: EventEmitter<IProductSelectedEvent> = new EventEmitter<
    IProductSelectedEvent
  >();

  @Output()
  completeSelection: EventEmitter<IProductSelectedEvent> = new EventEmitter<
    IProductSelectedEvent
  >();

  allProducts: Product[] = [];

  products$: BehaviorSubject<Product[]> = new BehaviorSubject<Product[]>([]);

  isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  searchCriteriaSource$: BehaviorSubject<
    SearchCriteria[]
  > = new BehaviorSubject<SearchCriteria[]>([]);

  searchCriteria$: BehaviorSubject<FieldOption[]> = new BehaviorSubject<
    FieldOption[]
  >([]);

  showNoProductsFound: boolean = false;
  showScrollToTop: boolean = false;

  private globalVendorSearchCriteria: SearchCriteria = null;
  private readonly PAGE_SIZE: number = 100;
  private requestProducts$: BehaviorSubject<boolean> = new BehaviorSubject<
    boolean
  >(false);

  constructor(
    private productService: ProductsService,
    private categoryService: CategoriesService,
    private globalFilterService: GloablFilterService,
    private solutionBuilderClient: SolutionBuilderClient
  ) {}

  ngOnInit(): void {
    this.setGlobalVendorFilter();
  }

  ngOnDestroy(): void {}

  ngAfterViewInit(): void {
    this.field$.pipe(untilDestroyed(this)).subscribe(() => {
      if (this.preload) {
        this.requestProducts$.next(true);
      }
    });

    // React to any updates in the filters
    this.globalFilterService
      .events()
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.setGlobalVendorFilter();
        this.requestProducts$.next(true);
      });

    this.requestProducts$
      .pipe(debounceTime(600), untilDestroyed(this))
      .subscribe(() => {
        this.searchProducts();
      });

    this.addExistingProducts();
  }

  addExistingProducts() {
    const solutions = this.solutionBuilderClient.getActiveSolution();
    const products = solutions.products;
    products.forEach(prod => this.knownProducts.push(prod));
  }

  hasAlreadySelectedProduct(product: Product): boolean {
    return this.knownProducts
      ? this.knownProducts.find(
          p => p.productNumber === product.productNumber
        ) != null
      : false;
  }

  searchProducts(): void {
    of(this._filters)
      .pipe(
        switchMap((filters: ICategoryFilters) => {
          // This is set is someone has selected a Category to look at
          const field: CpqField = this.field$.value;
          const hasCategoryField = this.field$.value != null;

          // This is set if there's a base model products should be
          // compatible with.
          const hasCompatability = this.compatibleWith != null;

          // Build search request
          const request = new ProductSearchRequest();

          if (filters) {
            if (filters.vendor) {
              request.searchCriteria.push(...filters.vendor);
            }

            if (filters.hcpcs) {
              request.searchCriteria.push(...filters.hcpcs);
            }

            // Push global vendor filter
            if (this.globalVendorSearchCriteria) {
              request.searchCriteria.push(this.globalVendorSearchCriteria);
            }

            request.searchString = filters.filter;
          }

          this.isLoading$.next(true);

          // The call that will ultimately produce the products
          // response
          let productSearchCall: Observable<Product[]> = null;

          //  Aftermarket flag
          const isAfterMarket: boolean = filters && filters.isAftermarket;

          // No compatability is passed in, no limiting category is passed in
          // just do a direct search
          if (!hasCompatability && !hasCategoryField) {
            return this.productService.searchProducts(request.searchString);
          } else {
            // We know a user wants to look at products for a specific category
            if (hasCategoryField) {
              productSearchCall = this.categoryService.searchProducts(
                field.id,
                this.compatibleWith,
                request,
                isAfterMarket
              );
            } else {
              // We know a user is just doing a compatible product lookup
              productSearchCall = this.productService.searchCompatibleProductsForBase(
                this.compatibleWith,
                request,
                isAfterMarket
              );
            }
          }

          return productSearchCall.pipe(
            debounceTime(300),
            finalize(() => this.isLoading$.next(false))
          );
        })
      )
      .subscribe(
        (products: Product[]) => {
          const attributeFilters = this._filters
            ? this._filters.attributeFilters
            : [];
          if (attributeFilters && attributeFilters.length > 0) {
            products = products.filter(p => {
              return p.hasMatchingAttributes(attributeFilters);
            });
          }

          // Store all products so we can pull
          // more data from it
          this.allProducts = Object.assign([], products);

          // Need to check here, because we
          // splice them for paginated loading
          const hasProducts = this.allProducts.length > 0;

          // Take the first pages worth of products
          // to display
          this.products$.next(this.allProducts.splice(0, this.PAGE_SIZE));

          // Determine if we should show
          if (hasProducts) {
            this.showNoProductsFound = false;
          } else {
            this.showNoProductsFound = true;
          }
        },
        () => {
          // If an error occurs inside of the main chain.
          this.isLoading$.next(false);
        }
      );
  }

  removeFromKnownProducts(product: Product) {
    // Remove item on second click
    this.knownProducts = this.knownProducts.filter(
      prod => prod.productNumber !== product.productNumber
    );
    this.solutionBuilderClient.removeProduct(product);
  }

  selectProduct(product: Product): void {
    const isAftermarket = this._filters && this._filters.isAftermarket;

    const foundProduct = this.knownProducts
      ? this.knownProducts.find(p => p.productNumber === product.productNumber)
      : null;

    if (foundProduct) {
      this.removeFromKnownProducts(product);
    } else {
      this.knownProducts.push(product);
      this.completeSelection.emit({ product, isAftermarket });
    }
  }

  noProductsFound(): boolean {
    const isLoading = this.isLoading$.value;
    const products = this.products$.value;

    if (isLoading) {
      return false;
    }

    if (products.length === 0) {
      return true;
    }

    if (this.field$.value && products.length === 0) {
      return true;
    }

    return false;
  }

  loadMore(): void {
    const products = this.allProducts.splice(0, this.PAGE_SIZE);
    this.products$.next([...this.products$.value, ...products]);
  }

  scrollToTop(srcElement: any): void {
    srcElement.scrollTop = 0;
  }

  onScroll(event: Event): void {
    const scrollTop = event['srcElement'].scrollTop;
    if (scrollTop > 150) {
      this.showScrollToTop = true;
    } else {
      this.showScrollToTop = false;
    }
  }

  private setGlobalVendorFilter(): void {
    const vendor: Vendor = this.globalFilterService.getVendor();
    let criteria: SearchCriteria = null;
    if (vendor) {
      const searchCriteria: SearchCriteria[] = this.searchCriteriaSource$.value;
      criteria = searchCriteria.find(c => c.id === vendor.accountNumber);
    }
    this.globalVendorSearchCriteria = criteria;
  }
}
