import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { map } from 'rxjs/operators';
import {
  WorkOrderLoadResults,
  Product,
  QuoteLineItem,
  LaborQuoteLineItem,
  SolutionLaborItem,
  SaveWorkOrderRequest,
  SolutionType,
  IWorkOrderLoadResults,
  ISolutionGrouping,
  SolutionGrouping,
  IClientAsset,
  ClientAsset,
  IClientAssetLoadResults
} from 'app/domain/models/solution-builder/models';
import { ExpectedReimbursementInfo } from 'app/domain/models/solution-builder/expected.reimbursement';
import { QuoteLineItemMapper } from './quote-line-item.mapper';

@Injectable({ providedIn: 'root' })
export class WorkOrderService {
  loadedResults: any;

  constructor(private http: HttpClient) {}

  getWorkOrder(workOrderNumber: string): Observable<WorkOrderLoadResults> {
    return this.http
      .get<IWorkOrderLoadResults>(
        `${environment.apiWorkOrder}/${workOrderNumber}/load`
      )
      .pipe(
        map((response: IWorkOrderLoadResults) => {
          const loadResults = new WorkOrderLoadResults();
          loadResults.fullName = response.fullName;
          loadResults.isEditable = response.isEditable;
          loadResults.type = response.type;
          loadResults.orderType = response.orderType;
          loadResults.marginThreshold = response.marginThreshold;
          loadResults.nonProfile = response.nonProfile;

          if (response.lineItems) {
            loadResults.lineItems = response.lineItems.map(p =>
              Object.assign(new QuoteLineItem(), p)
            );
          }
          if (response.laborItems) {
            loadResults.laborItems = response.laborItems.map(p =>
              Object.assign(new SolutionLaborItem(), p)
            );
          }
          if (response.missing) {
            loadResults.missing = response.missing;
          }
          if (response.uncategorizedProducts) {
            loadResults.uncategorizedProducts = response.uncategorizedProducts.map(
              p => Object.assign(new Product(), p)
            );
          }

          if (response.solutions) {
            loadResults.solutions = response.solutions.map(
              (grouping: ISolutionGrouping) => {
                const model = new SolutionGrouping();
                model.base = Object.assign(new Product(), grouping.base);
                model.solutionType = Object.assign(
                  new SolutionType(),
                  grouping.solutionType
                );
                model.products = grouping.products.map(p =>
                  Object.assign(new Product(), p)
                );
                return model;
              }
            );
          }

          if (response.solutionType) {
            loadResults.solutionType = Object.assign(
              new SolutionType(),
              response.solutionType
            );
          }
          if (response.expectedReimbursementInfo) {
            loadResults.expectedReimbursementInfo = Object.assign(
              new ExpectedReimbursementInfo(),
              response.expectedReimbursementInfo
            );
          }

          if (response.clientAsset) {
            loadResults.clientAsset = this.mapClientAsset(response.clientAsset);
          }
          if (response.clientAssets) {
            loadResults.clientAssets = response.clientAssets.map(
              (asset: IClientAsset) => {
                return this.mapClientAsset(asset);
              }
            );
          }

          if (response.missingItems) {
            loadResults.missingItems = response.missingItems.map(q =>
              Object.assign(new QuoteLineItem(), q)
            );
          }

          // Apply notes.
          if (loadResults.products) {
            loadResults.products.forEach((p: Product) => {
              const lineItem: QuoteLineItem = loadResults.lineItems.find(
                l => l.productNumber === p.productNumber
              );

              if (lineItem) {
                p.externalNote = lineItem.externalNotes;
                p.internalNote = lineItem.internalNotes;
              }
            });
          }
          this.loadedResults = loadResults;
          return loadResults;
        })
      );
  }

  getClientAssets(
    workOrderNumber: string
  ): Observable<IClientAssetLoadResults> {
    return this.http
      .get<IClientAssetLoadResults>(
        `${environment.apiWorkOrder}/${workOrderNumber}/assets`
      )
      .pipe(
        map((response: IClientAssetLoadResults) => {
          let products: Product[];
          let assets: ClientAsset[];

          if (response.clientAssets) {
            assets = response.clientAssets.map(ca => {
              const asset = Object.assign(new ClientAsset(), ca);
              if (ca.product) {
                asset.product = Object.assign(new Product(), ca.product);
              }
              return asset;
            });
          }

          if (response.products) {
            products = response.products.map(p =>
              Object.assign(new Product(), p)
            );
          }

          return {
            clientAssets: assets,
            products: products,
            missingItems: response.missingItems
          };
        })
      );
  }

  /**
   * Updates the Work Order.
   */
  updateWorkOrder(request: SaveWorkOrderRequest): Observable<boolean> {
    return this.http
      .post(`${environment.apiWorkOrder}/${request.workOrderId}`, {
        quoteLineItems: request.products?.length
          ? QuoteLineItemMapper.mapToLineItem(request.products)
          : [],
        laborCodeLineItems: request.laborItems
          ? request.laborItems.map(
              (labor: SolutionLaborItem, index: number) => {
                const lineItem = new LaborQuoteLineItem();
                lineItem.lineNumber = index + 1;
                lineItem.productNumber = labor.productNumber;
                lineItem.quantity = labor.quantity;
                lineItem.units =
                  lineItem.units < labor.quantity
                    ? labor.quantity
                    : labor.units;
                lineItem.notes = labor.notes;
                lineItem.reasons = labor.reasons;
                return lineItem;
              }
            )
          : [],
        estimatedReimbursementDetails: request.expectedReimbursementLineItems
          ? request.expectedReimbursementLineItems
          : [],
        clientAsset: request.asset,
        nonProfile: request.nonProfileBaseExplanation
      })
      .pipe(
        map(() => {
          return true;
        })
      );
  }

  private mapClientAsset(asset: IClientAsset): ClientAsset {
    const clientAsset = new ClientAsset();
    clientAsset.id = asset.id;
    clientAsset.serialNumber = asset.serialNumber;
    clientAsset.hasSerialNumber = asset.hasSerialNumber;
    clientAsset.wheelChairSerialNumber = asset.wheelChairSerialNumber;
    clientAsset.product = Object.assign(new Product(), asset.product);
    return clientAsset;
  }
}
