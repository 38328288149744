import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnDestroy
} from '@angular/core';
import {
  SolutionLaborItem,
  LaborCategory,
  LaborCode,
  FieldOption,
  Reason
} from 'app/domain';
import { fadeUpAndIn } from 'app/domain/animations/fade.up.in';
import {
  FormBuilder,
  FormGroup,
  FormControl,
  Validators,
  FormArray,
  ValidatorFn
} from '@angular/forms';
import { untilDestroyed } from 'app/core';
import { CustomValidators } from 'app/domain/validators/custom.validators';

@Component({
  selector: 'sb-edit-labor-item',
  templateUrl: './edit-labor-item.component.html',
  styleUrls: ['./edit-labor-item.component.scss'],
  animations: [fadeUpAndIn]
})
export class EditLaborItemComponent implements OnInit, OnDestroy {
  @Input()
  category: LaborCategory;

  @Output()
  completeSelection: EventEmitter<SolutionLaborItem> = new EventEmitter<
    SolutionLaborItem
  >();

  fgEditLabor: FormGroup;
  fcLaborCode: FormControl = new FormControl('', Validators.required);
  fcQuantity: FormControl = new FormControl(1, Validators.required);
  fcNotes: FormControl = new FormControl();
  faReasons: FormArray;

  laborCodeOptions: FieldOption[] = [];
  reasonOptions: FieldOption[] = [];

  requiresNote: boolean = false;

  maxQuantity: number = 1000;
  minQuantity: number = 1;

  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {
    this.laborCodeOptions = this.category.laborCodes.map(
      c => new FieldOption(c.name, c.productNumber)
    );

    this.faReasons = this.fb.array([], this.requiresOneCheckedValidator());

    this.fgEditLabor = this.fb.group({
      fcLaborCode: this.fcLaborCode,
      fcQuantity: this.fcQuantity,
      fcNotes: this.fcNotes,
      faReasons: this.faReasons
    });

    this.fcLaborCode.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe((laborCodeProductNumber: string) => {
        const laborCode = this.category.laborCodes.find(
          c => c.productNumber === laborCodeProductNumber
        );

        this.faReasons.clear();

        laborCode.reasonCodes.forEach(c =>
          this.faReasons.push(new FormControl(false))
        );

        this.reasonOptions = laborCode.reasonCodes.map(
          c => new FieldOption(c.description, c.code)
        );

        this.fcQuantity.clearValidators();

        this.maxQuantity = laborCode.maxQty;
        this.minQuantity = laborCode.unitOfService;

        if (laborCode.maxQty === 1) {
          this.fcQuantity.setValue(1);
          this.fcQuantity.disable();
        } else {
          this.fcQuantity.setValue(this.minQuantity);
          this.fcQuantity.enable();

          if (laborCode.maxQty > 1) {
            this.fcQuantity.setValidators(
              Validators.compose([
                Validators.min(this.minQuantity),
                Validators.max(this.maxQuantity),
                Validators.required
              ])
            );
          } else {
            this.fcQuantity.setValidators(Validators.required);
          }

          this.fcQuantity.updateValueAndValidity();
        }
      });

    this.faReasons.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe((selected: boolean[]) => {
        const laborCodeProductNumber = this.fcLaborCode.value;

        if (laborCodeProductNumber) {
          const laborCode = this.category.laborCodes.find(
            c => c.productNumber === laborCodeProductNumber
          );

          if (selected && selected.length > 0) {
            // Create a set of selected codes.

            const reasonCodeSet = new Set<string>();
            for (let i = 0, max = selected.length; i < max; i++) {
              if (selected[i]) {
                reasonCodeSet.add(this.reasonOptions[i].value as string);
              }
            }

            // Get all Reasons that require notes.
            const reasonsRequireNote = laborCode.reasonCodes.filter(
              r => r.requiresNote
            );

            // Reset requirement
            this.toggleRequireNote(false);

            // Set that notes are required if there's overlap.
            for (let i = 0, max = reasonsRequireNote.length; i < max; i++) {
              const reason = reasonsRequireNote[i];
              if (reasonCodeSet.has(reason.code)) {
                this.toggleRequireNote();
                break;
              }
            }
          } else {
            this.toggleRequireNote(false);
          }
        }
      });
  }

  ngOnDestroy(): void {}

  returnToLaborItems(): void {
    this.completeSelection.emit(null);
  }

  addLaborItem(): void {
    if (this.fgEditLabor.valid) {
      const solutionItem = new SolutionLaborItem();
      //solutionItem.name = this.category.name;
      solutionItem.notes = this.fcNotes.value;
      solutionItem.quantity = this.fcQuantity.value;
      solutionItem.isBillable = true;
      solutionItem.units = this.fcQuantity.value;
      const laborCodeProductNumber: string = this.fcLaborCode.value;

      const laborCode: LaborCode = this.category.laborCodes.find(
        c => c.productNumber === laborCodeProductNumber
      );

      solutionItem.productNumber = laborCodeProductNumber;
      solutionItem.name = laborCode.name;
      solutionItem.hcpcsCode1 = laborCode.hcpcsCode1;
      solutionItem.hcpcsCode2 = laborCode.hcpcsCode2;
      solutionItem.hcpcsCode3 = laborCode.hcpcsCode3;
      solutionItem.hcpcsCode4 = laborCode.hcpcsCode4;

      const reasons: Reason[] = [];
      this.faReasons.value.forEach((value: boolean, index: number) => {
        if (value) {
          reasons.push(laborCode.reasonCodes[index]);
        }
      });

      solutionItem.reasons = reasons;

      this.completeSelection.emit(solutionItem);
    } else {
      this.fcLaborCode.markAsDirty();
      this.faReasons.markAsDirty();
      this.fcNotes.markAsDirty();
      this.fcNotes.updateValueAndValidity();
    }
  }

  private toggleRequireNote(require: boolean = true): void {
    if (require) {
      this.fcNotes.setValidators(
        Validators.compose([
          Validators.required,
          CustomValidators.preventWhitespaceOnly
        ])
      );
    } else {
      this.fcNotes.clearValidators();
    }

    this.requiresNote = require;

    this.fcNotes.updateValueAndValidity();
  }

  private requiresOneCheckedValidator(): ValidatorFn {
    return (fa: FormArray) => {
      const selected = fa.controls
        .map((fc: FormControl) => (fc.value ? 'checked' : null))
        .filter((value: string) => value !== null).length;

      if (selected < 1) {
        return {
          requireCheckboxesToBeChecked: true
        };
      }
      return null;
    };
  }
}
