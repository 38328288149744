import { Injectable } from '@angular/core';
import { Category, CpqField, CpqSection } from 'app/domain';
import { CpqFieldHelperService } from './cpq-field-helper.service';

@Injectable({
  providedIn: 'root'
})
export class CategoryTreeBuilderService {
  constructor(private cpqFieldHelper: CpqFieldHelperService) {}
  /**
   * This is used to render the sections and categories
   * regardless of if they have products in them.  This is primarily
   * used for the Base Model Requirements admin page.
   * @param treeView
   * @param treeData
   */
  buildFullTree(treeView: CpqSection[], treeData: Category[]): void {
    treeData.forEach((cat: Category) => {
      if (this.shouldBeGroup(cat, true)) {
        const section = this.createSection(cat);

        if (this.shouldBeField(cat)) {
          section.fields.push(this.createField(cat));
        }

        // Direct children are those that do not have child categories
        // that have to be shown
        const directChildren = cat.children.filter(
          c => c.children.length === 0
        );
        directChildren.forEach((childCategory: Category) => {
          section.fields.push(this.createField(childCategory));
        });

        // Indirect children are those that HAVE child categories
        const indirectChildren = cat.children.filter(
          c => c.children.length > 0
        );
        if (indirectChildren.length > 0) {
          this.buildFullTree(treeView, indirectChildren);
        }

        // Determine section visibility
        if (section.fields.length > 0) {
          section.isCanBeHidden = false;
          treeView.push(section);
        }
      } else {
        this.buildFullTree(treeView, cat.children);
      }
    });
  }

  /**
   * Builds a section of the tree that contains only categories with products.
   * @param treeView - CpqSections that have been build so far.
   * @param treeData - The data we need to turn into CpqSections.
   */
  buildConciseTree(treeView: CpqSection[], treeData: Category[]): void {
    treeData.forEach((cat: Category) => {
      if (this.shouldBeGroup(cat)) {
        const section = this.createSection(cat);

        if (this.shouldBeField(cat)) {
          section.fields.push(this.createField(cat));
        }

        // Direct children are those that do not have child categories
        // that have to be shown
        const directChildren = cat.children.filter(
          c =>
            c.children.length === 0 &&
            ((c.productCount === 0 && !c.isCanBeHidden) || c.productCount > 0)
        );
        directChildren.forEach((childCategory: Category) => {
          if (this.shouldBeField(childCategory)) {
            section.fields.push(this.createField(childCategory));
          }
        });

        // Indirect children are those that HAVE child categories
        const indirectChildren = cat.children.filter(
          c => c.children.length > 0
        );
        if (indirectChildren.length > 0) {
          this.buildConciseTree(treeView, indirectChildren);
        }

        // Determine section visibility
        if (section.fields.length > 0) {
          section.isCanBeHidden = false;
          treeView.push(section);
        }
      } else {
        this.buildConciseTree(treeView, cat.children);
      }
    });
  }

  private shouldBeGroup(
    category: Category,
    ignoreProductCount: boolean = false
  ): boolean {
    if (category.children.length > 0) {
      // If this category has direct decendants without children
      const hasChildWithoutChildren = category.children.find(
        c => c.children.length === 0
      );
      return hasChildWithoutChildren != null;
    } else if (
      category.children.length === 0 &&
      !ignoreProductCount &&
      category.productCount > 0
    ) {
      return true;
    }
    return false;
  }

  private shouldBeField(category: Category): boolean {
    return category.productCount > 0 || !category.isCanBeHidden;
  }

  private createSection(category: Category): CpqSection {
    return this.cpqFieldHelper.buildCpqSection(category);
  }

  /**
   * Turn a Category into a CpqField
   * @param category Category to make a CpqField out of.
   */
  private createField(category: Category): CpqField {
    return this.cpqFieldHelper.buildCpqField(category);
  }
}
