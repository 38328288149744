import {
  Component,
  OnInit,
  Input,
  ViewChild,
  HostBinding,
  AfterViewInit,
  OnChanges,
  SimpleChanges
} from '@angular/core';

import { FieldOption } from 'app/domain';
import { SbBaseFieldComponent } from '../sb-base-field.component';

@Component({
  selector: 'sb-dropdown-field',
  templateUrl: './sb-dropdown-field.component.html',
  styleUrls: ['./sb-dropdown-field.component.scss']
})
export class SbDropdownFieldComponent extends SbBaseFieldComponent
  implements OnInit, AfterViewInit, OnChanges {
  @HostBinding('class') class: string = 'sb-dropdown-field';

  /**
   * Style overwrite to allow solid state colored dropdown
   */
  @Input()
  filled: boolean;

  @Input()
  options: FieldOption[];

  @Input()
  filterable: boolean;

  defaultItem: FieldOption;

  virtual: any = {
    itemHeight: 28
  };

  filteredData: FieldOption[] = [];

  constructor() {
    super();
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.options) {
      this.filteredData = changes.options.currentValue as FieldOption[];
    }
  }

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    if (this.placeholder) {
      this.defaultItem = new FieldOption(this.placeholder, '');
    } else {
      this.defaultItem = new FieldOption('Select...', '');
    }
  }

  valueChange(e: any): void {}
  isItemSelected(itemText: string): boolean {
    const values = this.control.value;

    if (!values) {
      return false;
    }

    return values.some((item: FieldOption) => {
      return (
        item.value.toString().toLowerCase() ===
        itemText.toString().toLowerCase()
      );
    });
  }

  handleFilter(value: string) {
    this.filteredData = this.options.filter(
      s => s.text.toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
  }
}
