import { Component, OnInit, Input, HostBinding } from '@angular/core';
import { SbBaseFieldComponent } from '../sb-base-field.component';

@Component({
  selector: 'sb-number-field',
  templateUrl: './sb-number-field.component.html',
  styleUrls: ['./sb-number-field.component.scss']
})
export class SbNumberFieldComponent extends SbBaseFieldComponent
  implements OnInit {
  @HostBinding('class') class: string = 'sb-number-field';

  @Input()
  min: number = 0;

  @Input()
  max: number = 0;

  constructor() {
    super();
  }

  ngOnInit(): void {}
}
