import { Injectable } from '@angular/core';
import { Product } from 'app/domain';
import * as _ from 'lodash';

@Injectable({ providedIn: 'root' })
export class ProductHelperService {
  mapProducts(incomingProducts: object[]): Product[] {
    if (incomingProducts && incomingProducts.length > 0) {
      const products = incomingProducts.map((product: Product) => {
        const p = Object.assign(new Product(), product);
        switch (p.rank) {
          case 1:
            p.normalizedRank = 'A';
            break;
          case 2:
            p.normalizedRank = 'B';
            break;
          case 3:
            p.normalizedRank = 'C';
            break;
          case 4:
            p.normalizedRank = 'D';
            break;
          default:
            p.normalizedRank = 'U';
            break;
        }
        return p;
      });

      return this.sortProducts(products);
    }
    return [];
  }

  sortProducts(products: Product[]): Product[] {
    return products
      ? _.sortBy(products, ['normalizedRank', 'description'])
      : [];
  }
}
