import { SolutionBuilderClient } from '../services/solution-builder-client.service';
import { ISaveValidator } from './models';

/**
 * Validation Handler for Discontinued Products within a Quote
 */
export class DiscontinuedSaveValidator implements ISaveValidator {
  validate(messages: string[], client: SolutionBuilderClient): void {
    const solutions = client.getSolutions();
    if (solutions.filter(s => s.hasDiscontinuedProducts()).length > 0) {
      messages.push(
        'There are discontinued products added to this Quote that need to removed/replaced.'
      );
    }
  }
}
