<div class="sb-container" kendoDialogContainer>
  <div #spacer></div>
  <header class="sb-header" stickyThing [spacer]="spacer">
    <div class="grid-x">
      <div class="small-7 cell">
        <ul class="sb-tab-menu">
          <li>
            <a class="is-active">Solution Type Settings</a>
          </li>
        </ul>
      </div>

      <div class="small-5 cell align-self-middle"></div>
    </div>
  </header>
  <main class="sb-content">
    <!-- Page Header Section-->
    <header class="sb-content-header">
      <div class="grid-x align-middle">
        <div class="small-8 cell">
          <ul class="sb-breadcrumbs">
            <li class="is-parent"><span>Solution Builder</span></li>
            <li class="is-divider"><span>/</span></li>
            <li class="is-parent"><a [routerLink]="'/admin'">Settings</a></li>
            <li class="is-divider"><span>/</span></li>
            <li class="is-parent">
              <a routerLink="/admin/solution-types">Solution Types</a>
            </li>
            <li class="is-divider"><span>/</span></li>
            <li class="is-active">
              <span class=""
                >{{
                  solutionType ? solutionType.name : 'New Solution Type'
                }}
                Settings</span
              >
            </li>
          </ul>
          <h1 class="sb-content-heading">
            {{ solutionType ? solutionType.name : 'New Solution Type' }}
            Settings
          </h1>
        </div>
        <div class="small-4 cell align-self-middle text-right">
          <button
            [disabled]="fgSettings.invalid"
            kendoButton
            primary="true"
            (click)="saveSolutionType()"
          >
            Save
          </button>
        </div>
      </div>
    </header>
    <!-- Template List Section-->
    <section>
      <div [formGroup]="fgSettings">
        <p>
          <sb-dropdown-field
            label="Solution Type"
            required="true"
            [control]="fcCategory"
            [options]="categoryOptions"
          ></sb-dropdown-field>
        </p>
        <p>
          <sb-radio-group-field
            label="Supports"
            [control]="fcFlow"
            [options]="flowOptions"
          ></sb-radio-group-field>
        </p>
        <p>
          <sb-dropdown-field
            label="Labor Category"
            placeholder="Please select..."
            [control]="fcLaborCategory"
            [options]="laborCategories"
          ></sb-dropdown-field>
        </p>
      </div>
    </section>
  </main>
</div>
