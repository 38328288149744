import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { CoreModule } from 'app/core';
import { SolutionBuilderSharedModule } from 'app/shared';
import { SolutionBuilderRoutingModule } from './solution-builder-routing.module';
import { ChairBuilderComponent } from './solution-builder.component';
import { SolutionBuilderDomainModule } from 'app/domain/solution-builder-domain.module';
import { ReactiveFormsModule } from '@angular/forms';
import { ToastrModule } from 'ngx-toastr';
import { CategoryNamePipe } from 'app/domain/pipes/category-name.pipe';
import { SortablejsModule } from 'ngx-sortablejs';
import { SolutionBuilderCommonViewsModule } from '../common/solution-builder-common-views.module';
import { SbLoadTemplateDialogComponent } from './dialogs/load-template-dialog/load-template-dialog.component';
import { SbSaveTemplateDialogComponent } from './dialogs/save-template-dialog/save-template-dialog.component';
import { SbNoteFormDialogComponent } from './dialogs/product-notes-dialog/product-notes-dialog.component';
import { SbCustomProductDialogComponent } from './dialogs/custom-product-dialog/custom-product-dialog.component';
import { SbQuoteSummaryComponent } from './quote-summary/quote-summary.component';
import { SbQuoteSummarySolutionComponent } from './quote-summary/quote-summary-solution/quote-summary-solution.component';
import { SbSemiCustomProductDialogComponent } from './dialogs/semi-custom-product-dialog/semi-custom-product-dialog.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslateModule,
    CoreModule,
    SolutionBuilderSharedModule,
    SolutionBuilderRoutingModule,
    SolutionBuilderDomainModule,
    SortablejsModule.forRoot({ animation: 150 }),
    ToastrModule.forRoot({
      positionClass: 'toast-bottom-right',
      preventDuplicates: true
    }),
    SolutionBuilderCommonViewsModule
  ],
  declarations: [
    ChairBuilderComponent,
    SbLoadTemplateDialogComponent,
    SbSaveTemplateDialogComponent,
    SbNoteFormDialogComponent,
    SbCustomProductDialogComponent,
    SbSemiCustomProductDialogComponent,
    SbQuoteSummaryComponent,
    SbQuoteSummarySolutionComponent
  ],
  providers: [CategoryNamePipe]
})
export class SolutionBuilderModule {}
