<div
  *ngIf="{
    isLoading: isLoading$ | async,
    options: options$ | async,
    viewState: viewState$ | async
  } as data"
>
  <div class="sb-base-model-lookup-filter">
    <div class="grid-x">
      <div class="small-10 cell" [formGroup]="fgSearch">
        <input
          #filter
          type="text"
          formControlName="fcSearchField"
          placeholder="Find by code or description"
          class="sb-filter-field"
          [class.disabled]="data.isLoading"
          (keyup.enter)="filterResults(filter.value)"
        />
      </div>
      <div class="small-2 cell align-self-middle text-center">
        <button
          kendoButton
          [iconClass]="'fas fa-search'"
          [primary]="true"
          (click)="filterResults(filter.value)"
        ></button>
      </div>
    </div>
  </div>
  <!--Base Model List-->
  <div class="sb-selection-menu-content">
    <ul
      class="sb-options-list"
      *ngIf="data.viewState === TemplateView.Groupings"
    >
      <li *ngFor="let option of groupingKeys; index as i">
        <a (click)="showGroup(option)">{{ option.text }}</a>
      </li>
    </ul>
    <ul
      class="sb-options-list"
      *ngIf="data.viewState === TemplateView.Products"
    >
      <li>
        <a (click)="showGroupings()"
          ><span class="icon fas fa-long-arrow-left"></span>Back</a
        >
      </li>
      <li>
        <span class="sb-category-group">{{
          selectedKey ? selectedKey.text : 'All Base Models'
        }}</span>
      </li>
      <li *ngFor="let option of data.options | rankProducts; index as i">
        <sb-product-item
          [product]="option"
          (click)="onProductSelected(option)"
        ></sb-product-item>
      </li>
    </ul>
    <sb-callout *ngIf="data.isLoading" class="sb-loading-wrapper"
      ><span class="fas fa-spinner fa-spin icon"></span>
      <span class="loading-text">Loading Base Models...</span></sb-callout
    >
    <sb-callout
      *ngIf="
        !data.isLoading &&
        data.viewState === TemplateView.Products &&
        data.options?.length == 0
      "
      >No products found
    </sb-callout>
  </div>
</div>
