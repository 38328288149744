<div
  class="sb-container"
  *ngIf="{
    viewState: viewState$ | async
  } as solutionData"
  kendoTooltip
  kendoDialogContainer
>
  <div #spacer></div>
  <header class="sb-header" stickyThing>
    <div class="sb-header-layout grid-x">
      <div class="small-4 cell">
        <ul class="sb-tab-menu">
          <li>
            <a class="is-active">{{
              isModification ? 'Modification' : 'Repair'
            }}</a>
          </li>
        </ul>
      </div>
      <div class="small-8 cell align-self-middle text-right">
        <div class="sb-solution-builder-actions" *ngIf="isEditable">
          <kendo-buttongroup look="bare">
            <!--button
              kendoButton
              iconClass="fab fa-accessible-icon"
              title="Loaner Settings"
              (click)="showLoanerDialog()"
            ></!--button-->
            <button
              kendoButton
              iconClass="fas fa-search"
              title="Product Lookup"
              (click)="toggleProductLookup()"
            ></button>
          </kendo-buttongroup>
        </div>
      </div>
    </div>
  </header>

  <main class="sb-content">
    <section class="sb-bars">
      <div class="sb-actions-bar">
        <button
          kendoButton
          [look]="'outline'"
          [primary]="false"
          [iconClass]="'icon fas fa-trash'"
          (click)="discardSolution()"
        >
          Discard Solution
        </button>
        <button
          kendoButton
          [disabled]="!isAllowedToSave() || isSaving"
          [primary]="true"
          [iconClass]="'icon fas fa-save'"
          (click)="saveQuote()"
        >
          Save Solution
        </button>
      </div>
    </section>
    <div class="sb-content-overflow-wrapper">
      <!-- Page Header Section-->
      <header class="sb-content-header">
        <div class="grid-x  align-middle">
          <div class="small-12 cell">
            <ul class="sb-breadcrumbs">
              <li class="is-parent"><a>Solution Builder</a></li>
              <li class="is-divider"><span>/</span></li>
              <li class="is-parent">
                <span>Solutions</span>
              </li>
              <li class="is-divider"><span>/</span></li>
              <li class="is-parent">
                <span>Repairs & Modifications</span>
              </li>
              <li class="is-divider"><span>/</span></li>
              <li class="is-parent">
                <span class="">{{ workOrderId }}</span>
              </li>
            </ul>
            <h1 class="sb-content-heading">
              {{ clientName }}
              <small
                >{{ isModification ? 'Modification' : 'Repair' }} -
                {{ workOrderId }}</small
              >
            </h1>
          </div>
          <div class="small-12 cell text-right">
            <kendo-buttongroup
              [selection]="'single'"
              *ngIf="hasSelectedClientAsset()"
            >
              <button
                kendoButton
                [toggleable]="true"
                (click)="showSpareParts()"
                [primary]="
                  solutionData.viewState === TemplateViewState.Products
                "
              >
                Products
              </button>
              <button
                kendoButton
                [toggleable]="true"
                (click)="showLaborItems()"
                [primary]="
                  solutionData.viewState ===
                  TemplateViewState.RepairProductsAndLabor
                "
              >
                Labor
              </button>
            </kendo-buttongroup>
          </div>
        </div>
      </header>
    </div>
    <section class="sb-repair-solution-view" *ngIf="activeSolution">
      <div class="sb-flow-item-list no-hover">
        <div class="sb-flow-item-wrapper">
          <div class="sb-selected-client-asset">
            <h3 class="sb-solution-heading">
              Client Asset
              <span *ngIf="clientAsset?.serialNumber"
                >(NSM-SN: {{ clientAsset.serialNumber }})</span
              >
              <span *ngIf="clientAsset?.wheelChairSerialNumber"
                >(WSN: {{ clientAsset.wheelChairSerialNumber }})</span
              >
            </h3>
            <sb-selected-client-asset
              *ngIf="clientAsset"
              [asset]="clientAsset"
              (edit)="toggleClientAssetLookup()"
              (remove)="setClientAsset(null)"
            ></sb-selected-client-asset>

            <button
              kendoButton
              primary="true"
              (click)="toggleClientAssetLookup()"
              class="sb-set-asset-button"
              *ngIf="clientAsset == null"
            >
              Set Client Asset
            </button>
          </div>
          <div>
            <div *ngIf="activeSolution.hasProducts()">
              <h3 class="sb-solution-heading for-products">Products</h3>
              <sb-selected-product-item
                *ngFor="let product of activeSolution?.products"
                [product]="product"
                [showPricing]="true"
                [showMsrp]="false"
                [editable]="isEditable"
                (increment)="updateQuantity($event)"
                (decrement)="updateQuantity($event, false)"
                (remove)="removeProductFromSolution($event)"
              ></sb-selected-product-item>
            </div>
            <div *ngIf="activeSolution.laborItems?.length > 0">
              <h3 class="sb-solution-heading for-labor">Labor</h3>
              <div class="sb-selected-labor-items">
                <sb-selected-labor-item
                  *ngFor="let labor of activeSolution?.laborItems"
                  [labor]="labor"
                  [editable]="isEditable"
                  (remove)="removeLaborFromSolution($event)"
                ></sb-selected-labor-item>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <sb-empty-menu
      *ngIf="solutionData.viewState === TemplateViewState.Empty"
      [title]="''"
    ></sb-empty-menu>

    <!--Multi Flow Category + Product Selection View-->
    <sb-multi-flow-menu
      *ngIf="solutionData.viewState === TemplateViewState.Products"
      [solution]="activeSolution"
      [solutionType]="activeSolutionType"
      (closeMenu)="showDefaultView()"
      (removeSelection)="removeProductFromSolution($event)"
      (completeSelection)="addProductToSolution($event)"
    ></sb-multi-flow-menu>

    <!--Repair Product/Labor Menu-->
    <sb-add-product-and-labor-step
      *ngIf="
        solutionData.viewState === TemplateViewState.RepairProductsAndLabor
      "
      (laborComplete)="addLaborItemToSolution($event)"
      (previousStep)="previousStep()"
    >
    </sb-add-product-and-labor-step>

    <sb-client-asset-dialog
      *ngIf="showClientAssetDialog"
      [workOrderId]="workOrderId"
      [clientAsset]="clientAsset"
      (selected)="setClientAsset($event)"
      (close)="toggleClientAssetLookup(false)"
    ></sb-client-asset-dialog>
  </main>
  <!--Product Lookup Dialog-->
  <sb-product-lookup-dialog
    *ngIf="showProductLookupDialog"
    (close)="toggleProductLookup(false)"
  ></sb-product-lookup-dialog>
</div>
