import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output,
  TemplateRef
} from '@angular/core';

import {
  DialogCloseResult,
  DialogRef,
  DialogService
} from '@progress/kendo-angular-dialog';
import { Product } from 'app/domain';
import { SbCustomProductDialogComponent } from 'app/modules/solution-builder/dialogs/custom-product-dialog/custom-product-dialog.component';
import { SbNoteFormDialogComponent } from 'app/modules/solution-builder/dialogs/product-notes-dialog/product-notes-dialog.component';
import { SbSemiCustomProductDialogComponent } from 'app/modules/solution-builder/dialogs/semi-custom-product-dialog/semi-custom-product-dialog.component';

@Component({
  selector: 'sb-selected-product-item',
  templateUrl: './selected-product-item.component.html',
  styleUrls: ['./selected-product-item.component.scss']
})
export class SbSelectedProductItemComponent implements OnInit {
  @HostBinding('class') class: string = 'sb-selected-product-item';
  @Input()
  product: Product;

  @Input()
  showPricing: boolean;

  @Input()
  showMsrp: boolean;

  // Whether the item overall is editable.  This typically comes from
  // whether the overall Quote is editable or not.
  @Input()
  editable: boolean = true;

  @Input()
  supportsNotes: boolean = true;

  // If the item should have a button that emits an "onUpdate()"
  @Input()
  updateable: boolean;

  @Output()
  remove: EventEmitter<Product> = new EventEmitter<Product>();

  @Output()
  increment: EventEmitter<Product> = new EventEmitter<Product>();

  @Output()
  decrement: EventEmitter<Product> = new EventEmitter<Product>();

  @Output()
  update: EventEmitter<Product> = new EventEmitter<Product>();

  constructor(private dialogService: DialogService) {}

  ngOnInit(): void {}

  hasNotes(): boolean {
    if (this.product && this.product.hasNotes()) {
      return true;
    } else {
      return false;
    }
  }

  updateItem(): void {
    this.update.emit(this.product);
  }

  showConfirmRemoveProductDialog(
    product: Product,
    template: TemplateRef<any>
  ): void {
    this.product = product;
    const dialog: DialogRef = this.dialogService.open({
      title: 'Confirm',
      content: template,
      actions: [
        { text: 'No', value: false },
        { text: 'Yes', value: true, primary: true }
      ],
      width: 450,
      height: 300,
      minWidth: 250
    });

    dialog.result.subscribe(result => {
      if (result instanceof DialogCloseResult) {
      } else {
        if (result.text === 'Yes') {
          this.removeProduct();
        } else {
          // User cancelled
        }
      }
    });
  }

  removeProduct(): void {
    this.remove.emit(this.product);
  }

  showNoteDialog(): void {
    const dialog: DialogRef = this.dialogService.open({
      content: SbNoteFormDialogComponent,
      width: 450,
      maxHeight: '75%',
      minWidth: 250,
      title: 'Update Note'
    });

    dialog.content.instance.product = this.product;
  }

  incrementQuantity(): void {
    this.increment.emit(this.product);
  }

  decrementQuantity(): void {
    this.decrement.emit(this.product);
  }

  showCustomizationDialog(product: Product): void {
    let dialog = null;
    if (product.isSemiCustomProduct()) {
      dialog = this.dialogService.open({
        content: SbSemiCustomProductDialogComponent,
        title: 'Semi-Custom Product',
        width: 450
      });
    } else {
      dialog = this.dialogService.open({
        content: SbCustomProductDialogComponent,
        title: 'Customize Product',
        width: 450
      });
    }

    dialog.content.instance.product = product;
  }
}

enum ViewState {
  Product,
  Notes
}
