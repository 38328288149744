<div class="sb-search-for-asset" [formGroup]="fgSearch">
  <div class="sb-product-lookup-form">
    <p>
      <sb-dropdown-field
        label="Solution Type"
        [options]="solutionTypeOptions"
        [control]="fcSolutionType"
        [placeholder]="'Pick Solution Type'"
        [required]="true"
      ></sb-dropdown-field>
    </p>
    <p>
      <sb-text-field
        label="Part Number or Description"
        [control]="fcFilter"
        [placeholder]="'Product description or number contains...'"
        [required]="true"
      ></sb-text-field>
    </p>
    <button kendoButton primary="true" (click)="searchProducts()">
      Search
    </button>
  </div>
  <div class="sb-asset-list-wrapper">
    <div>
      <div *ngIf="isLoading" class="sb-section-spinner">
        <span class="icon fad fa-circle-notch fa-spin"></span>
      </div>

      <sb-callout *ngIf="!isLoading && showNoProductsFound">
        No Products found.
      </sb-callout>
      <ul class="sb-options-list" *ngIf="!isLoading">
        <li *ngFor="let option of displayProducts | rankProducts; index as i">
          <sb-product-item
            [product]="option"
            (click)="selectProduct(option)"
          ></sb-product-item>
        </li>
      </ul>
      <div class="sb-load-more-wrapper">
        <a
          class="sb-load-more"
          *ngIf="filteredProducts.length > 0"
          (click)="loadMore()"
          >Load More</a
        >
      </div>
    </div>
  </div>
</div>
