import { Component, OnInit, Input, HostBinding } from '@angular/core';

@Component({
  selector: 'sb-square-check-indicator',
  templateUrl: './square-check-indicator.component.html',
  styleUrls: ['./square-check-indicator.component.scss']
})
export class SbSquareCheckIndicatorComponent implements OnInit {
  @HostBinding('class') class = 'sb-indicator';

  @Input()
  enabled: boolean;

  @Input()
  enabledTooltip: string;

  @Input()
  disabledTooltip: string;
  constructor() {}

  ngOnInit(): void {}
}
