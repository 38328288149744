import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output,
  TemplateRef
} from '@angular/core';
import {
  DialogCloseResult,
  DialogRef,
  DialogService
} from '@progress/kendo-angular-dialog';
import { SolutionLaborItem } from 'app/domain';

@Component({
  selector: 'sb-selected-labor-item',
  templateUrl: './selected-labor-item.component.html',
  styleUrls: ['./selected-labor-item.component.scss']
})
export class SbSelectedLaborItemComponent implements OnInit {
  @HostBinding('class') class: string = 'sb-selected-labor-item';
  @Input()
  labor: SolutionLaborItem;

  @Input()
  editable: boolean;

  @Output()
  remove: EventEmitter<SolutionLaborItem> = new EventEmitter<
    SolutionLaborItem
  >();

  constructor() {}

  ngOnInit(): void {}

  removeLabor(): void {
    this.remove.emit(this.labor);
  }
}
