import { Component, OnInit, AfterViewInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormControl,
  Validators
} from '@angular/forms';
import { DialogContentBase, DialogRef } from '@progress/kendo-angular-dialog';
import {
  ConfiguredSolution,
  FieldOption,
  Product,
  Template,
  TemplateLoadResults
} from 'app/domain';
import { SolutionBuilderLoggerService } from 'app/domain/services/solution-builder-logger.service';
import { ToastrService } from 'ngx-toastr';
import * as _ from 'lodash';
import { catchError, finalize, map } from 'rxjs/operators';
import { TemplateService } from 'app/services/template.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { SolutionBuilderClient } from '../../services/solution-builder-client.service';

@Component({
  selector: 'sb-load-template-dialog-component',
  templateUrl: './load-template-dialog.component.html',
  styleUrls: ['./load-template-dialog.component.scss']
})
export class SbLoadTemplateDialogComponent extends DialogContentBase
  implements OnInit, AfterViewInit {
  /**
   * Form controls for loading form a Template
   */

  fgLoadTemplate: FormGroup;
  fcAvailableTemplates: FormControl = new FormControl('', Validators.required);

  isLoading: boolean;

  templates$: Observable<FieldOption[]>;

  constructor(
    private fb: FormBuilder,
    private templateService: TemplateService,
    private solutionBuilderClient: SolutionBuilderClient,
    private logger: SolutionBuilderLoggerService,
    private toastr: ToastrService,
    dialog: DialogRef
  ) {
    super(dialog);
  }

  ngOnInit(): void {
    // Load Template Form Group
    this.fgLoadTemplate = this.fb.group({
      fcAvailabileTemplates: this.fcAvailableTemplates
    });

    // The available templates in the system
    this.templates$ = this.templateService.getTemplates().pipe(
      map((templates: Template[]) => {
        return _.sortBy(
          templates.map(t => new FieldOption(t.name, t.id)),
          (t: FieldOption) => t.text.toLowerCase()
        );
      })
    );
  }

  ngAfterViewInit(): void {}

  closeDialog(): void {
    this.dialog.close();
  }

  onConfirmAction(): void {
    if (this.fgLoadTemplate.valid) {
      this.loadSolutionFromTemplate();
    } else {
      this.toastr.warning(
        'The form is invalid.  Please fill out all fields before saving.'
      );
    }
  }

  private loadSolutionFromTemplate(): void {
    this.isLoading = true;
    const templateId = this.fcAvailableTemplates.value;

    this.templateService
      .loadTemplate(templateId)
      .pipe(
        catchError((e: HttpErrorResponse) => {
          if (e.status === 404) {
            return of(null);
          } else {
            return throwError(e);
          }
        }),
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe(
        (template: TemplateLoadResults) => {
          let products = template.products;
          // Assume the result didn't maintain the order.
          // Sort the resulting Products found by the order
          // of CE items.
          products = _.sortBy(products, (product: Product) => {
            return _.findIndex(
              template.products,
              y => product.productNumber === y.productNumber
            );
          });

          if (template.solutionType) {
            this.solutionBuilderClient.setActiveSolutionType(
              template.solutionType
            );
          } else {
            this.logger.debug('No Solution found on Template.');
          }

          if (template.solutions && template.solutions.length > 0) {
            const solutions: ConfiguredSolution[] = template.getAsConfiguredSolutions();
            this.solutionBuilderClient.addSolutions(solutions);
          }

          this.closeDialog();
        },
        () => {
          this.logger.error(
            'There was an issue loading the Solution from a Template.  Please check the console.'
          );
        }
      );
  }
}
