import {
  Component,
  OnInit,
  OnDestroy,
  AfterViewInit,
  ViewChild,
  ElementRef
} from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators
} from '@angular/forms';
import { untilDestroyed } from 'app/core';
import {
  FieldOption,
  Product,
  SolutionLaborItem,
  ConfiguredSolution,
  DataStoreEventType,
  SolutionResolvedModel,
  OrderType,
  SolutionType,
  SolutionTypeFlowType,
  MIN_SOLUTION_THRESHOLD,
  IExplanationModel,
  IProductSelectedEvent
} from 'app/domain';

import { SolutionBuilderClient } from './services/solution-builder-client.service';
import { Observable, BehaviorSubject, of } from 'rxjs';
import { finalize, map, debounceTime, catchError } from 'rxjs/operators';
import { fadeUpAndIn } from 'app/domain/animations/fade.up.in';
import { ActivatedRoute, Data, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import {
  DialogService,
  DialogRef,
  DialogCloseResult
} from '@progress/kendo-angular-dialog';
import { SolutionBuilderLoggerService } from 'app/domain/services/solution-builder-logger.service';
import { HttpErrorResponse } from '@angular/common/http';
import * as _ from 'lodash';
import { SolutionBuilderOptionsDataStore } from './services/solution-options-data-store.service';
import { ProductsService } from 'app/services/products.service';
import { SbLoadTemplateDialogComponent } from './dialogs/load-template-dialog/load-template-dialog.component';
import { SbSaveTemplateDialogComponent } from './dialogs/save-template-dialog/save-template-dialog.component';
import { SbLoanerDialogComponent } from '../common/loaner-dialog/loaner-dialog.component';
import { SbNonProfileBaseExplanationComponent } from 'app/shared/ui-elements/non-profile-base-explanation/non-profile-base-explanation.component';

@Component({
  selector: 'sb-solution-builder',
  templateUrl: './solution-builder.component.html',
  styleUrls: ['./solution-builder.component.scss'],
  animations: [fadeUpAndIn]
})
export class ChairBuilderComponent implements OnInit, AfterViewInit, OnDestroy {
  shouldDisableActions$: BehaviorSubject<boolean> = new BehaviorSubject<
    boolean
  >(false);

  /**
   * Holds the state of requests being made to save the template.
   */

  isSavingTemplate$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );

  /**
   * Holds the state of requests being made to load the template.
   */

  isLoadingTemplate$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );

  /**
   * Holds the state of the requests being made to load ER results.
   */

  isRequestingExpectedReimbursement$: BehaviorSubject<
    boolean
  > = new BehaviorSubject<boolean>(false);

  /**
   * Holds the state of visibility into pricing information of selected products
   */

  shouldShowPricingInformation$: BehaviorSubject<boolean> = new BehaviorSubject<
    boolean
  >(false);

  /**
   * Used to pass the types of solutions that can be configured to the dropdown.
   */
  solutionTypes: FieldOption[];

  /**
   * Whether the user can edit the Solution.
   */
  isEditable: boolean;

  /**
   * Stores the threshold for what's expected for this solution type.
   */

  expectedThreshold$: BehaviorSubject<number> = new BehaviorSubject<number>(
    null
  );

  /**
   * Stores the actual threshold for what's in the solution.
   */

  actualThreshold$: BehaviorSubject<number> = new BehaviorSubject<number>(null);

  /**
   * Helps the template section off which menus to show and for the component to react properly.
   */
  viewState$: BehaviorSubject<TemplateViewState> = new BehaviorSubject<
    TemplateViewState
  >(TemplateViewState.Empty);

  // Make the enum available in the template.
  TemplateViewState = TemplateViewState;

  /**
   * Represents the parent FormGroup.
   */
  fgChairBuilder: FormGroup;

  /**
   * FormControl that holds the currently selected solution type being configured.
   */
  fcSolutionType: FormControl = new FormControl('', Validators.required);

  workOrderId: string;

  /**
   * Metric variables.
   */
  totalUnitCost: number = 0;
  totalExpectedReimbursementCost: number = 0;

  showReturnToBaseModelMenuConfirmDialog: boolean;
  showSaveTemplateDialog: boolean;
  showProductLookupDialog: boolean;

  fgNotes: FormGroup;
  fcProduct: FormControl = new FormControl(null, Validators.required);
  fcNote: FormControl = new FormControl('', Validators.maxLength(1000));

  /**
   * The timestamp the Solution was last saved locally
   */
  lastSavedLocallyDate: Date;

  /**
   * The name of the Client
   */
  clientName: string;

  configuredSolutions: ConfiguredSolution[] = [];

  activeSolution: ConfiguredSolution;
  activeSolutionType: SolutionType;
  seatingPositioningSolutionType: SolutionType;

  /**
   * The element that contains the solution type, base, products, labor selections
   */
  @ViewChild('solutionContentContainer')
  private solutionContentContainer: ElementRef;

  constructor(
    private fb: FormBuilder,
    private solutionBuilderClient: SolutionBuilderClient,
    private toastrService: ToastrService,
    private activatedRoute: ActivatedRoute,
    private optionsDataStore: SolutionBuilderOptionsDataStore,
    private productService: ProductsService,
    private logger: SolutionBuilderLoggerService,
    private dialogService: DialogService,
    private router: Router
  ) {}

  ngOnInit(): void {
    // Base Model Product Notes
    this.fgNotes = this.fb.group({
      fcNote: this.fcNote,
      fcProduct: this.fcProduct
    });

    // Chair Build Form Group
    this.fgChairBuilder = this.fb.group({
      fcSolutionType: this.fcSolutionType
    });

    // The types of solution we support building
    this.solutionTypes = this.optionsDataStore
      .getSolutionTypes()
      .filter(st => st.flow != SolutionTypeFlowType.Repair)
      .map(st => new FieldOption(st.name, st.id));

    this.solutionBuilderClient
      .events()
      .subscribe((event: DataStoreEventType) => {
        switch (event) {
          case DataStoreEventType.SolutionTypeChange: {
            this.handleSolutionTypeChange();
            break;
          }
          case DataStoreEventType.ProductsChange: {
            this.handleProductsChange();
            break;
          }

          case DataStoreEventType.VendorImportChange: {
            this.handleVendorImportChange();
            break;
          }
          case DataStoreEventType.SolutionsChanged: {
            this.handleSolutionsChanged();
            break;
          }
          case DataStoreEventType.SolutionActivated: {
            this.handleSolutionActivated();
            break;
          }

          default: {
            this.logger.warn('DataStore event type not recognized.');
          }
        }

        // Update whether the solution type is editable
        this.updateSolutionTypeControlState();

        // Check and update base model threshold
        this.checkAndUpdateSolutionThreshold();

        // Update Margin
        this.calculateSolutionMargin();

        // Update the menu
        this.handleMenuDisplay();

        // Set last saved locally date
        this.lastSavedLocallyDate = new Date();
      });

    // Allow any events to completely fire
    // before actually updating ER/Totals
    this.solutionBuilderClient
      .events()
      .pipe(debounceTime(600))
      .subscribe(() => {
        this.updateExpectedReimbursement();
      });

    //  Store solution type id in the data store
    this.fcSolutionType.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe((solutionTypeId: number) => {
        const solutionType = this.solutionBuilderClient.getSolutionType(
          solutionTypeId
        );
        this.solutionBuilderClient.setActiveSolutionType(solutionType);
      });

    // Anything that is not the default view should show a menu
    this.viewState$
      .pipe(untilDestroyed(this))
      .subscribe((state: TemplateViewState) => {
        if (state === TemplateViewState.None) {
          this.hideSideMenu();
        } else {
          this.showSideMenu();
        }
      });

    this.activatedRoute.data.subscribe((resp: Data) => {
      const solution: SolutionResolvedModel = resp.solution;
      if (solution) {
        // Set meta data
        this.isEditable = solution.isEditable;
        this.clientName = solution.clientName;
        this.workOrderId = solution.workOrderId;
        this.solutionBuilderClient.setWorkOrderId(solution.workOrderId);
        this.solutionBuilderClient.setNonProfileBaseExplanation(
          solution.nonProfileBaseExplanation
        );

        let solutionTypeId = null;
        if (solution.type) {
          solutionTypeId = solution.type.id;
          this.solutionBuilderClient.setActiveSolutionType(solution.type);
        }

        this.fcSolutionType.setValue(solutionTypeId);

        if (solution.hasSolutions()) {
          const solutions: ConfiguredSolution[] = solution.solutions;

          this.solutionBuilderClient.setSolutions(solutions);

          // If there's a parts only bucket, it should be active first
          let selectedActiveSolution = solutions.find(s => s.isPartsOnly);

          if (!selectedActiveSolution) {
            selectedActiveSolution = solutions[0];
          }

          this.solutionBuilderClient.setActiveSolution(selectedActiveSolution);

          // Has discontinued products
          if (solutions.filter(s => s.hasDiscontinuedProducts()).length > 0) {
            this.toastrService.warning(
              'This Quote has discontinued Products.  Please remove or replace them in order to save.',
              'Discontinued Products',
              { closeButton: true, disableTimeOut: true }
            );
          }
        }
        // this.solutionBuilderClient.setLoaner(solution.loanerProduct);
        this.solutionBuilderClient.setLaborItems(solution.labor);
      } else {
        this.logger.debug(
          'No solution was resolved when navigating to Solution Builder.'
        );
      }
    });
  }

  ngAfterViewInit(): void {
    this.seatingPositioningSolutionType = this.solutionBuilderClient.getSeatingAndPositioningSolutionType();
    console.log(this.getKnownProducts());
  }

  setActiveSolution(solution: ConfiguredSolution): void {
    this.solutionBuilderClient.setActiveSolution(solution);
  }

  ngOnDestroy(): void {
    this.hideSideMenu();
  }

  quoteHasLoaner(): boolean {
    return false; // this.solutionBuilderClient.quoteHasLoaner();
  }
  hidePricingInformation(): void {
    this.shouldShowPricingInformation$.next(false);
  }
  togglePricingInformation(): void {
    this.shouldShowPricingInformation$.next(
      !this.shouldShowPricingInformation$.value
    );
  }

  isSolutionSingleWorkflow(): boolean {
    const solutionType = this.getSolutionType();
    if (solutionType) {
      return solutionType.flow === SolutionTypeFlowType.Single;
    }
    return false;
  }

  isSolutionMultiWorkflow(): boolean {
    const solutionType = this.getSolutionType();
    return solutionType && solutionType.flow === SolutionTypeFlowType.Multi;
  }

  isSolutionRepairWorkflow(): boolean {
    const value = this.fcSolutionType.value;
    if (value) {
      return this.solutionBuilderClient.isRepairSolutionType(value);
    }
    return false;
  }

  getSolutionTypeName(): string {
    const type = this.getSolutionType();

    if (!type) {
      return null;
    }
    if (type.isSingleFlowSolutionType()) {
      return `${type.name} Aftermarket`;
    } else {
      return `${type.name}`;
    }
  }

  getSolutionType(): SolutionType {
    return this.activeSolutionType;
  }

  getSelectedAftermaketProducts(): Product[] {
    const partsOnly = this.solutionBuilderClient.getPartsOnlySolution();
    if (partsOnly) {
      return partsOnly.products;
    }
    return [];
  }
  getNonProfileBaseExplanation(): IExplanationModel {
    return this.solutionBuilderClient.getNonProfileBaseExplanation();
  }

  updateExpectedReimbursement(): void {
    this.isRequestingExpectedReimbursement$.next(true);
    this.solutionBuilderClient
      .calculateExpectedReimbursement()
      .pipe(
        catchError(() => of(0)),
        finalize(() => {
          this.calculateTotals();
          this.isRequestingExpectedReimbursement$.next(false);
        })
      )
      .subscribe(
        (total: number) => {
          this.totalExpectedReimbursementCost = total;
          this.calculateSolutionMargin();
        },
        (error: HttpErrorResponse) => {
          debugger;
          this.toastrService.warning(
            'Unable to update Expected Reimbursement.'
          );
          this.logger.error(
            'Request to Expected Reimbursement ran into an issue.',
            error
          );
        }
      );
  }

  getKnownProducts(): Product[] {
    const solutions = this.solutionBuilderClient.getSolutions();
    let products: Product[] = [];

    if (!solutions) {
      return products;
    }

    solutions.forEach((solution: ConfiguredSolution) => {
      if (solution.hasBase()) {
        products.push(solution.base);
      }

      if (solution.hasProducts()) {
        products = [...products, ...solution.products];
      }
    });
    return products;
  }
  /**
   * Callback by the UI when a Product has been selected.
   */
  addProductToSolution(event: IProductSelectedEvent): void {
    const product = event.product;

    if (product.isBaseProduct) {
      const solution = new ConfiguredSolution(product, null, null);
      this.solutionBuilderClient.addSolution(solution);
    } else {
      this.solutionBuilderClient.addProduct(product, event.isAftermarket);
    }
  }

  /**
   * Callback by the UI when a Product has been removed.
   */
  removeProductFromSolution(event: IProductSelectedEvent): void {
    this.solutionBuilderClient.removeProduct(
      event.product,
      event.isAftermarket
    );
  }

  /**
   * Discard the Solution and start from scratch.
   */
  discardSolution(): void {
    const content = `
   Are you sure you'd like to discard this Solution and start over?
    `;
    this.showConfirmDialog(content, () => {
      this.solutionBuilderClient.reset();
      this.fgChairBuilder.reset();
      this.hidePricingInformation();
    });
  }

  /**
   * Save a quote back to CE.
   */
  saveQuote(): void {
    // Make sure the user is allowed to save.
    if (!this.isAllowedToSave()) {
      return;
    }

    const issues = this.solutionBuilderClient.validateSolution();

    if (issues.length === 0) {
      this.shouldDisableActions$.next(true);
      this.toastrService.info('One moment...', 'Saving Quote', {
        disableTimeOut: true
      });

      this.solutionBuilderClient
        .saveSolution()
        .pipe(finalize(() => this.shouldDisableActions$.next(false)))
        .subscribe(
          () => {
            this.toastrService.clear();
            this.toastrService.success('Solution has been saved.');
            this.solutionBuilderClient.clearLocalData();
            this.router.navigateByUrl(`/builder/${this.workOrderId}/summary`);
          },
          () => {
            this.toastrService.clear();
            this.toastrService.error('There was an issue saving the solution.');
          }
        );
    } else {
      this.showConfirmDialog(issues.join(' '), () => {});
    }
  }

  solutionHasProducts(): boolean {
    return this.activeSolution && this.activeSolution.hasProducts();
  }

  solutionHasBaseModel(): boolean {
    return this.activeSolution && this.activeSolution.hasBase();
  }

  solutionHasLaborItems(): boolean {
    return this.activeSolution && this.activeSolution.hasLaborItems();
  }

  setBaseModel(product: Product): void {
    if (this.activeSolution) {
      this.activeSolution.base = product;
    } else {
      const solution = new ConfiguredSolution(product);
      solution.isChairOnly = true;
      this.solutionBuilderClient.addSolution(solution);
    }
  }
  removeBaseModel(): void {
    // Removing the Base model means we are going to remove
    // it as a solution including its products
    this.solutionBuilderClient.removeSolution(this.activeSolution);
    this.showBaseModelView();
  }

  toggleProductLookup(show: boolean = true): void {
    this.showProductLookupDialog = show;
  }
  showConfirmRemoveLaborDialog(laborItem: SolutionLaborItem): void {
    const content = `
    Are you sure you want to remove
    <strong>${laborItem.name}</strong> from the solution?
    `;
    this.showConfirmDialog(content, () => {
      this.solutionBuilderClient.removeLaborItem(laborItem);
      this.calculateTotals();
    });
  }

  showLoanerDialog(): void {
    this.dialogService.open({
      content: SbLoanerDialogComponent,
      width: 450,
      height: 300,
      minWidth: 250,
      title: 'Loaner Settings'
    });
  }

  openLoadFromTemplateDialog(): void {
    this.dialogService.open({
      content: SbLoadTemplateDialogComponent,
      width: 450,
      height: 225,
      minWidth: 250,
      title: 'Load From Template'
    });
  }

  openSaveTemplateDialog(): void {
    this.dialogService.open({
      content: SbSaveTemplateDialogComponent,
      width: 450,
      height: 300,
      minWidth: 250,
      title: 'Save Template'
    });
  }

  showBaseModelView(): void {
    if (this.isSolutionSingleWorkflow()) {
      if (this.fcSolutionType.value) {
        this.viewState$.next(TemplateViewState.BaseModel);
      } else {
        this.logger.info(
          'Selecting a Base Model requires first selecting the Solution Type'
        );
      }
    }
  }
  showProductsView(): void {
    const solutionType = this.solutionBuilderClient.getActiveSolutionType();
    this.viewState$.next(TemplateViewState.Products);
  }

  showMultiFlowView(): void {
    this.viewState$.next(TemplateViewState.Multi);
  }

  showDefaultView(): void {
    this.viewState$.next(TemplateViewState.None);
  }
  showEmptyView(): void {
    this.viewState$.next(TemplateViewState.Empty);
  }
  showImportView(): void {
    this.viewState$.next(TemplateViewState.Import);
  }

  showSideMenu(): void {
    document.body.classList.add('sb-shift-left', 'sb-no-scroll');
  }
  hideSideMenu(): void {
    document.body.classList.remove('sb-shift-left', 'sb-no-scroll');
  }

  shouldShowBaseModelSection(): boolean {
    return this.isSolutionSingleWorkflow() || this.isSolutionRepairWorkflow();
  }

  canSaveAsTemplate(): boolean {
    return this.isSolutionSingleWorkflow() || this.isSolutionMultiWorkflow();
  }

  updateQuantity(product: Product, shouldIncrement: boolean = true): void {
    if (shouldIncrement) {
      this.solutionBuilderClient.incrementProductQuantity(product);
    } else {
      this.solutionBuilderClient.decrementProductQuantity(product);
    }
  }

  /**
   * Determines if the user can
   * save the Solution based on what we know
   * are the minimum requirements.
   */
  isAllowedToSave(): boolean {
    const solutions: ConfiguredSolution[] = this.solutionBuilderClient.getSolutions();

    if (this.isSolutionSingleWorkflow()) {
      const solutionWithBase = solutions.find(s => s.hasBase());
      if (solutionWithBase) {
        return true;
      }
    } else if (this.isSolutionMultiWorkflow()) {
      const solutionWithBase = solutions.find(s => s.hasBase());
      const solutionWithProducts = solutions.find(s => s.hasProducts());
      if (solutionWithBase || solutionWithProducts) {
        return true;
      }
    } else if (this.isSolutionRepairWorkflow()) {
      if (this.solutionHasProducts() || this.solutionHasLaborItems()) {
        return true;
      }
    }

    return false;
  }

  shouldShowProductExplanation(): boolean {
    return this.solutionBuilderClient.quoteRequiresProductExplanation();
  }

  /**
   * Show the Global Filters dialog for Solution Builder.
   */
  showGlobalFiltersDialog(): void {}

  shouldShowSingleBaseModelMenu(): boolean {
    const viewState = this.viewState$.value;
    const solutionType = this.solutionBuilderClient.getSolutionType(
      this.fcSolutionType.value
    );
    return (
      viewState === TemplateViewState.BaseModel &&
      solutionType.flow === SolutionTypeFlowType.Single
    );
  }

  shouldShowMultiBaseModelMenu(): boolean {
    const viewState = this.viewState$.value;

    if (viewState === TemplateViewState.Multi) {
      return true;
    }

    return false;
  }

  showSeatingAndPositioningMenu(): void {
    this.viewState$.next(TemplateViewState.SeatingAndPositioning);
  }

  /**
   * Handles making sure that we're displaying the right menu
   * based on the state.
   */
  handleMenuDisplay(): void {
    const hasBaseModel = this.solutionHasBaseModel();
    const solutionType: SolutionType = this.activeSolutionType;

    if (!solutionType) {
      this.showDefaultView();
      return;
    }

    const solutionCount = this.solutionBuilderClient.getSolutions().length;

    switch (solutionType.flow) {
      case SolutionTypeFlowType.Single: {
        if (!hasBaseModel) {
          if (solutionCount == 0) {
            this.showBaseModelView();
          } else {
            this.showEmptyView();
          }
        } else {
          this.showMultiFlowView();
        }
        break;
      }
      case SolutionTypeFlowType.Multi: {
        this.showMultiFlowView();
        break;
      }

      default: {
        this.showProductsView();
      }
    }
  }

  private scrollToContentBottom(): void {
    try {
      this.solutionContentContainer.nativeElement.scrollTop = this.solutionContentContainer.nativeElement.scrollHeight;
    } catch (err) {}
  }

  private calculateTotals(): void {
    const solutions = this.solutionBuilderClient.getSolutions();

    this.totalUnitCost = 0;
    solutions.forEach((solution: ConfiguredSolution) => {
      const base = solution.base;
      if (base) {
        this.totalUnitCost += base.costPricePerUnit;
      }

      const products = solution.products;
      if (products) {
        products.forEach((product: Product) => {
          this.totalUnitCost +=
            product.costPricePerUnit * product.defaultQuantity;
        });
      }
    });
  }

  /**
   * Short hand method to proc a simple dialog so the HTML and Component
   * don't become flooded with simplistic "Confirmation" dialogs.
   * @param content - Displayed in the contetn area of the Dialog
   * @param successCallback - The function to call on an affirmative answer
   */
  private showConfirmDialog(
    content: string,
    successCallback: (n: any) => any
  ): void {
    const dialog: DialogRef = this.dialogService.open({
      title: 'Confirm',
      content: content,
      actions: [
        { text: 'No', value: false },
        { text: 'Yes', value: true, primary: true }
      ],
      width: 450,
      minWidth: 250
    });

    dialog.result.subscribe(result => {
      if (result instanceof DialogCloseResult) {
        // User closed the dialog
      } else {
        if (result.text === 'Yes') {
          successCallback.call(this);
        } else {
          // User clicked No
        }
      }
    });
  }

  private calculateSolutionMargin(): void {
    const threshold: number = this.solutionBuilderClient.getMarginThresholdValue();

    // We expect the threshold from the API to be a number between 0 and 100
    // Angular expects the percentage to be a number between 0 and 1
    this.expectedThreshold$.next(threshold / 100);

    const margin = this.solutionBuilderClient.calculateSolutionMargin();
    this.actualThreshold$.next(margin);
  }

  private updateSolutionTypeControlState(): void {
    const hasBase = this.solutionHasBaseModel();
    const hasProducts = this.solutionHasProducts();

    this.fcSolutionType.enable({ emitEvent: false });

    if (this.isSolutionSingleWorkflow()) {
      if (hasBase) {
        this.fcSolutionType.disable({ emitEvent: false });
      }
    } else if (this.isSolutionMultiWorkflow()) {
      if (hasProducts) {
        this.fcSolutionType.disable({ emitEvent: false });
      }
    } else {
      // Do nothing
    }
  }

  /**
   * In response to a product change event from
   * the data store
   */
  private handleProductsChange(): void {
    this.scrollToContentBottom();
  }

  /**
   * In response to a base model change event from
   * the data store
   */
  private checkAndUpdateSolutionThreshold(): void {
    if (!this.activeSolution) {
      return;
    }

    const base: Product = this.activeSolution.base;

    if (base && base.itemGroup) {
      this.productService
        .getProductThreshold(base.itemGroup)
        .pipe(map(r => r.marginThreshold))
        .subscribe(
          (threshold: number) => {
            if (this.activeSolution) {
              this.activeSolution.marginThreshold = threshold;
            }
          },
          () => {
            this.logger.error(
              `Request to get margin threshold for selected base's itemGroup [${base.itemGroup}] failed.`
            );
          }
        );
    } else {
      // Default back to 40
      this.activeSolution.marginThreshold = MIN_SOLUTION_THRESHOLD;
    }
  }

  /**
   * In response to a solution type change event from
   * the data store
   */
  private handleSolutionTypeChange(): void {
    this.activeSolutionType = this.solutionBuilderClient.getActiveSolutionType();
    this.solutionBuilderClient.removePartsOnlySolution();
    if (this.activeSolutionType && this.isSolutionMultiWorkflow()) {
      this.solutionBuilderClient.createPartsOnlySolution();
    }
  }

  private handleVendorImportChange(): void {
    this.fcSolutionType.setValue(this.activeSolutionType.id);
  }
  private handleSolutionsChanged(): void {
    this.configuredSolutions = this.solutionBuilderClient.getSolutions();
  }

  private handleSolutionActivated(): void {
    this.activeSolution = this.solutionBuilderClient.getActiveSolution();
  }
}

enum TemplateViewState {
  None = 'None',
  Empty = 'Empty',
  BaseModel = 'BaseModel',
  Multi = 'Multi',
  Products = 'Products',
  Import = 'Import',
  SeatingAndPositioning = 'Seating And Positioning'
}
