import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpResponse,
  HttpResponseBase
} from '@angular/common/http';
import { Observable, forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'environments/environment';
import {
  Template,
  QuoteLineItem,
  TemplateLoadResults,
  Product,
  Solution,
  SolutionType,
  ITemplateLoadResults,
  ISolutionGrouping,
  SolutionGrouping
} from 'app/domain';

@Injectable({ providedIn: 'root' })
export class TemplateService {
  constructor(private http: HttpClient) {}

  getTemplates(): Observable<Template[]> {
    return this.http.get(`${environment.apiTemplates}`).pipe(
      map((result: Template[]) => {
        return this.templateMapper(result);
      })
    );
  }

  getTemplate(id: number): Observable<Template> {
    return this.http.get(`${environment.apiTemplates}/${id}`).pipe(
      map((result: Template) => {
        return this.templateMapper([result])[0];
      })
    );
  }

  loadTemplate(id: number): Observable<TemplateLoadResults> {
    return this.http
      .get<ITemplateLoadResults>(`${environment.apiTemplates}/${id}/load`)
      .pipe(
        map((response: ITemplateLoadResults) => {
          const templateResults = new TemplateLoadResults();
          if (response) {
            templateResults.name = response.name;

            if (response.template) {
              templateResults.templateId = response.template.id;
              templateResults.template = Object.assign(
                new Template(),
                response.template
              );
            }

            if (response.lineItems) {
              templateResults.lineItems = response.lineItems.map(l =>
                Object.assign(new QuoteLineItem(), l)
              );
            }
            if (response.products) {
              templateResults.products = response.products.map(p =>
                Object.assign(new Product(), p)
              );
            }
            if (response.missingItems) {
              templateResults.missingItems = response.missingItems.map(l =>
                Object.assign(new QuoteLineItem(), l)
              );
            }
            if (response.solutionType) {
              templateResults.solutionType = Object.assign(
                new SolutionType(),
                response.solutionType
              );
            }

            if (response.solutions) {
              templateResults.solutions = response.solutions.map(
                (grouping: ISolutionGrouping) => {
                  const model = new SolutionGrouping();
                  model.base = Object.assign(new Product(), grouping.base);
                  model.solutionType = Object.assign(
                    new SolutionType(),
                    grouping.solutionType
                  );
                  model.products = grouping.products.map(p =>
                    Object.assign(new Product(), p)
                  );
                  return model;
                }
              );
            }
          }

          return templateResults;
        })
      );
  }

  create(template: Template): Observable<number> {
    return this.http.post(`${environment.apiTemplates}`, template).pipe(
      map((t: Template) => {
        return t ? t.id : -1;
      })
    );
  }

  update(template: Template): Observable<number> {
    return this.http
      .put(`${environment.apiTemplates}/${template.id}`, template)
      .pipe(map(rresponse => template.id));
  }

  createOrUpdate(template: Template): Observable<number> {
    if (template.id > 0) {
      return this.update(template);
    } else {
      return this.create(template);
    }
  }

  deleteTemplates(ids: number[]): Observable<boolean> {
    return forkJoin(
      ids.map((id: number) =>
        this.http.delete(`${environment.apiTemplates}/${id}`)
      )
    ).pipe(map(response => true));
  }

  private templateMapper(response: Template[]): Template[] {
    if (response && response.length > 0) {
      return response.map((template: Template) => {
        const t = Object.assign(new Template(), template);
        t.products = template.products
          ? template.products.map(p => Object.assign(new QuoteLineItem(), p))
          : [];
        return t;
      });
    }
    return [];
  }
}
